import { method } from "@/utils/request"

const api = {
  GetApprovalRecordList: "approval/record/list",
  GetApprovalCcRecordList: "approval/cc-record/list",
  GetApprovalRecordDetail: "approval/record/detail",
  PassApprovalRecord: "approval/record/pass"
}

const url = process.env.VUE_APP_API_URL

/**
 * @param parameter
 * @returns {*}
 */
export function getApprovalRecordList(parameter) {
  return method(url)({
    url: api.GetApprovalRecordList,
    method: "post",
    data: {
      ...parameter
    }
  })
}

export function getApprovalCcRecordList(parameter) {
  return method(url)({
    url: api.GetApprovalCcRecordList,
    method: "post",
    data: {
      ...parameter
    }
  })
}

export function getApprovalRecordDetail(parameter) {
  return method(url)({
    url: api.GetApprovalRecordDetail,
    method: "get",
    data: {
      ...parameter
    }
  })
}

export function passApprovalRecord(parameter) {
  return method(url)({
    url: api.PassApprovalRecord,
    method: "post",
    data: {
      ...parameter
    }
  })
}

export default {
  getApprovalRecordList,
  getApprovalCcRecordList,
  getApprovalRecordDetail,
  passApprovalRecord
}

import { method } from "@/utils/request"

const api = {
  GetProjectByCustomer: "project/customer",
  GetContractProjects: "project/contract",
  GetProjectDetail: "project/detail",
  GetProjectGoodsAnalysisList: "project/goods/analysis/list"
}

const url = process.env.VUE_APP_API_URL

/**
 * @param parameter
 * @returns {*}
 */
export function GetProjectByCustomer(parameter) {
  return method(url)({
    url: api.GetProjectByCustomer,
    method: "get",
    data: {
      ...parameter
    }
  })
}

export function GetContractProjects(parameter) {
  return method(url)({
    url: api.GetContractProjects,
    method: "get",
    data: {
      ...parameter
    }
  })
}

export function GetProjectDetail(parameter) {
  return method(url)({
    url: api.GetProjectDetail,
    method: "get",
    data: {
      ...parameter
    }
  })
}

export function GetProjectGoodsAnalysisList(parameter) {
  return method(url)({
    url: api.GetProjectGoodsAnalysisList,
    method: "post",
    data: {
      ...parameter
    }
  })
}

export default {
  GetProjectByCustomer,
  GetContractProjects,
  GetProjectDetail,
  GetProjectGoodsAnalysisList
}

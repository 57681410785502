import { method } from "@/utils/request"
import http from "@/api/approval/axios"

const api = {
  Get: "approval/template/list",
  Add: "approval/template",
  Del: "approval/template",
  Edit: "approval/template",
  GetDepartmentsByParentCode: "role/parent-code",
  AddApprovalTemplate: "approval/template",
  GetApprovalTemplate: "approval/template"
}

const url = process.env.VUE_APP_API_URL

/**
 * 获取部门
 * @param {*} data
 * @returns
 */
export function getDepartments(data) {
  return method(url)({
    url: api.GetDepartmentsByParentCode,
    method: "get",
    data
  })
}

/**
 * 获取职员
 * @param {*} data
 * @returns
 */
export function getEmployees(data) {
  return http.get(`${url}employees.json`, { params: data })
}

/**
 * 获取条件字段
 * @param {*} data
 * @returns
 */
export function getConditions(data) {
  return http.get(`${url}conditions.json`, { params: data })
}

/**
 * 获取审批数据
 * @param {*} data
 * @returns
 */
export function getWorkFlowData(data) {
  // return http.get(`${url}data.json`, { params: data })
  return method(url)({
    url: api.GetApprovalTemplate,
    method: "get",
    data: {
      approvalTemplateID: data.approvalTemplateID
    }
  })
}

function recursiveApprovalProcess(approvalProcessArr, obj, deep) {
  if (obj.type === 1) {
    deep++
  }
  if (obj.nodeUserList.length == 0) {
    return false
  }
  approvalProcessArr.push({
    type: obj.type,
    sequence: obj.type == 2 ? 0 : deep,
    approverID: Number(obj.nodeUserList[0].targetId)
  })

  if (Object.keys(obj.childNode).length > 0) {
    return recursiveApprovalProcess(approvalProcessArr, obj.childNode, deep)
  }
}

/**
 * 设置审批数据
 * @param {*} data
 * @returns
 */
export function setWorkFlowData(data) {
  const approvalTemplate = {}
  const approvalProcessArr = []

  approvalTemplate.templateName = data.workFlowDef.name

  const nodeData = data.nodeConfig.childNode
  let deep = 0
  recursiveApprovalProcess(approvalProcessArr, nodeData, deep)
  return method(url)({
    url: api.AddApprovalTemplate,
    method: "post",
    data: {
      approvalTemplate,
      approvalProcessArr
    }
  })
}

/**
 * @param parameter
 * @returns {*}
 */
export function add(parameter) {
  return method(url)({
    url: api.Add,
    method: "post",
    data: {
      ...parameter
    }
  })
}

/**
 * @param {*} parameter
 */
export function del(ids) {
  return method(url)({
    url: api.Del,
    method: "delete",
    data: { contactIDs: ids }
  })
}

/**
 * @param {*} parameter
 */
export function edit(parameter) {
  return method(url)({
    url: api.Edit,
    method: "put",
    data: parameter
  })
}

/**
 * @param {*} parameter
 */
export function get(parameter) {
  return method(url)({
    url: api.Get,
    method: "post",
    data: {
      pageNum: parameter.pageNum,
      pageSize: parameter.pageSize,
      templateName: parameter.templateName,
      approvalNum: Number(parameter.approvalNum),
      ccNum: Number(parameter.ccNum),
      creatorID: parameter.creatorID,
      times: parameter.times
    }
  })
}

export default {
  get,
  add,
  del,
  edit
}

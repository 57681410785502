import { getDepartments, getRoles } from "@/api/approval/api"
import { GetAllUserList } from "@/api/public-api"

export default {
  data() {
    return {
      visibleDialog: false,
      searchVal: "",
      activeName: "1",
      departments: {},
      roles: []
    }
  },
  methods: {
    async getRoleList() {
      let {
        data: { list }
      } = await getRoles()
      this.roles = list
    },
    async getDepartmentList(parentId = 0) {
      let { data } = await getDepartments({ parentId })
      this.departments = data
    },
    getDebounceData(event) {
      this.$func.debounce(
        async function() {
          const searchName = event
            ? event.target
              ? event.target.value
              : ""
            : ""
          this.departments.childDepartments = []
          let res = await GetAllUserList()
          if (res.code === 0) {
            this.departments.employees = res.result
              .filter(item => {
                if (!searchName) {
                  return true
                }
                return item.displayName
                  .toUpperCase()
                  .includes(searchName.toUpperCase())
              })
              .map(item => ({
                employeeName: item.displayName,
                id: item.userID
              }))
          }
        }.bind(this)
      )()
    }
  }
}

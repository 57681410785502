<template>
  <div class="app-container">
    <!-- 顶部搜索 -->
    <div class="head-container">
      <!-- 给crud的query添加参数，执行封装的toQuery方法实现查询 -->
      <a-form layout="inline">
        <a-row :gutter="48">
          <a-col :md="4" :sm="24">
            <a-form-item label="姓名">
              <a-input
                v-model="crud.query.displayName"
                allow-clear
                placeholder="请输入查询姓名"
              />
            </a-form-item>
          </a-col>

          <a-col :md="4" :sm="24">
            <a-form-item label="工 号">
              <a-input
                v-model="crud.query.workNo"
                allow-clear
                placeholder="请输入用户工号"
                style="width: 100%"
              />
            </a-form-item>
          </a-col>

          <a-col :md="4" :sm="24">
            <a-form-item label="手机号">
              <a-input
                v-model="crud.query.userTel"
                allow-clear
                placeholder="请输入查询手机号"
              />
            </a-form-item>
          </a-col>

          <a-col :md="4" :sm="24">
            <a-form-item label="角色">
              <a-select
                v-model="crud.query.roleID"
                allow-clear
                placeholder="请选择用户角色"
              >
                <a-select-option
                  v-for="item in roleData"
                  :key="item.roleID"
                  :value="item.roleID"
                  >{{ item.roleName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :md="4" :sm="24">
            <a-form-item label="登录账号">
              <a-input
                v-model="crud.query.userName"
                placeholder="请输入查询账号"
                style="width: 100%"
              />
            </a-form-item>
          </a-col>

          <a-col :md="4" :sm="24">
            <SearchOptions :toggleShowAdvance="false" style="width:100%" />
          </a-col>
        </a-row>
      </a-form>
    </div>
    <!-- 表格部分 -->
    <div class="table-container">
      <Operations tableName="账号" @toDiyAdd="toDiyAdd" />
      <a-spin :spinning="crud.loading">
        <a-table
          ref="table"
          :columns="crud.columns"
          :data-source="crud.data"
          :pagination="false"
          :row-selection="{
            selectedRowKeys: crud.selections,
            onChange: crud.selectionChangeHandler
          }"
          :scroll="{ x: 1200 }"
          rowKey="userID"
        >
          <template slot="roleName" slot-scope="record">
            <span v-for="(v, i) in record.role" :key="i">
              <span v-if="v.userType == 1" style="margin-right: 20px">
                {{ v.roleName }}
              </span>
              <span v-else style="color: red;margin-right: 20px">
                {{ v.roleName }}
              </span>
            </span>
          </template>
          <template slot="insertTime" slot-scope="record">
            {{ fullTimeFilter(record.insertTime) }}
          </template>
          <template slot="operation" slot-scope="record">
            <a-button
              :disabled="!$route.meta.code.includes('查看账号详情')"
              type="link"
              @click="showInfo(record)"
            >
              详情
            </a-button>
            |
            <a-button
              :disabled="!$route.meta.code.includes('修改密码')"
              type="link"
              @click="Rest(record)"
            >
              重置密码
            </a-button>
          </template>
        </a-table>
      </a-spin>
      <Pagination />
    </div>
    <!-- 填写表单部分 -->
    <AcountInfo
      ref="accountInfo"
      @submit-success-after="submitSuccessAfter"
    ></AcountInfo>
  </div>
</template>

<script>
import { find } from "@/api/system/role"
import apiAccount from "@/api/system/account"
import SearchOptions from "@/components/Crud/SearchOptions"
import Operations from "@/components/Crud/Operations"
import Pagination from "@/components/Crud/Pagination"
import AcountInfo from "@/components/account/index"
import CRUD, { header, presenter } from "@crud/crud"
import { BASE_OPERATE_TYPE, GRENDER_MAP } from "@/config"
import { fullTimeFilter } from "@/utils/utils"
import "vue-okr-tree/dist/vue-okr-tree.css"
import { accountListColumns } from "@/views/system/account-list"

export default {
  name: "Home",
  mixins: [presenter(), header()],
  cruds() {
    return CRUD({
      title: "账号",
      columns: accountListColumns,
      crudMethod: { ...apiAccount },
      idField: "userID", // 设置每一条记录的唯一标失
      queryFun: (data, crud) => {
        if (data.code === 0) {
          for (const key in data.result) {
            if (key.includes("list")) {
              let res = data.result[key]
              for (let i = 0; i < res.length; i++) {
                res[i]["key"] = res[i]["userID"] + ""
                res[i]["gender"] = GRENDER_MAP[res[i]["gender"] || "未知"]
                res[i]["birthDate"] =
                  res[i]["birthDate"] == "0001-01-01T00:00:00Z"
                    ? ""
                    : res[i]["birthDate"]
              }
              crud.data = res
            }
          }
          crud.page.total = data.result.count
          crud.resetDataStatus()
        }
      }
    })
  },
  filters: {},
  watch: {},
  data() {
    return {
      roleData: []
    }
  },
  components: { SearchOptions, Operations, Pagination, AcountInfo },
  async created() {
    let { code } = this.$route.meta
    // 关闭下载功能
    this.crud.optShow.download = false
    this.crud.optShow.edit = false
    // 显示以下按钮
    this.crud.permission = {
      add: code.includes("新增账号"),
      edit:
        code.includes("修改账户名") ||
        code.includes("修改账户角色及所属地") ||
        code.includes("修改账号基本信息"),
      del: code.includes("删除账号")
    }

    const roleAllList = await find()
    this.roleData = roleAllList.result
  },
  async mounted() {},
  methods: {
    fullTimeFilter: fullTimeFilter,
    showInfo(record) {
      this.$refs.accountInfo.showData(record.userID, BASE_OPERATE_TYPE.INFO)
    },
    toDiyAdd() {
      this.$refs.accountInfo.showData(0, BASE_OPERATE_TYPE.ADD)
    },
    Rest(record) {
      const that = this
      this.$confirm({
        title: "确认要重置密码吗?",
        content: `重置密码后，该用户仅能通过初始密码123456登录系统，首次登录需修改密码`,
        onOk() {
          apiAccount.reset(record.userID).then((res) => {
            console.log(res)
            if(res.code === 14 ){
              that.$message.success(res.message)
            }

          }).catch(res=> {
            this.$message.error(res.message)
          })
        },
        onCancel() {}
      })
    },
    submitSuccessAfter() {
      this.crud.resetQuery()
    }
  }
}
</script>
<style lang="scss"></style>

import { method } from "@/utils/request"

const api = {
  Add: "project",
  Get: "project/list",
  Del: "project",
  Edit: "project",
  GetProjectByCustomer: "project/customer"
}

const url = process.env.VUE_APP_API_URL

export function add(parameter) {
  return method(url)({
    url: api.Add,
    method: "post",
    data: {
      ...parameter
    }
  })
}

/**
 * @param {*} parameter
 */
export function edit(parameter) {
  return method(url)({
    url: api.Edit,
    method: "put",
    data: parameter
  })
}

/**
 * @param {*} parameter
 */
export function get(parameter) {
  parameter.type = parameter.type ? Number(parameter.type) : 0
  return method(url)({
    url: api.Get,
    method: "post",
    data: parameter
  })
}

export function GetProjectByCustomer(parameter) {
  return method(url)({
    url: api.GetProjectByCustomer,
    method: "get",
    data: { ...parameter }
  })
}

export default {
  add,
  edit,
  get
}

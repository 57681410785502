<template>
  <div class="_center_tree">
    <vue-okr-tree
      :data="treeData"
      animate
      current-lable-class-name=".label-bg-blue"
      default-expand-all
      direction="horizontal"
      label-class-name=".label-class-blue"
      show-collapsable
      showNodeNum
      @node-click="handleNodeClick"
    />
  </div>
</template>

<script>
import { VueOkrTree } from "vue-okr-tree"
import "vue-okr-tree/dist/vue-okr-tree.css"

export default {
  name: "RoleTree",
  components: { VueOkrTree },
  data() {
    return {
      roleList: [],
      treeData: [
        {
          label: "组织架构权限",
          id: 0,
          children: []
        }
      ]
    }
  },
  methods: {
    //初始化数据
    async initData(roleList) {
      const that = this
      this.roleList = [...roleList]
      this.$nextTick(() => {
        that.treeData = [
          {
            label: "组织架构权限",
            id: 0,
            children: []
          }
        ]
        that.recursiveSetTreeData(null)
        that.treeData = [...that.treeData]
      })
      that.$emit("update-selected-role", [that.roleList[0].roleID])
    },
    recursiveSetTreeData(parent) {
      if (!parent) {
        this.roleList.forEach(item => {
          if (item.parentID === 0) {
            this.treeData[0].children.push({
              label: item.roleName,
              id: item.roleID,
              parentID: 0,
              children: []
            })
          }
        })

        if (this.treeData[0].children.length === 0) {
          return
        }

        for (const childrenItem of this.treeData[0].children) {
          this.recursiveSetTreeData(childrenItem)
        }
      } else {
        const parentID = parent.id
        this.roleList.forEach(item => {
          if (item.parentID === parentID) {
            parent.children.push({
              label: item.roleName,
              id: item.roleID,
              parentID: parentID,
              children: []
            })
          }
        })

        if (parent.children.length === 0) {
          return
        }
        for (const childrenItem of parent.children) {
          this.recursiveSetTreeData(childrenItem)
        }
      }
    },
    handleNodeClick(data) {
      if (data.id === 0) {
        return
      }
      this.$emit("update-selected-role", [data.id])
    }
  }
}
</script>

<style lang="scss" scoped>
._center_tree {
  width: 100%;
  min-height: 200px;
  max-height: 400px;
  overflow: auto;
}

.label-bg-blue {
  background: #1989fa;
  color: #fff;
}

.label-class-blue {
  color: #1989fa;
}
</style>

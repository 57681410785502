export const targetListColumns = [
  {
    title: "目标名称",
    width: 200,
    align: "center",
    dataIndex: "name"
  },
  {
    title: "目标类型",
    width: 150,
    align: "center",
    scopedSlots: { customRender: "type" }
  },
  {
    title: "总指标",
    width: 120,
    align: "center",
    dataIndex: "totalValue"
  },
  {
    title: "完成指标",
    width: 120,
    align: "center",
    dataIndex: "completeValue"
  },
  {
    title: "剩余指标",
    width: 120,
    align: "center",
    scopedSlots: { customRender: "residualValue" }
  },
  {
    title: "指标单位",
    width: 100,
    align: "center",
    dataIndex: "unit"
  },
  {
    title: "所属类型",
    width: 100,
    align: "center",
    scopedSlots: { customRender: "belongType" }
  },
  {
    title: "所属部门",
    width: 180,
    align: "center",
    scopedSlots: { customRender: "roleID" }
  },
  {
    title: "所属个人",
    width: 180,
    align: "center",
    scopedSlots: { customRender: "userID" }
  },

  {
    title: "起始时间",
    width: 180,
    align: "center",
    scopedSlots: { customRender: "startTime" }
  },

  {
    title: "截止时间",
    width: 180,
    align: "center",
    scopedSlots: { customRender: "deadlineTime" }
  },
  {
    title: "创建日期",
    width: 180,
    align: "center",
    scopedSlots: { customRender: "insertTime" }
  },
  {
    title: "近期修改日期",
    width: 180,
    align: "center",
    scopedSlots: { customRender: "updateTime" }
  },
  {
    title: "操作",
    fixed: "right",
    align: "center",
    scopedSlots: { customRender: "operation" }
  }
]

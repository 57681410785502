export function blobGenerateExcel(res, fileName) {
  const blob = new Blob([res], {
    type: "application/vnd.ms-excel;charset=utf-8"
  })
  const downloadElement = document.createElement("a")
  const href = window.URL.createObjectURL(blob) //�������ص�����
  downloadElement.href = href
  downloadElement.download = `${fileName}.xls` //���غ��ļ���
  document.body.appendChild(downloadElement)
  downloadElement.click() //�������
  document.body.removeChild(downloadElement) //��������Ƴ�Ԫ��
  window.URL.revokeObjectURL(href) //�ͷŵ�blob����
}

<template>
  <div>
      <a-form-model ref="ruleForm"
                    :model="currentReceipt"
                    :rules="rules" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
        <a-row :gutter="20">
          <a-col :lg="8" :md="24" :sm="24" :xl="8" :xs="24" :xxl="8">
            <a-form-model-item label="收款单ID">
              <a-input v-model="currentReceipt.contactReceiptID" disabled/>
            </a-form-model-item>
          </a-col>
          <a-col :lg="8" :md="24" :sm="24" :xl="8" :xs="24" :xxl="8">
            <a-form-model-item label="收款单编号">
              <a-input v-model="currentReceipt.receiptNo" disabled/>
            </a-form-model-item>
          </a-col>
          <a-col :lg="8" :md="24" :sm="24" :xl="8" :xs="24" :xxl="8">
            <a-form-model-item label="合同ID">
              <a-input v-model="currentReceipt.contractId" disabled/>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="20">
          <a-col :lg="8" :md="24" :sm="24" :xl="8" :xs="24" :xxl="8">
            <a-form-model-item label="收款金额" prop="receiptMoney">
              <a-input v-model="currentReceipt.receiptMoney" :disabled="disabled"/>
            </a-form-model-item>
          </a-col>
          <a-col :lg="8" :md="24" :sm="24" :xl="8" :xs="24" :xxl="8">
            <a-form-model-item label="创建人">
              <a-input :value="allUserListMap(currentReceipt.creatorId) && allUserListMap(currentReceipt.creatorId).userName" disabled/>
            </a-form-model-item>
          </a-col>
          <a-col :lg="8" :md="24" :sm="24" :xl="8" :xs="24" :xxl="8">
            <a-form-model-item label="收款人时间" prop="receiptTime">
              <a-date-picker
                v-model="currentReceipt.receiptTime"
                show-time
                type="date"
                placeholder="选择时间"
                style="width: 100%;"
                :disabled="disabled"
              />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="20">
          <a-col :lg="8" :md="24" :sm="24" :xl="8" :xs="24" :xxl="8">
            <a-form-model-item label="合同编号">
              <a-input v-model="currentReceipt.contractNo" disabled/>
            </a-form-model-item>
          </a-col>
          <a-col :lg="8" :md="24" :sm="24" :xl="8" :xs="24" :xxl="8">
            <a-form-model-item label="收款方式" prop="receiptType">
              <a-select :disabled="disabled" v-model="currentReceipt.receiptType"  placeholder="请选择支付方式">
                <a-select-option v-for="item in receiptType" :key="item.key" :value="Number(item.key)">
                  {{item.value}}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
  </div>
</template>

<script>
import { fullTimeFilter } from "@/utils/utils"
import { GetDictionary, GetAllUserList } from "@/api/public-api";
export default {
  name: "CurrentReceiptInfo",
  data(){
    return {
      receiptType:[],
      disabled: true,
      currentReceipt: {},
      allUserList:[],
      rules:{
        receiptType:[
          {required:true, message:'请选择输入方式',trigger:'change'}
        ],
        receiptMoney:[
          {required:true, message:'请输入收款单金额',trigger:'blur'}
        ],
        receiptTime:[
          {required:true, message:'请选择时间',trigger:'change'}
        ]
      },
    }
  },
  async created() {
    const resultObj = await GetDictionary({ typeName: "contractReceiptType" })
    const allUserListObj = await GetAllUserList()
    this.allUserList = allUserListObj.result
    this.receiptType = resultObj.result

  },
  methods:{
    fullTimeFilter,
    allUserListMap(num){
     return  this.allUserList.find(item=> item.userID === num)
    },

  }
}
</script>

<style scoped>

</style>
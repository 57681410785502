export const KeywordListColumns = [
  {
    title: "关键字",
    width: 190,
    align: "center",
    ellipsis: true,
    resizable: true,
    dataIndex: "value",
    key: "value"
  },
  {
    title: "创建者",
    width: 120,
    align: "center",
    ellipsis: true,
    resizable: true,
    dataIndex: "creator",
    key: "creator"
  },
  {
    title: "创建日期",
    width: 160,
    ellipsis: true,
    align: "center",
    resizable: true,
    scopedSlots: { customRender: "insertTime" },
    key: "insertTime"
  },
  {
    title: "修改日期",
    width: 160,
    ellipsis: true,
    align: "center",
    resizable: true,
    scopedSlots: { customRender: "updateTime" },
    key: "updateTime"
  },
  {
    title: "操作",
    fixed: "right",
    align: "center",
    width: 150,
    scopedSlots: { customRender: "operation" }
  }
]

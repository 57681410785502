 import { method } from "@/utils/request"

export const FileOperateAPI = {
  FollowUpFileUpload: "file-upload/follow-up",
  FollowUpFileRemoved: "file-upload/follow-up",
  FollowUpAllFileCancel: "file-upload/follow-up/cancel",

  ContractFileUpload: "file-upload/contract",
  ContractFileRemoved: "file-upload/contract",
  ContractAllFileCancel: "file-upload/contract/cancel"
}

const url = process.env.VUE_APP_API_URL

export function FileUpload(path, parameter) {
  return method(
    url,
    true,
    "json",
    "form"
  )({
    url: path,
    method: "post",
    data: parameter
  })
}

export function FileRemoved(path, parameter) {
  return method(url)({
    url: path,
    method: "delete",
    data: parameter
  })
}

export function AllFileCancel(path, parameter) {
  return method(url)({
    url: path,
    method: "delete",
    data: parameter
  })
}

export default {
  FileOperateAPI,
  FileUpload,
  FileRemoved,
  AllFileCancel
}

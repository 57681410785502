/*
 * @Description: 服务车
 * @Author: yuyongjie
 * @Date: 2020-12-26 17:51:20
 * @LastEditors: yuyongjie
 * @LastEditTime: 2020-12-29 22:21:53
 */
import { method } from "@/utils/request"

const api = {
  GetDictionary: "dictionary",
  GetAllRoleList: "role/all-list",
  GetAllUserList: "user/all-list",
  GetAllCustomerName: "customer/all-name",
  GetAllCustomer: "customer/all",
  GetAllProject: "project/all",
  GetAllKeyWord: "keyword/all",
  GetAllSourcePlatform: "source-platform/all",
  GetAllContact: "contact/all",
  GetAllConversation: "conversation/all"
}

let url = process.env.VUE_APP_API_URL

export function GetDictionary(data) {
  return method(url)({
    url: api.GetDictionary,
    method: "get",
    data: {
      typeName: data.typeName
    }
  })
}

export function GetAllRoleList() {
  return method(url)({
    url: api.GetAllRoleList,
    method: "get"
  })
}

export function GetAllCustomer() {
  return method(url)({
    url: api.GetAllCustomer,
    method: "get"
  })
}

export function GetAllCustomerName() {
  return method(url)({
    url: api.GetAllCustomerName,
    method: "get"
  })
}

export function GetAllUserList() {
  return method(url)({
    url: api.GetAllUserList,
    method: "get"
  })
}

export function GetAllKeyWord() {
  return method(url)({
    url: api.GetAllKeyWord,
    method: "get"
  })
}

export function GetAllProject() {
  return method(url)({
    url: api.GetAllProject,
    method: "get"
  })
}

export function GetAllSourcePlatform() {
  return method(url)({
    url: api.GetAllSourcePlatform,
    method: "get"
  })
}

export function GetAllConversation() {
  return method(url)({
    url: api.GetAllConversation,
    method: "get"
  })
}

export function GetAllContact() {
  return method(url)({
    url: api.GetAllContact,
    method: "get"
  })
}

export default {
  GetDictionary,
  GetAllRoleList,
  GetAllCustomerName,
  GetAllKeyWord,
  GetAllSourcePlatform,
  GetAllConversation,
  GetAllContact,
  GetAllCustomer,
  GetAllProject
}

<template>
  <div class="app-container">
    <el-collapse :value="showCollapse" class="my-collapse">
      <el-collapse-item :name="1" title="移入公海审批抄送">
        <IntoOpenSea></IntoOpenSea>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
import IntoOpenSea from "@/components/TodoList/intoOpenSea"

export default {
  components: { IntoOpenSea },
  data() {
    return {
      showCollapse: [1]
    }
  },

  async created() {},
  methods: {}
}
</script>

<style lang="scss" scoped>
.my-collapse {
  margin-bottom: 20px;

  .el-collapse-item {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 5px;

    /deep/ .el-collapse-item__content {
      padding-bottom: 0;
    }

    /deep/ .el-collapse-item__header {
      padding-left: 20px;
    }

    .item-content {
      background-color: #f0f2f5;
      padding: 10px;
    }
  }
}
</style>

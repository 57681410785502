<template>
  <div>
    <a-form-model
      ref="ruleForm"
      :model="addAttachmentInfo"
      :rules="rules"
      :label-col="{ span: 4 }"
      :wrapper-col="{ span: 20 }"
    >
      <a-row>
        <a-col :span="12">
          <a-form-model-item
            label="公司名称"
            prop="customerID"
            ref="customerID"
          >
            <a-select
              showSearch
              allowClear
              v-model="addAttachmentInfo.customerID"
              placeholder="请选择公司名称"
              :filter-option="filterOption"
              @change="toGetContract"
            >
              <a-select-option
                v-for="item in allCustomer"
                :key="item.customerID"
                :value="item.customerID"
              >
                {{ item.customerName }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="合同ID" prop="contractID" ref="contractID">
            <a-select
              showSearch
              allowClear
              v-model="addAttachmentInfo.contractID"
              placeholder="请选择合同ID"
              :filter-option="filterOption"
            >
              <a-select-option
                v-for="item in connectContract"
                :key="item.contractID"
                :value="item.contractID"
              >
                {{ item.contactId }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="产品ID" prop="projectID" ref="projectID">
            <a-select
              showSearch
              allowClear
              v-model="addAttachmentInfo.projectID"
              placeholder="请选择合同ID"
              :filter-option="filterOption"
            >
              <a-select-option
                v-for="item in currentAllProject"
                :key="item.projectID"
                :value="item.projectID"
              >
                {{ item.projectID }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="附件类型" prop="attachmentType">
            <a-select
              v-model="addAttachmentInfo.attachmentType"
              placeholder="请选择附件类型"
            >
              <a-select-option
                v-for="item in attachmentType"
                :key="item.key"
                :value="parseInt(item.key)"
              >
                {{ item.value }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="24">
          <a-form-model-item
            label="上传文件"
            prop="uploadFile"
            :labelCol="{ span: 2 }"
            :wrapperCol="{ span: 22 }"
          >
            <SingleUploadFile
              :customerID="addAttachmentInfo.customerID"
              fileUploadPath="file-upload/attachment"
              fileRemovedPath="file-upload/attachment"
              allFileCancelPath="file-upload/attachment/cancel"
              ref="singleUploadFile"
            ></SingleUploadFile>
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
  </div>
</template>

<script>
import { GetCustomerAll } from "@/api/customer/customer"
import { GetContractByCustomer } from "@/api/customer/contract"
import { GetProjectByCustomer } from "@/api/customer/project"
import { GetDictionary } from "@/api/public-api"
import SingleUploadFile from "@/components/SingleUploadFile/index.vue"

export default {
  name: "add-attachment",
  data() {
    return {
      rules: {
        customerID: [
          { required: true, message: "请选择公司名称", trigger: "blur" }
        ],
        contractID: [
          { required: true, message: "请选择关联合同", trigger: "blur" }
        ],
        projectID: [
          { required: true, message: "请选择产品ID", trigger: "blur" }
        ],
        attachmentType: [
          { required: true, message: "请选择附件类型", trigger: "blur" }
        ]
      },
      addAttachmentInfo: {},
      allCustomer: [],
      connectContract: [],
      currentAllProject: [],
      attachmentType: []
    }
  },
  components: {
    SingleUploadFile
  },
  methods: {
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
    async toGetContract() {
      const contractListObj = await GetContractByCustomer({
        customerID: this.addAttachmentInfo.customerID
      })
      const projectListObj = await GetProjectByCustomer({
        customerID: this.addAttachmentInfo.customerID
      })
      if (contractListObj.code === 0) {
        this.connectContract = contractListObj.result
      }
      if (projectListObj.code === 0) {
        this.currentAllProject = projectListObj.result
      }
    }
  },
  async created() {
    const customerAllNameObj = await GetCustomerAll()
    const resultObj = await GetDictionary({ typeName: "attachmentType" })
    this.attachmentType = resultObj.result
    this.allCustomer = customerAllNameObj.result
  }
}
</script>

<style scoped></style>

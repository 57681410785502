import { method } from "@/utils/request"

const api = {
  Get: "follow-up/list",
  Add: "follow-up",
  Del: "follow-up",
  Edit: "follow-up",
  getFollowUpDetailById: "follow-up/follow-up-id",
  Analysis: "follow-up/analysis",
  GetCustomerListByFollowUpUserID: "customer/follow-up/user-id",
  GetFollowUpRecordAnalysis: "follow-up/records/analysis",
  AddFollowUpRecord: "follow-up",
  GetCustomerFollowUpRecords: "follow-up/customer"
}

const url = process.env.VUE_APP_API_URL

/**
 * @param parameter
 * @returns {*}
 */
export function add(parameter) {
  return method(url)({
    url: api.Add,
    method: "post",
    data: {
      ...parameter
    }
  })
}

/**
 * @param {*} parameter
 */
export function del(ids) {
  return method(url)({
    url: api.Del,
    method: "delete",
    data: { contactIDs: ids }
  })
}

/**
 * @param {*} parameter
 */
export function edit(parameter) {
  return method(url)({
    url: api.Edit,
    method: "put",
    data: parameter
  })
}

/**
 * @param {*} parameter
 */
export function get(parameter) {
  return method(url)({
    url: api.Get,
    method: "post",
    data: parameter
  })
}

export function analysis(parameter) {
  return method(url)({
    url: api.Analysis,
    method: "get",
    data: parameter
  })
}

export function getCustomerListByFollowUpUserID(parameter) {
  return method(url)({
    url: api.GetCustomerListByFollowUpUserID,
    method: "get",
    data: parameter
  })
}

export function GetFollowUpRecordAnalysis(parameter) {
  return method(url)({
    url: api.GetFollowUpRecordAnalysis,
    method: "post",
    data: parameter
  })
}

export function AddFollowUpRecord(data) {
  return method(url)({
    url: api.AddFollowUpRecord,
    method: "post",
    data
  })
}

export function GetCustomerFollowUpRecords(customerID) {
  return method(url)({
    url: api.GetCustomerFollowUpRecords,
    method: "get",
    data: {
      customerID
    }
  })
}

export function GetFollowUpDetailById(followUpID) {
  return method(url)({
    url: api.getFollowUpDetailById,
    method: "get",
    data: {
      followUpID
    }
  })
}

export default {
  get,
  add,
  del,
  edit,
  analysis,
  getCustomerListByFollowUpUserID,
  GetFollowUpRecordAnalysis,
  AddFollowUpRecord,
  GetCustomerFollowUpRecords,
  GetFollowUpDetailById
}

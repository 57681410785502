import { method } from "@/utils/request"

const api = {
  Add: "dictionary",
  Del: "dictionary",
  Edit: "dictionary",
  Get: "dictionary/list",
  GetTypeName: "dictionary/type-name",
  GetDictionaryInfo:"dictionary/dictionary-id"
}

const url = process.env.VUE_APP_API_URL

/**
 * login func
 * @param parameter
 * @returns {*}
 */
export function add(parameter) {
  return method(url)({
    url: api.Add,
    method: "post",
    data: { ...parameter }
  })
}

/**
 * @param {*} parameter
 */
export function del(ids) {
  return method(url)({
    url: api.Del,
    method: "delete",
    data: {
      dictionaryIDs: [...ids]
    }
  })
}

/**
 * @param {*} parameter
 */
export function edit(parameter) {
  return method(url)({
    url: api.Edit,
    method: "put",
    data: { ...parameter }
  })
}

/**
 * @param {*} parameter
 */
export function get(parameter) {
  return method(url)({
    url: api.Get,
    method: "post",
    data: { ...parameter }
  })
}

export function getTypeName() {
  return method(url)({
    url: api.GetTypeName,
    method: "get",
    data: {}
  })
}

export function getDictionaryInfo(parameter) {
  return method(url)({
    url: api.GetDictionaryInfo,
    method: "get",
    data: {...parameter}
  })
}

export default {
  api,
  // getStationList,
  add,
  del,
  edit,
  get,
  getTypeName
}

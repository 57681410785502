<template>
  <div>
    <a-modal
      title="新增收款单"
      :visible="toShowFlag"
      :confirm-loading="false"
      width="80%"
      @cancel="handleCancel"
    >
      <a-row :gutter="48" v-if="currentReceipt">
        <a-col :lg="24" :md="24" :sm="24" :xl="24" :xs="24" :xxl="24">
          <div
            style="display: flex;justify-content: flex-start;vertical-align: middle;align-items: center"
          >
            <h3 style="color: #0b64f4;">合同关联收款单</h3>
          </div>
        </a-col>
      </a-row>
      <a-card v-if="currentReceipt" :body-style="{ padding: '0px' }" :bordered="false" class="card">
        <a-table
          :columns="receiptColumns"
          :data-source="currentReceipt"
          :pagination="false"
          bordered
        >
          <template slot="date" slot-scope="record">
            <a>{{fullTimeFilterYYYYMMDD(record.receiptTime) }}</a>
          </template>
          <template slot="amount" slot-scope="record">
            {{ record.receiptMoney }}
          </template>
          <template slot="time" slot-scope="record">
            {{ fullTimeFilter(record.receiptTime) }}
          </template>
          <!--          <template slot="footer" slot-scope="">-->
          <!--            Footer-->
          <!--          </template>-->
          <template slot="operation" slot-scope="record, recorded">
            <a-popconfirm
              title="确认删除吗?"
              @confirm="() => onDelete(recorded.key)"
            >
              <a href="javascript:;">删除</a>
            </a-popconfirm>
          </template>
        </a-table>
      </a-card>
      <a-row :gutter="48" v-if="currentReceipt">
        <a-col :lg="24" :md="24" :sm="24" :xl="24" :xs="24" :xxl="24">
          <div
            style="display: flex;justify-content: flex-start;vertical-align: middle;align-items: center"
          >
            <h3 style="color: #0b64f4;">合同详情</h3>
          </div>
        </a-col>
      </a-row>
      <a-collapse v-if="currentReceipt" v-model="activeKey">
        <a-collapse-panel key="1" :header="currentContract && currentContract.contractNo">
          <a-form-model
            :label-col="{ span: 6 }"
            :wrapper-col="{ span: 18 }"
            labelAlign="left"
          >
          <a-row :gutter="40">
            <a-col :lg="5" :md="24" :sm="24" :xl="5" :xs="24" :xxl="5">
              <a-form-model-item label="客户名称">
                <span>{{ currentContract.customerName }}</span>
              </a-form-model-item>
            </a-col>
            <a-col :lg="4" :md="24" :sm="24" :xl="4" :xs="24" :xxl="4">
              <a-form-model-item
                :label-col="{ span: 8 }"
                :wrapper-col="{ span: 16 }"
                label="客户联系人"
              >

                <span>{{currentContract.contactId}}</span>
              </a-form-model-item>
            </a-col>
            <a-col :lg="5" :md="24" :sm="24" :xl="5" :xs="24" :xxl="5">
              <a-form-model-item
                :label-col="{ span: 8 }"
                :wrapper-col="{ span: 16 }"
                label="销售负责人"
              >
                <span >{{currentContract.creatorID}}</span>
              </a-form-model-item>
            </a-col>
            <a-col :lg="4" :md="24" :sm="24" :xl="4" :xs="24" :xxl="4">
              <a-form-model-item label="合同编号">

                <span>{{ currentContract.contractNo }}</span>
              </a-form-model-item>
            </a-col>
            <a-col :lg="4" :md="24" :sm="24" :xl="4" :xs="24" :xxl="4">
              <a-form-model-item
                :label-col="{ span: 10 }"
                :wrapper-col="{ span: 14 }"
                label="商务对接方式"
              >
                <span>{{currentContract.businessConnectType}}</span>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row :gutter="40">
            <a-col :lg="4" :md="24" :sm="24" :xl="4" :xs="24" :xxl="4">
              <a-form-model-item
                :label-col="{ span: 10 }"
                :wrapper-col="{ span: 14 }"
                label="商务成交方式"
              >
                <span>{{currentContract.businessCooperationType}}</span>
              </a-form-model-item>
            </a-col>
            <a-col :lg="4" :md="24" :sm="24" :xl="4" :xs="24" :xxl="4">
              <a-form-model-item
                :label-col="{ span: 10 }"
                :wrapper-col="{ span: 14 }"
                label="质保金额"
              >
                <span >{{ currentContract.qualityGuaranteeMoney }}</span>
              </a-form-model-item>
            </a-col>

            <a-col :lg="5" :md="24" :sm="24" :xl="5" :xs="24" :xxl="5">
              <a-form-model-item
                :label-col="{ span: 8 }"
                :wrapper-col="{ span: 16 }"
                label="质保到期时间"
                labelAlign="left"
              >

                <span>{{ filterTime(currentContract.signTime) }}</span>
              </a-form-model-item>
            </a-col>

            <a-col :lg="5" :md="24" :sm="24" :xl="5" :xs="24" :xxl="5">
              <a-form-model-item
                label="签约日期"
                labelAlign="left"
                prop="signTime"
              >
                <span>{{ filterTime(currentContract.signTime) }}</span>
              </a-form-model-item>
            </a-col>

            <a-col :lg="5" :md="24" :sm="24" :xl="5" :xs="24" :xxl="5">
              <a-form-model-item label="交付日期:" prop="releasedTime">
                <span>{{ filterTime(currentContract.signTime) }}</span>
              </a-form-model-item>
            </a-col>
          </a-row>
          </a-form-model>
        </a-collapse-panel>
      </a-collapse>
      <a-row :gutter="48" style="margin-top: 20px">
        <a-col :lg="24" :md="24" :sm="24" :xl="24" :xs="24" :xxl="24">
          <div
            style="display: flex;justify-content: flex-start;vertical-align: middle;align-items: center"
          >
            <h3 style="color: #0b64f4;">填写表单信息</h3>
          </div>
        </a-col>
      </a-row>
      <a-form-model
        ref="ruleForm"
        :model="receiptAdd"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"

      >
        <a-row>
          <a-col :span="12">
            <a-form-model-item ref="contractID" label="合同id" prop="contractID">
              <a-input
                type="number"
                placeholder="请输入合同id"
                v-model.number="receiptAdd.contractID"
                @blur="
          () => {
            $refs.contractID.onFieldBlur();
          }
        "
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item ref="receiptMoney" label="收款金额" prop="receiptMoney">
              <a-input
                type="number"
                placeholder="请输入金额"
                v-model.number="receiptAdd.receiptMoney"
                @blur="
          () => {
            $refs.receiptMoney.onFieldBlur();
          }
        "
              />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-model-item label="收款方式" prop="receiptType">
              <a-select v-model="receiptAdd.receiptType"  placeholder="请选择支付方式">
                <a-select-option v-for="item in contractReceiptType" :key="item.key" :value="Number(item.key)">
                  {{item.value}}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="收款时间" prop="receiptTime">
              <a-date-picker
                v-model="receiptAdd.receiptTime"
                show-time
                type="date"
                placeholder="选择时间"
                style="width: 100%;"
              />
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
      <template slot="footer">
        <a-button key="back" @click="handleCancel">
          取消
        </a-button>
        <a-button key="submit" type="primary" @click="onSubmit">
          提交
        </a-button>
      </template>
    </a-modal>
  </div>
</template>
<script>
import { receiptColumns } from "@/components/customerInfo/contract-list"
import { getGetContractDetail,} from "@/api/customer/contract"
import {add} from "@/api/customer/receipt"
import {GetReceiptByContractId} from "@/api/customer/receipt"
// import { message } from "ant-design-vue"
import { fullTimeFilterYYYYMMDD, fullTimeFilter} from "@/utils/utils"
import { GetDictionary } from "@/api/public-api";
export default {
  name: "ReceiptAdd",
  data() {
    return {
      customerID:'',
      toShowFlag: false,
      receiptColumns,
      selectedRowKeys: [],
      currentReceipt: [],
      currentContract:{},
      activeKey: ["0"],
      contractId: "",
      receiptAdd: {
        contractID: '',
        receiptMoney: '',
        receiptTime: "",
        receiptType: ''
      },
      contractReceiptType:[],
      labelCol: { span: 8},
      wrapperCol: { span: 10 },
      rules: {
        contractID: [
          { required: true, message: '请输入合同id', trigger: 'blur' },
        ],
        receiptMoney: [
          { required: true, message: '请输入收款金额', trigger: 'blur' },
        ],
        region: [{ required: true, message: '请选择支付方式', trigger: 'change' }],
        receiptTime: [{ required: true, message: '请选择一个时间', trigger: 'change' }],


    }
    }
  },
  async created() {
    const resultObj = await GetDictionary({ typeName: "contractReceiptType" })
    this.contractReceiptType = resultObj.result

  },
  mounted() {},
  watch: {
    async toShowFlag(newValue) {
      if(newValue == true) {
        const receiptObj = await GetReceiptByContractId({
          contractID: this.contractId
        })
        const contractResult = await getGetContractDetail({
          contractID: this.contractId
        })
        this.currentContract = contractResult.result
        this.currentReceipt = receiptObj.result
      }
    }
  },
  methods: {
    handleCancel() {
      this.toShowFlag = false
      this.$refs.ruleForm.clearValidate()
      this.$parent.getReceiptInfo()
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },
    fullTimeFilterYYYYMMDD,
    fullTimeFilter,
    filterTime: timeStr => fullTimeFilter(timeStr),
    onSubmit() {
      const that = this
      this.$refs.ruleForm.validate(async valid => {
        if (valid) {
          const result = await add(this.receiptAdd)
          if(result.code===12){
            this.toShowFlag = false
            this.$parent.getReceiptInfo()
            const receiptObj = await GetReceiptByContractId({
              contractID: that.contractId
            })
            this.receiptAdd.receiptType = ''
            this.receiptAdd.receiptMoney = ''
            this.receiptAdd.receiptTime = ''
            this.receiptAdd.contractID = ''
            that.currentReceipt = receiptObj.result
          }
        } else {
          return false;
        }
      });
    },
  }
}
</script>

export const contractListColumns = [
  {
    title: "合同编号",
    width: 200,
    align: "center",
    ellipsis: true,
    resizable: true,
    // dataIndex: "customerName",
    key: "customerName",
    scopedSlots: { customRender: "contractNo" }
  },
  {
    title: "公司名称",
    width: 200,
    align: "center",
    ellipsis: true,
    resizable: true,
    // dataIndex: "customerName",
    key: "customerName",
    scopedSlots: { customRender: "customerName" }
  },
  {
    title: "客户联系人",
    width: 120,
    align: "center",
    ellipsis: true,
    resizable: true,
    dataIndex: "contactName",
    key: "contactName"
  },
  {
    title: "客户联系方式",
    width: 180,
    align: "center",
    ellipsis: true,
    resizable: true,
    dataIndex: "contactTel",
    key: "contactTel"
  },
  {
    title: "本次合同跟进数",
    width: 160,
    align: "center",
    ellipsis: true,
    resizable: true,
    dataIndex: "followupNum",
    key: "followupNum"
  },
  {
    title: "对接方式",
    width: 120,
    align: "center",
    ellipsis: true,
    resizable: true,
    dataIndex: "businessConnectTypeName",
    key: "businessConnectTypeName"
  },
  {
    title: "成交方式",
    width: 100,
    align: "center",
    ellipsis: true,
    resizable: true,
    dataIndex: "businessCooperationTypeName",
    key: "businessCooperationTypeName"
  },
  {
    title: "创建者",
    width: 120,
    align: "center",
    ellipsis: true,
    resizable: true,
    dataIndex: "creator",
    key: "creator"
  },
  {
    title: "签署时间",
    width: 120,
    align: "center",
    ellipsis: true,
    resizable: true,
    scopedSlots: { customRender: "signTime" },
    key: "signTime"
  },
  {
    title: "交付时间",
    width: 120,
    align: "center",
    ellipsis: true,
    resizable: true,
    scopedSlots: { customRender: "releasedTime" },
    key: "releasedTime"
  },
  {
    title: "数据生成时间",
    width: 180,
    align: "center",
    ellipsis: true,
    resizable: true,
    scopedSlots: { customRender: "insertTime" },
    key: "insertTime"
  },
  {
    title: "上次修改时间",
    width: 180,
    align: "center",
    ellipsis: true,
    resizable: true,
    scopedSlots: { customRender: "updateTime" },
    key: "updateTime"
  }
]

export const contractUserSignColumns = [
  {
    title: "排名",
    width: 80,
    align: "center",
    ellipsis: true,
    // dataIndex: "no",
    key: "no",
    scopedSlots: { customRender: "no" }
  },

  {
    title: "用户名",
    width: 100,
    align: "center",
    ellipsis: true,
    dataIndex: "userName",
    key: "userName"
    // scopedSlots: { customRender: "contractNo" }
  },
  {
    title: "签约数量",
    width: 100,
    align: "center",
    ellipsis: true,
    dataIndex: "count",
    key: "count"
    // scopedSlots: { customRender: "customerName" }
  }
]

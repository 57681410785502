export const ApprovalListColumns = [
  {
    title: "审批名称",
    width: 180,
    align: "center",
    ellipsis: true,
    resizable: true,
    dataIndex: "templateName",
    key: "templateName"
  },
  {
    title: "审批次数",
    width: 90,
    align: "center",
    ellipsis: true,
    resizable: true,
    dataIndex: "approvalNum",
    key: "approvalNum"
  },
  {
    title: "抄送次数",
    width: 90,
    align: "center",
    ellipsis: true,
    resizable: true,
    dataIndex: "ccNum",
    key: "ccNum"
  },
  {
    title: "创建者账号",
    width: 120,
    align: "center",
    ellipsis: true,
    resizable: true,
    dataIndex: "creatorUserName",
    key: "creatorUserName"
  },
  {
    title: "创建者",
    width: 100,
    align: "center",
    ellipsis: true,
    resizable: true,
    dataIndex: "creatorDisplayName",
    key: "creatorDisplayName"
  },
  {
    title: "创建日期",
    width: 160,
    ellipsis: true,
    align: "center",
    resizable: true,
    scopedSlots: { customRender: "insertTime" },
    key: "insertTime"
  },
  {
    title: "修改日期",
    width: 160,
    ellipsis: true,
    align: "center",
    resizable: true,
    scopedSlots: { customRender: "updateTime" },
    key: "updateTime"
  },
  {
    title: "操作",
    // fixed: "right",
    align: "center",
    width: 150,
    scopedSlots: { customRender: "operation" }
  }
]

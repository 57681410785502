<template>
  <div>
    <a-card :body-style="{ padding: '0px' }" :bordered="false">
      <a-row>
        <a-col :lg="24" :md="24" :sm="24" :xl="24" :xs="24" :xxl="24">
          <div
            style="display: flex;justify-content: flex-end;vertical-align: middle;align-items: center;margin-bottom: 20px"
          >
            <a-button
              :disabled="disableFormItem()"
              type="primary"
              @click="addContact"
            >
              新增联系人
            </a-button>
          </div>
        </a-col>

        <a-col :lg="24" :md="24" :sm="24" :xl="24" :xs="24" :xxl="24">
          <a-table
            :columns="contactColumns"
            :dataSource="currentContact"
            :scroll="{
              y: 300,
              scrollToFirstRowOnChange: true
            }"
            rowKey="contactID"
          >
            <template
              slot="contactName"
              slot-scope="text, recordContact, index"
            >
              <el-autocomplete
                v-model="recordContact.contactName"
                :disabled="disableFormItem()"
                :fetch-suggestions="changeFilterContactName"
                :trigger-on-focus="false"
                placeholder="请输入联系人姓名"
                style="width: 100%"
                @select="selectContact($event, index)"
              >
                <template slot-scope="{ item }">
                  <div class="name">{{ item.contactName }}</div>
                  <span class="addr">{{ item.contactTel }}</span>
                </template>
              </el-autocomplete>
            </template>
            <template slot="contactTel" slot-scope="text, recordContact, index">
              <el-autocomplete
                v-model="recordContact.contactTel"
                :disabled="disableFormItem()"
                :fetch-suggestions="changeFilterContactTel"
                :trigger-on-focus="false"
                placeholder="请输入联系方式"
                style="width: 100%"
                @select="selectContact($event, index)"
              >
                <template slot-scope="{ item }">
                  <div class="name">{{ item.contactName }}</div>
                  <span class="addr">{{ item.contactTel }}</span>
                </template>
              </el-autocomplete>
            </template>
            <template slot="contactType" slot-scope="recordContact">
              <a-select
                v-if="!disableFormItem()"
                v-model="recordContact.contactType"
                :allowClear="true"
                :filterOption="filterCategory"
                :not-found-content="null"
                :show-arrow="false"
                placeholder="选择联系人类型"
                productionSourceMap
                show-search
                style="width: 100%"
              >
                <a-select-option
                  v-for="item in allContactTypeMap"
                  :key="Number(item.key)"
                  :value="Number(item.key)"
                  >{{ item.value }}
                </a-select-option>
              </a-select>
              <el-tag
                v-else
                :type="recordContact.contactType === 1 ? '' : 'info'"
              >
                {{
              allContactTypeMap.find(
                item => Number(item.key) === recordContact.contactType
              )
              &&
              allContactTypeMap.find(
                    item => Number(item.key) === recordContact.contactType
                  ).value
                }}
              </el-tag>
            </template>
            <template slot="operation" slot-scope="text, recordContact, index">
              <a-button
                :disabled="disableFormItem()"
                type="link"
                @click="deleteContact(recordContact, index)"
              >
                删除
              </a-button>
            </template>
          </a-table>
        </a-col>
        <a-col :lg="24" :md="24" :sm="24" :xl="24" :xs="24" :xxl="24">
          <div
            style="display: flex;justify-content: center;vertical-align: middle;align-items: center;margin-top: 50px;margin-bottom: 20px"
          >
            <a-button
              v-if="disableFormItem()"
              key="submit"
              style="width:200px"
              @click="toEditContact()"
            >
              修改
            </a-button>
            <a-button
              v-if="!disableFormItem()"
              key="back"
              style="width:200px"
              @click="cancelSaveContact"
            >
              取消
            </a-button>
            <a-button
              v-if="!disableFormItem()"
              style="margin-left: 50px;width: 200px"
              type="primary"
              @click="saveContact"
            >
              保存
            </a-button>
          </div>
        </a-col>
      </a-row>
    </a-card>
  </div>
</template>

<script>
import { BASE_OPERATE_TYPE } from "@/config"
import { contactColumns } from "@/views/customer/customer-list"
import { GetAllContact, GetDictionary } from "@/api/public-api"
import { GetCustomerContact, saveContact } from "@/api/customer/customer"

export default {
  name: "ContractTabPane",
  data() {
    return {
      currentContact: [],
      currentCustomerID: 0,
      operateType: BASE_OPERATE_TYPE.INFO,
      allContactMap: [],
      BASE_OPERATE_TYPE,
      contactColumns: contactColumns,
      allContactTypeMap: []
    }
  },
  props: {},
  components: {},
  async created() {
    const [getAllContactTypeRes, getAllContactRes] = await Promise.all([
      GetDictionary({ typeName: "contactType" }),
      GetAllContact()
    ])
    if (getAllContactRes.code === 0) {
      this.allContactMap = getAllContactRes.result
    }
    if (getAllContactTypeRes.code === 0) {
      this.allContactTypeMap = getAllContactTypeRes.result
    }
  },
  methods: {
    filterCategory: function(value, option) {
      return option.componentOptions.children[0].text.indexOf(value) >= 0
    },
    disableFormItem() {
      const that = this
      return that.operateType == BASE_OPERATE_TYPE.INFO
    },
    changeFilterContactName(input, cb) {
      const that = this
      const result = [...that.allContactMap].filter(
        item =>
          input === "" ||
          item.contactName.toUpperCase().includes(input.toUpperCase())
      )
      cb(result)
    },
    changeFilterContactTel(input, cb) {
      const that = this
      const result = [...that.allContactMap].filter(
        item =>
          input === "" ||
          item.contactTel.toUpperCase().includes(input.toUpperCase())
      )
      cb(result)
    },
    selectContact(e, index) {
      this.currentContact[index].contactName = e.contactName
      this.currentContact[index].contactID = e.contactID
      this.currentContact[index].contactTel = e.contactTel
      this.$forceUpdate()
    },
    async showData(customerID, operateType = BASE_OPERATE_TYPE.INFO) {
      const that = this
      if (that.operateType !== BASE_OPERATE_TYPE.EDIT) {
        that.operateType = operateType
        if (BASE_OPERATE_TYPE.INFO === operateType) {
          const contactRes = await GetCustomerContact({ customerID })
          if (contactRes.code === 0) {
            that.currentContact = [...contactRes.result]
            that.currentCustomerID = customerID
          }
        } else if (BASE_OPERATE_TYPE.ADD === operateType) {
          that.currentContact = []
          that.currentCustomerID = 0
        }
      }
    },
    addContact() {
      this.currentContact.push({
        contactID: 0,
        contactName: "",
        contactTel: "",
        contactType: 2
      })
    },
    async deleteContact(recordContact, index) {
      delete this.currentContact[index]
      this.currentContact = this.currentContact.filter(item => item != null)
      this.$forceUpdate()
    },
    toEditContact() {
      this.operateType = BASE_OPERATE_TYPE.EDIT
    },
    async saveContact() {
      const that = this
      if (!that.currentCustomerID) {
        that.$message.error("请先创建客户基本信息并提交!")
        return
      }
      const contacts = that.currentContact.filter(
        item => item.contactName && item.contactTel && item.contactType
      )
      const saveRes = await saveContact({
        contacts: contacts,
        customerID: that.currentCustomerID
      })
      if (saveRes.code !== 0) {
        that.$message.error("修改/新增企业联系人失败")
        return
      }
      this.currentContact = [...saveRes.result]
      this.operateType = BASE_OPERATE_TYPE.INFO
      this.$forceUpdate()
    },
    cancelSaveContact() {
      const that = this
      that.operateType = BASE_OPERATE_TYPE.INFO
      that.showData(that.currentCustomerID)
    }
  }
}
</script>

<style scoped>
.card1 {
  margin-top: 20px;
}

.card2 {
  margin-top: 20px;
}

.ant-table-striped /deep/ .table-striped td {
  background-color: #fcfcfc;
}
</style>

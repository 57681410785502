import Vue from "vue"

import {
  Autocomplete,
  Breadcrumb as ElBreadcrumb,
  BreadcrumbItem as ElBreadcrumbItem,
  Button as ElButton,
  Card as ElCard,
  Checkbox as ElCheckbox,
  CheckboxGroup as ElCheckboxGroup,
  Col as ElCol,
  Collapse as ElCollapse,
  CollapseItem as ElCollapseItem,
  DatePicker as ElDatePicker,
  Dialog as ElDialog,
  Drawer as ElDrawer,
  Image,
  Option as ElOption,
  Popover as ElPopover,
  Radio as ElRadio,
  RadioButton as ElRadioButton,
  RadioGroup as ElRadioGroup,
  Row as ElRow,
  Select as ElSelect,
  Slider as ElSlider,
  TabPane as ElTabPane,
  Tabs as ElTabs,
  Tag as ElTag,
  Timeline as ElTimeline,
  TimelineItem as ELTimelineItem,
  TimePicker as ElTimePicker,
  Tree as ElTree
} from "element-ui" // antd 框架的input没有搜索  所以需要使用
import "element-ui/lib/theme-chalk/index.css"

import AmapVue from "@amap/amap-vue"
// base library
import {
  Breadcrumb,
  Button,
  Card,
  Checkbox,
  Col,
  Collapse,
  ConfigProvider,
  DatePicker,
  Divider,
  Drawer,
  Dropdown,
  Empty,
  Form,
  FormModel,
  Icon,
  Input,
  InputNumber,
  Layout,
  Menu,
  message,
  Modal,
  notification,
  Pagination,
  Popconfirm,
  Popover,
  Radio,
  Rate,
  Result,
  Row,
  Select,
  Spin,
  Steps,
  Switch,
  Table,
  Tabs,
  Tooltip,
  Tree,
  Upload
} from "ant-design-vue"

AmapVue.config.version = "2.0" // 默认2.0，这里可以不修改
AmapVue.config.key = "90d0866b76659e5d054f24e8ac3fd803"
AmapVue.config.plugins = [
  "AMap.ToolBar",
  "AMap.MoveAnimation"
  // 在此配置你需要预加载的插件，如果不配置，在使用到的时候会自动异步加载
]
Vue.use(AmapVue)

Vue.use(ElDatePicker)
Vue.use(ElTimePicker)
Vue.use(ElRow)
Vue.use(ElCol)
Vue.use(ElButton)
Vue.use(ElSlider)
Vue.use(ElSelect)
Vue.use(ElOption)
Vue.use(ElTag)
Vue.use(Image)
Vue.use(Autocomplete)

Vue.use(ElPopover)
Vue.use(ElDialog)
Vue.use(ElDrawer)
Vue.use(ElTree)
Vue.use(ElRadio)
Vue.use(ElRadioGroup)
Vue.use(ElRadioButton)
Vue.use(ElCheckbox)
Vue.use(ElCheckboxGroup)
Vue.use(ElBreadcrumb)
Vue.use(ElBreadcrumbItem)
Vue.use(ElTabs)
Vue.use(ElTabPane)
Vue.use(ElTimeline)
Vue.use(ELTimelineItem)
Vue.use(ElCard)
Vue.use(ElCollapse)
Vue.use(ElCollapseItem)

// Vue.prototype.$elMessage = {
//   error(msg) {
//     ElMessage.closeAll()
//     ElMessage.error(msg)
//   },
//   success(msg) {
//     ElMessage.closeAll()
//     ElMessage.success(msg)
//   }
// }

// ext library
Vue.use(Card)
Vue.use(Button)
Vue.use(Row)
Vue.use(Col)
Vue.use(Menu)
Vue.use(Layout)
Vue.use(Pagination)
Vue.use(Drawer)
Vue.use(ConfigProvider)
Vue.use(Breadcrumb)
Vue.use(Result)
Vue.use(Modal)
Vue.use(Checkbox)
Vue.use(Input)
Vue.use(Switch)
Vue.use(Tooltip)
Vue.use(Tabs)
Vue.use(Popover)
Vue.use(Select)
Vue.use(Radio)
Vue.use(Icon)
Vue.use(FormModel)
Vue.use(Dropdown)
Vue.use(Form)
Vue.use(InputNumber)
Vue.use(DatePicker)
Vue.use(Table)
Vue.use(Spin)
Vue.use(Tree)
Vue.use(Popconfirm)
Vue.use(Divider)
Vue.use(Upload)
Vue.use(Collapse)
Vue.use(Steps)
Vue.use(Empty)
Vue.use(Rate)

Vue.prototype.$message = message
Vue.prototype.$notification = notification
Vue.prototype.$confirm = Modal.confirm
Vue.prototype.$error = Modal.error

process.env.NODE_ENV !== "production" &&
  console.warn("[antd-pro] NOTICE: Antd use lazy-load.")

import Vue from "vue"
import App from "./App.vue"
import store from "./store"
import _ from "lodash"
import echarts from "echarts"
import "normalize.css/normalize.css"
import permission from "./directive/Permission"
import "./router/permission"
import "./utils/lazy_use"
import func from "@/api/approval/preload"
import nodeWrap from "@/components/Approval/nodeWrap"
import addNode from "@/components/Approval/addNode"
import router from "./router"

Vue.component("nodeWrap", nodeWrap) //初始化组件

Vue.component("addNode", addNode) //初始化组件
Vue.prototype.$func = func
Vue.directive("enterNumber", {
  bind: function(el, { value = 2 }) {
    el = el.nodeName == "INPUT" ? el : el.children[0]
    var RegStr =
      value == 0
        ? `^[\\+\\-]?\\d+\\d{0,0}`
        : `^[\\+\\-]?\\d+\\.?\\d{0,${value}}`
    el.addEventListener("keyup", function() {
      el.value = el.value.match(new RegExp(RegStr, "g"))
      el.dispatchEvent(new Event("input"))
    })
  }
})
Vue.directive("focus", {
  // 当被绑定的元素插入到 DOM 中时……
  inserted: function(el) {
    el.focus()
  }
})

Vue.config.productionTip = false

Vue.prototype.$_ = _
Vue.prototype.$echarts = echarts

Vue.use(permission)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app")

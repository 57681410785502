export const IntoOpenSeaApprovalColumns = [
  {
    title: "客户名称",
    width: 120,
    align: "center",
    ellipsis: true,
    resizable: true,
    scopedSlots: { customRender: "customerName" },
    // dataIndex: "customerName",
    key: "customerName"
  },
  {
    title: "发起者",
    width: 60,
    align: "center",
    ellipsis: true,
    resizable: true,
    dataIndex: "displayName",
    key: "displayName"
  },
  {
    title: "状态",
    width: 60,
    align: "center",
    ellipsis: true,
    resizable: true,
    scopedSlots: { customRender: "status" },
    // dataIndex: "displayName",
    key: "status"
  },
  {
    title: "审批内容",
    width: 180,
    align: "center",
    ellipsis: true,
    resizable: true,
    dataIndex: "content",
    key: "content"
  },
  {
    title: "发起日期",
    width: 100,
    ellipsis: true,
    align: "center",
    resizable: true,
    scopedSlots: { customRender: "insertTime" },
    key: "insertTime"
  },
  {
    title: "操作",
    fixed: "right",
    align: "center",
    width: 80,
    scopedSlots: { customRender: "operation" }
  }
]

export const IntoOpenSeaCopyInColumns = [
  {
    title: "客户名称",
    width: 120,
    align: "center",
    ellipsis: true,
    resizable: true,
    // dataIndex: "customerName",
    scopedSlots: { customRender: "customerName" },
    key: "customerName"
  },
  {
    title: "发起者",
    width: 60,
    align: "center",
    ellipsis: true,
    resizable: true,
    dataIndex: "displayName",
    key: "displayName"
  },
  {
    title: "状态",
    width: 60,
    align: "center",
    ellipsis: true,
    resizable: true,
    scopedSlots: { customRender: "status" },
    // dataIndex: "displayName",
    key: "status"
  },
  {
    title: "审批内容",
    width: 180,
    align: "center",
    ellipsis: true,
    resizable: true,
    dataIndex: "content",
    key: "content"
  },
  {
    title: "发起日期",
    width: 100,
    ellipsis: true,
    align: "center",
    resizable: true,
    scopedSlots: { customRender: "insertTime" },
    key: "insertTime"
  },
  {
    title: "操作",
    fixed: "right",
    align: "center",
    width: 80,
    scopedSlots: { customRender: "operation" }
  }
]

<template>
  <div class="app-container">
    <div class="head-container">
      <a-form layout="inline">
        <a-row :gutter="40">
          <a-col :md="3" :sm="24">
            <a-form-item label="姓名">
              <a-input
                v-model="crud.query.contactName"
                allow-clear
                placeholder="请输入联系人姓名"
              />
            </a-form-item>
          </a-col>

          <a-col :md="4" :sm="24">
            <a-form-item label="联系方式">
              <a-input
                v-model="crud.query.contactTel"
                allow-clear
                placeholder="请输入联系方式"
              />
            </a-form-item>
          </a-col>

          <a-col :md="4" :sm="24">
            <a-form-item label="所属企业"
                         :labelCol="{span: 8}"
                         :wrapperCol="{span:16}"
            >
              <a-select
                v-model="crud.query.customerID"
                :filterOption="filterCategory"
                allow-clear
                placeholder="请选择所属企业"
                show-search
              >
                <a-select-option
                  v-for="item in allCustomerMap"
                  :key="item.customerID"
                  :value="item.customerID"
                  >{{ item.customerName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :md="4" :sm="24">
            <a-form-item label="关联项目">
              <a-select
                v-model="crud.query.projectID"
                :filterOption="filterCategory"
                allow-clear
                placeholder="请选择关联项目"
                show-search
              >
                <a-select-option
                  v-for="item in allProjectMap"
                  :key="item.projectID"
                  :value="item.projectID"
                  >{{ item.projectNo }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :md="5" :sm="24">
            <a-form-item label="创建时间">
              <a-range-picker
                v-model="crud.query.times"
                :allowClear="true"
                allow-clear
                format="YYYY-MM-DD"
              />
            </a-form-item>
          </a-col>

          <a-col :md="4" :sm="24">
            <SearchOptions :toggleShowAdvance="false" style="width: 100%" />
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div class="table-container">
      <Operations :queryFuncName="queryFuncName" tableName="联系人" />
      <a-spin :spinning="crud.loading">
        <a-table
          :columns="crud.columns"
          :data-source="crud.data"
          :pagination="false"
          :row-selection="{
            selectedRowKeys: crud.selections,
            onChange: crud.selectionChangeHandler,
            getCheckboxProps: getCheckboxProps
          }"
          :scroll="{ y: 1000 }"
          rowKey="contactID"
        >
          <template slot="customers" slot-scope="record">
            {{ record.customers.map(item => item.customerName).join(",") }}
          </template>

          <template slot="insertTime" slot-scope="record">
            {{ filterTime(record.insertTime) }}
          </template>

          <template slot="updateTime" slot-scope="record">
            {{ filterTime(record.updateTime) }}
          </template>

          <template slot="operation" slot-scope="record">
            <a-button
              :disabled="!$route.meta.code.includes('查看联系人详情')"
              type="link"
              @click="toInfo(record)"
            >
              详情
            </a-button>
          </template>
        </a-table>
      </a-spin>
      <Pagination />
    </div>
    <!-- 填写表单部分 -->
    <div class="form-container">
      <a-modal
        :afterClose="
          () => {
            form = {}
            crud.refresh()
          }
        "
        :title="crud.status.title"
        :visible="crud.status.add > 0"
        wrapClassName="stationListDialog"
        @cancel="crud.cancelCU()"
      >
        <a-form-model
          ref="addForm"
          :class="
            crud.status.in > 0 && crud.status.cu === 0 ? 'disabled-form' : ''
          "
          :label-col="{ span: 6 }"
          :model="form"
          :rules="addRules"
          :wrapper-col="{ span: 18 }"
          labelAlign="left"
        >
          <a-form-model-item label="联系人姓名" prop="contactName">
            <el-autocomplete
              v-model="form.contactName"
              :fetch-suggestions="changeFilterContactName"
              :trigger-on-focus="false"
              allow-clear
              placeholder="请输入联系人姓名"
              style="width: 100%"
            >
              <template slot-scope="{ item }">
                <div class="name">{{ item.contactName }}</div>
                <span class="addr">{{ item.contactTel }}</span>
              </template>
            </el-autocomplete>
          </a-form-model-item>
          <a-form-model-item label="联系方式" prop="contactTel">
            <el-autocomplete
              v-model="form.contactTel"
              :fetch-suggestions="changeFilterContactTel"
              :trigger-on-focus="false"
              allow-clear
              placeholder="请输入联系方式"
              style="width: 100%"
            >
              <template slot-scope="{ item }">
                <div class="name">{{ item.contactName }}</div>
                <span class="addr">{{ item.contactTel }}</span>
              </template>
            </el-autocomplete>
          </a-form-model-item>
          <a-form-model-item label="关联企业客户" prop="customerID">
            <a-select
              v-model="form.customerID"
              :allowClear="true"
              :filterOption="filterCategory"
              :not-found-content="null"
              :show-arrow="false"
              placeholder="请选择关联的企业客户"
              show-search
            >
              <a-select-option
                v-for="item in allCustomerMap"
                :key="item.customerID"
                :value="item.customerID"
                >{{ item.customerName }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item
            v-if="form.customerID"
            label="所属企业角色"
            prop="customerContactType"
          >
            <a-select
              v-model="form.customerContactType"
              :allowClear="true"
              :filterOption="filterCategory"
              :not-found-content="null"
              :show-arrow="false"
              placeholder="请选择关联项目的角色"
              show-search
            >
              <a-select-option
                v-for="item in allUserTypeMap"
                :key="Number(item.key)"
                :value="Number(item.key)"
                >{{ item.value }}
              </a-select-option>
            </a-select>
          </a-form-model-item>

          <a-form-model-item label="关联项目" prop="projectID">
            <a-select
              v-model="form.projectID"
              :allowClear="true"
              :filterOption="filterCategory"
              :not-found-content="null"
              :show-arrow="false"
              placeholder="请选择关联项目"
              show-search
            >
              <a-select-option
                v-for="item in allProjectMap"
                :key="item.projectID"
                :value="item.projectID"
                >{{ item.projectNo }}
              </a-select-option>
            </a-select>
          </a-form-model-item>

          <a-form-model-item
            v-if="form.projectID"
            label="所属项目角色"
            prop="projectContactType"
          >
            <a-select
              v-model="form.projectContactType"
              :allowClear="true"
              :filterOption="filterCategory"
              :not-found-content="null"
              :show-arrow="false"
              placeholder="请选择关联项目的角色"
              show-search
            >
              <a-select-option
                v-for="item in allUserTypeMap"
                :key="Number(item.key)"
                :value="Number(item.key)"
                >{{ item.value }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-form-model>
        <template slot="footer">
          <div v-if="crud.status.cu > 0">
            <a-button
              v-if="crud.status.add > 0"
              key="back"
              @click="crud.cancelCU()"
            >
              取消
            </a-button>
            <a-button
              v-if="crud.status.add > 0"
              key="submit"
              type="primary"
              @click="addContact"
            >
              提交
            </a-button>
          </div>
        </template>
      </a-modal>
      <a-drawer
        :title="crud.status.title"
        :visible="
          (crud.status.cu > 0 || crud.status.in > 0) && crud.status.add == 0
        "
        :width="1200"
        placement="right"
        @close="crud.cancelCU()"
      >
        <a-tabs
          :activeKey="activeKey"
          type="card"
          @change="
            key => {
              activeKey = key
            }
          "
        >
          <a-tab-pane :key="1" tab="联系人信息">
            <a-card :body-style="{ padding: '0px' }" :bordered="false">
              <a-form-model
                ref="updateForm"
                :class="
                  crud.status.in > 0 && crud.status.cu === 0
                    ? 'disabled-form'
                    : ''
                "
                :label-col="{ span: 6 }"
                :model="form"
                :rules="addRules"
                :wrapper-col="{ span: 18 }"
                labelAlign="left"
              >
                <a-row :gutter="20">
                  <a-col :lg="8" :md="24" :sm="24" :xl="8" :xs="24" :xxl="8">
                    <a-form-model-item label="联系人姓名" prop="contactName">
                      <el-autocomplete
                        v-model="form.contactName"
                        :disabled="crud.status.in > 0 && crud.status.cu === 0"
                        :fetch-suggestions="changeFilterContactName"
                        :trigger-on-focus="false"
                        allow-clear
                        placeholder="请输入联系人姓名"
                        style="width: 100%"
                      >
                        <template slot-scope="{ item }">
                          <div class="name">{{ item.contactName }}</div>
                          <span class="addr">{{ item.contactTel }}</span>
                        </template>
                      </el-autocomplete>
                    </a-form-model-item>
                  </a-col>

                  <a-col :lg="8" :md="24" :sm="24" :xl="8" :xs="24" :xxl="8">
                    <a-form-model-item label="联系方式" prop="contactTel">
                      <el-autocomplete
                        v-model="form.contactTel"
                        :disabled="crud.status.in > 0 && crud.status.cu === 0"
                        :fetch-suggestions="changeFilterContactTel"
                        :trigger-on-focus="false"
                        allow-clear
                        placeholder="请输入联系方式"
                        style="width: 100%"
                      >
                        <template slot-scope="{ item }">
                          <div class="name">{{ item.contactName }}</div>
                          <span class="addr">{{ item.contactTel }}</span>
                        </template>
                      </el-autocomplete>
                    </a-form-model-item>
                  </a-col>

                  <a-col :lg="8" :md="24" :sm="24" :xl="8" :xs="24" :xxl="8">
                    <a-form-model-item
                      v-if="form.creatorID && form.creatorID > 0"
                      label="创建者"
                      prop="creatorDisplayName"
                    >
                      <a-input
                        v-model="form.creatorDisplayName"
                        :disabled="true"
                      />
                    </a-form-model-item>
                  </a-col>
                  <a-col :lg="8" :md="24" :sm="24" :xl="8" :xs="24" :xxl="8">
                    <a-form-model-item
                      v-if="form.creatorID && form.creatorID > 0"
                      label="最近修改日期"
                      prop="updateTime"
                    >
                      {{ filterTime(form.updateTime) }}
                    </a-form-model-item>
                  </a-col>
                  <a-col :lg="8" :md="24" :sm="24" :xl="8" :xs="24" :xxl="8">
                    <a-form-model-item
                      v-if="form.creatorID && form.creatorID > 0"
                      label="创建日期"
                      prop="insertTime"
                    >
                      {{ filterTime(form.insertTime) }}
                    </a-form-model-item>
                  </a-col>
                </a-row>
                <a-row style="margin-top: 20px">
                  <a-col :lg="24" :md="24" :sm="24" :xl="24" :xs="24" :xxl="24">
                    <div
                      style="display: flex;justify-content: flex-start;vertical-align: middle;align-items: center"
                    >
                      <h3 style="color: #0b64f4;">联系人关联客户信息</h3>
                    </div>
                  </a-col>
                </a-row>
                <a-row>
                  <a-col :lg="24" :md="24" :sm="24" :xl="24" :xs="24" :xxl="24">
                    <a-table
                      :columns="ContactCustomerListColumns"
                      :dataSource="form.customers"
                      :scroll="{
                        y: 300,
                        scrollToFirstRowOnChange: true
                      }"
                      height="200px"
                      rowKey="customerID"
                    >
                      <template slot="contactType" slot-scope="record">
                        <el-tag :type="record.contactType === 1 ? '' : 'info'">
                          {{
                            allUserTypeMap.find(
                              item => Number(item.key) === record.contactType
                            ).value
                          }}
                        </el-tag>
                      </template>
                      <template slot="tag" slot-scope="record">
                        {{
                          allCustomerTagMap.find(
                            item => Number(item.key) === record.tag
                          ).value
                        }}
                      </template>
                    </a-table>
                  </a-col>
                </a-row>

                <a-row style="margin-top: 20px">
                  <a-col :lg="24" :md="24" :sm="24" :xl="24" :xs="24" :xxl="24">
                    <div
                      style="display: flex;justify-content: flex-start;vertical-align: middle;align-items: center"
                    >
                      <h3 style="color: #0b64f4;">联系人关联项目信息</h3>
                    </div>
                  </a-col>
                </a-row>
                <a-row>
                  <a-col :lg="24" :md="24" :sm="24" :xl="24" :xs="24" :xxl="24">
                    <a-table
                      :columns="ContactProjectListColumns"
                      :dataSource="form.projects"
                      :scroll="{
                        y: 300,
                        scrollToFirstRowOnChange: true
                      }"
                      height="200px"
                      rowKey="customerID"
                    >
                      <template slot="contactType" slot-scope="record">
                        <el-tag :type="record.contactType === 1 ? '' : 'info'">
                          {{
                            allUserTypeMap.find(
                              item => Number(item.key) === record.contactType
                            ).value
                          }}
                        </el-tag>
                      </template>
                    </a-table>
                  </a-col>
                </a-row>
                <a-row>
                  <a-col :lg="24" :md="24" :sm="24" :xl="24" :xs="24" :xxl="24">
                    <div
                      style="display: flex;justify-content: center;vertical-align: middle;align-items: center;margin-top: 50px;margin-bottom: 20px"
                    >
                      <a-button
                        v-if="crud.status.add == 0 && crud.status.edit == 0"
                        key="submit"
                        :disabled="!crud.permission.edit"
                        style="width:200px"
                        @click="crud.toEdit()"
                      >
                        修改
                      </a-button>
                      <a-button
                        v-if="crud.status.edit > 0"
                        key="back"
                        style="width:200px"
                        @click="crud.cancelCU('uptoinfo')"
                      >
                        取消
                      </a-button>
                      <a-button
                        :disabled="crud.status.in > 0 && crud.status.cu === 0"
                        style="margin-left: 50px;width: 200px"
                        type="primary"
                        @click="updateContact"
                      >
                        保存
                      </a-button>
                    </div>
                  </a-col>
                </a-row>
              </a-form-model>
            </a-card>

            <template slot="footer">
              <div v-if="crud.status.cu > 0">
                <a-button
                  v-if="crud.status.add > 0"
                  key="back"
                  @click="crud.cancelCU()"
                >
                  取消
                </a-button>
                <a-button
                  v-if="crud.status.add > 0"
                  key="submit"
                  type="primary"
                  @click="addContact"
                >
                  提交
                </a-button>
              </div>
            </template>
          </a-tab-pane>
        </a-tabs>
      </a-drawer>
    </div>
  </div>
</template>

<script>
import SearchOptions from "@/components/Crud/SearchOptions"
import Operations from "@/components/Crud/Operations"
import Pagination from "@/components/Crud/Pagination"
import CRUD, { form, header, presenter } from "@crud/crud"
import Api from "@/api/customer/contact"
import { ADMIN_ROLE_ID } from "@/config"
import { fullTimeFilter } from "@/utils/utils"
import store from "@/store"
import {
  ContactCustomerListColumns,
  ContactListColumns,
  ContactProjectListColumns
} from "@/views/customer/contact-list"
import {
  GetAllContact,
  GetAllCustomer,
  GetAllProject,
  GetDictionary
} from "@/api/public-api"

// 表格的填充字段
const defaultForm = {
  contactName: "",
  contactTel: ""
}
export default {
  name: "Contact",
  // presenter 生成crud对象
  mixins: [presenter(), header(), form(defaultForm)],
  cruds() {
    return CRUD({
      title: "联系人",
      roleIDs: [],
      columns: ContactListColumns,
      crudMethod: { ...Api },
      idField: "contactID" // 设置每一条记录的唯一标失
    })
  },
  data() {
    const checkContactName = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请填写联系人姓名!"))
      }
      callback()
    }
    const checkContactTel = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请填写联系人联系方式！"))
      }

      if (
        this.crud.status.add > 0 &&
        this.allContactMap.find(item => item.contactTel === value)
      ) {
        return callback(new Error("联系方式已重复!"))
      }

      const that = this
      if (
        this.crud.status.edit > 0 &&
        this.allContactMap.find(
          item =>
            item.contactTel === value && item.contactID !== that.form.contactID
        )
      ) {
        return callback(new Error("联系方式已重复!"))
      }

      callback()
    }
    const checkCustomerContactType = (rule, value, callback) => {
      if (this.form.customerID && !value) {
        return callback(new Error("请选择联系人所属企业的角色!"))
      }
      callback()
    }
    const checkProjectContactType = (rule, value, callback) => {
      if (this.form.projectID && !value) {
        return callback(new Error("请选择联系人所属项目的角色！"))
      }
      callback()
    }

    return {
      roleIDs: [],
      queryFuncName: "查询联系人列表",
      activeKey: 1,
      addRules: {
        contactName: [
          { required: true, validator: checkContactName, trigger: "blur" }
        ],
        contactTel: [
          { required: true, validator: checkContactTel, trigger: "blur" }
        ],
        customerContactType: [
          {
            required: false,
            validator: checkCustomerContactType,
            trigger: "blur"
          }
        ],
        projectContactType: [
          {
            required: false,
            validator: checkProjectContactType,
            trigger: "blur"
          }
        ]
      },

      ContactListColumns,
      ContactCustomerListColumns,
      ContactProjectListColumns,
      allContactMap: [],
      allCustomerMap: [],
      allProjectMap: [],
      allUserTypeMap: [],
      allCustomerTagMap: []
    }
  },
  components: { SearchOptions, Operations, Pagination },
  async created() {
    /**
     * 设置按钮权限
     */
    let { code } = this.$route.meta
    // 关闭下载功能
    // this.crud.optShow.download = false
    this.crud.optShow.edit = false
    // 显示以下按钮
    this.crud.permission = {
      add: code.includes("新增联系人"),
      del: code.includes("删除联系人"),
      edit: code.includes("修改联系人")
      // download: true
    }

    const [
      getAllContactRes,
      getAllCustomerRes,
      getAllProjectRes,
      getUserTypeRes,
      getCustomerTagRes
    ] = await Promise.all([
      GetAllContact(),
      GetAllCustomer(),
      GetAllProject(),
      GetDictionary({ typeName: "customerUserType" }),
      GetDictionary({ typeName: "customerTag" })
    ])

    if (getAllContactRes.code === 0) {
      this.allContactMap = getAllContactRes.result
    }

    if (getAllCustomerRes.code === 0) {
      this.allCustomerMap = getAllCustomerRes.result
    }

    if (getAllProjectRes.code === 0) {
      this.allProjectMap = getAllProjectRes.result
    }

    if (getUserTypeRes.code === 0) {
      this.allUserTypeMap = getUserTypeRes.result
    }

    if (getCustomerTagRes.code === 0) {
      this.allCustomerTagMap = getCustomerTagRes.result
    }

    this.roleIDs = store.getters.roleIDs
  },
  methods: {
    filterTime: timeStr => fullTimeFilter(timeStr),
    startTimeChange(dates, dateStrings) {
      if (dateStrings[0] && dateStrings[1]) {
        let time1 = dateStrings[0]
        let time2 = dateStrings[1]
        if (
          new Date(time2.replace(/-/g, "/")).getTime() / 1000 -
            (new Date(time1.replace(/-/g, "/")).getTime() / 1000 +
              24 * 60 * 60) >
          365 * 24 * 60 * 60
        ) {
          this.$message.info("请选择一年内的时间！")
          this.crud.query.startTimes = [time1, time1]
          return
        }
      }
      this.crud.query.startTimes = dateStrings
    },
    deadlineTimeChange(dates, dateStrings) {
      if (dateStrings[0] && dateStrings[1]) {
        let time1 = dateStrings[0]
        let time2 = dateStrings[1]
        if (
          new Date(time2.replace(/-/g, "/")).getTime() / 1000 -
            (new Date(time1.replace(/-/g, "/")).getTime() / 1000 +
              24 * 60 * 60) >
          365 * 24 * 60 * 60
        ) {
          this.$message.info("请选择一年内的时间！")
          this.crud.query.deadlineTimes = [time1, time1]
          return
        }
      }
      this.crud.query.deadlineTimes = dateStrings
    },
    getCheckboxProps(record) {
      if (record.roleID === 0 && !this.roleIDs.includes(ADMIN_ROLE_ID)) {
        return { props: { disabled: true } }
      }
      return { props: { disabled: false } }
    },
    updateOrDeleteAllow(data) {
      return (
        this.crud.status.in > 0 &&
        this.crud.status.cu === 0 &&
        (this.roleIDs.includes(ADMIN_ROLE_ID) ||
          this.roleIDs.includes(data.roleID))
      )
    },
    toInfo(record) {
      this.crud.toInfo(record)
    },
    filterCategory: function(value, option) {
      return option.componentOptions.children[0].text.indexOf(value) >= 0
    },
    changeFilterContactName(input, cb) {
      const that = this
      const result = [...that.allContactMap].filter(
        item =>
          input === "" ||
          item.contactName.toUpperCase().includes(input.toUpperCase())
      )
      cb(result)
    },
    changeFilterContactTel(input, cb) {
      const that = this
      const result = [...that.allContactMap].filter(
        item =>
          input === "" ||
          item.contactTel.toUpperCase().includes(input.toUpperCase())
      )
      cb(result)
    },
    addContact() {
      const that = this
      this.$refs["addForm"].validate(async valid => {
        if (!valid) {
          return
        }
        const addRes = await Api.add(that.form)
        if (addRes.code === 12) {
          that.$message.success("新增成功")
          that.crud.cancelCU()
          that.crud.refresh()
        }
      })
    },
    updateContact() {
      const that = this
      this.$refs["updateForm"].validate(async valid => {
        if (!valid) {
          return
        }
        const contactName = that.form.contactName
        const contactTel = that.form.contactTel
        const addRes = await Api.edit(that.form)
        if (addRes.code === 14) {
          that.$message.success("修改成功")
          that.crud.cancelCU("uptoinfo")
          that.crud.form.contactName = contactName
          that.crud.form.contactTel = contactTel
          that.crud.refresh()
        }
      })
    }
  }
}
</script>

import { method } from "@/utils/request"

const api = {
  CustomerMap: "analysis/customer/map"
}

const url = process.env.VUE_APP_API_URL

/**
 * @param parameter
 * @returns {*}
 */
export function customerMap() {
  return method(url)({
    url: api.CustomerMap,
    method: "get"
  })
}

export default {
  customerMap
}

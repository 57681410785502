import { method } from "@/utils/request"

const api = {
  Get: "invoice/list/customer-id",
  Post: "invoice",
  Edit: "invoice",
  Del: "invoice",
  GetByContractId: "invoice/list/contract-id",
  Put:"invoice",
  GetDetail: "invoice/detail"
}

const url = process.env.VUE_APP_API_URL

export function GetInvoiceListByCustomerId(parameter) {
  return method(url)({
    url: api.Get,
    method: "get",
    data: parameter
  })
}

export function GetInvoiceListByContractId(parameter) {
  return method(url)({
    url: api.GetByContractId,
    method: "get",
    data: parameter
  })
}

export function AddInvoiceByContractId(parameter) {
  return method(url)({
    url: api.Post,
    method: "post",
    data: { ...parameter }
  })
}

export function editInvoiceInfo(parameter){
  return method(url)({
    url: api.Put,
    method: "put",
    data: { ...parameter }
  })
}

export function GetInvoiceDetailById(parameter) {
  return method(url)({
    url: api.GetDetail,
    method: "get",
    data: parameter
  })
}

export function edit(parameter) {
  return method(url)({
    url: api.Edit,
    method: "put",
    data: parameter
  })
}

export function delInvoice(parameter) {
  return method(url)({
    url: api.Del,
    method: "delete",
    data: parameter
  })
}
import request, { method } from "@/utils/request"
import _ from "lodash"

const api = {
  Add: "user/add",
  Del: "user",
  Edit: "user",
  updateUser: "user",
  Get: "user/list",
  Reset: "user/reset-pwd",
  GetUserDetail: "user/detail/user-id",
  UpdateUserLoginName: "user/login-name",
  UpdateUserRoleAndArea: "user/role",
  UpdateUserFunctions: "user/functions",
  getStationList: "station/user/list"
}

/**
 * @param {*} parameter
 */
export function reset(id) {
  return request({
    url: api.Reset,
    method: "post",
    data: { userID: id }
  })
}

/**
 * getrole func
 * @param parameter
 * @returns {*}
 */
// export function getStationList(parameter) {
//     return request({
//         url: api.getStationList,
//         method: "get",
//         data: parameter
//     })
// }

/**
 * @param parameter
 * "userID": "",
 "loginName": ""
 * @returns {*}
 */
export function updateuserloginname(parameter) {
  return request({
    url: api.UpdateUserLoginName,
    method: "put",
    data: parameter
  })
}

/**
 * @param parameter
 * "userID": "",
 "workNo": "",
 "displayName": ""
 * @returns {*}
 */
export function updateuser(parameter) {
  return request({
    url: api.updateUser,
    method: "put",
    data: parameter
  })
}

/**
 * @param parameter
 * @returns {*}
 */
export function updateuserorleandarea(parameter) {
  return request({
    url: api.UpdateUserRoleAndArea,
    method: "put",
    data: parameter
  })
}

/**
 * login func
 * @param parameter
 * @returns {*}
 */
export function add(parameter) {
  const roleIDs = [
    ...parameter.roleIDs
      .filter(item => item.id !== 0 && item.userType !== 0)
      .filter((item, index, self) => index === self.indexOf(item))
  ]
  return method(
    process.env.VUE_APP_API_URL,
    false
  )({
    url: api.Add,
    method: "post",
    data: {
      workNo: parameter.workNo,
      displayName: parameter.displayName,
      userName: parameter.userName,
      userTel: parameter.userTel,
      birthDate: parameter.birthDate,
      gender: parseInt(parameter.gender),
      jobTitle: parameter.jobTitle,
      email: parameter.email,
      roleIDs
    }
  })
}

/**
 * @param {*} parameter
 */
export function del(ids) {
  return method(
    process.env.VUE_APP_API_URL,
    false
  )({
    url: api.Del,
    method: "delete",
    data: { userIDs: ids }
  })
}

/**
 * @param {*} parameter
 */
export function get(parameter) {
  return method(
    process.env.VUE_APP_API_URL,
    false
  )({
    url: api.Get,
    method: "post",
    data: { ...parameter }
  })
}

/**
 * @param {*} parameter
 */
export function edit(parameter) {
  parameter["userRoleInfoList"] = [{ roleID: parameter.roleID }]
  parameter["userStationInfoList"] = _.map(parameter.station, it => {
    let itemArr = it.split("@")
    return {
      stationID: itemArr[0],
      stationName: itemArr[1]
    }
  })

  return request({
    url: api.Edit,
    method: "post",
    data: parameter
  })
}

export function GetUserDetail(parameter) {
  return method(
    process.env.VUE_APP_API_URL,
    false
  )({
    url: api.GetUserDetail,
    method: "get",
    data: { ...parameter }
  })
}

export function UpdateUserFunctions(parameter) {
  return method(
    process.env.VUE_APP_API_URL,
    false
  )({
    url: api.UpdateUserFunctions,
    method: "put",
    data: { ...parameter }
  })
}

export default {
  api,
  get,
  // getStationList,
  add,
  del,
  edit,
  updateuserloginname,
  updateuser,
  updateuserorleandarea,
  reset,
  GetUserDetail,
  UpdateUserFunctions
}

/*
 * @Description: 服务车
 * @Author: yuyongjie
 * @Date: 2020-12-26 17:51:20
 * @LastEditors: yuyongjie
 * @LastEditTime: 2020-12-29 22:21:53
 */
import { method } from "@/utils/request"

const api = {
  Get: "goods/list",
  GetAll: "goods/all",
  GetAllCategory: "goods/category/all"
}

const url = process.env.VUE_APP_API_URL

/**
 * @param {*} parameter
 */
export function get(parameter) {
  parameter.type = parameter.type ? Number(parameter.type) : 0
  return method(url)({
    url: api.Get,
    method: "post",
    data: parameter
  })
}

/**
 * @param {*} parameter
 */
export function getAll() {
  return method(url)({
    url: api.GetAll,
    method: "get"
  })
}

export function GetAllCategory() {
  return method(url)({
    url: api.GetAllCategory,
    method: "post"
  })
}

export default {
  get,
  getAll,
  GetAllCategory
}

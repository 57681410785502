<template>
  <div class="app-container">
    <el-collapse
      :value="showCollapse"
      class="my-collapse"
    >
      <el-collapse-item :name="1" title="统计分析">
        <div class="item-content">
          <div class="top-card" v-if="typeInfo.length === 4">
            <a-card
              style="color: #FFFFFF"
              class="card card1"

              v-for="(item,idx) in typeInfo"
              :key="idx"
            >
              <template slot="title">
                <div class="title">
                  {{item.typeName}}
                </div>
              </template>
              <span slot="extra">截止{{ parseTime(new Date()) }}</span>
              <div class="_content">
                <div class="_top">
                  <div class="_l">
                    <span style="color: #1890ff">
                      {{item.count}}
                    </span>
                    <span> 附件数量 : {{ item.count }} </span>
                  </div>
                  <div class="cardbg">
                    <img src="/cardbg.svg" />
                  </div>
                </div>
              </div>
            </a-card>
          </div>
          <div class="top-card" v-else>
            <a-card
              style="color: #FFFFFF"
              class="card card1"

              v-for="(item,idx) in ['发票','收款单','合同','跟进']"
              :key="idx"
            >
              <template slot="title">
                <div class="title">
                  {{ item }}
                </div>
              </template>
              <span slot="extra">截止{{ parseTime(new Date()) }}</span>
              <div class="_content">
                <div class="_top">
                  <div class="_l">
                    <span style="color: #1890ff">
                      {{item.count}}
                    </span>
                    <span> 附件数量 : 0 </span>
                  </div>
                  <div class="cardbg">
                    <img src="/cardbg.svg" />
                  </div>
                </div>
              </div>
            </a-card>
          </div>
          <div class="top-right">
            <a-card
              :body-style="{ padding: '0px', color:'#1890ff'}"
              :bordered="false"
              :title="
                '附件类型比例  '
              "
              class="card22"
            >
              <span slot="extra">截止{{ parseTime(new Date()) }}</span>
              <div class="_box">
                <div class="_t"></div>
                <div ref="chartTagRate" style="width:100%;height:350px"></div>
              </div>
            </a-card>
          </div>
        </div>
      </el-collapse-item>
    </el-collapse>
    <div class="head-container">
      <a-form layout="inline">
        <a-row :gutter="40">

          <a-col :md="4" :sm="24">
            <a-form-item label="所属企业">
              <a-select
                v-model="crud.query.customerID"
                :filterOption="filterCategory"
                allow-clear
                placeholder="请选择所属企业"
                show-search
                :maxTagCount="100"
                @search="handleSearch"
                @popupScroll="handlePopupScroll"
                @select="handleSelect"
              >
                <a-select-option
                  v-for="item in allCustomerMap"
                  :key="item.customerID"
                  :value="item.customerID"
                >{{ item.customerName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :md="4" :sm="24">
            <a-form-item label="关联项目">
              <a-select
                v-model.number="crud.query.projectID"
                :filterOption="filterCategory"
                allow-clear
                placeholder="请选择关联项目"
                show-search
              >
                <a-select-option
                  v-for="item in allProjectMap"
                  :key="item.projectID"
                  :value="item.projectID"
                >{{ item.projectNo }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :md="4" :sm="24">
            <a-form-item label="联系人">
              <a-select
                v-model="crud.query.contactID"
                :allowClear="true"
                :filterOption="filterCategory"
                placeholder="请选择客户分类"
                show-search
              >
                <a-select-option
                  v-for="item in [...allContactMap]"
                  :key="Number(item.contactID)"
                  :value="Number(item.contactID)"
                >{{ item.contactName + "-" + item.contactTel }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :md="4" :sm="24">
            <a-form-item label="创建时间">
              <a-range-picker
                v-model="crud.query.times"
                :allowClear="true"
                allow-clear
                format="YYYY-MM-DD"
              />
            </a-form-item>
          </a-col>

          <a-col :md="4" :sm="24"></a-col>

          <template v-if="crud.advanced">
          </template>

          <a-col :md="4" :sm="24">
            <SearchOptions
              :toggleShowAdvance="false"
              :queryFuncName="queryFuncName"
              style="width: 100%"
              @toDiyQuery="toDiyQuery"
              @toDiyResetQuery="toDiyResetQuery"
            />
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div class="table-container">
      <Operations tableName="附件" @toDiyAdd="toDiyAdd"/>
      <a-spin :spinning="crud.loading">
        <a-table
          :columns="crud.columns"
          :data-source="crud.data"
          :pagination="false"
          :row-selection="{
            selectedRowKeys: crud.selections,
            onChange: crud.selectionChangeHandler,
            getCheckboxProps: getCheckboxProps
          }"
          rowKey="attachmentID"
          :scroll="{ y: 1000 }"
        >
          <template slot="filePath" slot-scope="record">
            <a @click="toImg(record.filePath)">{{ record.filePath }}</a>
          </template>

          <template slot="operation" slot-scope="record">
            <a-button
              type="link"
              @click="toInfo(record)"
            >
              详情
            </a-button>
          </template>
        </a-table>
      </a-spin>
      <Pagination />
    </div>
    <div class="form-container">
      <a-drawer
        :title="crud.status.title"
        :visible="
          (crud.status.cu > 0 || crud.status.in > 0) && crud.status.add == 0
        "
        :width="1200"
        placement="right"
        @close="crud.cancelCU()"
      >
        <a-tabs
          :activeKey="activeKey"
          type="card"
          @change="
            key => {
              activeKey = key
            }
          "
        >
          <a-tab-pane :key="1" tab="附件相关信息">
            <a-card :body-style="{ padding: '0px' }" :bordered="false">
              <CurrentAttachmentInfoC ref="currentAttachmentInfoC"></CurrentAttachmentInfoC>
            </a-card>
            <div
              style="display: flex;justify-content: flex-start;vertical-align: middle;align-items: center; margin-top: 10px"
            >
              <h3 style="color: #1890ff;font-size: 15px;margin-left: 10px">当前合同关联的附件列表</h3>
            </div>
<!--            <Operations tableName="合同关联的附件列表" />-->
            <a-spin :spinning="crud.loading">
              <a-table
                :columns="crud.columns"
                :data-source="attachmentListContractId"
                :pagination="false"
                rowKey="attachmentID"
              >
                <template slot="operation" slot-scope="record">
                  <a-button
                    type="link"
                    @click="contractAttachmentToInfo(record)"
                  >
                    详情
                  </a-button>
                </template>
              </a-table>
            </a-spin>
          </a-tab-pane>
        </a-tabs>
        <div class="bottom-button-area">
          <a-button
            v-if="isEdit"
            class="bottom-button"
            type="primary"
            @click="toEdit"
          >
            编辑
          </a-button>
          <div class="edit" v-else>
            <a-button
              class="bottom-button1"
              @click="toCancelEdit"
            >
              取消
            </a-button>

            <a-button
              class="bottom-button2"
              type="primary"
              @click="toSubmitEdit"
            >
              提交
            </a-button>
          </div>
        </div>
      </a-drawer>
      <a-modal
        title="附件详情"
        :visible="visible"
        width="60%"
        @cancel="handleCancel"
        :footer="null"
      >
        <CurrentAttachmentInfoC ref="reCurrentAttachmentInfoC"></CurrentAttachmentInfoC>
        <div class="bottom-button-area">
          <a-button
            v-if="contractRelationIsEdit"
            class="bottom-button"
            type="primary"
            @click="contractRelationToEdit"
          >
            编辑
          </a-button>
          <div class="edit" v-else>
            <a-button
              class="bottom-button1"
              @click="contractRelationToCancelEdit"
            >
              取消
            </a-button>

            <a-button
              class="bottom-button2"
              type="primary"
              @click="contractRelationToSubmitEdit"
            >
              提交
            </a-button>
          </div>
        </div>
      </a-modal>
    </div>

    <a-modal
      width="60%"
      v-model="addFlag"
      title="新增附件"
      @cancel="cancelAdd"
      @ok="toSubAdd">
      <addAttachment ref="addAttachment"></addAttachment>
    </a-modal>
  </div>
</template>

<script>
import CurrentAttachmentInfoC from "@/components/customerInfo/current-attachment-info.vue";
import { getAttachmentListByContractId, getTypeRate } from "@/api/customer/attachment";
import { fullTimeFilter } from "@/utils/utils"
import Operations from "@/components/Crud/Operations"
import SearchOptions from "@/components/Crud/SearchOptions"
import CRUD, { form, header, presenter } from "@crud/crud"
import { AttachmentListColumns } from "@/views/attachment/attachment-list"
import Api from "@/api/customer/attachmentCrud"
import {
  GetAllContact,
  GetAllCustomer,
  GetAllProject,
  GetDictionary
} from "@/api/public-api"
import store from "@/store"
import { ADMIN_ROLE_ID } from "@/config"
import Pagination from "@/components/Crud/Pagination"
import {
  drawGeneralStatisticsChart
} from "@/views/customer/char"
import { GetProjectGoodsAnalysisList, } from "@/api/project";
import { checkOperateDisable } from "@/router/permission"

import addAttachment from "@/components/customerInfo/add-attachment.vue";
import { postAttachmentInfo } from "@/api/customer/attachment"
import debounce from 'lodash/debounce'
const LOAD_NUM = 30

const defaultForm = {
  contactName: "",
  contactTel: ""
}
export default {
  name: "Attachment",
  mixins: [presenter(), header(), form(defaultForm)],
  cruds() {
    return CRUD({
      title: "附件",
      roleIDs: [],
      columns: AttachmentListColumns,
      crudMethod: { ...Api },
      idField: "attachmentID" // 设置每一条记录的唯一标失
    })
  },
  data(){
    return {
      selectedVal: '',
      filterDataList: [],
      oriDataList: [],
      queryFuncName: "查询附件列表",
      contractRelationIsEdit: true,
      isEdit: true,
      visible:false,
      attachmentListContractId:[],
      activeKey: 1,
      attachmentType:{},
      allContactMap: [],
      allCustomerMap: [],
      allProjectMap: [],
      allUserTypeMap: [],
      allCustomerTagMap: [],

      showCollapse: [1],
      typeInfo:[],

      addFlag: false
    }
  },
  components: {
    SearchOptions,
    Operations,
    Pagination,
    CurrentAttachmentInfoC,
    addAttachment
  },
  methods: {
    handleSearch (val) {
      console.log('val',val)
      this.searchVal = val
      let filterList = []
      if (val) {
        filterList = (this.oriDataList).filter(item => item.customerName.indexOf(val) > -1)
      } else {
        filterList = this.oriDataList
      }
      this.filterDataList = filterList
      this.allCustomerMap = filterList.length < LOAD_NUM ? filterList : filterList.slice(0, LOAD_NUM)
    },
    handlePopupScroll: debounce(function () {
      if (this.searchVal === '') {
        this.loadMoreData(this.oriDataList)
      } else {
        this.loadMoreData(this.filterDataList)
      }
    }, 400),
    // 加载更多数据到select框
    loadMoreData (dataList) {
      const renderedLen = this.allCustomerMap.length // 已渲染的下拉列表长度
      const totalLen = dataList.length // 当前数据源的长度
      let addList = []
      if (renderedLen < totalLen) {
        if (renderedLen + LOAD_NUM <= totalLen) {
          addList = dataList.slice(renderedLen, renderedLen + LOAD_NUM)
        } else {
          addList = dataList.slice(renderedLen, renderedLen + (totalLen % LOAD_NUM))
        }
        this.allCustomerMap = (this.allCustomerMap).concat(addList)
      }
    },
    handleSelect (val) {
      if (this.searchVal) {
        const selectedArr = (this.oriDataList).filter(item => item === val) // 从数据源中过滤出下拉框选中的值，并返回一个数组
        const restList = (this.oriDataList).filter(item => item !== val) // 从数据源中过滤出其他的值，返回一个数组
        const newList = selectedArr.concat(restList).slice(0, LOAD_NUM) // 将选中的元素放到下拉列表的第一位
        this.renderedOptions = newList // 更新已渲染的下拉列表
        this.oriDataList = selectedArr.concat(restList) // 更新数据源
        this.searchVal = '' // 因为触发handleSelect函数时，会自动清空用户输入的内容。因此，searchVal需要重置。
      }
    },
    contractRelationToSubmitEdit(){
      this.$refs.reCurrentAttachmentInfoC.$refs.ruleForm.validate(async valid => {
        if (valid) {
          await this.$refs.reCurrentAttachmentInfoC.subEdit()
          if(this.$refs.reCurrentAttachmentInfoC.isSuccess === 0){
            this.visible = false
            this.$refs.currentAttachmentInfoC.showData()
          }
        } else {
          return false;
        }
      });
    },
    contractRelationToCancelEdit(){
      this.$refs.reCurrentAttachmentInfoC.$refs.singleUploadFileAfter.AllFileCancel()
      this.$refs.reCurrentAttachmentInfoC.fileList = [...this.$refs.currentAttachmentInfoC.originFileList]
      this.$refs.reCurrentAttachmentInfoC.disabled = true
      this.contractRelationIsEdit = true
    },
    contractRelationToEdit(){
      this.contractRelationIsEdit = false
      this.$refs.reCurrentAttachmentInfoC.disabled = false
    },
    toSubmitEdit(){
      this.$refs.currentAttachmentInfoC.$refs.ruleForm.validate(async valid => {
        if (valid) {
          await this.$refs.currentAttachmentInfoC.subEdit()
          if(this.$refs.currentAttachmentInfoC.isSuccess === 0){
            this.isEdit = true
          }
        } else {
          return false;
        }
      });
    },
    toCancelEdit(){
      this.$refs.currentAttachmentInfoC.$refs.singleUploadFileAfter.AllFileCancel()
      this.$refs.currentAttachmentInfoC.fileList = [...this.$refs.currentAttachmentInfoC.originFileList]
      this.$refs.currentAttachmentInfoC.disabled = true
      this.isEdit = true
    },
    toEdit(){
      this.isEdit = false
      this.$refs.currentAttachmentInfoC.disabled = false
    },
    handleCancel() {
      this.visible = false
    },
    contractAttachmentToInfo(record) {
      this.visible = true
      this.$nextTick(()=>{
        this.$refs.reCurrentAttachmentInfoC.currentAttachmentInfo = record
        this.$refs.reCurrentAttachmentInfoC.showData()
      })
    },
    async toInfo(record) {
      console.log(record)
      this.crud.toInfo(record)
      this.$nextTick(()=>{
        this.$refs.currentAttachmentInfoC.currentAttachmentInfo = record
        this.$refs.currentAttachmentInfoC.showData()
      })
      if (this.form.contractID === 0) return
      const attachmentObj = await getAttachmentListByContractId({
        contractID: this.form.contractID
      })
      this.attachmentListContractId = attachmentObj.result
    },
    filterCategory: function(value, option) {
      return option.componentOptions.children[0].text.indexOf(value) >= 0
    },
    getCheckboxProps(record) {
      if (record.roleID === 0 && !this.roleIDs.includes(ADMIN_ROLE_ID)) {
        return { props: { disabled: true } }
      }
      return { props: { disabled: false } }
    },
    fullTimeFilter,

    drawChart: drawGeneralStatisticsChart,
    async toDiyQuery() {
      const that = this
      that.crud.toQuery()
      if (!this.checkOperateDisable("项目产品数据统计")) {
        const queryRes = await GetProjectGoodsAnalysisList({
          ...that.crud.query
        })
        if (queryRes.code === 0) {
          that.projectGoodsNumList = (
            queryRes.result.goodsNumAnalysisList || []
          ).map((item, index) => ({ index: index + 1, ...item }))
          that.projectGoodsPriceList = (
            queryRes.result.goodsPriceAnalysisList || []
          ).map((item, index) => ({ index: index + 1, ...item }))
        }
      }
    },
    async toDiyResetQuery() {
      const that = this
      that.crud.resetQuery()
      if (!this.checkOperateDisable("项目产品数据统计")) {
        const queryRes = await GetProjectGoodsAnalysisList()
        if (queryRes.code === 0) {
          that.projectGoodsNumList = (
            queryRes.result.goodsNumAnalysisList || []
          ).map((item, index) => ({ index: index + 1, ...item }))
          that.projectGoodsPriceList = (
            queryRes.result.goodsPriceAnalysisList || []
          ).map((item, index) => ({ index: index + 1, ...item }))
        }
      }
    },
    checkOperateDisable,
    toDiyAdd(){
      this.addFlag = true
    },
    toSubAdd(){
      const that = this.$refs.addAttachment
      that.$refs.ruleForm.validate(async valid => {
        if (valid) {
          const addResultObj = await postAttachmentInfo({...that.addAttachmentInfo,
            fileName: that.$refs.singleUploadFile.fileList[0]?.fileName,
            filePath: that.$refs.singleUploadFile.fileList[0]?.filePathUrl})
          if(addResultObj.code === 12){
            this.addFlag = false
            this.crud.refresh()
          }
        } else {
          return false;
        }
      });

    },
    cancelAdd(){
      this.$refs.addAttachment.$refs.ruleForm.clearValidate()
      this.addFlag = false
    },
    toImg(url){
      window.open(url,'_blank')
    }

  },
  async created() {
    const resultObj = await GetDictionary({ typeName: "attachmentType" })
    this.attachmentType = resultObj.result
    /**
     * 设置按钮权限
     */
    let { code } = this.$route.meta
    // 关闭下载功能
    // this.crud.optShow.download = false
    this.crud.optShow.edit = false
    // 显示以下按钮
    this.crud.permission = {
      add: code.includes("新增附件"),
      del: code.includes("删除附件"),
      edit: code.includes("更改附件")
      // download: true
    }

    const [
      getAllContactRes,
      getAllCustomerRes,
      getAllProjectRes,
      getUserTypeRes,
      getCustomerTagRes
    ] = await Promise.all([
      GetAllContact(),
      GetAllCustomer(),
      GetAllProject(),
      GetDictionary({ typeName: "customerUserType" }),
      GetDictionary({ typeName: "customerTag" })
    ])

    if (getAllContactRes.code === 0) {
      this.allContactMap = getAllContactRes.result
    }

    if (getAllCustomerRes.code === 0) {
      this.oriDataList = getAllCustomerRes.result
      console.log('oriDataList',this.oriDataList)
      this.allCustomerMap = getAllCustomerRes.result.slice(0, LOAD_NUM)
    }

    if (getAllProjectRes.code === 0) {
      this.allProjectMap = getAllProjectRes.result
    }

    if (getUserTypeRes.code === 0) {
      this.allUserTypeMap = getUserTypeRes.result
    }

    if (getCustomerTagRes.code === 0) {
      this.allCustomerTagMap = getCustomerTagRes.result
    }

    this.roleIDs = store.getters.roleIDs



    const getTypeRateObj = await getTypeRate()
    console.log('getTypeRateObj',getTypeRateObj)
    if(getTypeRateObj.code === 0){
      this.typeInfo = getTypeRateObj.result.filter((item)=>item.typeName !== '')
      this.drawChart(
        "chartTagRate",
        this.typeInfo.map(item=>({
          name:item.typeName,
          value:item.count
        }))
      )
    }

  }
}
</script>
<style lang="scss" scoped>
.ant-table-striped /deep/ .table-striped td {
  background-color: #fcfcfc;
}

.my-collapse {
  margin-bottom: 20px;

  .el-collapse-item {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 5px;

    /deep/ .el-collapse-item__content {
      padding-bottom: 0;
    }

    /deep/ .el-collapse-item__header {
      padding-left: 20px;
    }

    .item-content {
      background-color: #f0f2f5;
      padding: 10px;
      display: flex;
    }
  }
}

.top-card {
  width: 50%;
  min-width: 1156px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  flex-wrap: wrap;

  .card {
    width: calc(50% - 20px);
    margin-right: 18px;
     height: 165px;
    &:nth-child(3) {
      margin-top: 20px;
    }
    &:nth-child(4) {
      margin-top: 20px;
    }
    min-width: 275px;
    border: none;
    border-radius: 10px;
    box-shadow: 4px 4px 8px 0px rgba(201, 201, 201, 0.5);

    /deep/ .ant-card-head,
    /deep/ .ant-card-body {
      padding-left: 19px;
      padding-right: 19px;
    }

    /deep/ .ant-card-head {
      .ant-card-head-title {
        padding: 0;
      }

      .title {
        height: 30px;
        font-size: 22px;
        font-weight: 500;
        text-align: left;
        color: #1890ff;
        line-height: 30px;
      }

      .ant-card-extra {
        font-size: 14px;
        font-weight: 400;
        text-align: left;
        color: #afafaf;
      }
    }

    /deep/ .ant-card-body {
      padding-top: 12px;
      padding-bottom: 16px;

      ._content {
        ._top {
          display: flex;
          align-items: center;
          justify-content: space-between;
          // margin-bottom: 18px;
          .cardbg{

          }
          ._l {
            display: flex;
            align-items: center;

            span {
              display: block;

              &:first-child {
                height: 47px;
                font-size: 40px;
                font-weight: 700;
                text-align: left;
                color: #3d3d3d;
                line-height: 47px;
                letter-spacing: 2px;
              }

              &:last-child {
                height: 20px;
                font-size: 14px;
                font-weight: 400;
                text-align: left;
                color: #afafaf;
                line-height: 20px;
                margin-top: 10px;
                margin-left: 9px;
              }
            }
          }

          ._r {
            & > div {
              &.HB {
                margin-top: 9px;
              }

              display: flex;
              align-items: center;

              ._title {
                height: 20px;
                font-size: 14px;
                font-weight: 400;
                text-align: left;
                color: #afafaf;
                line-height: 20px;
                margin-right: 22px;
              }
            }
          }
        }
      }
    }

    &.card1 {
      //background: linear-gradient(163deg, rgba(55,120,255,0) 0%, rgba(55,82,255,0.1) 54%, rgba(113,165,255,0.5) 100%);
      opacity: 1;
      .ant-card-body {
        ._content {
          ._top {
            ._r {
              & > div {
                &.HB {
                  ._right {
                    span {
                      display: flex;
                      align-items: center;

                      & > * {
                        display: block;
                      }

                      span {
                        &:last-child {
                          margin-left: -8px;
                        }
                      }

                      &.add {
                        span {
                          color: #ff4343;

                          &:first-child {
                            width: 0;
                            height: 0;
                            border-left: 9px solid transparent;
                            border-right: 9px solid transparent;
                            border-bottom: 10px solid red;
                          }
                        }
                      }

                      &.reduce {
                        span {
                          color: #05c065;

                          &:first-child {
                            width: 0;
                            height: 0;
                            border-left: 9px solid transparent;
                            border-right: 9px solid transparent;
                            border-top: 10px solid #05c065;
                          }
                        }
                      }
                    }
                  }
                }

                ._right {
                  span {
                    &:last-child {
                      color: #afafaf;
                    }
                  }
                }
              }

              ._right {
                display: flex;
                align-items: center;

                span {
                  display: block;
                  max-width: 58px;

                  &:first-child {
                    height: 22px;
                    font-size: 16px;
                    font-weight: 500;
                    text-align: left;
                    color: #3b3b3b;
                    line-height: 22px;
                    margin-right: 16px;
                  }

                  &:last-child {
                    height: 20px;
                    font-size: 14px;
                    font-weight: 500;
                    text-align: left;
                    color: #6f7fff;
                    line-height: 20px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.top-right{
  flex: 1;
  //border-radius: 10px;
  margin-right: 15px;
  .card22 {
    //background: linear-gradient(163deg, rgba(55,120,255,0) 0%, rgba(55,82,255,0.1) 54%, rgba(113,165,255,0.5) 100%);
    width: 100%;
    display: inline-block;
    float: left;
    height: 360px;
    margin-bottom: 20px;
    margin-left: 1%;
    border: none;
    border-radius: 10px;
    box-shadow: 4px 4px 8px 0px rgba(201, 201, 201, 0.5);
  }
}

.bottom-button-area {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center; /* 水平居中 */
  padding-top: 200px;

  .bottom-button {
    width: 200px;
    line-height: 30px;
    height: 30px;
  }

  .edit {
    width: 500px;
    height: 40px;

    .bottom-button1 {
      display: inline-block;
      float: left;
      width: 200px;
      line-height: 30px;
      height: 30px;
      margin-right: 100px;
    }

    .bottom-button2 {
      display: inline-block;
      float: left;
      width: 200px;
      line-height: 30px;
      height: 30px;
    }
  }
}
</style>

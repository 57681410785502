<template>
  <div>
    <a-button
      key="submit"
      :disabled="checkOperateDisable('新增合同')"
      class="send_but"
      type="primary"
      @click="toAddContract"
      >新增合同
    </a-button>
    <a-card :body-style="{ padding: '0px' }" :bordered="false" class="card1">
      <a-table
        :bordered="true"
        :columns="contractColumns"
        :data-source="contractData"
        :pagination="false"
        :row-class-name="
          (_record, index) => (index % 2 === 1 ? 'table-striped' : null)
        "
        :scroll="{ x: 1100 }"
        class="ant-table-striped"
        empty-text="-"
        rowKey="contractID"
        @resizeColumn="
          (w, col) => {
            col.width = w
          }
        "
      >
        <template slot="contractNo" slot-scope="record">
          <a @click="toInfoContract(record)">{{ record.contractNo }}</a>
        </template>
        <template slot="insertTime" slot-scope="record">
          {{ filterTimeYYYYMMDD(record.insertTime) }}
        </template>
        <template slot="releasedTime" slot-scope="record">
          {{ filterTimeYYYYMMDD(record.releasedTime) }}
        </template>
      </a-table>
    </a-card>
    <a-card :body-style="{ padding: '0px' }" :bordered="false" class="card2">
      <a-table
        :bordered="true"
        :columns="projectColumns"
        :data-source="projectData"
        :row-class-name="
          (_record, index) => (index % 2 === 1 ? 'table-striped' : null)
        "
        :scroll="{ x: 1100 }"
        class="ant-table-striped"
        empty-text="-"
        rowKey="projectID"
        @resizeColumn="
          (w, col) => {
            col.width = w
          }
        "
      >
        <template slot="projectNo" slot-scope="record">
          <a @click="toInfoProject(record)">{{ record.projectNo }}</a>
        </template>
        <template slot="releasedTime" slot-scope="record">
          {{ filterTimeYYYYMMDD(record.releasedTime) }}
        </template>
      </a-table>
    </a-card>

    <ContractInfo ref="contractInfo"></ContractInfo>
    <ProjectInfo ref="projectInfo"></ProjectInfo>
  </div>
</template>

<script>
import { fullTimeFilterYYYYMMDD } from "@/utils/utils"
import {
  ACTIVITY_KEY_MAP,
  CONTRACT_SHOW_TYPE,
  PROJECT_SHOW_TYPE
} from "@/config"
import ContractInfo from "@/components/customerInfo/contract-info"
import ProjectInfo from "@/components/customerInfo/project-info"
import {
  contractColumns,
  projectColumns
} from "@/components/customerInfo/contract-list"
import {
  GetContractProjects,
  GetProjectByCustomer,
  GetProjectDetail
} from "@/api/project"
import store from "@/store"
import {
  GetContractAttachment,
  GetContractByCustomer
} from "@/api/customer/contract"
import { checkOperateDisable } from "@/router/permission"

export default {
  name: "ContractTabPane",
  data() {
    return {
      ACTIVITY_KEY_MAP,
      activeKey: ACTIVITY_KEY_MAP.CONTRACT,
      contractColumns,
      projectColumns,
      contractData: [],
      projectData: []
    }
  },
  props: {
    addContract: {
      type: Boolean,
      default() {
        return false
      }
    },
    customerID: {
      type: Number,
      default() {
        return 0
      }
    },
    customerName: {
      type: String,
      default() {
        return "-"
      }
    }
  },
  components: {
    ContractInfo,
    ProjectInfo
  },
  created() {
    const that = this
    this.showData(that.customerID, that.customerName)
  },
  methods: {
    checkOperateDisable,
    toAddContract() {
      const that = this
      this.$refs.contractInfo.toShowFlag = CONTRACT_SHOW_TYPE.ADD_CONTRACT
      this.$refs.contractInfo.currentContract = {
        customerID: that.customerID,
        customerName: that.customerName,
        userID: store.getters.user.userId
      }
      this.$refs.contractInfo.customerID = that.customerID
    },
    async toInfoContract(record) {
      const that = this
      const [
        getContractProjectsRes,
        getContractAttachmentRes
      ] = await Promise.all([
        GetContractProjects({
          contractID: record.contractID
        }),
        GetContractAttachment({
          contractID: record.contractID
        })
      ])
      that.$refs.contractInfo.toShowFlag = CONTRACT_SHOW_TYPE.SHOW_CONTRACT
      that.$refs.contractInfo.currentContract = {
        ...record,
        userID: store.getters.user.userId
      }
      that.$refs.contractInfo.customerID = record.customerID
      if (
        getContractProjectsRes.code === 0 &&
        getContractProjectsRes.result &&
        getContractProjectsRes.result.length > 0
      ) {
        that.$refs.contractInfo.projectGoodsData = [
          ...getContractProjectsRes.result
        ]
      }
      if (
        getContractAttachmentRes.code === 0 &&
        getContractAttachmentRes.result &&
        getContractAttachmentRes.result.length > 0
      ) {
        that.$refs.contractInfo.uploadFilePath = [
          ...getContractAttachmentRes.result
        ]
      }
      // that.$refs.contractInfo.$forceUpdate()
    },
    async toInfoProject(record) {
      const that = this
      that.$refs.projectInfo.toShowFlag = PROJECT_SHOW_TYPE.SHOW_CONTRACT

      const getProjectDetail = await GetProjectDetail({
        projectID: record.projectID,
        contractID: record.contractID
      })

      if (
        getProjectDetail.code === 0 &&
        getProjectDetail.result &&
        getProjectDetail.result.project
      ) {
        that.$refs.projectInfo.currentProject = {
          ...getProjectDetail.result.project
        }
        that.$refs.projectInfo.otherProjects = [
          ...getProjectDetail.result.otherProjects
        ]
      }
    },
    async showData(customerID, customerName) {
      const that = this
      that.customerID = customerID
      that.customerName = customerName
      const [getContractRes, getProjectRes] = await Promise.all([
        GetContractByCustomer({ customerID }),
        GetProjectByCustomer({ customerID })
      ])
      if (getContractRes.code === 0) {
        that.contractData = getContractRes.result
      }
      if (getProjectRes.code === 0) {
        that.projectData = getProjectRes.result
      }
    },
    filterTimeYYYYMMDD: timeStr => fullTimeFilterYYYYMMDD(timeStr)
  }
}
</script>

<style scoped>
.card1 {
  margin-top: 20px;
}

.card2 {
  margin-top: 20px;
}

.ant-table-striped /deep/ .table-striped td {
  background-color: #fcfcfc;
}
</style>

<template>
  <div class="app-container">
    <div class="head-container">
      <a-form layout="inline">
        <a-row :gutter="48">
          <a-col :md="4" :sm="24">
            <a-form-item label="字典类型">
              <a-select
                v-model="crud.query.typeName"
                :filterOption="filterCategory"
                allow-clear
                placeholder="请选择字典分类"
                show-search
              >
                <a-select-option
                  v-for="item in allDictionaryTypeNames"
                  :key="item.typeName"
                  :value="item.typeName"
                  >{{ item.description }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24">
            <a-form-item label="KEY">
              <a-input
                v-model="crud.query.key"
                allow-clear
                placeholder="请输入字典KEY"
              />
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24">
            <a-form-item label="字典值">
              <a-input
                v-model="crud.query.value"
                allow-clear
                placeholder="请输入字典值模糊搜索"
              />
            </a-form-item>
          </a-col>

          <a-col :md="6" :sm="24"></a-col>
          <a-col :md="6" :sm="24">
            <SearchOptions
              :queryFuncName="queryFuncName"
              :toggleShowAdvance="false"
              style="width: 100%"
            />
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div class="table-container">
      <Operations tableName="字典" @toDiyAdd="toDiyAdd" />
      <a-spin :spinning="crud.loading">
        <a-table
          :columns="crud.columns"
          :data-source="crud.data"
          :pagination="false"
          :row-selection="{
            selectedRowKeys: crud.selections,
            onChange: crud.selectionChangeHandler,
          }"
          rowKey="dictionaryID"
        >
          <template slot="insertTime" slot-scope="record">
            {{ filterTime(record.insertTime) }}
          </template>

          <template slot="updateTime" slot-scope="record">
            {{ filterTime(record.updateTime) }}
          </template>

          <template slot="operation" slot-scope="record">
            <a-button
              :disabled="checkOperateDisable('查看字典详情')"
              type="link"
              @click="toInfo(record)"
            >
              详情
            </a-button>
          </template>
        </a-table>
      </a-spin>
      <Pagination />
    </div>

    <a-modal
      :afterClose="closeModal"
      :visible="
        [
          BASE_OPERATE_TYPE.ADD,
          BASE_OPERATE_TYPE.EDIT,
          BASE_OPERATE_TYPE.INFO
        ].includes(operateType)
      "
      :title="operateType === BASE_OPERATE_TYPE.ADD ? '新增字典' : '字典详情'"
      wrapClassName="stationListDialog"
      @cancel="closeModal"
    >
      <a-form-model
        ref="dictionaryForm"
        :label-col="{ span: 6 }"
        :model="form"
        :rules="rules"
        :wrapper-col="{ span: 18 }"
        labelAlign="left"
      >
        <a-form-model-item label="字典Key" prop="key">
          <a-input
            v-model="form.key"
            :disabled="operateType == BASE_OPERATE_TYPE.INFO "
            allow-clear
            placeholder="请输入字典KEY"
          >
          </a-input>
        </a-form-model-item>
        <a-form-model-item label="字典值" prop="value">
          <a-input
            v-model="form.value"
            :disabled="operateType == BASE_OPERATE_TYPE.INFO "
            allow-clear
            placeholder="请输入字典值"
          >
          </a-input>
        </a-form-model-item>
        <a-form-model-item label="字典类型" prop="typeName">
          <a-select
            v-model="form.typeName"
            :disabled="operateType == BASE_OPERATE_TYPE.INFO "
            :filterOption="filterCategory"
            allow-clear
            placeholder="请选择字典分类"
            show-search
          >
            <a-select-option
              v-for="item in allDictionaryTypeNames"
              :key="item.typeName"
              :value="item.typeName"
              >{{ item.description }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
      <template slot="footer">
        <div v-if="!(operateType === BASE_OPERATE_TYPE.EDIT) && operateType === BASE_OPERATE_TYPE.INFO">
          <a-button type="primary" key="edit" @click="toEdit">
            编辑
          </a-button>
        </div>
        <div v-else>
          <a-button key="back" @click="closeModal">
            取消
          </a-button>
          <a-button key="submit" type="primary" @click="operateType === BASE_OPERATE_TYPE.ADD ? addModal() : editModal()">
            提交
          </a-button>
        </div>
      </template>
    </a-modal>

    <div>
<!--      <a-modal-->
<!--        :afterClose="closeModal"-->
<!--        :visible="infoFlag"-->
<!--        title="字典详情"-->
<!--        @cancel="infoCancel"-->
<!--      >-->
<!--        <a-form-model-->
<!--          ref="dictionaryInfoForm"-->
<!--          :label-col="{ span: 6 }"-->
<!--          :model="form"-->
<!--          :rules="rules"-->
<!--          :wrapper-col="{ span: 18 }"-->
<!--          labelAlign="left"-->
<!--        >-->
<!--          <a-form-model-item label="字典Key" prop="key">-->
<!--            <a-input-->
<!--              v-model="form.key"-->
<!--              :disabled="editBtn"-->
<!--              allow-clear-->
<!--              placeholder="请输入字典KEY"-->
<!--            >-->
<!--            </a-input>-->
<!--          </a-form-model-item>-->
<!--          <a-form-model-item label="字典值" prop="value">-->
<!--            <a-input-->
<!--              v-model="form.value"-->
<!--              :disabled="editBtn"-->
<!--              allow-clear-->
<!--              placeholder="请输入字典值"-->
<!--            >-->
<!--            </a-input>-->
<!--          </a-form-model-item>-->
<!--          <a-form-model-item label="字典类型" prop="typeName">-->
<!--            <a-select-->
<!--              v-model="form.typeName"-->
<!--              :disabled="editBtn"-->
<!--              :filterOption="filterCategory"-->
<!--              allow-clear-->
<!--              placeholder="请选择字典分类"-->
<!--              show-search-->
<!--            >-->
<!--              <a-select-option-->
<!--                v-for="item in allDictionaryTypeNames"-->
<!--                :key="item.typeName"-->
<!--                :value="item.typeName"-->
<!--              >{{ item.description }}-->
<!--              </a-select-option>-->
<!--            </a-select>-->
<!--          </a-form-model-item>-->
<!--        </a-form-model>-->
<!--        <template slot="footer">-->
<!--          <div v-if="editBtn">-->
<!--            <a-button type="primary" key="edit" @click="editBtn=false">-->
<!--              编辑-->
<!--            </a-button>-->
<!--          </div>-->
<!--          <div v-else>-->
<!--            <a-button key="back" @click="editBtn=true">-->
<!--              取消-->
<!--            </a-button>-->
<!--            <a-button key="submit" type="primary" @click="editSub">-->
<!--              提交-->
<!--            </a-button>-->
<!--          </div>-->
<!--        </template>-->
<!--      </a-modal>-->
    </div>
  </div>
</template>

<script>
import SearchOptions from "@/components/Crud/SearchOptions"
import Operations from "@/components/Crud/Operations"
import Pagination from "@/components/Crud/Pagination"
import CRUD, { header, presenter } from "@crud/crud"
import Api, { getTypeName, edit, getDictionaryInfo } from "@/api/system/dictionary"
import { fullTimeFilter } from "@/utils/utils"
import { checkOperateDisable } from "@/router/permission"
import { dictionaryListColumns } from "@/views/system/dictionary-list"
import { BASE_OPERATE_TYPE } from "@/config"

// 表格的填充字段
export default {
  name: "DictionaryComponent",
  // presenter 生成crud对象
  mixins: [presenter(), header()],
  cruds() {
    return CRUD({
      title: "字典",
      roleIDs: [],
      columns: [...dictionaryListColumns],
      crudMethod: { ...Api },
      idField: "dictionaryID" // 设置每一条记录的唯一标失
    })
  },
  data() {
    return {
      queryFuncName: "查询字典列表",
      allDictionaryTypeNames: [],
      operateType: "",
      BASE_OPERATE_TYPE,
      form: {},
      rules: {
        key: [{ required: true, message: "请输入字典Key", trigger: "blur" }],
        value: [{ required: true, message: "请选择字典值", trigger: "blur" }],
        typeName: [
          { required: true, message: "请选择字典类型", trigger: "blur" }
        ]
      },

      infoFlag:false,
      editBtn:true
    }
  },
  components: { SearchOptions, Operations, Pagination },
  async created() {
    // 关闭下载功能
    // this.crud.optShow.download = false
    this.crud.optShow.edit = false
    // 显示以下按钮
    this.crud.permission = {
      add: !checkOperateDisable("新增字典"),
      del: !checkOperateDisable("删除字典"),
      edit: !checkOperateDisable("修改字典")
    }
    const getTypeNameRes = await getTypeName()
    if (getTypeNameRes.code === 0) {
      this.allDictionaryTypeNames = [...getTypeNameRes.result]
    }
  },
  methods: {
    filterTime: timeStr => fullTimeFilter(timeStr),
    filterCategory: function(value, option) {
      return option.componentOptions.children[0].text.indexOf(value) >= 0
    },
    checkOperateDisable,
    toDiyAdd() {
      this.operateType = BASE_OPERATE_TYPE.ADD
    },
    async toInfo(record) {
      this.operateType = BASE_OPERATE_TYPE.INFO
      const dictionaryInfoObj = await getDictionaryInfo({dictionaryID : record.dictionaryID})
      this.form = { ...dictionaryInfoObj.result }
    },
    toEdit(){
      this.operateType = BASE_OPERATE_TYPE.EDIT
    },

    closeModal() {
      this.operateType = ""
      this.form = {}
      this.$refs.dictionaryForm.clearValidate()
    },
    addModal() {
      const that = this
      this.$refs["dictionaryForm"].validate(async valid => {
        if (!valid) {
          return
        }
        this.form.description = this.allDictionaryTypeNames.find(
          item => item.typeName === that.form.typeName
        ).description

        const addRes = await Api.add({ ...that.form })
        if (addRes.code !== 0) {
          that.$message.error("新增失败")
        } else {
          this.operateType = ""
          this.form = {}
          this.$refs.dictionaryForm.clearValidate()
          this.crud.refresh()
        }
      })
    },
    editModal() {
      const that = this
      this.$refs["dictionaryForm"].validate(async valid => {
        if (!valid) {
          return
        }
        this.form.description = this.allDictionaryTypeNames.find(
          item => item.typeName === that.form.typeName
        ).description

        const addRes = await edit({ ...that.form })
        if (addRes.code !== 0) {
          that.$message.error("编辑失败")
        } else {
          this.operateType = ""
          this.form = {}
          this.$refs.dictionaryForm.clearValidate()
          this.crud.refresh()
        }
      })
    },

  }
}
</script>

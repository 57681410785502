<template>
  <div class="app-container">
    <div class="head-container">
      <a-form-model
        ref="createWeighOrderForm"
        :class="
          crud.status.in > 0 && crud.status.cu === 0 ? 'disabled-form' : ''
        "
        :label-col="{ span: 2 }"
        :model="createWeighOrderForm"
        :rules="rulesWeighOrder"
        :wrapper-col="{ span: 8 }"
        labelAlign="left"
      >
        <div style="margin-bottom: 20px">
          <div
            v-if="selectWeight"
            style="float: left; color: green;margin-bottom: 10px"
          >
            已选择磅
          </div>
          <div
            v-if="selectWeight"
            style="float: right; color: green;margin-bottom: 10px"
          >
            <a-button
              key="submit"
              :disabled="selectWeightNum == 1"
              style="margin-right: 20px"
              type="primary"
              @click="selectWeightNum--"
            >
              减少磅
            </a-button>
            <a-button
              key="submit"
              :disabled="selectWeightNum == 4"
              type="primary"
              @click="selectWeightNum++"
            >
              增加磅
            </a-button>
          </div>
          <div v-else style="color: red;margin-bottom: 5px">点击选择磅</div>
          <template>
            <div style="display: flex; width: 100%">
              <a-card
                v-for="index of selectWeightNum"
                :key="index"
                :activeTabKey="index + ''"
                :style="`width: ${100 / selectWeightNum}%`"
                hoverable
                @click="clickCard(index)"
              >
                <template slot="title">
                  <span>{{ index }}号磅 实时重量</span>
                  <span
                    v-if="selectWeight == index"
                    style="padding-left:20px;color:green"
                  >
                    已选中
                  </span>
                  <span
                    v-if="selectWeight != index"
                    style="padding-left:20px;color:red"
                  >
                    未选中
                  </span>
                  <a-icon
                    v-if="selectWeight == index"
                    style="color: green"
                    type="check"
                  />
                  <a-icon
                    v-if="selectWeight != index"
                    style="color: red"
                    type="close"
                  />
                </template>
                <span slot="extra">截止{{ parseTime(new Date()) }}</span>
                <div>
                  <div style="width: 100%">
                    <numberGrow
                      :countEnterNum="weighGroupValue[index]"
                      :time="5"
                    ></numberGrow>
                  </div>
                  <!--                  <div :ref="`chart${index}`" style="width:100%;height:300px"></div>-->
                  <div
                    :id="`chart${index}`"
                    style="width:100%;height:300px"
                  ></div>
                </div>
              </a-card>
            </div>
          </template>
        </div>

        <a-form layout="inline">
          <a-row :gutter="30">
            <a-col :md.camel="5" :sm="25">
              <a-form-item label="毛重" prop="roughWight">
                <a-input
                  v-model="createWeighOrderForm.roughWight"
                  :allowClear="true"
                  :disabled="true"
                  :min="0"
                  placeholder="毛重"
                  style="width: 100%"
                  type="number"
                />
              </a-form-item>
            </a-col>

            <a-col :md.camel="5" :sm="25">
              <a-form-item label="皮重" prop="tareWight">
                <a-input
                  v-model="createWeighOrderForm.tareWight"
                  :allowClear="true"
                  :disabled="createWeighOrderForm.weightMethodType == 2"
                  :min="0"
                  placeholder="请输入扣重"
                  style="width: 100%"
                  type="number"
                />
              </a-form-item>
            </a-col>

            <a-col :md.camel="5" :sm="25">
              <a-form-item label="净重" prop="netWight">
                <!-- <a-input
                  v-model="
                    createWeighOrderForm.roughWight -
                      createWeighOrderForm.tareWight >
                    0
                      ? createWeighOrderForm.roughWight -
                        createWeighOrderForm.tareWight
                      : createWeighOrderForm.tareWight -
                        createWeighOrderForm.roughWight
                  "
                  :allowClear="false"
                  :disabled="true"
                  :min="0"
                  placeholder="净重"
                  style="width: 100%"
                  type="number"
                /> -->
              </a-form-item>
            </a-col>
          </a-row>
          <a-row :gutter="30">
            <a-col :md="5" :sm="25">
              <a-form-item label="过磅方式" prop="weightMethodType">
                <a-radio-group v-model="createWeighOrderForm.weightMethodType">
                  <a-radio
                    v-for="item in weightMethodType"
                    :key="item.key"
                    :value="item.key"
                    >{{ item.value }}
                  </a-radio>
                </a-radio-group>
              </a-form-item>
            </a-col>
            <a-col :md="5" :sm="25">
              <a-form-item label="过磅类型" prop="weightType">
                <a-radio-group
                  v-model="createWeighOrderForm.weightType"
                  :disabled="crud.status.in > 0 && crud.status.cu === 0"
                >
                  <a-radio
                    v-for="item in weightType"
                    :key="item.key"
                    :value="item.key"
                    >{{ item.value }}
                  </a-radio>
                </a-radio-group>
              </a-form-item>
            </a-col>
            <a-col :md="5" :sm="25">
              <a-form-item label="选择派车单">
                <a-button
                  v-if="!selectedTruckOrder"
                  :disabled="createWeighOrderForm.weightMethodType == 1"
                  shape="circle"
                  type="primary"
                  @click="showTruckOrder"
                  >选择
                </a-button>
                <span v-if="selectedTruckOrder">已选择 </span>
                <a-button
                  v-if="selectedTruckOrder"
                  shape="circle"
                  type="primary"
                  @click="resetTruckOrder"
                  >重置
                </a-button>
              </a-form-item>
            </a-col>
            <a-col :md="5" :sm="25">
              <a-form-item label="扣重" prop="deduct">
                <a-input
                  v-model="createWeighOrderForm.deduct"
                  :allowClear="true"
                  :disabled="selectedTruckOrder"
                  :min="0"
                  placeholder="请输入扣重"
                  style="width: 100%"
                  suffix="KG"
                  type="number"
                />
              </a-form-item>
            </a-col>
          </a-row>
          <a-row :gutter="30">
            <a-col :md="5" :sm="25">
              <a-form-item label="收货单位" prop="receiveID">
                <a-select
                  v-model="createWeighOrderForm.receiveID"
                  :allowClear="true"
                  :disabled="selectedTruckOrder"
                  :filterOption="filterMapSearch"
                  placeholder="请选择收货地址"
                  show-search
                >
                  <a-select-option
                    v-for="item in allReceiveMap"
                    :key="item.id"
                    :value="item.id"
                    >{{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="5" :sm="25">
              <a-form-item label="发货单位">
                <a-select
                  v-model="createWeighOrderForm.originID"
                  :allowClear="true"
                  :disabled="selectedTruckOrder"
                  :filterOption="filterMapSearch"
                  placeholder="请选择收货地址"
                  show-search
                >
                  <a-select-option
                    v-for="item in allOriginMap"
                    :key="item.id"
                    :value="item.id"
                    >{{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="5" :sm="25">
              <a-form-item label="货品名称">
                <a-select
                  v-model="createWeighOrderForm.goodsID"
                  :allowClear="true"
                  :disabled="selectedTruckOrder"
                  :filterOption="filterMapSearch"
                  placeholder="请输入货品名称"
                  show-search
                >
                  <a-select-option
                    v-for="item in allGoodsNameMap"
                    :key="item.id"
                    :value="item.id"
                    >{{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row :gutter="30">
            <a-col :md="5" :sm="25">
              <a-form-item label="车牌号码">
                <a-select
                  v-model="createWeighOrderForm.driverID"
                  :allowClear="true"
                  :disabled="selectedTruckOrder"
                  :filterOption="filterMapSearch"
                  placeholder="请输入车牌号"
                  show-search
                  @select="selectedDriver"
                >
                  <a-select-option
                    v-for="item in allDriverMap"
                    :key="item.driverID"
                    :value="item.driverID"
                    >{{ item.vehicleNumber }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :md="5" :sm="25">
              <a-form-item label="司机姓名">
                <span>
                  {{ createWeighOrderForm.driverName || "-" }}
                </span>
              </a-form-item>
            </a-col>
            <a-col :md="5" :sm="25">
              <a-form-item label="司机电话">
                {{ createWeighOrderForm.driverTel || "-" }}
              </a-form-item>
            </a-col>

            <a-col :md="8" :sm="25">
              <a-form-item label="备注信息">
                <a-input
                  v-model="createWeighOrderForm.note"
                  :allowClear="true"
                  placeholder="请输入备注信息"
                  type="textarea"
                />
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </a-form-model>
      <div style="width: 100%;height:80px;margin:0 auto">
        <a-button
          key="submit"
          style="margin-top: 20px;margin-left: 40%;width: 100px"
          type="primary"
          @click="createWeighOrder"
        >
          提交
        </a-button>
      </div>
    </div>
    <div class="head-container">
      <a-form layout="inline">
        <a-row :gutter="30">
          <a-col :md="4" :sm="24">
            <a-form-item label="过磅单编号">
              <a-input
                v-model="crud.query.weighOrderNo"
                :allowClear="true"
                placeholder="请输入过磅单号"
                style="width: 100%"
              />
            </a-form-item>
          </a-col>

          <a-col :md="4" :sm="24">
            <a-form-item label="派车单号">
              <a-input
                v-model="crud.query.truckOrderNo"
                :allowClear="true"
                placeholder="请输入派车单号"
                style="width: 100%"
              />
            </a-form-item>
          </a-col>

          <a-col :md="4" :sm="24">
            <a-form-item label="订单编号">
              <a-input
                v-model="crud.query.orderNo"
                :allowClear="true"
                placeholder="请输入订单单号"
                style="width: 100%"
              />
            </a-form-item>
          </a-col>

          <a-col :md="4" :sm="24">
            <a-form-item label="车牌号码">
              <a-input
                v-model="crud.query.vehicleNumber"
                :allowClear="true"
                placeholder="请输入车牌号"
                style="width: 100%"
              />
            </a-form-item>
          </a-col>

          <a-col :md="4" :sm="24">
            <a-form-item label="订单类型">
              <a-select
                v-model="crud.query.orderType"
                :allowClear="true"
                :filterOption="filterMapSearch"
                placeholder="请选择类型"
                show-search
              >
                <a-select-option
                  v-for="item in orderTypeMap"
                  :key="item.key"
                  :value="item.key"
                  >{{ item.value }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <template v-if="crud.advanced">
            <a-col :md="4" :sm="24">
              <a-form-item label="订单状态">
                <a-select
                  v-model="crud.query.status"
                  :allowClear="true"
                  :filterOption="filterMapSearch"
                  placeholder="请选择订单状态"
                  show-search
                >
                  <a-select-option
                    v-for="item in weighOrderStatusMap"
                    :key="item.key"
                    :value="item.key"
                    >{{ item.value }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :md="6" :sm="24">
              <a-form-item label="收货地名称">
                <a-select
                  v-model="crud.query.receiveID"
                  :allowClear="true"
                  :filterOption="filterMapSearch"
                  placeholder="请选择收货地名称"
                  show-search
                >
                  <a-select-option
                    v-for="item in allReceiveMap"
                    :key="item.id"
                    :value="item.id"
                    >{{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :md="6" :sm="24">
              <a-form-item label="发货地名称">
                <a-select
                  v-model="crud.query.originID"
                  :allowClear="true"
                  :filterOption="filterMapSearch"
                  placeholder="请选择收货地名称"
                  show-search
                >
                  <a-select-option
                    v-for="item in allOriginMap"
                    :key="item.id"
                    :value="item.id"
                    >{{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :md="4" :sm="24">
              <a-form-item label="货品名称">
                <a-input
                  v-model="crud.query.goodsName"
                  :allowClear="true"
                  placeholder="请输入货品名称"
                  style="width: 100%"
                />
              </a-form-item>
            </a-col>

            <a-col :md="4" :sm="24">
              <a-form-item label="货品编码">
                <a-input
                  v-model="crud.query.goodsNo"
                  :allowClear="true"
                  placeholder="请输入货品编码"
                  style="width: 100%"
                />
              </a-form-item>
            </a-col>

            <a-col :md="4" :sm="24">
              <a-form-item label="创建时间">
                <a-range-picker
                  v-model="crud.query.insertTimes"
                  :allowClear="true"
                  format="YYYY-MM-DD"
                  @change="insertTimeChange"
                />
              </a-form-item>
            </a-col>

            <a-col :md="6" :sm="24">
              <a-form-item label="仓库审核时间">
                <a-range-picker
                  v-model="crud.query.warehouseConfirmTime"
                  :allowClear="true"
                  format="YYYY-MM-DD"
                  @change="warehouseTimeChange"
                />
              </a-form-item>
            </a-col>

            <a-col :md="6" :sm="24">
              <a-form-item label="财务审核时间">
                <a-range-picker
                  v-model="crud.query.financialConfirmTime"
                  :allowClear="true"
                  format="YYYY-MM-DD"
                  @change="financialTimeChange"
                />
              </a-form-item>
            </a-col>

            <a-col :md="8" :sm="24"></a-col>
          </template>
          <a-col :md="4" :sm="24">
            <SearchOptions style="width: 100%" />
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div class="table-container">
      <Operations tableName="过磅单列表" />
      <a-spin :spinning="crud.loading">
        <a-table
          :columns="crud.columns"
          :data-source="crud.data"
          :pagination="false"
          :row-selection="{
            selectedRowKeys: crud.selections,
            onChange: crud.selectionChangeHandler
          }"
          :scroll="{ x: 2000 }"
          rowKey="weighOrderID"
        >
          <template slot="orderType" slot-scope="record">
            <div
              :style="{
                color: record.orderType === 1 ? '#3300FF' : '#33CC33'
              }"
            >
              {{ orderType[record.orderType] }}
            </div>
          </template>

          <template slot="tareTime" slot-scope="record">
            {{ filterInfoTime(record.tareTime) }}
          </template>

          <template slot="roughTime" slot-scope="record">
            {{ filterInfoTime(record.roughTime) }}
          </template>

          <template slot="weightOverTime" slot-scope="record">
            {{ filterInfoTime(record.weightOverTime) }}
          </template>

          <template slot="insertTime" slot-scope="record">
            {{ filterInfoTime(record.insertTime) }}
          </template>

          <template slot="updateTime" slot-scope="record">
            {{ filterInfoTime(record.updateTime) }}
          </template>

          <template slot="status" slot-scope="record">
            <div
              :style="{
                color:
                  record.status === 1
                    ? 'blue'
                    : record.status == 2
                    ? 'red'
                    : record.status == 3
                    ? 'green'
                    : record.status == 4
                    ? 'ff9900'
                    : 'black'
              }"
            >
              {{ weighOrderStatus[record.status] }}
            </div>
          </template>

          <template slot="operation" slot-scope="record">
            <template v-if="record.status === 1 || record.status === 3">
              <a-button type="link" @click="warehouseCheckOrderOk(record)">
                仓库确认
              </a-button>
            </template>

            <template v-if="record.status < 2">
              <a-popconfirm
                cancel-text="取消"
                ok-text="确定"
                title="确定要作废该过磅单吗？"
                @cancel="() => {}"
                @confirm="okInvalid(record)"
              >
                <a href="#">作废</a>
              </a-popconfirm>
            </template>

            <template v-if="record.status === 5">
              <a-button type="link" @click="financialCheckOrderOk(record)">
                财务确认
              </a-button>
            </template>

            <a-button
              :disabled="!$route.meta.code.includes('获取过磅单详情')"
              type="link"
              @click="crud.toInfo(record)"
            >
              详情
            </a-button>
          </template>
        </a-table>
      </a-spin>
      <Pagination />
    </div>
    <!-- 填写表单部分 -->
    <div class="form-container">
      <!--      选择派车单-->
      <a-modal
        :afterClose="afterCloseSelectTruckOrder"
        :visible="createWeighOrderForm.showTruckOrder"
        title="选择派车单"
        width="70%"
        wrapClassName="stationListDialog"
        @cancel="createWeighOrderForm.showTruckOrder = false"
        @ok="okSelectTruckOrder"
      >
        <a-form layout="inline">
          <a-row :gutter="5">
            <a-col :md="6" :sm="24">
              <a-form-item label="派车单号">
                <a-input
                  v-model="queryTruckOrder.truckOrderNo"
                  :allowClear="true"
                  placeholder="请输入过派车单号"
                  style="width: 100%"
                />
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="订单编号">
                <a-input
                  v-model="queryTruckOrder.orderNo"
                  :allowClear="true"
                  placeholder="请输入订单编号"
                  style="width: 100%"
                />
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="发货单位">
                <a-input
                  v-model="queryTruckOrder.originName"
                  :allowClear="true"
                  placeholder="请输入发货单位"
                  style="width: 100%"
                />
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="收货单位">
                <a-input
                  v-model="queryTruckOrder.receiveName"
                  :allowClear="true"
                  placeholder="请输入收货单位"
                  style="width: 100%"
                />
              </a-form-item>
            </a-col>
          </a-row>
          <a-row :gutter="5">
            <a-col :md="6" :sm="24">
              <a-form-item label="司机姓名">
                <a-input
                  v-model="queryTruckOrder.driverName"
                  :allowClear="true"
                  placeholder="请输入司机姓名"
                  style="width: 100%"
                />
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="车牌号码">
                <a-input
                  v-model="queryTruckOrder.vehicleNumber"
                  :allowClear="true"
                  placeholder="请输入车牌号码"
                  style="width: 100%"
                />
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="货品名称">
                <a-input
                  v-model="queryTruckOrder.goodsName"
                  :allowClear="true"
                  placeholder="请输入货品名称"
                  style="width: 100%"
                />
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="货品编号">
                <a-input
                  v-model="queryTruckOrder.goodsNo"
                  :allowClear="true"
                  placeholder="请输入货品编号"
                  style="width: 100%"
                />
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
        <a-form-model labelAlign="left" style="margin-top: 10px">
          <a-table
            :columns="selectTruckOrderColumns"
            :dataSource="createWeighOrderForm.filterTruckOrders || []"
            :row-selection="{
              type: 'radio',
              selectedRowKeys: createWeighOrderForm.selectedTruckOrderIDs,
              onChange: selectTruckOrderHandler
            }"
            :scroll="{ x: 2500, y: 500, scrollToFirstRowOnChange: true }"
            rowKey="truckOrderID"
          >
          </a-table>
        </a-form-model>
      </a-modal>
      <!--      详情-->
      <div>
        <a-modal
          :title="crud.status.title"
          :visible="crud.status.in > 0 && crud.status.cu === 0"
          :width="1080"
          wrapClassName="truckOrderInfo"
          @cancel="crud.cancelCU()"
          @ok="crud.cancelCU()"
        >
          <a-tabs :activeKey="activeKey" type="card" @change="callback">
            <a-tab-pane :key="1" tab="过磅单详情">
              <div class="tab1">
                <div>
                  <div class="_l">过磅单号：</div>
                  <div class="_r">
                    <span>
                      {{ form.weighOrderNo }}
                    </span>
                  </div>
                </div>

                <div>
                  <div class="_l">派送单号：</div>
                  <div class="_r">
                    <span>
                      {{ form.truckOrderNo }}
                    </span>
                  </div>
                </div>

                <div>
                  <div class="_l">关联订单号：</div>
                  <div class="_r">
                    <span>
                      {{ form.orderNo }}
                    </span>
                  </div>
                </div>

                <div>
                  <div class="_l">订单类型：</div>
                  <div class="_r">
                    <span>
                      {{ orderType[form.orderType] }}
                    </span>
                  </div>
                </div>

                <div>
                  <div class="_l">过磅单状态：</div>
                  <div class="_r">
                    <span>
                      {{ weighOrderStatus[form.status] }}
                    </span>
                  </div>
                </div>

                <div>
                  <div class="_l">收货单位：</div>
                  <div class="_r">
                    <span>
                      {{ form.receiveName }}
                    </span>
                  </div>
                </div>

                <div>
                  <div class="_l">发货单位：</div>
                  <div class="_r">
                    <span>
                      {{ form.originName }}
                    </span>
                  </div>
                </div>

                <div>
                  <div class="_l">装卸货地点：</div>
                  <div class="_r">
                    <span>
                      {{ form.cargotoName }}
                    </span>
                  </div>
                </div>

                <div>
                  <div class="_l">扣除杂重：</div>
                  <div class="_r">
                    <span>
                      {{ filterFloat(form.otherWight) }}
                    </span>
                  </div>
                </div>

                <div>
                  <div class="_l">毛重：</div>
                  <div class="_r">
                    <span>
                      {{ filterFloat(form.roughWight) }}
                    </span>
                  </div>
                </div>

                <div>
                  <div class="_l">皮重：</div>
                  <div class="_r">
                    <span>
                      {{ filterFloat(form.tareWight) }}
                    </span>
                  </div>
                </div>

                <div>
                  <div class="_l">净重：</div>
                  <div class="_r">
                    <span>
                      {{ filterFloat(form.netWight) }}
                    </span>
                  </div>
                </div>

                <div>
                  <div class="_l">备注：</div>
                  <div class="_r">
                    <span>
                      {{ form.weighOrderNote }}
                    </span>
                  </div>
                </div>

                <div>
                  <div class="_l">仓库确认管理员：</div>
                  <div class="_r">
                    <span>
                      {{ form.warehouseName ? form.warehouseName : "-" }}
                    </span>
                  </div>
                </div>

                <div>
                  <div class="_l">作废人：</div>
                  <div class="_r">
                    <span>
                      {{ form.invalidName ? form.invalidName : "-" }}
                    </span>
                  </div>
                </div>

                <div>
                  <div class="_l">仓库确认时间：</div>
                  <div class="_r">
                    <span>
                      {{ filterInfoTime(form.warehouseConfirmTime) }}
                    </span>
                  </div>
                </div>

                <div>
                  <div class="_l">毛重时间：</div>
                  <div class="_r">
                    <span>
                      {{ filterInfoTime(form.roughTime) }}
                    </span>
                  </div>
                </div>

                <div>
                  <div class="_l">皮重时间：</div>
                  <div class="_r">
                    <span>
                      {{ filterInfoTime(form.tareTime) }}
                    </span>
                  </div>
                </div>

                <div>
                  <div class="_l">净重时间：</div>
                  <div class="_r">
                    <span>
                      {{ filterInfoTime(form.weightOverTime) }}
                    </span>
                  </div>
                </div>

                <div>
                  <div class="_l">作废时间：</div>
                  <div class="_r">
                    <span>
                      {{ filterInfoTime(form.invalidTime) }}
                    </span>
                  </div>
                </div>

                <div>
                  <div class="_l">创建时间：</div>
                  <div class="_r">
                    <span>
                      {{ filterTime(form.insertTime) }}
                    </span>
                  </div>
                </div>

                <div>
                  <div class="_l">打印时间：</div>
                  <div class="_r">
                    <span>
                      {{ filterInfoTime(form.printTime) }}
                    </span>
                  </div>
                </div>

                <div>
                  <div class="_l">记录最新变动时间：</div>
                  <div class="_r">
                    <span>
                      {{ filterTime(form.updateTime) }}
                    </span>
                  </div>
                </div>
              </div>
            </a-tab-pane>
            <a-tab-pane :key="2" tab="司机车辆详情">
              <div class="tab1">
                <div>
                  <div class="_l">司机姓名：</div>
                  <div class="_r">
                    <span>
                      {{ form.driverName }}
                    </span>
                  </div>
                </div>

                <div>
                  <div class="_l">车牌号：</div>
                  <div class="_r">
                    <span>
                      {{ form.vehicleNumber }}
                    </span>
                  </div>
                </div>

                <div>
                  <div class="_l">最大载重：</div>
                  <div class="_r">
                    <span>
                      {{ form.limitTotalLoad }}
                    </span>
                  </div>
                </div>

                <div>
                  <div class="_l">是否限重：</div>
                  <div class="_r">
                    <span>
                      {{ form.isWeightLimit == 1 ? "不限重" : "限重" }}
                    </span>
                  </div>
                </div>

                <div>
                  <div class="_l">集装箱/柜号：</div>
                  <div class="_r">
                    <span>
                      {{ form.containerNo }}
                    </span>
                  </div>
                </div>

                <div>
                  <div class="_l">装卸货地点：</div>
                  <div class="_r">
                    <span>
                      {{ form.cargotoName }}
                    </span>
                  </div>
                </div>

                <div>
                  <div class="_l">银行：</div>
                  <div class="_r">
                    <span>
                      {{ form.bankName }}
                    </span>
                  </div>
                </div>

                <div>
                  <div class="_l">银行卡号：</div>
                  <div class="_r">
                    <span>
                      {{ form.bankNo }}
                    </span>
                  </div>
                </div>
              </div>
            </a-tab-pane>
            <a-tab-pane :key="3" tab="货品信息">
              <div class="tab1">
                <div>
                  <div class="_l">货品名称：</div>
                  <div class="_r">
                    <span>
                      {{ form.goodsName }}
                    </span>
                  </div>
                </div>

                <div>
                  <div class="_l">货品分类：</div>
                  <div class="_r">
                    <span>
                      {{ form.goodsCategoryName }}
                    </span>
                  </div>
                </div>

                <div>
                  <div class="_l">货品编号：</div>
                  <div class="_r">
                    <span>
                      {{ form.goodsNo }}
                    </span>
                  </div>
                </div>

                <div>
                  <div class="_l">货品规格：</div>
                  <div class="_r">
                    <span>
                      {{ form.goodsSpecification }}
                    </span>
                  </div>
                </div>

                <div>
                  <div class="_l">货品总件数：</div>
                  <div class="_r">
                    <span>
                      {{ form.goodsNum }}
                    </span>
                  </div>
                </div>

                <div>
                  <div class="_l">货品重量：</div>
                  <div class="_r">
                    <span>
                      {{ form.goodsWeight }}
                    </span>
                  </div>
                </div>

                <div>
                  <div class="_l">货品重量单位：</div>
                  <div class="_r">
                    <span>
                      {{ form.goodsUnit }}
                    </span>
                  </div>
                </div>

                <div>
                  <div class="_l">货品生产批号：</div>
                  <div class="_r">
                    <span>
                      {{ form.goodsBatchNo }}
                    </span>
                  </div>
                </div>

                <div>
                  <div class="_l">允许额外可配发重量：</div>
                  <div class="_r">
                    <span>
                      {{ form.goodsExtraWeight }}
                    </span>
                  </div>
                </div>

                <div>
                  <div class="_l">每吨扣kg数(吨)：</div>
                  <div class="_r">
                    <span>
                      {{ form.goodsDeductWeight }}
                    </span>
                  </div>
                </div>

                <div>
                  <div class="_l">编织袋重量：</div>
                  <div class="_r">
                    <span>
                      {{ form.goodsBagWeight }}
                    </span>
                  </div>
                </div>
              </div>
            </a-tab-pane>
          </a-tabs>
        </a-modal>
      </div>

      <!--      仓库审核确认-->
      <a-modal
        v-if="showWareHouseCheck"
        :afterClose="
          () => {
            currentOperateRecord = null
            currentRecordTruckOrders = []
            form.selectedTruckOrderIDs = []
            form.otherWight = 0
          }
        "
        :visible="true"
        title="仓库审核确认过磅单"
        width="70%"
        wrapClassName="stationListDialog"
        @cancel="showWareHouseCheck = false"
        @ok="okWarehouseCheck"
      >
        <a-form-model :rules="rules" labelAlign="left">
          <a-table
            :columns="selectGoodsColumns"
            :dataSource="currentRecordTruckOrders || []"
            :row-selection="{
              selectedRowKeys: form.selectedTruckOrderIDs,
              onChange: changeTruckOrderHandler
            }"
            :scroll="{ x: 1100, y: 250, scrollToFirstRowOnChange: true }"
            rowKey="truckOrderID"
          >
          </a-table>
          <a-form-model-item></a-form-model-item>
          <a-form-model-item
            :label-col="{ span: 3 }"
            :wrapper-col="{ span: 6 }"
            label="扣除杂重(吨)"
          >
            <a-input
              v-model="form.otherWight"
              :min="0"
              placeholder="请输入扣除杂重"
              type="number"
            />
          </a-form-model-item>
          <a-form-model-item
            :label-col="{ span: 3 }"
            :wrapper-col="{ span: 17 }"
            label="批号"
          >
            <a-input
              v-model="currentOperateRecord.goodsBatchNo"
              placeholder="请输入批号信息"
              type="textarea"
            />
          </a-form-model-item>
        </a-form-model>
      </a-modal>
    </div>
  </div>
</template>

<script>
import SearchOptions from "@/components/Crud/SearchOptions"
import Operations from "@/components/Crud/Operations"
import Pagination from "@/components/Crud/Pagination"
import CRUD, { form, header, presenter } from "@crud/crud"
import Api, {
  getAllCheckedTruckOrder,
  getAllDrivers,
  getVehicleProcessWeighOrder,
  getVehicleTruckOrder,
  invalidOrder,
  warehousecheckOrder
} from "@/api/order/weigh-order"
import { getAll as getAllReceive } from "@/api/station/receive"
import { getAll as getAllOrigin } from "@/api/station/origin"
import { getAll as getAllGoods, getAllCategory } from "@/api/station/goods"
import {
  ORDER_TYPE,
  ORDER_TYPE_MAP,
  UNIT_TYPE_MAP,
  WEIGH_MAP,
  WEIGH_NUM_MAP,
  WEIGH_ORDER_STATUS,
  WEIGH_ORDER_STATUS_MAP,
  WEIGHT_METHOD_MAP,
  WEIGHT_TYPE_MAP
} from "@/config"
import { filterMapSearch, fullTimeFilter } from "@/utils/utils"
import {
  selectTruckOrderGoodsColumns,
  selectTruckOrdersColumns
} from "@/views/order/view-columns"
import numberGrow from "@/components/Number/index"
import mqtt from "mqtt"
import echarts from "echarts"

// 表格的填充字段
const defaultForm = {
  orderType: null,
  receiveType: null,
  receiveID: null,
  originType: null,
  originID: null,
  goodCategoryID: null,
  goodsIDs: [],
  selectGoods: [],
  goodsID: null,
  goodsNum: null,
  goodsWeight: null,
  goodsNote: "",
  verifierID: null,

  selectedTruckOrderIDs: [],
  otherWight: 0
}

export default {
  name: "WeightOrder",
  // presenter 生成crud对象
  mixins: [presenter(), header(), form(defaultForm)],
  cruds() {
    return CRUD({
      title: "过磅单",
      columns: [
        {
          title: "过磅单号",
          dataIndex: "weighOrderNo",
          align: "center",
          width: 160
        },
        {
          title: "关联派车单号",
          dataIndex: "truckOrderNo",
          align: "center",
          width: 220
        },
        {
          title: "关联订单号",
          dataIndex: "orderNo",
          align: "center",
          width: 220
        },
        {
          title: "订单类型",
          width: 110,
          align: "center",
          scopedSlots: { customRender: "orderType" }
        },
        {
          title: "车牌号",
          dataIndex: "vehicleNumber",
          width: 100,
          align: "vehicleNumber"
        },
        {
          title: "发货单位",
          dataIndex: "originName",
          ellipsis: true,
          align: "center",
          width: 220
        },
        {
          title: "收货单位",
          dataIndex: "receiveName",
          ellipsis: true,
          align: "center",
          width: 220
        },
        {
          title: "装卸货地点",
          dataIndex: "cargotoName",
          ellipsis: true,
          align: "center",
          width: 180
        },
        {
          title: "货品名称",
          dataIndex: "goodsName",
          ellipsis: true,
          align: "center",
          width: 200
        },
        {
          title: "货品编码",
          dataIndex: "goodsNo",
          ellipsis: true,
          align: "center",
          width: 170
        },
        {
          title: "货品规格",
          dataIndex: "goodsSpecification",
          align: "center",
          width: 100
        },
        {
          title: "皮重",
          dataIndex: "tareWight",
          align: "center",
          width: 110
        },
        {
          title: "毛重",
          dataIndex: "roughWight",
          align: "center",
          width: 110
        },

        {
          title: "净重",
          dataIndex: "netWight",
          align: "center",
          width: 80
        },

        {
          title: "皮重时间",
          // dataIndex: "tareTime",
          align: "center",
          width: 160,
          scopedSlots: { customRender: "tareTime" }
        },

        {
          title: "毛重时间",
          // dataIndex: "roughTime",
          align: "center",
          width: 160,
          scopedSlots: { customRender: "roughTime" }
        },

        {
          title: "过磅结束时间",
          // dataIndex: "weightOverTime",
          align: "center",
          width: 160,
          scopedSlots: { customRender: "weightOverTime" }
        },

        {
          title: "创建日期",
          width: 160,
          align: "center",
          scopedSlots: { customRender: "insertTime" }
        },
        {
          title: "近期修改日期",
          width: 160,
          align: "center",
          scopedSlots: { customRender: "updateTime" }
        },
        {
          title: "过磅单状态",
          align: "center",
          fixed: "right",
          width: 110,
          scopedSlots: { customRender: "status" }
        },
        {
          title: "操作",
          fixed: "right",
          width: 220,
          align: "center",
          scopedSlots: { customRender: "operation" }
        }
      ],
      crudMethod: { ...Api },
      idField: "weighOrderID" // 设置每一条记录的唯一标失
    })
  },
  watch: {
    queryTruckOrder: {
      handler(newVal) {
        this.createWeighOrderForm.filterTruckOrders = this.allQueryTruckOrderList.filter(
          item => {
            for (const key of Object.keys(newVal)) {
              if (!item[key].includes(newVal[key])) {
                return false
              }
            }
            return true
          }
        )
      },
      deep: true
    },
    selectedTruckOrder: {
      handler(newVal) {
        if (!newVal) {
          this.createWeighOrderForm.selectedTruckOrderIDs = []
          this.createWeighOrderForm.selectedTruckOrderID = null
          this.createWeighOrderForm.hasTruckOrder = 0
        }

        this.createWeighOrderForm.goodsID = newVal ? newVal.goodsID : null
        this.createWeighOrderForm.originID = newVal ? newVal.originID : null
        this.createWeighOrderForm.receiveID = newVal ? newVal.receiveID : null
        this.createWeighOrderForm.driverID = newVal ? newVal.driverID : null
        this.createWeighOrderForm.driverTel = newVal ? newVal.driverTel : null
        this.createWeighOrderForm.driverName = newVal ? newVal.driverName : null
        this.createWeighOrderForm.vehicleNumber = newVal
          ? newVal.vehicleNumber
          : null
        this.createWeighOrderForm.deduct = newVal ? newVal.otherWight : null

        this.createWeighOrderForm.hasTruckOrder = newVal.truckOrderID
      },
      deep: true
    },
    createWeighOrderForm: {
      async handler(newVal) {
        if (newVal.weightMethodType === 1) {
          this.selectedTruckOrder = null
        }
        if (newVal.weightMethodType === 1 && newVal.weightType === 2) {
          this.createWeighOrderForm.weightType = 1
        }
        if (
          newVal.vehicleNumber !== this.lastVehicleNumber ||
          newVal.weightMethodType !== this.lastWeighMethod ||
          newVal.weightType != this.lastWeighType
        ) {
          if (newVal.weightMethodType === 1 && this.lastWeighMethod !== 1) {
            this.createWeighOrderForm.tareWight = 0
            this.createWeighOrderForm.weighOrderID = 0
            this.createWeighOrderForm.weightType = 1
          } else {
            if (newVal.vehicleNumber) {
              const res = await getVehicleProcessWeighOrder({
                vehicleNumber: newVal.vehicleNumber
              })
              if (res.code === 0 && res.result && res.result.length > 0) {
                this.lastVehicleWeighOrder = res.result.sort(
                  (p, b) =>
                    new Date(p.insertTime).getTime() -
                    new Date(b.insertTime).getTime()
                )[0]

                if (this.lastVehicleWeighOrder) {
                  this.createWeighOrderForm.weighOrderID = this.lastVehicleWeighOrder.weighOrderID
                  this.createWeighOrderForm.roughWight = this.lastVehicleWeighOrder.roughWight
                  this.createWeighOrderForm.tareWight = this.lastVehicleWeighOrder.tareWight
                  this.createWeighOrderForm.netWight = this.lastVehicleWeighOrder.netWight
                } else {
                  this.createWeighOrderForm.weighOrderID = 0
                  this.createWeighOrderForm.tareWight = 0
                  this.createWeighOrderForm.roughWight = 0
                  this.createWeighOrderForm.netWight = 0
                }
              } else {
                this.createWeighOrderForm.weighOrderID = 0
                this.createWeighOrderForm.tareWight = 0
                this.createWeighOrderForm.roughWight = 0
                this.createWeighOrderForm.netWight = 0
              }
            } else {
              this.createWeighOrderForm.weighOrderID = 0
              this.createWeighOrderForm.tareWight = 0
              this.createWeighOrderForm.roughWight = 0
              this.createWeighOrderForm.netWight = 0
            }
          }
          this.lastWeighType = newVal.weightType
          this.lastVehicleNumber = newVal.vehicleNumber
          this.lastWeighMethod = newVal.weightMethodType
        }
      },
      deep: true
    },
    selectWeightNum: {
      handler() {
        const that = this
        setTimeout(() => {
          that.drawChart()
        }, 200)
      }
    }
  },
  data() {
    return {
      mapVisible: false,
      lng: "",
      lat: "",
      showType: "info",
      unitMap: UNIT_TYPE_MAP,

      weighOrderStatus: WEIGH_ORDER_STATUS,
      weighOrderStatusMap: WEIGH_ORDER_STATUS_MAP,
      orderType: { ...ORDER_TYPE, "4": "直接称重" },
      orderTypeMap: [...ORDER_TYPE_MAP, { key: 4, value: "直接称重" }],

      allReceiveMap: [],
      allOriginMap: [],
      allGoodsMap: [],
      allGoodsNameMap: [],
      allGoodsNoMap: [],
      allCategoryMap: [],
      allDriverMap: [],

      activeKey: 1,

      currentOperateRecord: null,
      currentRecordTruckOrders: [],
      showWareHouseCheck: false,

      selectGoodsColumns: selectTruckOrderGoodsColumns,
      selectTruckOrderColumns: selectTruckOrdersColumns,

      weightMethodType: WEIGHT_METHOD_MAP,
      weightType: WEIGHT_TYPE_MAP,
      weightNumMap: WEIGH_NUM_MAP,
      weighMap: WEIGH_MAP,

      mqttClient: null,

      allQueryTruckOrderList: [],
      queryTruckOrder: {},
      selectedTruckOrder: null,
      weighGroupValue: {
        "1": 0,
        "2": 0,
        "3": 0,
        "4": 0,
        "5": 0,
        "6": 0,
        "7": 0,
        "8": 0,
        "9": 0,
        "10": 0
      },
      weighGroupValueArray: {
        "1": [],
        "2": [],
        "3": [],
        "4": [],
        "5": [],
        "6": [],
        "7": [],
        "8": [],
        "9": [],
        "10": []
      },
      weightGroupValueSize: 50,

      selectWeightNum: 1,
      selectWeight: 1,

      lastVehicleNumber: "",
      lastWeighMethod: 2,
      lastWeighType: 1,
      lastVehicleWeighOrder: null,

      defaultWeighOrderForm: {
        showTruckOrder: false,
        filterTruckOrders: [],
        selectedTruckOrderIDs: [],
        selectedTruckOrderID: null,

        weightMethodType: 2,
        weightType: 1,

        tareWight: 0, //皮重
        netWight: 0, //净重
        roughWight: 0, //毛重

        truckOrderID: 0,
        weighOrderID: 0,
        deduct: 0,
        goodsID: null,
        originID: null,
        receiveID: null,
        driverID: null,
        driverTel: null,
        driverName: null,
        vehicleNumber: null,
        note: null
      },
      createWeighOrderForm: { ...this.defaultWeighOrderForm },
      weightValue: [],

      rules: {
        orderType: [
          { required: true, message: "请选择类型", trigger: "change" }
        ],
        receiveID: [
          { required: true, message: "请选择发货地址", trigger: "change" }
        ],
        originID: [
          { required: true, message: "请选择收货地址", trigger: "change" }
        ],
        goodsID: [{ required: true, message: "请选择货品", trigger: "change" }]
      }
    }
  },
  components: { SearchOptions, Operations, Pagination, numberGrow },
  async created() {
    /**
     * 设置按钮权限
     */
    let { code } = this.$route.meta
    // 关闭下载功能
    // this.crud.optShow.download = true
    this.crud.optShow.edit = false
    // 显示以下按钮
    this.crud.permission = {
      // add: code.includes("新增过磅单"),
      del: code.includes("删除过磅单"),
      edit: code.includes("修改过磅单"),
      download: code.includes("下载过磅单")
    }

    const [
      receiveListRes,
      originListRes,
      goodsListRes,
      getAllCategoryRes,
      getAllDriverRes
    ] = await Promise.all([
      getAllReceive(),
      getAllOrigin(),
      getAllGoods(),
      getAllCategory(),
      getAllDrivers()
    ])
    if (receiveListRes.code === 0) {
      this.allReceiveMap = receiveListRes.result.list.map(item => ({
        id: item.receiveID,
        type: item.type,
        name: item.name
      }))
    }

    if (originListRes.code === 0) {
      this.allOriginMap = originListRes.result.list.map(item => ({
        id: item.originID,
        type: item.type,
        name: item.name
      }))
    }

    if (goodsListRes.code === 0) {
      this.allGoodsMap = goodsListRes.result.list
      this.allGoodsNameMap = goodsListRes.result.list.map(item => ({
        id: item.goodsID,
        categoryID: item.categoryID,
        name: item.name
      }))

      this.allGoodsNoMap = goodsListRes.result.list.map(item => ({
        id: item.goodsID,
        name: item.goodsNo
      }))
    }

    if (getAllCategoryRes.code === 0) {
      this.allCategoryMap = getAllCategoryRes.result.list.map(item => ({
        id: item.categoryID,
        name: item.name
      }))
    }

    if (getAllDriverRes.code === 0) {
      this.allDriverMap = getAllDriverRes.result
    }

    this.createWeighOrderForm = { ...this.defaultWeighOrderForm }
    const that = this

    setTimeout(() => {
      that.drawChart()
    }, 100)
    that.mqttClientInit()
  },
  methods: {
    mqttClientInit() {
      const that = this
      const options = {
        host: "127.0.0.1",
        port: 8083,
        path: "/mqtt",
        connectTimeout: 4000, // 超时时间
        reconnectPeriod: 4000,
        // host: process.env.MQTT_SERVER_HOST,
        // port: process.env.MQTT_SERVER_PORT,
        // path: process.env.MQTT_SERVER_PATH,
        // connectTimeout: process.env.MQTT_CONNECT_TIME_OUT, // 超时时间
        // reconnectPeriod: process.env.MQTT_RECONNECT_PERIOD, // 重连时间间隔
        clean: true, // 保留会话
        // 认证信息
        clientId: `mqtt_${Math.random()
          .toString(16)
          .slice(3)}`
      }

      try {
        that.mqttClient = mqtt.connect(options)
      } catch (error) {
        console.log("mqtt.connect error", error)
      }
      that.mqttClient.on("connect", () => {
        console.log("Connection succeeded!")
        const topic = "weight/com/+"
        const qos = 0
        that.mqttClient.subscribe(topic, { qos }, error => {
          if (error) {
            console.log("Subscribe to topics error", error)
            return
          }
          console.log("Subscribe to topics res")
        })
      })
      that.mqttClient.on("error", error => {
        console.log(error)
        console.log("Connection failed")
      })
      that.mqttClient.on("message", (topic, message) => {
        console.log("topic:", topic)
        console.log("message:", message.toString())

        let [, num] = topic.match(/weight\/com\/(\d)/)
        num = Number(num)
        that.weighGroupValue[num] = Number(message.toString())
        that.weighGroupValueArray[num].push(Number(message.toString()))
        if (that.weighGroupValueArray[num].length > that.weightGroupValueSize) {
          that.weighGroupValueArray[num].splice(
            0,
            that.weighGroupValueArray[num].length - that.weightGroupValueSize
          )
        }

        if (that.createWeighOrderForm.weightType == 1) {
          that.createWeighOrderForm.roughWight =
            that.weighGroupValue[that.selectWeight]
        } else {
          that.createWeighOrderForm.tareWight =
            that.weighGroupValue[that.selectWeight]
        }

        setTimeout(() => {
          that.drawChart()
        }, 100)
      })
    },
    drawChart() {
      // const data = this.weightValue
      const that = this
      for (let i = 1; i <= that.selectWeightNum; i++) {
        const data = that.weighGroupValueArray[i]
        const chartEleStr = `chart${i}`
        const chartEle = document.getElementById(chartEleStr)
        let myChart = that.$echarts.init(chartEle)
        const option = {
          color: ["#80FFA5", "#00DDFF", "#37A2FF", "#FF0087", "#FFBF00"],
          legend: {
            // data: ["Line 1", "Line 2", "Line 3", "Line 4", "Line 5"]
          },
          grid: {
            left: "3%",
            right: "4%",
            bottom: "3%",
            containLabel: true
          },
          xAxis: [
            {
              type: "category",
              boundaryGap: false
              // data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"]
            }
          ],
          yAxis: [
            {
              type: "value"
            }
          ],
          series: [
            {
              type: "line",
              stack: "Total",
              smooth: true,
              lineStyle: {
                width: 0
              },
              showSymbol: false,
              areaStyle: {
                opacity: 0.8,
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: "rgb(55, 162, 255)"
                  },
                  {
                    offset: 1,
                    color: "rgb(116, 21, 219)"
                  }
                ])
              },
              emphasis: {
                focus: "series"
              },
              data
            }
          ]
        }
        myChart.setOption(option, true)
        myChart.resize({ animation: { duration: 1000 } })
      }
    },
    filterTime: timeStr => fullTimeFilter(timeStr),
    filterMapSearch: filterMapSearch,
    filterInfoTime: function(timeStr) {
      const ymdStr = this.filterTime(timeStr).substring(0, 10)
      if (ymdStr === "0001-01-01") {
        return "-"
      }
      return this.filterTime(timeStr)
    },
    filterFloat: floatStr => {
      const floatValue = parseFloat(floatStr)
      return floatValue.toFixed(2)
    },
    insertTimeChange(dates, dateStrings) {
      if (dateStrings[0] && dateStrings[1]) {
        let time1 = dateStrings[0]
        let time2 = dateStrings[1]
        if (
          new Date(time2.replace(/-/g, "/")).getTime() / 1000 -
            (new Date(time1.replace(/-/g, "/")).getTime() / 1000 +
              24 * 60 * 60) >
          365 * 24 * 60 * 60
        ) {
          this.$message.info("请选择一年内的时间！")
          this.crud.query.insertTimes = [time1, time1]
          return
        }
      }
      this.crud.query.insertTimes = dateStrings
    },
    warehouseTimeChange(dates, dateStrings) {
      if (dateStrings[0] && dateStrings[1]) {
        let time1 = dateStrings[0]
        let time2 = dateStrings[1]
        if (
          new Date(time2.replace(/-/g, "/")).getTime() / 1000 -
            (new Date(time1.replace(/-/g, "/")).getTime() / 1000 +
              24 * 60 * 60) >
          365 * 24 * 60 * 60
        ) {
          this.$message.info("请选择一年内的时间！")
          this.crud.query.warehouseConfirmTime = [time1, time1]
          return
        }
      }
      this.crud.query.warehouseConfirmTime = dateStrings
    },
    financialTimeChange(dates, dateStrings) {
      if (dateStrings[0] && dateStrings[1]) {
        let time1 = dateStrings[0]
        let time2 = dateStrings[1]
        if (
          new Date(time2.replace(/-/g, "/")).getTime() / 1000 -
            (new Date(time1.replace(/-/g, "/")).getTime() / 1000 +
              24 * 60 * 60) >
          365 * 24 * 60 * 60
        ) {
          this.$message.info("请选择一年内的时间！")
          this.crud.query.financialConfirmTime = [time1, time1]
          return
        }
      }
      this.crud.query.financialConfirmTime = dateStrings
    },

    async warehouseCheckOrderOk(record) {
      this.currentOperateRecord = { ...record }
      this.currentOperateRecord["goodsBatchNo"] = record.goodsBatchNo || 0
      const truckOrdersRes = await getVehicleTruckOrder({
        vehicleNumber: record.vehicleNumber.trim()
      })
      this.showWareHouseCheck = true
      if (
        truckOrdersRes.code === 0 &&
        truckOrdersRes.result &&
        truckOrdersRes.result.length > 0
      ) {
        this.currentRecordTruckOrders = truckOrdersRes.result
        if (
          truckOrdersRes.result.find(
            item => item.truckOrderID === record.truckOrderID
          )
        ) {
          this.form.selectedTruckOrderIDs = [record.truckOrderID]
        } else {
          this.form.selectedTruckOrderIDs = []
        }
      } else {
        this.currentRecordTruckOrders = []
        this.form.selectedTruckOrderIDs = []
      }
    },

    changeTruckOrderHandler(electedRowKeys) {
      if (electedRowKeys.length > 0) {
        this.form.selectedTruckOrderIDs = [
          electedRowKeys[electedRowKeys.length - 1]
        ]
      }
    },

    financialCheckOrderOk(record) {
      this.currentOperateRecord = record
    },
    async okWarehouseCheck() {
      if (
        !this.form.selectedTruckOrderIDs ||
        this.form.selectedTruckOrderIDs.length === 0
      ) {
        this.$message.error("请选择本次装车的货品")
        return
      }
      const reqData = {
        weighOrderID: this.currentOperateRecord.weighOrderID,
        goodsBatchNo: this.currentOperateRecord.goodsBatchNo,
        otherWight: this.form.otherWight,
        truckOrderID: this.form.selectedTruckOrderIDs[0]
      }
      await warehousecheckOrder({ ...reqData })
      this.showWareHouseCheck = false
      this.crud.refresh()
    },

    okInvalid(record) {
      invalidOrder({ weighOrderID: record.weighOrderID })
      this.crud.refresh()
    },
    callback(key) {
      this.activeKey = key
    },
    clickCard(ele) {
      this.selectWeight = ele
    },
    async showTruckOrder() {
      const allCheckedTruckOrderRes = await getAllCheckedTruckOrder()
      if (
        allCheckedTruckOrderRes.code === 0 &&
        allCheckedTruckOrderRes.result !== null &&
        allCheckedTruckOrderRes.result.length > 0
      ) {
        this.allQueryTruckOrderList = allCheckedTruckOrderRes.result
        this.createWeighOrderForm.filterTruckOrders =
          allCheckedTruckOrderRes.result
      } else {
        this.allQueryTruckOrderList = []
        this.createWeighOrderForm.filterTruckOrders = []
      }
      this.queryTruckOrder = {}
      this.createWeighOrderForm.showTruckOrder = true
    },
    selectTruckOrderHandler(selectedRowKey) {
      this.createWeighOrderForm.selectedTruckOrderIDs = selectedRowKey
      this.createWeighOrderForm.selectedTruckOrderID = selectedRowKey[0]
    },
    afterCloseSelectTruckOrder() {
      this.allQueryTruckOrderList = []
      this.createWeighOrderForm.filterTruckOrders = []
      this.queryTruckOrder = {}
    },
    okSelectTruckOrder() {
      const that = this
      if (that.createWeighOrderForm.selectedTruckOrderID) {
        that.selectedTruckOrder = that.createWeighOrderForm.filterTruckOrders.find(
          item =>
            Number(item.truckOrderID) ===
            Number(that.createWeighOrderForm.selectedTruckOrderID)
        )
      }
      that.createWeighOrderForm.showTruckOrder = false
    },
    resetTruckOrder() {
      this.selectedTruckOrder = null

      this.createWeighOrderForm.selectedTruckOrderIDs = []
      this.createWeighOrderForm.selectedTruckOrderID = null

      this.createWeighOrderForm.goodsID = null
      this.createWeighOrderForm.originID = null
      this.createWeighOrderForm.receiveID = null
      this.createWeighOrderForm.driverID = null
      this.createWeighOrderForm.driverTel = null
      this.createWeighOrderForm.driverName = null
      this.createWeighOrderForm.vehicleNumber = null
      this.createWeighOrderForm.deduct = 0
    },
    selectedDriver(value) {
      if (value) {
        const selectedDriverItem = this.allDriverMap.find(
          item => item.driverID === value
        )
        if (!selectedDriverItem) {
          this.createWeighOrderForm.driverID = null
        } else {
          this.createWeighOrderForm.driverTel = selectedDriverItem.tel
          this.createWeighOrderForm.driverName = selectedDriverItem.driverName
          this.createWeighOrderForm.vehicleNumber =
            selectedDriverItem.vehicleNumber
        }
      }
    },
    async createWeighOrder() {
      const data = { ...this.createWeighOrderForm }

      if (!data.receiveID) {
        this.$message.error("请输入收货地址")
        return
      }
      if (!data.originID) {
        this.$message.error("请输入发货地址")
        return
      }
      if (!data.goodsID) {
        this.$message.error("请输入称重货品")
        return
      }
      if (!data.driverID) {
        this.$message.error("请选择过磅车辆")
        return
      }
      const res = await Api.add({ ...this.createWeighOrderForm })
      console.log(`res ： ${JSON.stringify(res)}`)
      this.createWeighOrderForm = { ...this.defaultWeighOrderForm }
    }
  }
}
</script>

<style lang="scss" scoped>
.card1 {
  border: none;
  border-radius: 10px;
  background-color: #f9f9f9;
  box-shadow: 16px 16px 32px 0px rgba(201, 201, 201, 0.5);
  width: 30%;
  height: 420px;
}
</style>

import { method } from "@/utils/request"
const url = process.env.VUE_APP_API_URL
const api = {
  Get: "attachment/list/customer-id",
  GetByContractId:"attachment/list/contract-id",
  Post:"attachment",
  Put:"attachment",
  GetTypeRate:"attachment/static/type-rate",
  GetAttachmentDetailById: "attachment/detail"
}
export function getAttachmentListByCustomerId(parameter) {
  return method(url)({
    url: api.Get,
    method: "get",
    data: parameter
  })
}
export function getAttachmentListByContractId(parameter) {
  return method(url)({
    url: api.GetByContractId,
    method: "get",
    data: parameter
  })
}

export function postAttachmentInfo(parameter) {
  return method(url)({
    url:api.Post,
    method:'post',
    data:{...parameter}
  })
}

export function editAttachmentInfo(parameter) {
  return method(url)({
    url:api.Put,
    method:'put',
    data:{...parameter}
  })
}
export function getTypeRate(parameter) {
  return method(url)({
    url:api.GetTypeRate,
    method:'get',
    data:{...parameter}
  })
}

export function getAttachmentDetailById(parameter) {
  return method(url)({
    url: api.GetAttachmentDetailById,
    method: "get",
    data: { ...parameter }
  })
}
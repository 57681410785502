export const AttachmentListColumns = [
  {
    title: "公司名称",
    align: "center",
    ellipsis: true,
    resizable: true,
    dataIndex: "customerName",
    key: "customerName",
    // scopedSlots: { customRender: "customerName" }
  },
  {
    title: "附件类型",
    align: "center",
    ellipsis: true,
    resizable: true,
    dataIndex: "attachmentTypeValue",
    key: "attachmentTypeValue"
  },
  {
    title: "附件名称",
    align: "center",
    ellipsis: true,
    resizable: true,
    dataIndex: "fileName",
    key: "fileName"
    // scopedSlots: { customRender: "customerNum" }
  },
  {
    title: "附件地址",
    align: "center",
    ellipsis: true,
    resizable: true,
    // dataIndex: "filePath",
    key: "filePath",
    scopedSlots: { customRender: "filePath" }
  },
  {
    title: "创建者",
    align: "center",
    ellipsis: true,
    resizable: true,
    dataIndex: "creator",
    key: "creator"
  },

  {
    title: "合同编号",
    ellipsis: true,
    align: "center",
    resizable: true,
    dataIndex: "contractNo",
    // scopedSlots: { customRender: "insertTime" },
    key: "contractNo"
  },
  {
    title: "操作",
    fixed: "right",
    align: "center",
    key:"operation",
    scopedSlots: { customRender: "operation" }
  }
]
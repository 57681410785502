<template>
  <div class="app-container">
    <div class="head-container">
      <a-form layout="inline">
        <a-row :gutter="40">

          <a-col :md="4" :sm="24">
            <a-form-item label="所属企业">
              <a-select
                v-model="crud.query.customerID"
                :filterOption="filterCategory"
                allow-clear
                placeholder="请选择所属企业"
                show-search
              >
                <a-select-option
                  v-for="item in allCustomerMap"
                  :key="item.customerID"
                  :value="item.customerID"
                >{{ item.customerName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

<!--          <a-col :md="4" :sm="24">-->
<!--            <a-form-item label="关联项目">-->
<!--              <a-select-->
<!--                v-model="crud.query.projectID"-->
<!--                :filterOption="filterCategory"-->
<!--                allow-clear-->
<!--                placeholder="请选择关联项目"-->
<!--                show-search-->
<!--              >-->
<!--                <a-select-option-->
<!--                  v-for="item in allProjectMap"-->
<!--                  :key="item.projectID"-->
<!--                  :value="item.projectID"-->
<!--                >{{ item.projectNo }}-->
<!--                </a-select-option>-->
<!--              </a-select>-->
<!--            </a-form-item>-->
<!--          </a-col>-->

<!--          <a-col :md="4" :sm="24">-->
<!--            <a-form-item label="联系人">-->
<!--              <a-select-->
<!--                v-model="crud.query.contactID"-->
<!--                :allowClear="true"-->
<!--                :filterOption="filterCategory"-->
<!--                placeholder="请选择客户分类"-->
<!--                show-search-->
<!--              >-->
<!--                <a-select-option-->
<!--                  v-for="item in [...allContactMap]"-->
<!--                  :key="Number(item.contactID)"-->
<!--                  :value="Number(item.contactID)"-->
<!--                >{{ item.contactName + "-" + item.contactTel }}-->
<!--                </a-select-option>-->
<!--              </a-select>-->
<!--            </a-form-item>-->
<!--          </a-col>-->

          <a-col :md="4" :sm="24">
            <a-form-item label="合同编号">
              <a-input
                v-model="crud.query.contractNo"
                allow-clear
                placeholder="请输入合同编号"
                style="width: 100%"
              />
            </a-form-item>
          </a-col>
<!--          <a-col :md="4" :sm="24">-->
<!--            <a-form-item label="发票编号">-->
<!--              <a-input-->
<!--                v-model="crud.query.invoiceNo"-->
<!--                allow-clear-->
<!--                placeholder="请输入发票编号"-->
<!--                style="width: 100%"-->
<!--              />-->
<!--            </a-form-item>-->
<!--          </a-col>-->
<!--          <a-col :md="5" :sm="24">-->
<!--            <a-form-item label="发票时间">-->
<!--              <a-range-picker-->
<!--                v-model="crud.query.invoiceTime"-->
<!--                :allowClear="true"-->
<!--                allow-clear-->
<!--                format="YYYY-MM-DD"-->
<!--              />-->
<!--            </a-form-item>-->
<!--          </a-col>-->
          <template v-if="crud.advanced">

          </template>

          <a-col :md="4" :sm="24"></a-col>
          <a-col :md="4" :sm="24"></a-col>
          <a-col :md="4" :sm="24"></a-col>

          <a-col :md="4" :sm="24">
            <SearchOptions
              :toggleShowAdvance="false"
              style="width: 100%"
            />
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div class="table-container">
      <Operations tableName="发票" @toDiyAdd="toDiyAdd"/>
      <a-spin :spinning="crud.loading">
        <a-table
          :columns="crud.columns"
          :data-source="crud.data"
          :pagination="false"
          :row-selection="{
            selectedRowKeys: crud.selections,
            onChange: crud.selectionChangeHandler,
            getCheckboxProps: getCheckboxProps
          }"
          rowKey="contractInvoiceID"
          :scroll="{ y: 1000 }"
        >
          <template slot="invoiceMoney" slot-scope="record">
            {{record.invoiceMoney}}
          </template>
          <template slot="invoiceTime" slot-scope="record">
            {{fullTimeFilter(record.invoiceTime)}}
          </template>
          <template slot="operation" slot-scope="record">
            <a-button
              type="link"
              @click="toInfo(record)"
            >
              详情
            </a-button>
          </template>
        </a-table>
        <Pagination />
      </a-spin>
    </div>
    <div class="form-container">
      <a-drawer
        :title="crud.status.title"
        :visible="
          (crud.status.cu > 0 || crud.status.in > 0) && crud.status.add == 0
        "
        :width="1200"
        placement="right"
        @close="crud.cancelCU()"
      >
        <a-tabs
          :activeKey="activeKey"
          type="card"
          @change="
            key => {
              activeKey = key
            }
          "
        >
          <a-tab-pane :key="1" tab="发票相关信息">
            <a-card :body-style="{ padding: '0px' }" :bordered="false">
              <CurrentInvoiceInfoC ref="currentInvoiceInfoC"></CurrentInvoiceInfoC>
            </a-card>
            <div
              style="display: flex;justify-content: flex-start;vertical-align: middle;align-items: center;margin-top: 10px"
            >
              <h3 style="color: #1890ff;font-size: 14px;margin-left: 10px">该合同下的发票列表</h3>
            </div>
              <a-spin :spinning="crud.loading">
                <a-table
                  :columns="crud.columns"
                  :data-source="currentContractInvoice"
                  :pagination="false"
                  rowKey="contractInvoiceID"
                >
                  <template slot="operation" slot-scope="record">
                    <a-button
                      type="link"
                      @click="contactToInfo(record)"
                    >
                      详情
                    </a-button>
                  </template>
                </a-table>
              </a-spin>
          </a-tab-pane>
        </a-tabs>
        <div class="bottom-button-area">
          <a-button
            v-if="isEdit"
            class="bottom-button"
            type="primary"
            @click="toEdit"
          >
            编辑
          </a-button>
          <div class="edit" v-else>
            <a-button
              class="bottom-button1"
              @click="toCancelEdit"
            >
              取消
            </a-button>

            <a-button
              class="bottom-button2"
              type="primary"
              @click="toSubmitEdit"
            >
              提交
            </a-button>
          </div>
        </div>
      </a-drawer>
      <a-modal
        title="发票单详情"
        :visible="visible"
        width="60%"
        @cancel="handleCancel"
        :footer="null"
      >
        <CurrentInvoiceInfoC ref="reCurrentInvoiceInfoC"></CurrentInvoiceInfoC>
        <div class="bottom-button-area">
          <a-button
            v-if="contractRelationIsEdit"
            class="bottom-button"
            type="primary"
            @click="contractRelationToEdit"
          >
            编辑
          </a-button>
          <div class="edit" v-else>
            <a-button
              class="bottom-button1"
              @click="contractRelationToCancelEdit"
            >
              取消
            </a-button>

            <a-button
              class="bottom-button2"
              type="primary"
              @click="contractRelationToSubmitEdit"
            >
              提交
            </a-button>
          </div>
        </div>
      </a-modal>
    </div>

    <a-modal
      title="添加发票"
      :visible="addFlag"
      :confirm-loading="confirmLoading"
      @ok="toSubAdd"
      @cancel="cancelAdd"
      width="60%"
    >
      <AddInvoice ref="addInvoice"></AddInvoice>
    </a-modal>
  </div>
</template>

<script>
import CurrentInvoiceInfoC from "@/components/customerInfo/current-invoice-info.vue";
import { fullTimeFilter} from "@/utils/utils"
import SearchOptions from "@/components/Crud/SearchOptions"
import Operations from "@/components/Crud/Operations"
import CRUD, { form, header, presenter } from "@crud/crud"
import Api from "@/api/customer/invoiceCrud"
import { GetInvoiceListByContractId,AddInvoiceByContractId } from "@/api/customer/invoice";
import { InvoiceListColumns } from "@/views/invoice/invoice-list";
import { ADMIN_ROLE_ID } from "@/config";
import { GetAllContact, GetAllCustomer, GetAllProject, GetDictionary } from "@/api/public-api";
import store from "@/store";
import Pagination from "@/components/Crud/Pagination"
import { GetProjectGoodsAnalysisList } from "@/api/project";
import { checkOperateDisable } from "@/router/permission"
import AddInvoice from "@/components/customerInfo/add-invoice.vue";

const defaultForm = {
  contactName: "",
  contactTel: "",
  invoiceNo: ""
}

export default {
  name:"Invoice",
  mixins: [presenter(), header(), form(defaultForm)],
  cruds() {
    return CRUD({
      title: "发票",
      roleIDs: [],
      columns: InvoiceListColumns,
      crudMethod: { ...Api },
      idField: "contractInvoiceID" // 设置每一条记录的唯一标失
    })
  },
  components:{
    SearchOptions,
    Operations,
    Pagination,
    CurrentInvoiceInfoC,
    AddInvoice
  },
  data(){
    return {
      queryFuncName: '查询发票列表',
      contractRelationIsEdit: true,
      isEdit:true,
      visible:false,
      currentContractInvoice:[],
      currentInvoice:{},
      activeKey: 1,
      InvoiceListColumns,
      allCustomerMap: [],
      allProjectMap: [],
      allContactMap: [],

      addFlag: false,
      confirmLoading: false
    }
  },
  methods:{
    contractRelationToSubmitEdit(){
      this.$refs.reCurrentInvoiceInfoC.$refs.ruleForm.validate(async valid => {
        if (valid) {
          await this.$refs.reCurrentInvoiceInfoC.subEdit()
            if(this.$refs.reCurrentInvoiceInfoC.isSuccess === 0){
              this.visible = false
              this.$refs.currentInvoiceInfoC.showData()
            }
        } else {
          return false;
        }
      });
    },
    contractRelationToCancelEdit(){
      this.$refs.reCurrentInvoiceInfoC.$refs.singleUploadFileAfter.AllFileCancel()
      this.$refs.reCurrentInvoiceInfoC.fileList = [...this.$refs.currentInvoiceInfoC.originFileList]
      this.$refs.reCurrentInvoiceInfoC.disabled = true
      this.contractRelationIsEdit = true
    },
    contractRelationToEdit(){
      this.contractRelationIsEdit = false
      this.$refs.reCurrentInvoiceInfoC.disabled = false
    },
    toSubmitEdit(){
      this.$refs.currentInvoiceInfoC.$refs.ruleForm.validate(async valid => {
        if (valid) {
          await this.$refs.currentInvoiceInfoC.subEdit()
          if(this.$refs.currentInvoiceInfoC.isSuccess === 0){
            this.isEdit = true
          }

        } else {
          return false;
        }
      });
    },
    toCancelEdit(){
      this.$refs.currentInvoiceInfoC.$refs.singleUploadFileAfter.AllFileCancel()
      this.$refs.currentInvoiceInfoC.fileList = [...this.$refs.currentInvoiceInfoC.originFileList]
      this.$refs.currentInvoiceInfoC.disabled = true
      this.isEdit = true
    },
    toEdit(){
      this.isEdit = false
      this.$refs.currentInvoiceInfoC.disabled = false
    },
    handleCancel(){
      this.visible = false
    },
    contactToInfo(record){
      this.visible = true
      this.$nextTick(()=>{
        this.$refs.reCurrentInvoiceInfoC.currentInvoiceInfo = record
        this.$refs.reCurrentInvoiceInfoC.showData()
      })
    },
    async toInfo(record) {
      console.log(record)
      this.crud.toInfo(record)
      this.$nextTick(()=>{
        this.$refs.currentInvoiceInfoC.currentInvoiceInfo = record
        this.$refs.currentInvoiceInfoC.showData()
      })
      this.currentInvoice = record
      const invoiceObj = await GetInvoiceListByContractId({contractID :this.form.contractID })
      this.currentContractInvoice = invoiceObj.result
    },
    filterCategory: function(value, option) {
      return option.componentOptions.children[0].text.indexOf(value) >= 0
    },
    getCheckboxProps(record) {
      if (record.roleID === 0 && !this.roleIDs.includes(ADMIN_ROLE_ID)) {
        return { props: { disabled: true } }
      }
      return { props: { disabled: false } }
    },
    fullTimeFilter,
    checkOperateDisable,

    async toDiyQuery() {
      const that = this
      that.crud.toQuery()
      if (!this.checkOperateDisable("项目产品数据统计")) {
        const queryRes = await GetProjectGoodsAnalysisList({
          ...that.crud.query
        })
        if (queryRes.code === 0) {
          that.projectGoodsNumList = (
            queryRes.result.goodsNumAnalysisList || []
          ).map((item, index) => ({ index: index + 1, ...item }))
          that.projectGoodsPriceList = (
            queryRes.result.goodsPriceAnalysisList || []
          ).map((item, index) => ({ index: index + 1, ...item }))
        }
      }
    },
    async toDiyResetQuery() {
      const that = this
      that.crud.resetQuery()
      // if (!this.checkOperateDisable("项目产品数据统计")) {
      //   const queryRes = await GetProjectGoodsAnalysisList()
      //   if (queryRes.code === 0) {
      //     that.projectGoodsNumList = (
      //       queryRes.result.goodsNumAnalysisList || []
      //     ).map((item, index) => ({ index: index + 1, ...item }))
      //     that.projectGoodsPriceList = (
      //       queryRes.result.goodsPriceAnalysisList || []
      //     ).map((item, index) => ({ index: index + 1, ...item }))
      //   }
      // }
    },
    toDiyAdd(){
      this.addFlag = true
    },
    toSubAdd(){
      const that = this.$refs.addInvoice
      that.$refs.ruleForm.validate(async valid => {
        if (valid) {
          const addResultObj = await AddInvoiceByContractId({...that.addInvoiceInfo,
            fileName: that.$refs.singleUploadFile.fileList[0].fileName,
            filePath: that.$refs.singleUploadFile.fileList[0].filePathUrl})
          if(addResultObj.code === 12){
            this.addFlag = false
            this.crud.refresh()
          }
        } else {
          return false;
        }
      });

    },
    cancelAdd(){
      this.$refs.addInvoice.$refs.ruleForm.clearValidate()
      this.addFlag = false
    }
  },
  async created() {
    /**
     * 设置按钮权限
     */
    let { code } = this.$route.meta
    // 关闭下载功能
    // this.crud.optShow.download = false
    this.crud.optShow.edit = false
    // 显示以下按钮
    this.crud.permission = {
      add: code.includes("新增发票"),
      del: code.includes("删除发票"),
      edit: code.includes("修改发票")
      // download: true
    }

    const [
      getAllContactRes,
      getAllCustomerRes,
      getAllProjectRes,
      getUserTypeRes,
      getCustomerTagRes
    ] = await Promise.all([
      GetAllContact(),
      GetAllCustomer(),
      GetAllProject(),
      GetDictionary({ typeName: "customerUserType" }),
      GetDictionary({ typeName: "customerTag" })
    ])

    if (getAllContactRes.code === 0) {
      this.allContactMap = getAllContactRes.result
    }

    if (getAllCustomerRes.code === 0) {
      this.allCustomerMap = getAllCustomerRes.result
    }

    if (getAllProjectRes.code === 0) {
      this.allProjectMap = getAllProjectRes.result
    }

    if (getUserTypeRes.code === 0) {
      this.allUserTypeMap = getUserTypeRes.result
    }

    if (getCustomerTagRes.code === 0) {
      this.allCustomerTagMap = getCustomerTagRes.result
    }

    this.roleIDs = store.getters.roleIDs
  },
}
</script>
<style lang="scss" scoped>
.ant-table-striped /deep/ .table-striped td {
  background-color: #fcfcfc;
}

.bottom-button-area {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center; /* 水平居中 */
  padding-top: 200px;

  .bottom-button {
    width: 200px;
    line-height: 30px;
    height: 30px;
  }

  .edit {
    width: 500px;
    height: 40px;

    .bottom-button1 {
      display: inline-block;
      float: left;
      width: 200px;
      line-height: 30px;
      height: 30px;
      margin-right: 100px;
    }

    .bottom-button2 {
      display: inline-block;
      float: left;
      width: 200px;
      line-height: 30px;
      height: 30px;
    }
  }
}
</style>
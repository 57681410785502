<template>
  <a-upload-dragger
    :before-upload="beforeUpload"
    :fileList="fileList"
    :multiple="true"
    name="file"
    @change="uploadChange"
  >
    <p class="ant-upload-drag-icon">
<!--      <inbox-outlined></inbox-outlined>-->
    </p>
    <p class="ant-upload-text">点击或拖拽文件到当前区域，上传文件</p>
    <p class="ant-upload-hint">
      支持单个上传文件
    </p>
  </a-upload-dragger>
</template>

<script>
import { FileUpload, FileRemoved, FileOperateAPI, AllFileCancel } from "@/components/SingleUploadFile/index";
export default {
  name: "SingleUploadFile",
  data(){
    return {
      fileList: [],
      FileOperateAPI
    }
  },
  props:{
    customerID: {
      type: Number,
      default() {
        return 0
      }
    },
    fileUploadPath: {
      type: String,
      default() {
        return FileOperateAPI.FollowUpFileUpload
      }
    },
    fileRemovedPath: {
      type: String,
      default() {
        return FileOperateAPI.FollowUpFileRemoved
      }
    },
    allFileCancelPath: {
      type: String,
      default() {
        return FileOperateAPI.FollowUpAllFileCancel
      }
    }
  },
  methods:{
    async beforeUpload(file) {
      const that = this
      let fd = new FormData() //表单格式
      fd.append("file", file) //添加file表单数据
      fd.append("customerID", that.customerID)
      const uploadFileRes = await FileUpload(that.fileUploadPath, fd)
      console.log(uploadFileRes)
      if (uploadFileRes.code === 0) {
        file["fileName"] = uploadFileRes.result.fileName
        file["filePathUrl"] = uploadFileRes.result.filePathUrl
        this.fileList = [file]
      }
      console.log(`this.fileList ： ${JSON.stringify(this.fileList)}`)
    },
    async uploadChange(data) {
      const that = this
      if(this.fileList.length > 1){
        const deletedUid = this.fileList[0].uid
        const deletedFileName = this.fileList[0].fileName
        const removedRes = await FileRemoved(that.fileRemovedPath, {
          uid: deletedUid,
          fileName: deletedFileName,
          customerID: that.customerID
        })
        if (removedRes.code === 0) {
          console.log(`that.fileList : ${JSON.stringify(that.fileList)}`)
          const deletedIndex = that.fileList.findIndex(
            item => item.uid == deletedUid
          )
          that.fileList.splice(deletedIndex, 1)
          that.$forceUpdate()
        }
      }
      const status = data.file.status
      //删除文件
      if (status === "removed") {
        const deletedUid = data.file.uid
        const deletedFileName = data.file.fileName
        const removedRes = await FileRemoved(that.fileRemovedPath, {
          uid: deletedUid,
          fileName: deletedFileName,
          customerID: that.customerID
        })
        if (removedRes.code === 0) {
          console.log(`that.fileList : ${JSON.stringify(that.fileList)}`)
          const deletedIndex = that.fileList.findIndex(
            item => item.uid == deletedUid
          )
          that.fileList.splice(deletedIndex, 1)
          that.$forceUpdate()
        }
      }
    },
    async AllFileCancel() {
      const that = this
      const allFile = that.fileList
      if (allFile.length > 0) {
        await AllFileCancel(that.allFileCancelPath, {
          files: allFile,
          customerID: that.customerID
        })
      }
      this.fileList = []
    }
  }
};
</script>

<style scoped>

</style>
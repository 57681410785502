var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-layout-header',{staticClass:"own-header",style:({
    position: _vm.navigationmode,
    zIndex: 100,
    width: _vm.navigationmode === 'fixed' ? _vm.headerWidth : '100%',
    height: _vm.headerHeight + 'px'
  })},[_c('a-icon',{staticClass:"trigger",attrs:{"type":_vm.collapsed ? 'menu-unfold' : 'menu-fold'},on:{"click":function () { return (_vm.collapsed = !_vm.collapsed); }}}),_c('div',[_c('a-dropdown',{attrs:{"trigger":['click']}},[_c('div',[_vm._v(" "+_vm._s(_vm.timeFix())+"， "),_c('a',{staticClass:"ant-dropdown-link",on:{"click":function (e) { return e.preventDefault(); }}},[_vm._v(" "+_vm._s(_vm.user.displayName || _vm.user.loginName || "用户名")+" "),_c('a-icon',{attrs:{"type":"down"}})],1)]),_c('a-menu',{attrs:{"slot":"overlay"},slot:"overlay"},[_c('a-menu-item',{on:{"click":_vm.resetPwd}},[_vm._v(" 修改密码 ")]),_c('a-menu-divider'),_c('a-menu-item',{on:{"click":_vm.logout}},[_vm._v(" 退出登录 ")])],1)],1)],1),_c('a-modal',{attrs:{"confirm-loading":_vm.confirmLoading,"visible":_vm.visible,"title":"修改密码"},on:{"cancel":_vm.handleCancel,"ok":_vm.handleOk}},[_c('a-form-model',{ref:"form",attrs:{"label-col":{ span: 5 },"model":_vm.form,"rules":_vm.rules,"wrapper-col":{ span: 19 },"labelAlign":"left"}},[_c('a-form-model-item',{ref:"oldPwd",attrs:{"label":"旧密码","prop":"oldPwd"}},[_c('a-input',{attrs:{"allow-clear":"","type":"password"},on:{"blur":function () {
              _vm.$refs.oldPwd.onFieldBlur()
            }},model:{value:(_vm.form.oldPwd),callback:function ($$v) {_vm.$set(_vm.form, "oldPwd", $$v)},expression:"form.oldPwd"}})],1),_c('a-form-model-item',{ref:"newPwd",attrs:{"label":"新密码","prop":"newPwd"}},[_c('a-input',{attrs:{"allow-clear":"","type":"password"},on:{"blur":function () {
              _vm.$refs.newPwd.onFieldBlur()
            }},model:{value:(_vm.form.newPwd),callback:function ($$v) {_vm.$set(_vm.form, "newPwd", $$v)},expression:"form.newPwd"}})],1),_c('a-form-model-item',{ref:"rePwd",attrs:{"label":"确认密码","prop":"rePwd"}},[_c('a-input',{attrs:{"allow-clear":"","type":"password"},on:{"blur":function () {
              _vm.$refs.rePwd.onFieldBlur()
            }},model:{value:(_vm.form.rePwd),callback:function ($$v) {_vm.$set(_vm.form, "rePwd", $$v)},expression:"form.rePwd"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-container"},[_c('div',{staticClass:"head-container"},[_c('a-form',{attrs:{"layout":"inline"}},[_c('a-row',{attrs:{"gutter":20}},[_c('a-col',{attrs:{"md":4,"sm":24}},[_c('a-form-item',{attrs:{"label":"审批名称"}},[_c('a-input',{attrs:{"allowClear":true,"placeholder":"请输入审批名称"},model:{value:(_vm.crud.query.templateName),callback:function ($$v) {_vm.$set(_vm.crud.query, "templateName", $$v)},expression:"crud.query.templateName"}})],1)],1),_c('a-col',{attrs:{"md":4,"sm":24}},[_c('a-form-item',{attrs:{"label":"审批次数"}},[_c('a-input',{attrs:{"allowClear":true,"placeholder":"请输入审批次数","type":"number"},on:{"input":function () {
                  if (_vm.crud.query.approvalNum < 0) {
                    _vm.crud.query.approvalNum = 0
                  }
                }},model:{value:(_vm.crud.query.approvalNum),callback:function ($$v) {_vm.$set(_vm.crud.query, "approvalNum", $$v)},expression:"crud.query.approvalNum"}})],1)],1),_c('a-col',{attrs:{"md":4,"sm":24}},[_c('a-form-item',{attrs:{"label":"抄送次数"}},[_c('a-input',{attrs:{"allowClear":true,"placeholder":"请输入抄送次数","type":"number"},on:{"input":function () {
                  if (_vm.crud.query.ccNum < 0) {
                    _vm.crud.query.ccNum = 0
                  }
                }},model:{value:(_vm.crud.query.ccNum),callback:function ($$v) {_vm.$set(_vm.crud.query, "ccNum", $$v)},expression:"crud.query.ccNum"}})],1)],1),_c('a-col',{attrs:{"md":4,"sm":24}},[_c('a-form-item',{attrs:{"label":"创建者"}},[_c('a-select',{attrs:{"allowClear":true,"filterOption":_vm.filterCategory,"placeholder":"请选择客户分类","show-search":""},model:{value:(_vm.crud.query.creatorID),callback:function ($$v) {_vm.$set(_vm.crud.query, "creatorID", $$v)},expression:"crud.query.creatorID"}},_vm._l(([].concat( _vm.allUserListMap )),function(item){return _c('a-select-option',{key:Number(item.userID),attrs:{"value":Number(item.userID)}},[_vm._v(_vm._s(item.displayName)+" ")])}),1)],1)],1),_c('a-col',{attrs:{"md":4,"sm":24}},[_c('a-form-item',{attrs:{"label":"创建时间"}},[_c('a-range-picker',{attrs:{"allowClear":true,"format":"YYYY-MM-DD"},model:{value:(_vm.crud.query.times),callback:function ($$v) {_vm.$set(_vm.crud.query, "times", $$v)},expression:"crud.query.times"}})],1)],1),_c('a-col',{attrs:{"md":4,"sm":24}},[_c('SearchOptions',{staticStyle:{"width":"100%"},attrs:{"toggleShowAdvance":false}})],1)],1)],1)],1),_c('div',{staticClass:"table-container"},[_c('Operations',{attrs:{"tableName":"审批模板"},on:{"toDiyAdd":_vm.toDiyAdd}}),_c('a-spin',{attrs:{"spinning":_vm.crud.loading}},[_c('a-table',{staticClass:"ant-table-striped",attrs:{"bordered":true,"columns":_vm.crud.columns,"data-source":_vm.crud.data,"pagination":false,"row-class-name":function (_record, index) { return (index % 2 === 1 ? 'table-striped' : null); },"row-selection":{
          selectedRowKeys: _vm.crud.selections,
          onChange: _vm.crud.selectionChangeHandler
        },"empty-text":"-","rowKey":"approvalTemplateID"},on:{"resizeColumn":function (w, col) {
            col.width = w
          }},scopedSlots:_vm._u([{key:"insertTime",fn:function(record){return [_vm._v(" "+_vm._s(_vm.filterTime(record.insertTime))+" ")]}},{key:"updateTime",fn:function(record){return [_vm._v(" "+_vm._s(_vm.filterTime(record.updateTime))+" ")]}},{key:"operation",fn:function(record){return [_c('a-button',{attrs:{"disabled":!_vm.$route.meta.code.includes('查看审批模板详情'),"type":"link"},on:{"click":function($event){return _vm.toInfo(record)}}},[_vm._v(" 详情 ")])]}}])})],1),_c('Pagination')],1),_c('div',{staticClass:"draw-container"})])}
var staticRenderFns = []

export { render, staticRenderFns }
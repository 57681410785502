export const targetListColumns = [
  {
    title: "图片",
    width: 100,
    align: "center",
    // dataIndex: "unit"
    scopedSlots: { customRender: "goodsImg" }
  },
  {
    title: "货品名",
    width: 200,
    align: "center",
    dataIndex: "goodsName"
  },
  {
    title: "货品编号",
    width: 150,
    align: "center",
    dataIndex: "goodsNo"
  },
  {
    title: "类别",
    width: 120,
    align: "center",
    dataIndex: "categoryName"
  },
  {
    title: "规格型号",
    width: 120,
    align: "center",
    dataIndex: "goodsSpec"
  },
  {
    title: "品牌",
    width: 120,
    align: "center",
    dataIndex: "goodsBrand"
  },
  {
    title: "单位",
    width: 120,
    align: "center",
    dataIndex: "goodsUnit"
  }
]

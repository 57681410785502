export const accountListColumns = [
  {
    title: "工号",
    dataIndex: "workNo",
    width: 80,
    align: "center"
  },
  {
    title: "姓名",
    dataIndex: "displayName",
    width: 120,
    align: "center"
  },
  {
    title: "登录账号",
    dataIndex: "userName",
    width: 150,
    align: "center"
  },
  {
    title: "角色",
    width: 400,
    align: "center",
    ellipsis: true,
    scopedSlots: { customRender: "roleName" }
  },
  {
    title: "职位",
    dataIndex: "jobTitle",
    width: 120,
    align: "center"
  },
  {
    title: "手机号",
    align: "center",
    dataIndex: "userTel",
    width: 180
  },
  {
    title: "创建时间",
    align: "center",
    width: 180,
    scopedSlots: { customRender: "insertTime" }
  },
  {
    title: "操作",
    fixed: "right",
    align: "center",
    width: 200,
    scopedSlots: { customRender: "operation" }
  }
]

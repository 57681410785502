<template>
  <div>
    <a-modal
      :visible="toShowFlag != PROJECT_SHOW_TYPE.NOT_SHOW"
      title="产品详情"
      width="80%"
      wrapClassName="stationListDialog"
      @cancel="cancelProject"
    >
      <a-form-model
        ref="addProjectForm"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 18 }"
        labelAlign="left"
      >
        <a-row :gutter="48">
          <a-col :lg="24" :md="24" :sm="24" :xl="24" :xs="24" :xxl="24">
            <div
              style="display: flex;justify-content: flex-start;vertical-align: middle;align-items: center"
            >
              <h3 style="color: #0b64f4;">产品基本信息</h3>
            </div>
          </a-col>
        </a-row>
        <a-row :gutter="48">
          <a-col :lg="6" :md="24" :sm="24" :xl="6" :xs="24" :xxl="6">
            <a-form-model-item label="合同编号">
              <span>{{ currentProject.contractNo }}</span>
            </a-form-model-item>
          </a-col>
          <a-col :lg="6" :md="24" :sm="24" :xl="6" :xs="24" :xxl="6">
            <a-form-model-item label="产品编号">
              <span>{{ currentProject.projectNo }}</span>
            </a-form-model-item>
          </a-col>
          <a-col :lg="6" :md="24" :sm="24" :xl="6" :xs="24" :xxl="6">
            <a-form-model-item label="货品名称">
              <span>{{ currentProject.goodsName }}</span>
            </a-form-model-item>
          </a-col>
          <a-col :lg="6" :md="24" :sm="24" :xl="6" :xs="24" :xxl="6">
            <a-form-model-item label="货品规格">
              <span>{{ currentProject.goodsSpec }}</span>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="48">
          <a-col :lg="6" :md="24" :sm="24" :xl="6" :xs="24" :xxl="6">
            <a-form-model-item label="货品类型">
              <span>{{ currentProject.categoryName }}</span>
            </a-form-model-item>
          </a-col>
          <a-col :lg="6" :md="24" :sm="24" :xl="6" :xs="24" :xxl="6">
            <a-form-model-item label="单价">
              <span>{{ currentProject.unitPrice }}</span>
            </a-form-model-item>
          </a-col>
          <a-col :lg="6" :md="24" :sm="24" :xl="6" :xs="24" :xxl="6">
            <a-form-model-item label="数量">
              <span>{{ currentProject.number }}</span>
            </a-form-model-item>
          </a-col>
          <a-col :lg="6" :md="24" :sm="24" :xl="6" :xs="24" :xxl="6">
            <a-form-model-item label="总价">
              <span>{{ currentProject.totalPrice }}</span>
            </a-form-model-item>
          </a-col>
        </a-row>

        <a-row :gutter="48">
          <a-col :lg="6" :md="24" :sm="24" :xl="6" :xs="24" :xxl="6">
            <a-form-model-item label="客户联系人">
              <span>{{ currentProject.contactName }}</span>
            </a-form-model-item>
          </a-col>
          <a-col :lg="6" :md="24" :sm="24" :xl="6" :xs="24" :xxl="6">
            <a-form-model-item label="客户联系方式">
              <span>{{ currentProject.contactTel }}</span>
            </a-form-model-item>
          </a-col>
          <a-col :lg="6" :md="24" :sm="24" :xl="6" :xs="24" :xxl="6">
            <a-form-model-item label="签约日期" labelAlign="left">
              <span>{{ filterTime(currentProject.signTime) }}</span>
            </a-form-model-item>
          </a-col>
          <a-col :lg="6" :md="24" :sm="24" :xl="6" :xs="24" :xxl="6">
            <a-form-model-item label="交付日期" labelAlign="left">
              <span>{{ filterTime(currentProject.releasedTime) }}</span>
            </a-form-model-item>
          </a-col>
        </a-row>

        <a-row :gutter="48">
          <a-col :lg="6" :md="24" :sm="24" :xl="6" :xs="24" :xxl="6">
            <a-form-model-item label="创建日期">
              <span>{{ filterTime(currentProject.insertTime) }}</span>
            </a-form-model-item>
          </a-col>
          <a-col :lg="6" :md="24" :sm="24" :xl="6" :xs="24" :xxl="6">
            <a-form-model-item label="近期修改日期" labelAlign="left">
              <span>{{ filterTime(currentProject.updateTime) }}</span>
            </a-form-model-item>
          </a-col>
        </a-row>

        <a-row :gutter="48" style="margin-top: 20px">
          <a-col :lg="20" :md="24" :sm="24" :xl="20" :xs="24" :xxl="20">
            <div
              style="display: flex;justify-content: flex-start;vertical-align: middle;align-items: center"
            >
              <h3 style="color: #0b64f4;">合同关联的其他产品</h3>
            </div>
          </a-col>
        </a-row>
        <a-row>
          <a-col :lg="24" :md="24" :sm="24" :xl="24" :xs="24" :xxl="24">
            <a-card
              :body-style="{ padding: '0px' }"
              :bordered="false"
              class="card2"
            >
              <a-table
                :bordered="true"
                :columns="projectColumns"
                :data-source="otherProjects"
                :row-class-name="
                  (_record, index) => (index % 2 === 1 ? 'table-striped' : null)
                "
                :scroll="{ x: 1100 }"
                class="ant-table-striped"
                empty-text="-"
                rowKey="projectID"
                @resizeColumn="
                  (w, col) => {
                    col.width = w
                  }
                "
              >
                <template slot="projectNo" slot-scope="record">
                  {{ record.projectNo }}
                </template>
                <template slot="releasedTime" slot-scope="record">
                  {{ filterTime(record.releasedTime) }}
                </template>
              </a-table>
            </a-card>
          </a-col>
        </a-row>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import { PROJECT_SHOW_TYPE } from "@/config"
import { fullTimeFilter } from "@/utils/utils"
import { projectColumns } from "@/components/customerInfo/contract-list"

export default {
  name: "ContractInfo",
  data() {
    return {
      PROJECT_SHOW_TYPE,
      toShowFlag: PROJECT_SHOW_TYPE.NOT_SHOW,
      currentProject: {},
      otherProjects: [],
      projectColumns
    }
  },
  props: {},
  async created() {},
  methods: {
    filterTime: timeStr => fullTimeFilter(timeStr),
    cancelProject() {
      const that = this
      that.toShowFlag = PROJECT_SHOW_TYPE.NOT_SHOW
      that.currentProject = {}
      that.otherProjects = []
    }
  }
}
</script>

<style scoped>
.ant-table-striped /deep/ .table-striped td {
  background-color: #fcfcfc;
}
</style>

<template>
  <div>
    <a-form-model
      ref="form"
      :label-col="{ span: 4 }"
      :model="userInfo"
      :rules="rules"
      :wrapper-col="{ span: 20 }"
      class="form-model"
      labelAlign="left"
    >
      <a-form-model-item label="姓名:" prop="displayName">
        <a-input
          v-model="userInfo.displayName"
          :disabled="operateType === BASE_OPERATE_TYPE.INFO"
          @change="userInfo.userChanged = true"
        />
      </a-form-model-item>
      <a-form-model-item label="账号:" prop="userName">
        <a-input
          v-model="userInfo.userName"
          :disabled="operateType === BASE_OPERATE_TYPE.INFO"
          @change="userInfo.loginNameChanged = true"
        />
      </a-form-model-item>
      <a-form-model-item label="工号:" prop="workNo">
        <a-input
          v-model="userInfo.workNo"
          :disabled="operateType === BASE_OPERATE_TYPE.INFO"
          @change="userInfo.userChanged = true"
        />
      </a-form-model-item>
      <a-form-model-item v-if="roleIDs.length > 0" label="角色:" prop="roleIDs">
        <div v-for="(item, index) in roleIDs" :key="index">
          <a-select
            v-model="item.id"
            :disabled="operateType === BASE_OPERATE_TYPE.INFO"
            placeholder="请选择"
            style="width: 200px"
            @change="changeRoleID(item.id)"
          >
            <a-select-option
              v-for="item in [...allRoleMap, { roleID: 0, roleName: '无' }]"
              :key="item.roleID"
              :value="item.roleID"
              >{{ item.roleName }}
            </a-select-option>
          </a-select>
          <a-radio-group
            v-model="item.userType"
            :disabled="operateType === BASE_OPERATE_TYPE.INFO"
            style="margin-left: 10px"
          >
            <a-radio :value="1">员工</a-radio>
            <a-radio :value="2">负责人</a-radio>
          </a-radio-group>
          <!--          <a-button-->
          <!--            :disabled="operateType === BASE_OPERATE_TYPE.INFO"-->
          <!--            type="link"-->
          <!--            @click="deleteRole()"-->
          <!--            >删除-->
          <!--          </a-button>-->
          <!--          <a-button-->
          <!--            :disabled="operateType === BASE_OPERATE_TYPE.INFO"-->
          <!--            type="link"-->
          <!--            @click="addRole()"-->
          <!--            >新增-->
          <!--          </a-button>-->
        </div>
      </a-form-model-item>
      <a-form-model-item label="手机号:" prop="userTel">
        <a-input
          v-model="userInfo.userTel"
          :disabled="operateType === BASE_OPERATE_TYPE.INFO"
          @change="userInfo.userChanged = true"
        />
      </a-form-model-item>
      <a-form-model-item label="生日:" prop="birthDate">
        <a-date-picker
          :v-model="userInfo.birthDate && userInfo.birthDate"
          :disabled="operateType === BASE_OPERATE_TYPE.INFO"
          @change="userInfo.userChanged = true"
        />
      </a-form-model-item>
      <a-form-model-item label="性别:" prop="gender">
        <a-radio-group
          v-model="userInfo.gender"
          :disabled="operateType === BASE_OPERATE_TYPE.INFO"
          @change="userInfo.userChanged = true"
        >
          <a-radio :value="1">男</a-radio>
          <a-radio :value="2">女</a-radio>
          <a-radio :value="3">未知</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="职位:" prop="jobTitle">
        <a-input
          v-model="userInfo.jobTitle"
          :disabled="operateType === BASE_OPERATE_TYPE.INFO"
          @change="userInfo.userChanged = true"
        />
      </a-form-model-item>
      <a-form-model-item label="邮箱:" prop="email">
        <a-input
          v-model="userInfo.email"
          :disabled="operateType === BASE_OPERATE_TYPE.INFO"
          @change="userInfo.userChanged = true"
        />
      </a-form-model-item>
      <a-form-model-item
        v-if="operateType === BASE_OPERATE_TYPE.INFO"
        label="创建时间:"
      >
        <div style="padding-left:10px;">
          {{ fullTimeFilter(userInfo.insertTime) }}
        </div>
      </a-form-model-item>
    </a-form-model>
    <div class="footer">
      <div
        v-if="operateType === BASE_OPERATE_TYPE.INFO && userInfo.userID != 1"
      >
        <a-button type="primary" @click="toEdit()">
          编辑
        </a-button>
      </div>
      <div
        v-if="operateType === BASE_OPERATE_TYPE.EDIT && userInfo.userID != 1"
      >
        <a-button class="first_btn" @click="cancelEdit()">
          取消
        </a-button>
        <a-button type="primary" @click="submitEdit()">
          提交
        </a-button>
      </div>
      <div v-if="operateType === BASE_OPERATE_TYPE.ADD">
        <a-button type="primary" @click="submitAdd()">
          保存
        </a-button>
      </div>
    </div>
  </div>
</template>

<script>
import { BASE_OPERATE_TYPE } from "@/config"
import { add, GetUserDetail, updateuser } from "@/api/system/account"
import { fullTimeFilter } from "@/utils/utils"
import { listRole } from "@/api/system/menu"

export default {
  name: "account-pane",
  data() {
    let checkPending

    let checkLoginName = (rule, value, callback) => {
      clearTimeout(checkPending)
      if (!value) {
        return callback(new Error("请输入账号！"))
      }
      checkPending = setTimeout(() => {
        if (value.length < 4 || value.length > 12) {
          callback(new Error("请输入4-12位用户名"))
        }
        if (!/^[a-zA-Z0-9]+$/.test(value)) {
          callback(new Error("请输入数字或字母！"))
        }
        callback()
      }, 1000)
    }

    let checkDisplayName = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入姓名！"))
      }
      if (!/^[\u4e00-\u9fa5]+$/.test(value)) {
        callback(new Error("姓名只能输入中文！"))
      } else if (value.trim().length < 2 || value.trim().length > 5) {
        callback(new Error("姓名只能2-5个字符！"))
      } else {
        callback()
      }
    }
    return {
      hasChangeRole: 0,
      originRoleIDs: [],
      operateType: "",
      userID: 0,
      BASE_OPERATE_TYPE,
      userInfo: {},
      rules: {
        displayName: [
          { required: true, validator: checkDisplayName, trigger: "blur" }
        ],
        userName: [
          { required: true, validator: checkLoginName, trigger: "blur" }
        ]
      },
      hasLoginNameChange: this.$route.meta.code.includes("修改账户名"),
      hasUserChange: this.$route.meta.code.includes("修改账号基本信息"),
      hasUserRoleChange: this.$route.meta.code.includes("修改账户角色"),
      roleIDs: [{ id: 0, userType: 0 }],
      allRoleMap: []
    }
  },
  async mounted() {
    const listRoleRes = await listRole()
    if (listRoleRes.code === 0) {
      this.allRoleMap = listRoleRes.result
    }
  },
  methods: {
    fullTimeFilter: fullTimeFilter,
    async showData(userID, operateType) {
      const that = this
      that.operateType = operateType
      that.userInfo = {}
      that.roleIDs = [{ id: 0, userType: 0 }]
      if (that.operateType === BASE_OPERATE_TYPE.INFO) {
        const userDetailRes = await GetUserDetail({ userID })
        that.$nextTick(() => {
          that.userInfo = { ...userDetailRes.result }
          that.roleIDs = [
            ...userDetailRes.result.role.map(item => ({
              id: item.roleID,
              userType: item.userType
            }))
          ]
          that.originRoleIDs = JSON.parse(JSON.stringify([...that.roleIDs]))
          that.$emit("change-role-id", that.roleIDs[0]?.id)
        })
      }
    },
    addRole() {
      this.roleIDs.push({ id: 0, userType: 0 })
      this.$forceUpdate()
    },
    deleteRole(n) {
      if (this.roleIDs.length === 1) {
        return this.$message.error("至少存在一个权限角色")
      }
      this.roleIDs.splice(n - 1, 1)
      this.$forceUpdate()
    },
    toEdit() {
      this.operateType = BASE_OPERATE_TYPE.EDIT
    },
    cancelEdit() {
      this.operateType = BASE_OPERATE_TYPE.INFO
    },
    checkRoleIDs: function() {
      const value = this.roleIDs
      if (
        !value ||
        value.length == 0 ||
        value.filter(item => item.id !== 0 && item.userType !== 0).length == 0
      ) {
        this.$message.error("请选择角色！")
        return false
      }
      if (
        value.filter(item => item.id === 0 || item.userType === 0).length > 0
      ) {
        this.$message.error("请确认角色和用户类型！")
        return false
      }
      if (
        value.filter(
          (item, index, self) =>
            index === self.findIndex(subItem => subItem.id === item.id)
        ).length !== value.length
      ) {
        this.$message.error("请勿选择重复角色权限！")
        return false
      }
      return true
    },
    submitAdd() {
      const that = this
      that.$refs["form"].validate(async valid => {
        if (!valid || !that.checkRoleIDs()) {
          return
        }
        const res = await add({ ...that.userInfo, roleIDs: that.roleIDs })
        if (res.code === 12) {
          setTimeout(function() {
            that.$message.success("新增用户成功")
            that.$emit("submit-success-after", res.result, that.roleIDs[0].id)
            that.showData(res.result, BASE_OPERATE_TYPE.INFO)
          }, 500)
        } else {
          that.$message.error("新增用户失败")
        }
      })
    },
    submitEdit() {
      const that = this
      that.$refs["form"].validate(async valid => {
        if (!valid || !that.checkRoleIDs()) {
          return
        }
        const res = await updateuser({
          ...that.userInfo,
          roleIDs: that.roleIDs,
          hasChangeRole:
            JSON.stringify(that.roleIDs[0]) ===
            JSON.stringify(that.originRoleIDs[0])
              ? 0
              : 1
        })
        if (res.code === 14) {
          that.$message.success("新增用户成功")
          that.$emit(
            "submit-success-after",
            that.userInfo.userID,
            that.roleIDs[0]
          )
          that.showData(that.userInfo.userID, BASE_OPERATE_TYPE.INFO)
        } else {
          that.$message.error("新增用户失败")
        }
      })
    },
    changeRoleID(roleID) {
      this.$emit("change-role-id", roleID)
    },
    closeDrawer() {
      const that = this
      that.$nextTick(() => {
        that.operateType = ""
        that.userID = 0
        that.roleID = 0
        that.roleIDs = []
        that.originRoleIDs = []
        that.$refs.form.clearValidate()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.form-model {
  height: 800px;
}

.footer {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;

  .send_but {
    margin-left: 50px;
  }

  .first_btn {
    margin-right: 20px;
  }
}
</style>

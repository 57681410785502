var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-layout',{staticStyle:{"transition":"all 0.2s"},style:({
    'padding-left': _vm.navigationmode === 'fixed' ? _vm.paddingLeft + 'px' : 0
  })},[(_vm.navigationmode === 'fixed')?_c('div',{style:({ height: _vm.headerHeight + 'px' })}):_vm._e(),_c('Header',{ref:"header"}),_c('a-layout-content',{staticClass:"LayoutMain",style:({
      margin: '16px 16px 0 16px',
      minHeight: ("calc(100vh - " + (_vm.headerHeight + 32) + "px)"),
      minWidth: ("calc(100vh - " + (_vm.paddingLeft + 32) + "px)")
    })},[_c('div',{style:({
        height: ("calc(100vh - " + (_vm.headerHeight + 32) + "px)")
      })},[_c('transition',{attrs:{"name":"fade-transform","mode":"out-in"}},[_c('router-view')],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
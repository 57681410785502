<template>
  <div>
      <a-table
        :columns="invoiceColumns"
        :data-source="invoiceItem"
        :pagination="false"
        bordered>
        <template slot="invoiceNum" slot-scope="record">
          <a @click="toInfo(record)">{{record.invoiceNo}}</a>
        </template>
        <template slot="amount" slot-scope="record">
          {{record.invoiceMoney}}
        </template>
        <template slot="time" slot-scope="record">
          {{fullTimeFilter(record.invoiceTime)}}
        </template>
        <template slot="uploadFile" slot-scope="record">
          {{record.fileName}}
        </template>
        <template slot="operation" slot-scope="record">
          <a href="javascript:;" @click="toEdit(record)">编辑</a>
        </template>

<!--        <template slot="operation" slot-scope="">-->
<!--          <a-popconfirm-->
<!--            title="确认删除吗?"-->
<!--            @confirm="() => onDelete(record.key)"-->
<!--          >-->
<!--            <a href="javascript:;">删除</a>-->
<!--          </a-popconfirm>-->
<!--        </template>-->
      </a-table>
    <a-modal
      title="附件详情"
      :visible="visible"
      width="60%"
      @cancel="handleCancel"
      :footer="null"
    >
      <CurrentInvoiceInfoC ref="currentInvoiceInfoC"></CurrentInvoiceInfoC>
    </a-modal>
    <a-modal width="80%" v-model="editFlag" title="编辑发票相关信息" @ok="editSub" @cancel="cancelEdit">
      <CurrentInvoiceInfoC ref="currentInvoiceInfoEditC"></CurrentInvoiceInfoC>
    </a-modal>
  </div>
</template>

<script>
import {editInvoiceInfo} from "@/api/customer/invoice"
import CurrentInvoiceInfoC from "@/components/customerInfo/current-invoice-info.vue";
import { invoiceColumns } from "@/components/customerInfo/contract-list"
import {fullTimeFilter} from "@/utils/utils"
export default {
  name:"InvoiceItem",
  props:{
    invoiceItem:{
      type:Array,
    }
  },
  data(){
   return{
     editFlag:false,
     invoiceColumns,
     visible:false,
   }
  },
  methods:{
    fullTimeFilter,
    handleCancel(){
      this.visible = false
    },
    toInfo(record){
      this.visible = true
      this.$nextTick(()=>{
        this.$refs.currentInvoiceInfoC.currentInvoiceInfo = record
      })
    },
    toEdit(record){
      this.editFlag = true
      this.$nextTick(()=>{
        this.$refs.currentInvoiceInfoEditC.currentInvoiceInfo = record
        this.$refs.currentInvoiceInfoEditC.disabled = false
      })
    },
    editSub(){
      this.$refs.currentInvoiceInfoEditC.$refs.ruleForm.validate(async valid => {
        if (valid) {
          const currentInvoice = this.$refs.currentInvoiceInfoEditC.currentInvoiceInfo
          const editInvoiceObj = await editInvoiceInfo({
            contractID:currentInvoice.contractID,
            fileName:currentInvoice.fileName,
            filePath:currentInvoice.filePath,
            invoiceID:currentInvoice.contractInvoiceID,
            invoiceMoney:currentInvoice.invoiceMoney,
            invoiceNo:currentInvoice.invoiceNo,
            invoiceTime:currentInvoice.invoiceTime,
            projectID:currentInvoice.projectID,
            projectNum:currentInvoice.projectNum
          })
          if(editInvoiceObj.code===14){
            this.editFlag = false
            this.$parent.getInvoiceList()
          }
        } else {
          return false;
        }
      });
    },
    cancelEdit(){
      this.editFlag = false
    }
  },
  components:{
    CurrentInvoiceInfoC
  },
}
</script>
<template>
  <div class="draw-container">
    <a-drawer
      :visible="showCustomerFlag"
      :width="1200"
      placement="right"
      title="客户详情"
      @close="closeDrawer"
    >
      <a-tabs
        :activeKey="activeKey"
        type="card"
        @change="
          key => {
            activeKey = key
          }
        "
      >
        <a-tab-pane
          :key="ACTIVITY_KEY_MAP.CUSTOMER"
          :disabled="checkOperateDisable('查看客户详情')"
          tab="客户基本信息"
        >
          <CustomerInfoTabPane
            ref="customerInfoTabPane"
            @update-customer-info="updateCustomerInfo"
            @update-customer-operate-type="updateCustomerOperateType"
            @close-drawer="closeDrawer"
          ></CustomerInfoTabPane>
        </a-tab-pane>

        <a-tab-pane
          :key="ACTIVITY_KEY_MAP.CONTACT"
          :disabled="
            operateType != BASE_OPERATE_TYPE.INFO ||
              checkOperateDisable('客户详情-企业联系人')
          "
          tab="企业联系人"
        >
          <ContactTabPane ref="contactTabPane"></ContactTabPane>
        </a-tab-pane>

        <a-tab-pane
          :key="ACTIVITY_KEY_MAP.FOLLOWUP"
          :disabled="
            operateType != BASE_OPERATE_TYPE.INFO ||
              checkOperateDisable('客户详情-跟进记录')
          "
          tab="跟进记录"
        >
          <FollowUpTabPane ref="followupTabPane"></FollowUpTabPane>
        </a-tab-pane>

        <a-tab-pane
          :key="ACTIVITY_KEY_MAP.CONTRACT"
          :disabled="
            operateType != BASE_OPERATE_TYPE.INFO ||
              checkOperateDisable('客户详情-合同管理')
          "
          tab="合同管理"
        >
          <ContractTabPane
            ref="contractTabPane"
            :customerID="currentCustomerInfo.customerID"
            :customerName="currentCustomerInfo.customerName"
          ></ContractTabPane>
        </a-tab-pane>

        <a-tab-pane
            :key="ACTIVITY_KEY_MAP.RECEIPT"
            :disabled="operateType != BASE_OPERATE_TYPE.INFO"
            tab="收款单"
        >
          <ReceiptTabPane
            ref="receiptTabPane"
            :customerID="currentCustomerInfo.customerID"
          ></ReceiptTabPane>
        </a-tab-pane>

        <a-tab-pane :key="ACTIVITY_KEY_MAP.ATTACHMENT" :disabled="operateType != BASE_OPERATE_TYPE.INFO" tab="附件">
          <AttachmentTabPane ref="attachmentTabPane"  :customerID="currentCustomerInfo.customerID"></AttachmentTabPane>
        </a-tab-pane>
        <a-tab-pane :key="ACTIVITY_KEY_MAP.INVOICE" :disabled="operateType != BASE_OPERATE_TYPE.INFO" tab="发票">
          <InvoiceTabPane ref="invoiceTabPane" :customerID="currentCustomerInfo.customerID"></InvoiceTabPane>
        </a-tab-pane>
      </a-tabs>
    </a-drawer>
  </div>
</template>

<script>
import CustomerInfoTabPane from "@/components/customerInfo/customer-info-tab-pane"
import ContactTabPane from "@/components/customerInfo/contact-tab-pane"
import FollowUpTabPane from "@/components/customerInfo/follow-up-tab-pane"
import { ACTIVITY_KEY_MAP, BASE_OPERATE_TYPE } from "@/config"
import ContractTabPane from "@/components/customerInfo/contract-tab-pane"
import ReceiptTabPane from "@/components/customerInfo/receipt-tab-pane.vue"
import AttachmentTabPane from "@/components/customerInfo/attachment-tab-pane.vue";
import InvoiceTabPane from "@/components/customerInfo/invoice-tab-pane.vue"
import { checkOperateDisable } from "@/router/permission"

export default {
  name: "CustomerInfo",
  data() {
    return {
      BASE_OPERATE_TYPE,
      ACTIVITY_KEY_MAP,
      activeKey: "",
      operateType: "",
      showCustomerFlag: false,
      currentCustomerInfo: {},
      currentCustomerID: 0
    }
  },
  components: {
    CustomerInfoTabPane,
    ContractTabPane,
    ReceiptTabPane,
    AttachmentTabPane,
    InvoiceTabPane,
    ContactTabPane,
    FollowUpTabPane
  },
  watch: {
    activeKey(newVal) {
      const that = this
      switch (newVal) {
        case that.ACTIVITY_KEY_MAP.CUSTOMER:
          if (that.currentCustomerID && that.$refs["customerInfoTabPane"]) {
            that.$refs["customerInfoTabPane"].showData(that.currentCustomerID)
          }
          break
        case that.ACTIVITY_KEY_MAP.CONTACT:
          that.$nextTick(() => {
            that.$refs["contactTabPane"].showData(that.currentCustomerID)
          })
          break
        case that.ACTIVITY_KEY_MAP.FOLLOWUP:
          that.$nextTick(() => {
            that.$refs["followupTabPane"].showData(that.currentCustomerID)
          })
          break
        case that.ACTIVITY_KEY_MAP.CONTRACT:
          if (
            that.currentCustomerInfo &&
            that.currentCustomerInfo.customerID &&
            that.$refs["contractTabPane"]
          ) {
            that.$refs["contractTabPane"].showData(
              that.currentCustomerInfo.customerID,
              that.currentCustomerInfo.customerName
            )
          }
          break
        case that.ACTIVITY_KEY_MAP.RECEIPT:
          that.$nextTick(() => {
            that.$refs.receiptTabPane.getReceiptInfo()
          })
          break
        case that.ACTIVITY_KEY_MAP.ATTACHMENT:
          that.$nextTick(()=>{
            that.$refs.attachmentTabPane.getAttachmentList()
          })
          break
        case that.ACTIVITY_KEY_MAP.INVOICE:
          that.$nextTick(()=>{
            that.$refs.invoiceTabPane.getInvoiceList()
          })
          break
      }
    }
  },
  async created() {},
  methods: {
    checkOperateDisable,
    closeDrawer() {
      this.activeKey = ""
      this.operateType = ""
      this.currentCustomerInfo = {}
      this.currentCustomerID = 0
      this.showCustomerFlag = false
      this.$refs.customerInfoTabPane.onCloseCustomerModal()
      this.$emit("close-drawer")
    },
    toCustomerInfo(customerID, activeKey = ACTIVITY_KEY_MAP.CUSTOMER) {
      const that = this
      this.showCustomerFlag = true
      this.currentCustomerID = customerID
      this.activeKey = activeKey
      that.operateType = BASE_OPERATE_TYPE.INFO
      this.$nextTick(() => {
        that.$refs.customerInfoTabPane.showData(
          that.currentCustomerID,
          that.operateType
        )
      })
    },
    async toAddCustomer() {
      this.showCustomerFlag = true
      this.currentCustomerInfo = {}
      this.currentCustomerID = 0
      this.operateType = BASE_OPERATE_TYPE.ADD
      this.activeKey = ACTIVITY_KEY_MAP.CUSTOMER
      const that = this
      that.$nextTick(() => {
        that.$refs.customerInfoTabPane.showData(0, that.operateType)
      })
    },
    updateCustomerInfo(customerInfo) {
      this.currentCustomerInfo = { ...customerInfo }
      this.currentCustomerID = customerInfo.customerID
    },
    updateCustomerOperateType(operateType) {
      this.operateType = operateType
    },
  }
}
</script>

<style scoped></style>

<template>
  <div>
    <div v-if="nodeConfig.type < 3" class="node-wrap">
      <div
        :class="
          (nodeConfig.type == 0 ? 'start-node ' : '') +
            (isTried && nodeConfig.error ? 'active error' : '')
        "
        class="node-wrap-box"
      >
        <div>
          <div :style="`background: rgb(${bgColor});`" class="title">
            <span v-if="nodeConfig.type == 0">{{ nodeConfig.nodeName }}</span>
            <template v-else>
              <span class="iconfont">{{
                nodeConfig.type == 1 ? "" : ""
              }}</span>
              <input
                v-if="isInput"
                v-model="nodeConfig.nodeName"
                v-focus
                :placeholder="defaultText"
                class="ant-input editable-title-input"
                type="text"
                @blur="blurEvent()"
                @focus="$event.currentTarget.select()"
              />
              <span v-else class="editable-title" @click="clickEvent()">{{
                nodeConfig.nodeName
              }}</span>
              <i class="anticon anticon-close close" @click="delNode"></i>
            </template>
          </div>
          <div class="content" @click="setPerson">
            <div class="text">
              <span v-if="!showText" class="placeholder"
                >请选择{{ defaultText }}</span
              >
              {{ showText }}
            </div>
            <i class="anticon anticon-right arrow"></i>
          </div>
          <div v-if="isTried && nodeConfig.error" class="error_tip">
            <i class="anticon anticon-exclamation-circle"></i>
          </div>
        </div>
      </div>
      <addNode :childNodeP.sync="nodeConfig.childNode"></addNode>
    </div>
    <div v-if="nodeConfig.type == 4" class="branch-wrap">
      <div class="branch-box-wrap">
        <div class="branch-box">
          <button class="add-branch" @click="addTerm">添加条件</button>
          <div
            v-for="(item, index) in nodeConfig.conditionNodes"
            :key="index"
            class="col-box"
          >
            <div class="condition-node">
              <div class="condition-node-box">
                <div
                  :class="isTried && item.error ? 'error active' : ''"
                  class="auto-judge"
                >
                  <div
                    v-if="index != 0"
                    class="sort-left"
                    @click="arrTransfer(index, -1)"
                  >
                    &lt;
                  </div>
                  <div class="title-wrapper">
                    <input
                      v-if="isInputList[index]"
                      v-model="item.nodeName"
                      v-focus
                      class="ant-input editable-title-input"
                      type="text"
                      @blur="blurEvent(index)"
                      @focus="$event.currentTarget.select()"
                    />
                    <span
                      v-else
                      class="editable-title"
                      @click="clickEvent(index)"
                      >{{ item.nodeName }}</span
                    >
                    <span
                      class="priority-title"
                      @click="setPerson(item.priorityLevel)"
                      >优先级{{ item.priorityLevel }}</span
                    >
                    <i
                      class="anticon anticon-close close"
                      @click="delTerm(index)"
                    ></i>
                  </div>
                  <div
                    v-if="index != nodeConfig.conditionNodes.length - 1"
                    class="sort-right"
                    @click="arrTransfer(index)"
                  >
                    &gt;
                  </div>
                  <div class="content" @click="setPerson(item.priorityLevel)">
                    {{ $func.conditionStr(nodeConfig, index) }}
                  </div>
                  <div v-if="isTried && item.error" class="error_tip">
                    <i class="anticon anticon-exclamation-circle"></i>
                  </div>
                </div>
                <addNode :childNodeP.sync="item.childNode"></addNode>
              </div>
            </div>
            <nodeWrap
              v-if="item.childNode"
              :nodeConfig.sync="item.childNode"
            ></nodeWrap>
            <template v-if="index == 0">
              <div class="top-left-cover-line"></div>
              <div class="bottom-left-cover-line"></div>
            </template>
            <template v-if="index == nodeConfig.conditionNodes.length - 1">
              <div class="top-right-cover-line"></div>
              <div class="bottom-right-cover-line"></div>
            </template>
          </div>
        </div>
        <addNode :childNodeP.sync="nodeConfig.childNode"></addNode>
      </div>
    </div>
    <nodeWrap
      v-if="nodeConfig.childNode"
      :nodeConfig.sync="nodeConfig.childNode"
    ></nodeWrap>
  </div>
</template>
<script>
import { mapMutations, mapState } from "vuex"

export default {
  props: ["nodeConfig", "flowPermission"],
  data() {
    return {
      placeholderList: ["发起人", "审核人", "抄送人"],
      isInputList: [],
      isInput: false
    }
  },
  mounted() {
    if (this.nodeConfig.type == 1) {
      this.nodeConfig.error = !this.$func.setApproverStr(this.nodeConfig)
    } else if (this.nodeConfig.type == 2) {
      this.nodeConfig.error = !this.$func.copyerStr(this.nodeConfig)
    } else if (this.nodeConfig.type == 4) {
      this.resetConditionNodesErr()
    }
  },
  computed: {
    ...mapState([
      "isTried",
      "flowPermission1",
      "approverConfig1",
      "copyerConfig1",
      "conditionsConfig1"
    ]),
    defaultText() {
      return this.placeholderList[this.nodeConfig.type]
    },
    showText() {
      if (this.nodeConfig.type == 0)
        return this.$func.arrToStr(this.flowPermission) || "所有人"
      if (this.nodeConfig.type == 1)
        return this.$func.setApproverStr(this.nodeConfig)
      return this.$func.copyerStr(this.nodeConfig)
    },
    bgColor() {
      return ["87, 106, 149", "255, 148, 62", "50, 150, 250"][
        this.nodeConfig.type
      ]
    }
  },
  watch: {
    flowPermission1(data) {
      if (data.flag && data.id === this._uid) {
        this.$emit("update:flowPermission", data.value)
      }
    },
    approverConfig1(data) {
      if (data.flag && data.id === this._uid) {
        this.$emit("update:nodeConfig", data.value)
      }
    },
    copyerConfig1(data) {
      if (data.flag && data.id === this._uid) {
        this.$emit("update:nodeConfig", data.value)
      }
    },
    conditionsConfig1(data) {
      if (data.flag && data.id === this._uid) {
        this.$emit("update:nodeConfig", data.value)
      }
    }
  },
  methods: {
    ...mapMutations([
      "setPromoter",
      "setApprover",
      "setCopyer",
      "setCondition",
      "setFlowPermission",
      "setApproverConfig",
      "setCopyerConfig",
      "setConditionsConfig"
    ]),
    clickEvent(index) {
      if (index || index === 0) {
        this.$set(this.isInputList, index, true)
      } else {
        this.isInput = true
      }
    },
    blurEvent(index) {
      if (index || index === 0) {
        this.$set(this.isInputList, index, false)
        this.nodeConfig.conditionNodes[index].nodeName =
          this.nodeConfig.conditionNodes[index].nodeName || "条件"
      } else {
        this.isInput = false
        this.nodeConfig.nodeName = this.nodeConfig.nodeName || this.defaultText
      }
    },
    delNode() {
      this.$emit("update:nodeConfig", this.nodeConfig.childNode)
    },
    addTerm() {
      let len = this.nodeConfig.conditionNodes.length + 1
      this.nodeConfig.conditionNodes.push({
        nodeName: "条件" + len,
        type: 3,
        priorityLevel: len,
        conditionList: [],
        nodeUserList: [],
        childNode: null
      })
      this.resetConditionNodesErr()
      this.$emit("update:nodeConfig", this.nodeConfig)
    },
    delTerm(index) {
      this.nodeConfig.conditionNodes.splice(index, 1)
      this.nodeConfig.conditionNodes.map((item, index) => {
        item.priorityLevel = index + 1
        item.nodeName = `条件${index + 1}`
      })
      this.resetConditionNodesErr()
      this.$emit("update:nodeConfig", this.nodeConfig)
      if (this.nodeConfig.conditionNodes.length == 1) {
        if (this.nodeConfig.childNode) {
          if (this.nodeConfig.conditionNodes[0].childNode) {
            this.reData(
              this.nodeConfig.conditionNodes[0].childNode,
              this.nodeConfig.childNode
            )
          } else {
            this.nodeConfig.conditionNodes[0].childNode = this.nodeConfig.childNode
          }
        }
        this.$emit(
          "update:nodeConfig",
          this.nodeConfig.conditionNodes[0].childNode
        )
      }
    },
    reData(data, addData) {
      if (!data.childNode) {
        data.childNode = addData
      } else {
        this.reData(data.childNode, addData)
      }
    },
    setPerson(priorityLevel) {
      console.log(
        `setPerson  ---------- priorityLevel : ${JSON.stringify(priorityLevel)}`
      )
      var { type } = this.nodeConfig
      if (type == 0) {
        this.setPromoter(true)
        this.setFlowPermission({
          value: this.flowPermission,
          flag: false,
          id: this._uid
        })
      } else if (type == 1) {
        this.setApprover(true)
        this.setApproverConfig({
          value: {
            ...JSON.parse(JSON.stringify(this.nodeConfig)),
            ...{
              settype: this.nodeConfig.settype ? this.nodeConfig.settype : 1
            }
          },
          flag: false,
          id: this._uid
        })
        this.$forceUpdate()
      } else if (type == 2) {
        this.setCopyer(true)
        this.setCopyerConfig({
          value: JSON.parse(JSON.stringify(this.nodeConfig)),
          flag: false,
          id: this._uid
        })
      } else {
        this.setCondition(true)
        this.setConditionsConfig({
          value: JSON.parse(JSON.stringify(this.nodeConfig)),
          priorityLevel,
          flag: false,
          id: this._uid
        })
      }
    },
    arrTransfer(index, type = 1) {
      //向左-1,向右1
      this.nodeConfig.conditionNodes[
        index
      ] = this.nodeConfig.conditionNodes.splice(
        index + type,
        1,
        this.nodeConfig.conditionNodes[index]
      )[0]
      this.nodeConfig.conditionNodes.map((item, index) => {
        item.priorityLevel = index + 1
      })
      this.$emit("update:nodeConfig", this.nodeConfig)
    },
    resetConditionNodesErr() {
      for (var i = 0; i < this.nodeConfig.conditionNodes.length; i++) {
        this.nodeConfig.conditionNodes[i].error =
          this.$func.conditionStr(this.nodeConfig, i) == "请设置条件" &&
          i != this.nodeConfig.conditionNodes.length - 1
      }
    }
  }
}
</script>
<style>
.error_tip {
  position: absolute;
  top: 0px;
  right: 0px;
  transform: translate(150%, 0px);
  font-size: 24px;
}

.promoter_person .el-dialog__body {
  padding: 10px 20px 14px 20px;
}

.selected_list {
  margin-bottom: 20px;
  line-height: 30px;
}

.selected_list span {
  margin-right: 10px;
  padding: 3px 6px 3px 9px;
  line-height: 12px;
  white-space: nowrap;
  border-radius: 2px;
  border: 1px solid rgba(220, 220, 220, 1);
}

.selected_list img {
  margin-left: 5px;
  width: 7px;
  height: 7px;
  cursor: pointer;
}
</style>

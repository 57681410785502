<template>
  <el-drawer
    :append-to-body="true"
    :before-close="saveCondition"
    :visible.sync="conditionDrawer"
    class="condition_copyer"
    direction="rtl"
    size="550px"
    title="条件设置"
  >
    <select v-model="conditionConfig.priorityLevel" class="priority_level">
      <option
        v-for="item in conditionsConfig.conditionNodes.length"
        :key="item"
        :value="item"
        >优先级{{ item }}
      </option>
    </select>
    <div class="demo-drawer__content">
      <div class="condition_content drawer_content">
        <p class="tip">当审批单同时满足以下条件时进入此流程</p>
        <ul>
          <li
            v-for="(item, index) in conditionConfig.conditionList"
            :key="index"
          >
            <span class="ellipsis"
              >{{ item.type == 1 ? "发起人" : item.showName }}：</span
            >
            <div v-if="item.type == 1">
              <p
                :class="
                  conditionConfig.nodeUserList.length > 0 ? 'selected_list' : ''
                "
                style="cursor:text"
                @click.self="addConditionRole"
              >
                <span
                  v-for="(item1, index1) in conditionConfig.nodeUserList"
                  :key="index1"
                >
                  {{ item1.name
                  }}<img
                    src="@/assets/image/add-close1.png"
                    @click="
                      $func.removeEle(
                        conditionConfig.nodeUserList,
                        item1,
                        'targetId'
                      )
                    "
                  />
                </span>
                <input
                  v-if="conditionConfig.nodeUserList.length == 0"
                  placeholder="请选择具体人员/角色/部门"
                  type="text"
                  @click="addConditionRole"
                />
              </p>
            </div>
            <div
              v-else-if="
                item.columnType == 'String' && item.showType == 'checkBox'
              "
            >
              <p class="check_box">
                <a
                  v-for="(item1, index1) in JSON.parse(item.fixedDownBoxValue)"
                  :key="index1"
                  :class="$func.toggleStrClass(item, item1.key) && 'active'"
                  @click="toStrChecked(item, item1.key)"
                  >{{ item1.value }}</a
                >
              </p>
            </div>
            <div v-else>
              <p>
                <select
                  v-model="item.optType"
                  :style="'width:' + (item.optType == 6 ? 370 : 100) + 'px'"
                  @change="changeOptType(item)"
                >
                  <option value="1">小于</option>
                  <option value="2">大于</option>
                  <option value="3">小于等于</option>
                  <option value="4">等于</option>
                  <option value="5">大于等于</option>
                  <option value="6">介于两个数之间</option>
                </select>
                <input
                  v-if="item.optType != 6"
                  v-model="item.zdy1"
                  v-enter-number="2"
                  :placeholder="'请输入' + item.showName"
                  type="text"
                />
              </p>
              <p v-if="item.optType == 6">
                <input
                  v-model="item.zdy1"
                  v-enter-number="2"
                  class="mr_10"
                  style="width:75px;"
                  type="text"
                />
                <select v-model="item.opt1" style="width:60px;">
                  <option value="<">&lt;</option>
                  <option value="≤">≤</option>
                </select>
                <span
                  class="ellipsis"
                  style="display:inline-block;width:60px;vertical-align: text-bottom;"
                  >{{ item.showName }}</span
                >
                <select v-model="item.opt2" class="ml_10" style="width:60px;">
                  <option value="<">&lt;</option>
                  <option value="≤">≤</option>
                </select>
                <input
                  v-model="item.zdy2"
                  v-enter-number="2"
                  style="width:75px;"
                  type="text"
                />
              </p>
            </div>
            <a
              v-if="item.type == 1"
              @click="
                conditionConfig.nodeUserList = []
                $func.removeEle(conditionConfig.conditionList, item, 'columnId')
              "
              >删除</a
            >
            <a
              v-if="item.type == 2"
              @click="
                $func.removeEle(conditionConfig.conditionList, item, 'columnId')
              "
              >删除</a
            >
          </li>
        </ul>
        <el-button type="primary" @click="addCondition">添加条件</el-button>
        <el-dialog
          :visible.sync="conditionVisible"
          append-to-body
          class="condition_list"
          title="选择条件"
          width="480px"
        >
          <p>请选择用来区分审批流程的条件字段</p>
          <p class="check_box">
            <a
              :class="
                $func.toggleClass(conditionList, { columnId: 0 }, 'columnId') &&
                  'active'
              "
              @click="
                $func.toChecked(conditionList, { columnId: 0 }, 'columnId')
              "
              >发起人</a
            >
            <a
              v-for="(item, index) in conditions"
              :key="index"
              :class="
                $func.toggleClass(conditionList, item, 'columnId') && 'active'
              "
              @click="$func.toChecked(conditionList, item, 'columnId')"
              >{{ item.showName }}</a
            >
          </p>
          <span slot="footer" class="dialog-footer">
            <el-button @click="conditionVisible = false">取 消</el-button>
            <el-button type="primary" @click="sureCondition">确 定</el-button>
          </span>
        </el-dialog>
      </div>
      <employees-role-dialog
        :data.sync="checkedList"
        :isDepartment="true"
        :visible.sync="conditionRoleVisible"
        @change="sureConditionRole"
      />
      <div class="demo-drawer__footer clear">
        <el-button type="primary" @click="saveCondition">确 定</el-button>
        <el-button @click="setCondition(false)">取 消</el-button>
      </div>
    </div>
  </el-drawer>
</template>

<script>
import { mapMutations, mapState } from "vuex"
import employeesRoleDialog from "../dialog/employeesRoleDialog.vue"
import { getConditions } from "@/api/approval/api"

export default {
  components: {
    employeesRoleDialog
  },
  data() {
    return {
      conditionVisible: false,
      conditionsConfig: {
        conditionNodes: []
      },
      conditionConfig: {},
      PriorityLevel: "",
      conditions: [],
      conditionList: [],
      checkedList: [],
      conditionRoleVisible: false
    }
  },
  computed: {
    ...mapState(["tableId", "conditionsConfig1", "conditionDrawer"])
  },
  watch: {
    conditionsConfig1(val) {
      this.conditionsConfig = val.value
      this.PriorityLevel = val.priorityLevel
      this.conditionConfig = val.priorityLevel
        ? this.conditionsConfig.conditionNodes[val.priorityLevel - 1]
        : { nodeUserList: [], conditionList: [] }
    }
  },
  methods: {
    ...mapMutations(["setCondition", "setConditionsConfig"]),
    changeOptType(item) {
      if (item.optType == 1) {
        item.zdy1 = 2
      } else {
        item.zdy1 = 1
        item.zdy2 = 2
      }
    },
    toStrChecked(item, key) {
      let a = item.zdy1 ? item.zdy1.split(",") : []
      var isIncludes = this.$func.toggleStrClass(item, key)
      if (!isIncludes) {
        a.push(key)
        item.zdy1 = a.toString()
      } else {
        this.removeStrEle(item, key)
      }
    },
    removeStrEle(item, key) {
      let a = item.zdy1 ? item.zdy1.split(",") : []
      var includesIndex
      a.map((item, index) => {
        if (item == key) {
          includesIndex = index
        }
      })
      a.splice(includesIndex, 1)
      item.zdy1 = a.toString()
    },
    async addCondition() {
      this.conditionList = []
      this.conditionVisible = true
      let { data } = await getConditions({ tableId: this.tableId })
      this.conditions = data
      if (this.conditionConfig.conditionList) {
        for (var i = 0; i < this.conditionConfig.conditionList.length; i++) {
          var { columnId } = this.conditionConfig.conditionList[i]
          if (columnId == 0) {
            this.conditionList.push({ columnId: 0 })
          } else {
            this.conditionList.push(
              this.conditions.filter(item => item.columnId == columnId)[0]
            )
          }
        }
      }
    },
    sureCondition() {
      //1.弹窗有，外面无+
      //2.弹窗有，外面有不变
      for (var i = 0; i < this.conditionList.length; i++) {
        var {
          columnId,
          showName,
          columnName,
          showType,
          columnType,
          fixedDownBoxValue
        } = this.conditionList[i]
        if (
          this.$func.toggleClass(
            this.conditionConfig.conditionList,
            this.conditionList[i],
            "columnId"
          )
        ) {
          continue
        }
        if (columnId == 0) {
          this.conditionConfig.nodeUserList == []
          this.conditionConfig.conditionList.push({
            type: 1,
            columnId,
            showName: "发起人"
          })
        } else {
          if (columnType == "Double") {
            this.conditionConfig.conditionList.push({
              showType,
              columnId,
              type: 2,
              showName,
              optType: "1",
              zdy1: "2",
              opt1: "<",
              zdy2: "",
              opt2: "<",
              columnDbname: columnName,
              columnType
            })
          } else if (columnType == "String" && showType == "checkBox") {
            this.conditionConfig.conditionList.push({
              showType,
              columnId,
              type: 2,
              showName,
              zdy1: "",
              columnDbname: columnName,
              columnType,
              fixedDownBoxValue
            })
          }
        }
      }
      //3.弹窗无，外面有-
      for (let i = this.conditionConfig.conditionList.length - 1; i >= 0; i--) {
        if (
          !this.$func.toggleClass(
            this.conditionList,
            this.conditionConfig.conditionList[i],
            "columnId"
          )
        ) {
          this.conditionConfig.conditionList.splice(i, 1)
        }
      }
      this.conditionConfig.conditionList.sort(function(a, b) {
        return a.columnId - b.columnId
      })
      this.conditionVisible = false
    },
    saveCondition() {
      this.setCondition(false)
      var a = this.conditionsConfig.conditionNodes.splice(
        this.PriorityLevel - 1,
        1
      ) //截取旧下标
      this.conditionsConfig.conditionNodes.splice(
        this.conditionConfig.priorityLevel - 1,
        0,
        a[0]
      ) //填充新下标
      this.conditionsConfig.conditionNodes.map((item, index) => {
        item.priorityLevel = index + 1
      })
      for (var i = 0; i < this.conditionsConfig.conditionNodes.length; i++) {
        this.conditionsConfig.conditionNodes[i].error =
          this.$func.conditionStr(this.conditionsConfig, i) == "请设置条件" &&
          i != this.conditionsConfig.conditionNodes.length - 1
      }
      this.setConditionsConfig({
        value: this.conditionsConfig,
        flag: true,
        id: this.conditionsConfig1.id
      })
    },
    addConditionRole() {
      this.conditionRoleVisible = true
      this.checkedList = this.conditionConfig.nodeUserList
    },
    sureConditionRole(data) {
      this.conditionConfig.nodeUserList = data
      this.conditionRoleVisible = false
    }
  }
}
</script>

<style lang="less">
.condition_copyer {
  .el-drawer__body {
    .priority_level {
      position: absolute;
      top: 11px;
      right: 30px;
      width: 100px;
      height: 32px;
      background: rgba(255, 255, 255, 1);
      border-radius: 4px;
      border: 1px solid rgba(217, 217, 217, 1);
    }
  }

  .condition_content {
    padding: 20px 20px 0;

    p.tip {
      margin: 20px 0;
      width: 510px;
      text-indent: 17px;
      line-height: 45px;
      background: rgba(241, 249, 255, 1);
      border: 1px solid rgba(64, 163, 247, 1);
      color: #46a6fe;
      font-size: 14px;
    }

    ul {
      max-height: 500px;
      overflow-y: scroll;
      margin-bottom: 20px;

      li {
        & > span {
          float: left;
          margin-right: 8px;
          width: 70px;
          line-height: 32px;
          text-align: right;
        }

        & > div {
          display: inline-block;
          width: 370px;

          & > p:not(:last-child) {
            margin-bottom: 10px;
          }
        }

        &:not(:last-child) > div > p {
          margin-bottom: 20px;
        }

        & > a {
          float: right;
          margin-right: 10px;
          margin-top: 7px;
        }

        select,
        input {
          width: 100%;
          height: 32px;
          background: rgba(255, 255, 255, 1);
          border-radius: 4px;
          border: 1px solid rgba(217, 217, 217, 1);
        }

        select + input {
          width: 260px;
        }

        select {
          margin-right: 10px;
          width: 100px;
        }

        p.selected_list {
          padding-left: 10px;
          border-radius: 4px;
          min-height: 32px;
          border: 1px solid rgba(217, 217, 217, 1);
          word-break: break-word;
        }

        p.check_box {
          line-height: 32px;
        }
      }
    }

    .el-button {
      margin-bottom: 20px;
    }
  }
}

.condition_list {
  .el-dialog__body {
    padding: 16px 26px;
  }

  p {
    color: #666666;
    margin-bottom: 10px;

    & > .check_box {
      margin-bottom: 0;
      line-height: 36px;
    }
  }
}
</style>

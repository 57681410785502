<template>
  <a-upload-dragger
    :before-upload="beforeUpload"
    :disabled="disabled"
    :fileList="fileList"
    :multiple="true"
    name="file"
    @change="uploadChange"
  >
    <p class="ant-upload-drag-icon">
<!--      <inbox-outlined></inbox-outlined>-->
    </p>
    <p class="ant-upload-text">点击或拖拽文件到当前区域，上传文件</p>
    <p class="ant-upload-hint">
      支持单个或批量上传公司资料文件或其他批量文件
    </p>
  </a-upload-dragger>
</template>

<script>
import {
  AllFileCancel,
  DeleteOriginFile,
  FileOperateAPI,
  FileUpload
} from "@/api/upload-file-after/index"
import { FileRemoved } from "@/components/UploadFile"

export default {
  name: "UploadFile",
  props: {
    customerID: {
      type: Number,
      default() {
        return 0
      }
    },
    disabled: {
      type: Boolean,
      default() {
        return false
      }
    },
    originFileList: {
      type: Array,
      default: () => []
    },
    fileList: {
      type: Array,
      default: () => []
    },
    fileUploadPath: {
      type: String,
      default() {
        return FileOperateAPI.FollowUpFileUpload
      }
    },
    fileRemovedPath: {
      type: String,
      default() {
        return FileOperateAPI.FollowUpFileRemoved
      }
    },
    allFileCancelPath: {
      type: String,
      default() {
        return FileOperateAPI.FollowUpAllFileCancel
      }
    },
    originFileDeletePath: {
      type: String,
      default() {
        return FileOperateAPI.FollowUpOriginFileDelete
      }
    }
  },
  data() {
    return {
      upFileLoading: false,
      current: 0,
      errList: [],
      FileOperateAPI
    }
  },
  methods: {
    showData() {},
    //上传文件
    async beforeUpload(file) {
      const that = this
      let fd = new FormData() //表单格式
      fd.append("file", file) //添加file表单数据
      fd.append("customerID", that.customerID)
      const uploadFileRes = await FileUpload(that.fileUploadPath, fd)
      if (uploadFileRes.code === 0) {
        that.fileList = [
          ...that.fileList,
          {
            uid: file.uid,
            fileName: uploadFileRes.result.fileName,
            name: uploadFileRes.result.fileName,
            url: uploadFileRes.result.filePathUrl,
            oldFile: false,
            status: "done"
          }
        ]
        that.$forceUpdate()
      }
      that.$emit("update-file-list", [...that.fileList])
    },
    async uploadChange(data) {
      const that = this
      // 删除文件
      if (data.file.status === "removed") {
        if (data.file.oldFile === false) {
          const deletedUid = data.file.uid
          const deletedFileName = data.file.fileName
          await FileRemoved(that.fileRemovedPath, {
            uid: deletedUid,
            fileName: deletedFileName,
            customerID: that.customerID
          })
        }
        that.fileList = data.fileList
      }
      that.$emit("update-file-list", [...that.fileList])
    },
    async AllFileCancel() {
      const that = this
      const allFile = that.fileList.filter(item => !item.oldFile)
      if (allFile.length > 0) {
        await AllFileCancel(that.allFileCancelPath, {
          files: [...allFile],
          customerID: that.customerID
        })
      }
      that.$emit("update-file-list", [...that.fileList])
    },

    async deleteOriginFile() {
      const that = this
      const deleteFileList = [
        ...that.originFileList.filter(
          item1 => !that.fileList.find(item2 => item1.uid === item2.uid)
        )
      ]
      if (deleteFileList.length > 0) {
        const delRes = DeleteOriginFile(that.originFileDeletePath, {
          customerID: that.customerID,
          fileList: deleteFileList
        })
        if (delRes.code === 0) {
          that.$message.success("更新成功")
          return 0
        } else {
          return -1
        }
      }
      return 0
    }
  }
}
</script>

<style scoped></style>

import { method } from "@/utils/request"
import { parseTime } from "@/utils/utils"
import { blobGenerateExcel } from "@/utils/excel_util"

const api = {
  Add: "marketing",
  Del: "marketing",
  Edit: "marketing",
  Get: "marketing/list",
  Download: "marketing/download",
  Detail: "marketing/detail",

  AddSourcePlatformMarketing: "marketing/source-platform",
  DelSourcePlatformMarketing: "marketing/source-platform",
  EditSourcePlatformMarketing: "marketing/source-platform",
  GetSourcePlatformMarketing: "marketing/source-platform/list",
  DetailSourcePlatformMarketing: "marketing/source-platform/detail",
  DownloadSourcePlatform: "marketing/source-platform/download",

  AnalysisMarketingRecordTrend: "analysis/marketing/record"
}

const url = process.env.VUE_APP_API_URL

/**
 * @param parameter
 * @returns {*}
 */
export function add(parameter) {
  return method(url)({
    url: api.Add,
    method: "post",
    data: {
      ...parameter
    }
  })
}

/**
 * @param {*} parameter
 */
export function del(ids) {
  return method(url)({
    url: api.Del,
    method: "delete",
    data: { sourcePlatformIDs: ids }
  })
}

/**
 * @param {*} parameter
 */
export function edit(parameter) {
  return method(url)({
    url: api.Edit,
    method: "put",
    data: parameter
  })
}

export function get(parameter) {
  return method(url)({
    url: api.Get,
    method: "post",
    data: parameter
  })
}

export async function download(parameter) {
  const res = await method(
    url,
    true,
    "file"
  )({
    url: api.Download,
    method: "post",
    data: parameter,
    responseType: "blob"
  })
  blobGenerateExcel(res, `每日营销汇总表${parseTime(new Date())}`)
}

export async function downloadSourcePlatform(parameter) {
  const res = await method(
    url,
    true,
    "file"
  )({
    url: api.DownloadSourcePlatform,
    method: "post",
    data: parameter,
    responseType: "blob"
  })
  blobGenerateExcel(res, `各平台每日营销汇总表${parseTime(new Date())}`)
}

export function detail(parameter) {
  return method(url)({
    url: api.Detail,
    method: "get",
    data: parameter
  })
}

/**
 * @param parameter
 * @returns {*}
 */
export function addSourcePlatformMarketing(
  sourcePlatformMarketingRecordForm,
  sourcePlatformMarketingRecordConversationArr
) {
  return method(url)({
    url: api.AddSourcePlatformMarketing,
    method: "post",
    data: {
      dateNo: sourcePlatformMarketingRecordForm.dateNo,
      sourcePlatformID: Number(
        sourcePlatformMarketingRecordForm.sourcePlatformID
      ),
      cost: Number(sourcePlatformMarketingRecordForm.cost),
      appearNum: parseInt(sourcePlatformMarketingRecordForm.appearNum),
      clickNum: parseInt(sourcePlatformMarketingRecordForm.clickNum),
      arriveNum: parseInt(sourcePlatformMarketingRecordForm.arriveNum),
      conversationTotal: parseInt(
        sourcePlatformMarketingRecordForm.conversationTotal
      ),
      conversationValid: parseInt(
        sourcePlatformMarketingRecordForm.conversationValid
      ),
      conversations: (sourcePlatformMarketingRecordConversationArr || [])
        .filter(item => item.conversationNum > 0)
        .map(item => ({
          name: item.name,
          conversationID: item.conversationID,
          conversationNum: parseInt(item.conversationNum)
        }))
    }
  })
}

/**
 * @param {*} parameter
 */
export function delSourcePlatformMarketing(data) {
  return method(url)({
    url: api.DelSourcePlatformMarketing,
    method: "delete",
    data
  })
}

/**
 * @param {*} parameter
 */
export function editSourcePlatformMarketing(
  sourcePlatformMarketingRecordForm,
  sourcePlatformMarketingRecordConversationArr
) {
  const dateNo =
    sourcePlatformMarketingRecordForm.dateNo.length === 10
      ? new Date(sourcePlatformMarketingRecordForm.dateNo + " 00:00:00")
      : sourcePlatformMarketingRecordForm.dateNo
  return method(url)({
    url: api.EditSourcePlatformMarketing,
    method: "put",
    data: {
      marketingRecordID: sourcePlatformMarketingRecordForm.marketingRecordID,
      dateNo,
      sourcePlatformID: Number(
        sourcePlatformMarketingRecordForm.sourcePlatformID
      ),
      cost: Number(sourcePlatformMarketingRecordForm.cost),
      appearNum: parseInt(sourcePlatformMarketingRecordForm.appearNum),
      clickNum: parseInt(sourcePlatformMarketingRecordForm.clickNum),
      arriveNum: parseInt(sourcePlatformMarketingRecordForm.arriveNum),
      conversationTotal: parseInt(
        sourcePlatformMarketingRecordForm.conversationTotal
      ),
      conversationValid: parseInt(
        sourcePlatformMarketingRecordForm.conversationValid
      ),
      conversations: (sourcePlatformMarketingRecordConversationArr || [])
        .filter(item => item.conversationNum > 0)
        .map(item => ({
          name: item.name,
          conversationID: item.conversationID,
          conversationNum: parseInt(item.conversationNum)
        }))
    }
  })
}

export function getSourcePlatformMarketing(parameter) {
  return method(url)({
    url: api.GetSourcePlatformMarketing,
    method: "post",
    data: parameter
  })
}

export function detailSourcePlatformMarketing(parameter) {
  return method(url)({
    url: api.DetailSourcePlatformMarketing,
    method: "get",
    data: parameter
  })
}

export function AnalysisMarketingRecordTrend(parameter) {
  return method(url)({
    url: api.AnalysisMarketingRecordTrend,
    method: "get",
    data: parameter
  })
}

export default {
  add,
  del,
  edit,
  get,
  detail,
  download,
  downloadSourcePlatform,
  addSourcePlatformMarketing,
  delSourcePlatformMarketing,
  editSourcePlatformMarketing,
  getSourcePlatformMarketing,
  detailSourcePlatformMarketing,
  AnalysisMarketingRecordTrend
}

<template>
  <div class="app-container">
    <div class="head-container">
      <a-form layout="inline">
        <a-row :gutter="48">
          <a-col :md="4" :sm="24">
            <a-form-item label="货品名">
              <a-input
                v-model="crud.query.goodsName"
                placeholder="请输入货品名称"
              />
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24">
            <a-form-item label="类别">
              <a-select
                v-model="crud.query.categoryID"
                :allowClear="true"
                :filterOption="filterCategory"
                placeholder="请选择分类"
                show-search
              >
                <a-select-option
                  v-for="item in allCategoryMap"
                  :key="item.categoryID"
                  :value="item.categoryID"
                  >{{ item.categoryName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24">
            <a-form-item label="货品编号">
              <a-input
                v-model="crud.query.goodsNo"
                placeholder="请输入货品编号"
              />
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24">
            <a-form-item label="货品型号">
              <a-input
                v-model="crud.query.goodsSpec"
                placeholder="请输入货品编号"
              />
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24">
            <a-form-item label="货品品牌">
              <a-input
                v-model="crud.query.goodsBrand"
                placeholder="请输入货品品牌"
              />
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24">
            <SearchOptions
              :queryFuncName="queryFuncName"
              :toggleShowAdvance="false"
              style="width: 100%"
            />
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div class="table-container">
      <Operations tableName="货品" />
      <a-spin :spinning="crud.loading">
        <a-table
          :columns="crud.columns"
          :data-source="crud.data"
          :pagination="false"
          :scroll="{ y:1000 }"
          rowKey="targetID"
        >
          <template slot="goodsImg" slot-scope="record">
            <img
              v-if="record.goodsImg"
              :src="record.goodsImg"
              style="width: 40px;height: 40px"
            />
            <span v-else>-</span>
          </template>
        </a-table>
      </a-spin>
      <Pagination />
    </div>
  </div>
</template>

<script>
import SearchOptions from "@/components/Crud/SearchOptions"
import Operations from "@/components/Crud/Operations"
import Pagination from "@/components/Crud/Pagination"
import CRUD, { form, header, presenter } from "@crud/crud"
import { targetListColumns } from "./goods-list"
import Api, { GetAllCategory } from "@/api/goods/index"
import { TargetBelongTypeMap } from "@/config"

// 表格的填充字段
const defaultForm = {
  totalValue: 0,
  completeValue: 0,
  unit: "",
  deadlineTime: null,
  startTime: null,
  roleID: 0,
  belongType: 0,
  type: 0,
  name: ""
}
export default {
  name: "Goods",
  // presenter 生成crud对象
  mixins: [presenter(), header(), form(defaultForm)],
  cruds() {
    return CRUD({
      title: "货品",
      columns: targetListColumns,
      crudMethod: { ...Api },
      idField: "goodsID" // 设置每一条记录的唯一标失
    })
  },
  data() {
    return {
      allCategoryMap: [],
      queryFuncName: "查询货品列表",
      targetTypeMap: [],
      allRoleListMap: [],
      allUserListMap: [],
      targetBelongTypeMap: TargetBelongTypeMap
    }
  },
  components: { SearchOptions, Operations, Pagination },
  async created() {
    // 关闭下载功能
    // this.crud.optShow.download = false
    this.crud.optShow.edit = false
    // 显示以下按钮
    this.crud.permission = {
      add: false,
      del: false,
      edit: false
      // download: true
    }
    const getAllCategoryRes = await GetAllCategory()
    if (getAllCategoryRes.code === 0) {
      this.allCategoryMap = getAllCategoryRes.result
    }
  },
  methods: {
    filterCategory: function(value, option) {
      return option.componentOptions.children[0].text.indexOf(value) >= 0
    }
  }
}
</script>

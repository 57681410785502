<template>
  <div>
    <a-card :body-style="{ padding: '0px' }" :bordered="false">
      <a-form-model ref="followUpForm" :labelCol="{ span: 6 }" :model="followUpForm" :rules="followUpRules"
        :wrapperCol="{ span: 18 }" labelAlign="left" @submit.native.prevent>
        <a-row :gutter="40">
          <a-col :lg="16" :md="24" :sm="24" :xl="16" :xs="24" :xxl="16">
            <div style="display: flex;justify-content: flex-start;vertical-align: middle;align-items: center">
              <h3 style="color: #0b64f4;">本次跟进记录</h3>
            </div>
          </a-col>
          <a-col :lg="8" :md="24" :sm="24" :xl="8" :xs="24" :xxl="8">
            <div
              style="display: flex;justify-content: flex-end;vertical-align: middle;align-items: center;margin-bottom: 20px">
              <a-button v-if="disableFormItem()" :disabled="checkOperateDisable('新增跟进')" type="primary"
                @click="toAddFollowUp">
                新增跟进
              </a-button>
              <a-button v-if="!disableFormItem()" :disabled="checkOperateDisable('新增跟进')" type="primary"
                @click="addFollowUp">
                保存
              </a-button>
              <a-button v-if="!disableFormItem()" style="margin-left: 20px" @click="cancelFollowUp">
                取消
              </a-button>
            </div>
          </a-col>
        </a-row>
        <a-row :gutter="40">
          <a-col :lg="8" :md="24" :sm="24" :xl="8" :xs="24" :xxl="8">
            <a-form-model-item label="客户名称" prop="customerName">
              <span>
                {{
                  currentCustomerInfo && currentCustomerInfo.customerName
                  ? currentCustomerInfo.customerName
                  : "-"
                }}
              </span>
            </a-form-model-item>
          </a-col>

          <a-col :lg="8" :md="24" :sm="24" :xl="8" :xs="24" :xxl="8">
            <a-form-model-item :labelCol="{ span: 6 }" :wrapperCol="{ span: 18 }" label="负责人" prop="userID">
              <span>
                {{
                  currentCustomerInfo &&
                  currentCustomerInfo.users &&
                  currentCustomerInfo.users.length > 0
                  ? currentCustomerInfo.users
                    .map(item => item.displayName)
                    .join(" , ")
                  : "-"
                }}
              </span>
            </a-form-model-item>
          </a-col>

          <a-col :lg="8" :md="24" :sm="24" :xl="8" :xs="24" :xxl="8">
            <a-form-model-item label="客户状态" prop="tag">
              <a-select v-model="followUpForm.tag" :allowClear="true" :disabled="disableFormItem()"
                :filterOption="filterCategory" :not-found-content="null" :show-arrow="false" placeholder="输入客户状态"
                show-search>
                <a-select-option v-for="item in allCustomerTagMap" :key="Number(item.key)" :value="Number(item.key)">{{
                  item.value }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>

          <a-col :lg="8" :md="24" :sm="24" :xl="8" :xs="24" :xxl="8">
            <a-form-model-item label="客户联系人" prop="contactID">
              <a-select v-model="followUpForm.contactID" :allowClear="true" :disabled="disableFormItem()"
                :filterOption="filterCategory" :labelCol="{ span: 6 }" :not-found-content="null" :show-arrow="false"
                :wrapperCol="{ span: 18 }" showArrow placeholder="请选择联系人" notFoundContent="暂无数据" show-search>
                <a-select-option v-for="item in currentContact" :key="item.contactID" :value="item.contactID">{{
                  item.contactName }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>

          <a-col :lg="8" :md="24" :sm="24" :xl="8" :xs="24" :xxl="8">
            <a-form-model-item label="下次回访:" labelAlign="left" prop="returnVisitTime" :labelCol="{ span: 6 }"
              :wrapperCol="{ span: 18 }">
              <a-date-picker v-model="followUpForm.returnVisitTime" :allowClear="true" :disabled="disableFormItem()"
                :disabled-time="() => { }" :show-time="showTime()" format="YYYY-MM-DD HH:mm:ss" />
            </a-form-model-item>
          </a-col>

          <a-col :lg="24" :md="24" :sm="24" :xl="24" :xs="24" :xxl="24">
            <a-form-model-item :labelCol="{ span: 3 }" :wrapperCol="{ span: 21 }" label="跟进记录" prop="followUpRecord">
              <a-textarea v-model="followUpForm.followUpRecord" :disabled="disableFormItem()" />
            </a-form-model-item>
          </a-col>

          <a-col :lg="24" :md="24" :sm="24" :xl="24" :xs="24" :xxl="24">
            <a-form-model-item :labelCol="{ span: 3 }" :wrapperCol="{ span: 21 }" label="上传文件附件" prop="followUpFile">
              <UploadFile ref="uploadFile" :allFileCancelPath="FileOperateAPI.FollowUpAllFileCancel"
                :customerID="currentCustomerID" :disabled="disableFormItem()"
                :fileRemovedPath="FileOperateAPI.FollowUpFileRemoved" :fileUploadPath="FileOperateAPI.FollowUpFileUpload">
              </UploadFile>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </a-card>

    <div>
      <el-timeline :reverse="true">
        <el-timeline-item v-for="(item, index) in followUpList" :key="index" :color="item.color" :timestamp="item.timeStr"
          placement="top">
          <el-card>
            <div style="display: flex; justify-content: space-between">
              <div>
                <img v-if="item.type == 'addCustomer'" src="@/assets/image/follow-up-create.png"
                  style="display: inline-block;width: 30px;height: 30px" />
                <img v-if="item.type == 'followCustomer'" src="@/assets/image/follow-up-item.png"
                  style="display: inline-block;width: 30px;height: 30px" />
                <img v-if="item.type == 'signContract'" src="@/assets/image/contract-item.png"
                  style="display: inline-block;width: 30px;height: 30px" />
                <h3 style="display: inline-block;margin-left: 30px">
                  {{ item.title }}
                </h3>
              </div>
              <el-row>
                <el-button v-if="item.recordID" circle icon="el-icon-edit" size="mini" type="primary"
                  @click="toInfo(item.recordID)"></el-button>
              </el-row>
            </div>
            <!-- <h4 style="margin-left: 30px;">
              <span style="font-weight: bold">{{ item.contentLine2.split(':')[0] }}: </span>
              {{ item.contentLine2.split(':')[1] }}
            </h4> -->
            <h4>
              <span v-for="(line1Part, index) in item.contentLinePartArr" :key="index"
                style="display: inline-block;margin-left: 30px;color:rgb(192,192,192);font-size: 12px">
                <template v-if="line1Part">
                  <span style="font-weight: bold">{{ line1Part.split(':')[0] }}: </span>
                  {{ line1Part.split(':')[1] }}&nbsp;&nbsp;&nbsp;&nbsp;
                </template>
              </span>
            </h4>
            <h4 style="margin-left: 30px;font-size: 16px; font-weight:500;">
              {{ item.contentLine2 }}
            </h4>
            <h4 v-if="item.hasUploadFile == 2">
              <a v-for="(fileItem, index) in item.uploadFilePath" :key="index" :href="fileItem.filePath"
                style="display: inline-block;margin-right: 30px" target="_blank">{{ fileItem.fileName }}</a>
            </h4>
          </el-card>
        </el-timeline-item>
      </el-timeline>
    </div>
    <FollowUpEditModal ref="followUpEditModal" @update-follow-up="updateFollowUp"></FollowUpEditModal>
  </div>
</template>

<script>
import {
  AddFollowUpRecord,
  GetCustomerFollowUpRecords
} from "@/api/customer/follow-up"
import { BASE_OPERATE_TYPE } from "@/config"
import {
  GetCustomerContact,
  GetCustomerDetailByCustomerID
} from "@/api/customer/customer"
import { GetDictionary } from "@/api/public-api"
import dayjs from "dayjs"
import { FileOperateAPI } from "@/components/UploadFile/index.js"
import UploadFile from "@/components/UploadFile/index.vue"
import { fullTimeFilter } from "@/utils/utils"
import { checkOperateDisable } from "@/router/permission"
import FollowUpEditModal from "@/components/FollowUp/editModal"

export default {
  name: "ContractTabPane",
  data () {
    return {
      FileOperateAPI,
      allCustomerTagMap: [],
      currentContact: [],
      currentCustomerInfo: {},
      operateType: BASE_OPERATE_TYPE.INFO,
      currentCustomerID: 0,
      followUpList: [],
      followUpForm: {
        userID: 0,
        tag: 0,
        contactID: 0,
        returnVisitTime: "",
        followUpRecord: ""
      },
      followUpRules: {
        tag: {
          required: true,
          message: "请选择当前的客户状态",
          trigger: "blur"
        },
        contactID: {
          required: true,
          message: "请选择对接客户的联系人",
          trigger: "blur"
        },
        followUpRecord: {
          required: true,
          message: "请输入本次跟进内容",
          trigger: "blur"
        }
      },
      editModal: false,

      rules: {},
      currentFollowUpInfo: {}
    }
  },
  props: {},
  components: { UploadFile, FollowUpEditModal },
  async created () {
    const [getAllCustomerTagRes] = await Promise.all([
      GetDictionary({ typeName: "customerTag" })
    ])
    if (getAllCustomerTagRes.code === 0) {
      this.allCustomerTagMap = getAllCustomerTagRes.result
    }
  },
  methods: {
    filterTime: timeStr => fullTimeFilter(timeStr),
    checkOperateDisable,
    filterCategory: function (value, option) {
      return option.componentOptions.children[0].text.indexOf(value) >= 0
    },
    showTime () {
      return { defaultValue: dayjs("00:00:00", "HH:mm:ss") }
    },
    disableFormItem () {
      return this.operateType === BASE_OPERATE_TYPE.INFO
    },
    toAddFollowUp () {
      this.operateType = BASE_OPERATE_TYPE.ADD
    },
    cancelFollowUp () {
      this.$refs.followUpForm.clearValidate()
      this.operateType = BASE_OPERATE_TYPE.INFO
      this.$refs.uploadFile.AllFileCancel()
      this.showData(this.currentCustomerID)
    },
    addFollowUp: function () {
      const that = this
      const currentCustomer = that.currentCustomerInfo
      const followUpRecord = { ...that.followUpForm }
      that.$refs["followUpForm"].validate(async valid => {
        if (!valid) {
          this.$message.error("请完善跟进信息")
          return
        }

        const followUpData = {
          customerName: currentCustomer.customerName,
          customerID: currentCustomer.customerID,
          oldTag: currentCustomer.tag,
          newTag: followUpRecord.tag,
          contactID: followUpRecord.contactID,
          contactName: currentCustomer.contacts.find(
            item => item.contactID === followUpRecord.contactID
          ).contactName,
          returnVisitTime: followUpRecord.returnVisitTime,
          note: followUpRecord.followUpRecord,
          files: that.$refs["uploadFile"].fileList
        }

        const addFollowRecordRes = await AddFollowUpRecord({ ...followUpData })

        if (addFollowRecordRes.code === 12) {
          this.$message.success("跟进成功")
          this.followUpForm = {
            tag: that.followUpForm.tag,
            contactID: null,
            returnVisitTime: null,
            followUpRecord: ""
          }
          this.operateType = BASE_OPERATE_TYPE.INFO
          this.$refs.uploadFile.fileList = []
          this.showData(that.currentCustomerID)
        }
      })
    },
    async showData (customerID) {
      const that = this
      if (!customerID) {
        return
      }
      that.currentCustomerID = customerID
      const [
        getCustomerFollowUpRecordsRes,
        customerDetailRes,
        contactRes
      ] = await Promise.all([
        GetCustomerFollowUpRecords(customerID),
        GetCustomerDetailByCustomerID(customerID),
        GetCustomerContact({ customerID })
      ])
      if (contactRes.code === 0) {
        that.currentContact = [...contactRes.result]
      }
      if (customerDetailRes.code === 0) {
        that.currentCustomerInfo = { ...customerDetailRes.result }
        that.followUpForm = {
          tag: that.currentCustomerInfo.tag,
          contactID: null,
          returnVisitTime: null,
          followUpRecord: ""
        }
      }
      if (getCustomerFollowUpRecordsRes.code === 0) {
        that.followUpList = [
          ...getCustomerFollowUpRecordsRes.result.approachRecords.map(item => {
            return {
              title: "【新增客户】",
              timeStr: that.filterTime(item.insertTime),
              type: "addCustomer",
              color: "#25ef06",
              contentLinePartArr: [
                `操作人:${item.creatorName}`,
                `客户名称:${item.customerName}`,
                `客户状态:${item.tagName}`,
                `客户需求评级:${item.rate}(满分5分)`
              ],
              contentLine2: `首次接洽记录:${item.note}`
            }
          }),
          ...[
            ...getCustomerFollowUpRecordsRes.result.followUpRecords.map(
              item => {
                return {
                  recordID: item.recordID,
                  title: "【跟进客户】",
                  timeStr: that.filterTime(item.insertTime),
                  type: "followCustomer",
                  color: "#0000ff",
                  hasUploadFile: item.hasUploadFile,
                  uploadFilePath:
                    item.hasUploadFile == 2
                      ? JSON.parse(item.uploadFilePath)
                      : [],
                  contentLinePartArr: [
                    `操作人:${item.creatorName}`,
                    `客户名称:${item.customerName}`,
                    `客户联系人:${item.contactName}`,
                    `是否更改客户状态:${item.changeTag == 2 ? "是" : "否"}`,
                    `${item.changeTag == 2
                      ? "客户之前状态:" + item.oldTagName
                      : ""
                    }`,
                    `客户当前状态:${item.newTagName}`
                  ],
                  contentLine2: `跟进记录:${item.note}`
                }
              }
            ),
            ...getCustomerFollowUpRecordsRes.result.contractRecords.map(
              item => {
                return {
                  title: "【签署合同】",
                  timeStr: that.filterTime(item.insertTime),
                  type: "signContract",
                  color: "#FF0000FF",
                  hasUploadFile: item.hasUploadFile,
                  uploadFilePath:
                    item.hasUploadFile == 2
                      ? JSON.parse(item.uploadFilePath)
                      : [],
                  contentLinePartArr: [
                    `操作人:${item.creatorName}`,
                    `客户名称:${item.customerName}`,
                    `合同编号:${item.contractNo}`,
                    `合同总金额:${item.totalPrice}`
                  ],
                  contentLine2: `合同备注:${item.note}`
                }
              }
            )
          ].sort((a, b) => a.insertTime - b.insertTime)
        ]
      }
      this.$forceUpdate()
    },
    toInfo (recordID) {
      this.$refs.followUpEditModal.showData(recordID, BASE_OPERATE_TYPE.INFO)
    },
    updateFollowUp () {
      this.toDiyResetQuery()
    }
  }
}
</script>

<style scoped>
.card1 {
  margin-top: 20px;
}

.card2 {
  margin-top: 20px;
}

.ant-table-striped /deep/ .table-striped td {
  background-color: #fcfcfc;
}
</style>

export const ContactListColumns = [
  {
    title: "联系人",
    // width: 180,
    align: "center",
    ellipsis: true,
    resizable: true,
    dataIndex: "contactName",
    // scopedSlots: { customRender: "contactName" },
    key: "contactName"
  },
  {
    title: "联系方式",
    // width: 200,
    align: "center",
    ellipsis: true,
    resizable: true,
    dataIndex: "contactTel",
    // scopedSlots: { customRender: "contactTel" },
    key: "contactTel"
  },
  {
    title: "企业数量",
    // width: 150,
    align: "center",
    ellipsis: true,
    resizable: true,
    dataIndex: "customerNum",
    key: "customerNum"
    // scopedSlots: { customRender: "customerNum" }
  },
  {
    title: "项目数量",
    // width: 150,
    align: "center",
    ellipsis: true,
    resizable: true,
    dataIndex: "projectNum",
    key: "projectNum"
    // scopedSlots: { customRender: "projectNum" }
  },
  {
    title: "关联企业",
    // width: 450,
    align: "center",
    ellipsis: true,
    resizable: true,
    // dataIndex: "contactTel",
    key: "customers",
    scopedSlots: { customRender: "customers" }
  },
  {
    title: "创建者",
    // width: 150,
    align: "center",
    ellipsis: true,
    resizable: true,
    dataIndex: "creatorDisplayName",
    key: "creatorDisplayName"
  },

  {
    title: "创建日期",
    // width: 180,
    ellipsis: true,
    align: "center",
    resizable: true,
    scopedSlots: { customRender: "insertTime" },
    key: "insertTime"
  },
  {
    title: "近期修改日期",
    // width: 180,
    ellipsis: true,
    resizable: true,
    align: "center",
    scopedSlots: { customRender: "updateTime" },
    key: "updateTime"
  },
  {
    title: "操作",
    fixed: "right",
    align: "center",
    scopedSlots: { customRender: "operation" }
  }
]

export const ContactCustomerListColumns = [
  {
    title: "公司名称",
    width: 200,
    align: "center",
    ellipsis: true,
    resizable: true,
    dataIndex: "customerName",
    key: "customerName"
    // scopedSlots: { customRender: "customerName" }
  },
  {
    title: "客户编号",
    width: 180,
    align: "center",
    ellipsis: true,
    resizable: true,
    dataIndex: "customerNo",
    key: "customerNo"
  },
  {
    title: "联系人角色",
    width: 120,
    align: "center",
    ellipsis: true,
    resizable: true,
    scopedSlots: { customRender: "contactType" },
    key: "contactType"
  },
  {
    title: "客户状态",
    width: 120,
    align: "center",
    ellipsis: true,
    resizable: true,
    scopedSlots: { customRender: "tag" },
    key: "tag"
  },
  {
    title: "区",
    width: 100,
    align: "center",
    ellipsis: true,
    resizable: true,
    dataIndex: "area",
    key: "area"
  },
  {
    title: "市",
    width: 100,
    align: "center",
    ellipsis: true,
    resizable: true,
    dataIndex: "city",
    key: "city"
  },
  {
    title: "省",
    width: 100,
    align: "center",
    ellipsis: true,
    resizable: true,
    dataIndex: "province",
    key: "province"
  },
  {
    title: "详细地址",
    width: 200,
    align: "center",
    ellipsis: true,
    resizable: true,
    dataIndex: "address",
    key: "address"
  }
]

export const ContactProjectListColumns = [
  {
    title: "项目名称",
    width: 200,
    align: "center",
    ellipsis: true,
    resizable: true,
    dataIndex: "projectName",
    key: "projectName"
    // scopedSlots: { customRender: "customerName" }
  },
  {
    title: "项目编号",
    width: 180,
    align: "center",
    ellipsis: true,
    resizable: true,
    dataIndex: "projectNo",
    key: "projectNo"
  },
  {
    title: "联系人角色",
    width: 100,
    align: "center",
    ellipsis: true,
    resizable: true,
    scopedSlots: { customRender: "contactType" },
    key: "contactType"
  }
]

export const contractCustomerListColumns = [
  {
    title: "公司名称",
    width: 120,
    align: "center",
    ellipsis: true,
    resizable: true,
    // dataIndex: "customerName",
    key: "customerName",
    scopedSlots: { customRender: "customerName" }
  },
  {
    title: "签约合同数",
    width: 80,
    align: "center",
    ellipsis: true,
    resizable: true,
    dataIndex: "contractNum",
    key: "contractNum"
  },
  {
    title: "合同总金额",
    width: 80,
    align: "center",
    ellipsis: true,
    resizable: true,
    // dataIndex: "totalPrice",
    scopedSlots: { customRender: "contractTotalPrice" },
    key: "contractTotalPrice"
  },
  {
    title: "上次合同签约时间",
    width: 100,
    align: "center",
    ellipsis: true,
    resizable: true,
    scopedSlots: { customRender: "contractTime" },
    key: "contractTime"
  }
]

export const receiptColumns = [
  {
    title: "日期",
    // dataIndex: "date",
    key: "date",
    scopedSlots: { customRender: "date" },
    align: "center"
  },
  {
    title: "收款单编号",
    key: "date",
    dataIndex: "receiptNo",
    align: "center"
  },
  {
    title: "金额",
    key: "amount",
    scopedSlots: { customRender: "amount" },
    align: "center"
  },
  {
    title: "付款时间",
    key: "time",
    scopedSlots: { customRender: "time" },
    align: "center"
  },
  {
    title: "收款方式",
    key: "receiptTypeName",
    scopedSlots: { customRender: "receiptTypeName" },
    align: "center"
  },
  {
    title: "合同编号",
    key: "contractNo",
    scopedSlots: { customRender: "contractNo" },
    align: "center"
  },
  {
    title: "操作",
    key: "operation",
    scopedSlots: { customRender: "operation" },
    align: "center"
  },
]
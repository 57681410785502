<template>
  <div :class="load ? 'loading' : ''">
    <a-spin v-if="load" />
    <a-tree
      v-model="functionSelect"
      :auto-expand-parent="autoExpandParent"
      :tree-data="resolvePermissionList"
      checkable
      @check="functionSelectCheck"
    />
    <div
      v-if="showButton && operateType === BASE_OPERATE_TYPE.INFO"
      class="_bottom"
    >
      <a-button
        :disabled="roleSelect[0] === ADMIN_ROLE_ID"
        class="_update"
        type="primary"
        @click="updatePermission"
      >
        编辑
      </a-button>
    </div>
    <div
      v-if="showButton && operateType === BASE_OPERATE_TYPE.EDIT"
      class="_bottom"
    >
      <a-button
        :disabled="roleSelect[0] === ADMIN_ROLE_ID"
        class="_update"
        style="margin-right: 20px"
        type="success"
        @click="cancelSavePermission"
      >
        取消
      </a-button>
      <a-button
        :disabled="roleSelect[0] === ADMIN_ROLE_ID"
        class="_update"
        type="success"
        @click="toSavePermission"
      >
        保存
      </a-button>
    </div>
  </div>
</template>

<script>
import {
  ADMIN_ROLE_ID,
  BASE_OPERATE_TYPE,
  GET_ROLE_PROMISSION_FUN,
  GET_ROLE_PROMISSION_TYPE
} from "@/config"
import { GetRoleFunctions } from "@/api/system/menu"
import { edit } from "@/api/system/role"

export default {
  name: "RolePart",
  components: {},
  props: {
    roleSelect: {
      type: Array,
      default: () => []
    },
    showButton: {
      type: Boolean,
      default: () => true
    }
  },
  data() {
    return {
      load: false,
      ADMIN_ROLE_ID,
      BASE_OPERATE_TYPE,
      functionSelect: [], // 功能权限列表
      autoExpandParent: true, // 展开父级TREE
      resolvePermissionList: [], // 处理完的权限列表树
      halfFunctionSelect: [],
      permissionList: [],
      PPpermissionList: [],
      operateType: BASE_OPERATE_TYPE.INFO
    }
  },
  mounted() {
    this.getRolePermission(GET_ROLE_PROMISSION_TYPE.ALL)
  },
  watch: {
    operateType: {
      handler(value) {
        if (value === BASE_OPERATE_TYPE.INFO) {
          this.resolvePermissionList = this.resolvePermission(
            this.permissionList
          )
        }
      },
      deep: true
    },
    roleSelect: {
      handler() {
        const that = this
        that.operateType = BASE_OPERATE_TYPE.INFO
        that.$nextTick(() => {
          that.clearChecked()
          that.getRolePermission()
        })
      },
      deep: true
    }
  },
  methods: {
    async getRolePermission(
      type = GET_ROLE_PROMISSION_TYPE.SINGLE,
      fun = GET_ROLE_PROMISSION_FUN.LIST
    ) {
      let role_id =
        type === GET_ROLE_PROMISSION_TYPE.ALL ? 1 : this.roleSelect[0]
      const res = await GetRoleFunctions({ roleId: role_id })
      if (res.code === 0) {
        if (type === GET_ROLE_PROMISSION_TYPE.ALL) {
          if (fun === GET_ROLE_PROMISSION_FUN.LIST) {
            this.permissionList = [...res.result].sort(
              (a, b) => a.index - b.index
            )
            this.PPpermissionList = this.jsonToArray(
              JSON.parse(
                JSON.stringify(
                  [...res.result].sort((a, b) => a.index - b.index)
                )
              )
            )

            this.$nextTick(() => {
              this.resolvePermissionList = this.resolvePermission(
                this.permissionList
              )
            })
          } else {
            //表单去除系统管理权限
            this.permissionList = [...res.result]
              .sort((a, b) => a.index - b.index)
              .filter(item => item.functionID !== 2)

            //默认选择的权限
            this.functionSelect = this.permissionList
              .filter(
                item => item.systemCode === 1 && item.parentFunctionCode === 0
              )
              .map(it => {
                return it.functionID
              })

            this.$nextTick(() => {
              this.resolvePermissionList = this.resolvePermission(
                this.permissionList,
                false
              )
            })
          }
        } else {
          this.choosePermisson(
            [...res.result].sort((a, b) => a.index - b.index)
          )
          this.filterSelect()
        }
      }
    },
    // 处理权限未勾选
    resolvePermission(list) {
      let that = this
      return list.map(item => {
        let obj = {
          title: item.functionName,
          key: item.functionID
        }
        if (item.parentFunctionCode === 0 && item.systemCode === 1) {
          obj["disableCheckbox"] = true
        }
        if (that.operateType === BASE_OPERATE_TYPE.INFO) {
          obj["disableCheckbox"] = true
        }
        if (item.children && item.children.length) {
          obj["children"] = that.resolvePermission(item.children)
        }
        return obj
      })
    },
    // 勾选
    choosePermisson(list) {
      let that = this
      list.map(item => {
        let childLength = that.getChildren(item)
        if (childLength < (item.children === null ? 0 : item.children.length)) {
          this.halfFunctionSelect.push(item.functionID)
          this.getParentFunctionID(item.parentFunctionCode)
        } else {
          this.functionSelect.push(item.functionID)
        }
        if (item.children && item.children.length) {
          that.choosePermisson(item.children)
        }
      })
    },
    getChildren(item) {
      return this.PPpermissionList.filter(it => {
        return it.parentFunctionCode === item.functionCode
      }).length
    },

    jsonToArray(nodes) {
      let that = this
      let r = []
      if (Array.isArray(nodes)) {
        for (let i = 0, l = nodes.length; i < l; i++) {
          r.push(nodes[i]) // 取每项数据放入一个新数组
          if (
            Array.isArray(nodes[i]["children"]) &&
            nodes[i]["children"].length > 0
          )
            // 若存在children则递归调用，把数据拼接到新数组中，并且删除该children
            r = r.concat(that.jsonToArray(nodes[i]["children"]))
          delete nodes[i]["children"]
        }
      }
      return r
    },
    getParentFunctionID(functionCode) {
      let list = this.PPpermissionList.filter(it => {
        return it.functionCode === functionCode
      })
      if (list.length !== 0) {
        this.halfFunctionSelect.push(list[0].functionID)
        if (list[0].parentFunctionCode !== "0") {
          this.getParentFunctionID(list[0].parentFunctionCode)
        }
      }
    },
    filterSelect() {
      for (let i = 0; i < this.functionSelect.length; i++) {
        const element = this.functionSelect[i]
        for (let j = 0; j < this.halfFunctionSelect.length; j++) {
          const elementj = this.halfFunctionSelect[j]
          if (elementj === element) {
            this.functionSelect.splice(i, 1)
            i = i - 1
          }
        }
      }
    },

    updatePermission() {
      const that = this
      that.operateType = BASE_OPERATE_TYPE.EDIT
      that.$nextTick(() => {
        that.resolvePermissionList = that.resolvePermission(that.permissionList)
      })
    },
    cancelSavePermission() {
      this.operateType = BASE_OPERATE_TYPE.INFO
      this.getRolePermission()
    },

    clearChecked() {
      this.functionSelect = []
      this.halfFunctionSelect = []
    },

    functionSelectCheck(checkedKeys, e) {
      this.halfFunctionSelect = e.halfCheckedKeys
    },
    async toSavePermission(role_id = "", type = "update") {
      this.load = true
      let funList = []
      if (type === "update") {
        role_id = this.roleSelect[0]
      }
      funList = [...this.functionSelect, ...this.halfFunctionSelect]
      let params = []
      for (let i = 0; i < funList.length; i++) {
        const element = funList[i]
        params.push({
          roleID: role_id,
          functionID: element
        })
      }
      const editRes = await edit({ roleFunctionList: params })
      if (editRes.code === 0) {
        if (type === "add") {
          this.$message.success("新增成功")
        } else {
          this.$message.success("修改成功")
        }
        this.operateType = BASE_OPERATE_TYPE.INFO
        this.$emit("edit-success")
      }
      this.load = false
    },
    toAddRole() {
      this.operateType = BASE_OPERATE_TYPE.ADD
      this.getRolePermission(
        GET_ROLE_PROMISSION_TYPE.ALL,
        GET_ROLE_PROMISSION_FUN.ADD
      )
    },
    toCloseAddRole() {
      this.operateType = BASE_OPERATE_TYPE.INFO
      this.permissionList = []
      this.resolvePermissionList = []
      this.functionSelect = []
    },
    getSelectedFunctionIDs() {
      let functionIDs = []
      const functionIdList = [
        ...this.functionSelect,
        ...this.halfFunctionSelect
      ]
      for (let i = 0; i < functionIdList.length; i++) {
        functionIDs.push(functionIdList[i])
      }
      return functionIDs
    }
  }
}
</script>

<style lang="scss" scoped>
._bottom {
  display: flex;
  justify-content: flex-end;
}
.loading {
  position: relative;
  width: 150%;
  background: rgba(0, 0, 0, 0.05);
}
</style>

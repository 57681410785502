export const projectListColumns = [
  {
    title: "项目编号",
    width: 190,
    align: "center",
    ellipsis: true,
    resizable: true,
    key: "projectNo",
    scopedSlots: { customRender: "projectNo" }
  },
  {
    title: "客户",
    width: 190,
    align: "center",
    ellipsis: true,
    resizable: true,
    key: "customerName",
    scopedSlots: { customRender: "customerName" }
  },
  {
    title: "货品名称",
    width: 150,
    align: "center",
    ellipsis: true,
    resizable: true,
    dataIndex: "goodsName",
    // scopedSlots: { customRender: "assignType" },
    key: "goodsName"
  },
  {
    title: "货品规格",
    width: 150,
    align: "center",
    ellipsis: true,
    resizable: true,
    dataIndex: "goodsSpec",
    // scopedSlots: { customRender: "tag" },
    key: "goodsSpec"
  },
  {
    title: "货品类别",
    width: 100,
    align: "center",
    ellipsis: true,
    resizable: true,
    dataIndex: "categoryName",
    // scopedSlots: { customRender: "userName" },
    key: "categoryName"
  },
  {
    title: "联系人",
    width: 100,
    align: "center",
    ellipsis: true,
    resizable: true,
    dataIndex: "contactName",
    // scopedSlots: { customRender: "changeTag" },
    key: "contactName"
  },
  {
    title: "联系人电话",
    width: 120,
    align: "center",
    ellipsis: true,
    resizable: true,
    dataIndex: "contactTel",
    // scopedSlots: { customRender: "changeTag" },
    key: "contactTel"
  },
  {
    title: "支付状态",
    width: 100,
    align: "center",
    ellipsis: true,
    resizable: true,
    dataIndex: "paymentStatusName",
    key: "paymentStatusName"
  },
  {
    title: "价格",
    width: 80,
    align: "center",
    ellipsis: true,
    resizable: true,
    dataIndex: "totalPrice",
    // scopedSlots: { customRender: "hasUploadFile" },
    key: "totalPrice"
  },
  {
    title: "销售负责人",
    width: 100,
    align: "center",
    ellipsis: true,
    resizable: true,
    dataIndex: "creator",
    // scopedSlots: { customRender: "hasUploadFile" },
    key: "creator"
  },
  {
    title: "签约日期",
    width: 120,
    ellipsis: true,
    resizable: true,
    align: "center",
    scopedSlots: { customRender: "signTime" },
    key: "signTime"
  },
  {
    title: "交付日期",
    width: 120,
    ellipsis: true,
    align: "center",
    resizable: true,
    scopedSlots: { customRender: "releasedTime" },
    key: "releasedTime"
  },
  {
    title: "创建日期",
    width: 180,
    ellipsis: true,
    align: "center",
    resizable: true,
    scopedSlots: { customRender: "insertTime" },
    key: "insertTime"
  }
]

export const projectGoodsListColumns = [
  {
    title: "排名",
    width: 60,
    align: "center",
    ellipsis: true,
    resizable: true,
    scopedSlots: { customRender: "index" },
    // dataIndex: "index",
    key: "index"
  },
  {
    title: "货品名称",
    width: 120,
    align: "center",
    ellipsis: true,
    resizable: true,
    dataIndex: "goodsName",
    key: "goodsName"
  },
  {
    title: "货品品牌",
    width: 120,
    align: "center",
    ellipsis: true,
    resizable: true,
    dataIndex: "goodsBrand",
    key: "goodsBrand"
  },
  {
    title: "货品规格",
    width: 120,
    align: "center",
    ellipsis: true,
    resizable: true,
    dataIndex: "goodsSpec",
    key: "goodsSpec"
  },
  {
    title: "货品类别",
    width: 120,
    align: "center",
    ellipsis: true,
    resizable: true,
    dataIndex: "categoryName",
    key: "categoryName"
  },
  {
    title: "销售数量",
    width: 100,
    align: "center",
    ellipsis: true,
    resizable: true,
    // dataIndex: "goodsNum",
    scopedSlots: { customRender: "goodsNum" },
    key: "goodsNum"
  },
  {
    title: "销售总金额",
    width: 100,
    align: "center",
    ellipsis: true,
    resizable: true,
    // dataIndex: "goodsTotalPrice",
    scopedSlots: { customRender: "goodsTotalPrice" },
    key: "goodsTotalPrice"
  }
]

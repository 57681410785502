export const SourcePlatformListColumns = [
  {
    title: "推广平台",
    width: 190,
    align: "center",
    ellipsis: true,
    resizable: true,
    dataIndex: "platformName",
    key: "platformName"
  },
  {
    title: "创建者",
    width: 120,
    align: "center",
    ellipsis: true,
    resizable: true,
    dataIndex: "creatorName",
    key: "creatorName"
  },
  {
    title: "创建日期",
    width: 160,
    ellipsis: true,
    align: "center",
    resizable: true,
    scopedSlots: { customRender: "insertTime" },
    key: "insertTime"
  },
  {
    title: "操作",
    fixed: "right",
    align: "center",
    width: 150,
    scopedSlots: { customRender: "operation" }
  }
]

export const AllConversationListColumns = [
  {
    title: "沟通方式",
    width: 190,
    align: "center",
    ellipsis: true,
    resizable: true,
    dataIndex: "name",
    key: "name"
  },
  {
    title: "创建者",
    width: 120,
    align: "center",
    ellipsis: true,
    resizable: true,
    dataIndex: "creatorName",
    key: "creatorName"
  },
  {
    title: "创建日期",
    width: 160,
    ellipsis: true,
    align: "center",
    resizable: true,
    scopedSlots: { customRender: "insertTime" },
    key: "insertTime"
  },
  {
    title: "操作",
    fixed: "right",
    align: "center",
    width: 150,
    scopedSlots: { customRender: "operation" }
  }
]

export const MarketingListColumns = [
  {
    title: "日期",
    width: 80,
    align: "center",
    ellipsis: true,
    resizable: true,
    // dataIndex: "dateNo",
    scopedSlots: { customRender: "dateNo" },
    key: "dateNo"
  },
  {
    title: "消费",
    width: 50,
    align: "center",
    ellipsis: true,
    resizable: true,
    dataIndex: "cost",
    key: "cost"
  },
  {
    title: "展现数",
    width: 50,
    align: "center",
    ellipsis: true,
    resizable: true,
    dataIndex: "appearNum",
    key: "appearNum"
  },
  {
    title: "点击数",
    width: 50,
    align: "center",
    ellipsis: true,
    resizable: true,
    dataIndex: "clickNum",
    key: "clickNum"
  },
  {
    title: "抵达数",
    width: 50,
    align: "center",
    ellipsis: true,
    resizable: true,
    dataIndex: "arriveNum",
    key: "arriveNum"
  },
  {
    title: "总对话数",
    width: 60,
    align: "center",
    ellipsis: true,
    resizable: true,
    dataIndex: "conversationTotal",
    key: "conversationTotal"
  },
  {
    title: "有效对话数",
    width: 60,
    align: "center",
    ellipsis: true,
    resizable: true,
    dataIndex: "conversationValid",
    key: "conversationValid"
  },
  {
    title: "总对话率",
    width: 60,
    align: "center",
    ellipsis: true,
    resizable: true,
    // dataIndex: "cost",
    scopedSlots: { customRender: "totalConversationRate" },
    key: "totalConversationRate"
  },
  {
    title: "有效对话率",
    width: 70,
    align: "center",
    ellipsis: true,
    resizable: true,
    // dataIndex: "cost",
    scopedSlots: { customRender: "validConversationRate" },
    key: "validConversationRate"
  },
  {
    title: "抵达率",
    width: 50,
    align: "center",
    ellipsis: true,
    resizable: true,
    // dataIndex: "cost",
    scopedSlots: { customRender: "arriveRate" },
    key: "arriveRate"
  },
  {
    title: "点击率",
    width: 50,
    align: "center",
    ellipsis: true,
    resizable: true,
    // dataIndex: "cost",
    scopedSlots: { customRender: "clickRate" },
    key: "clickRate"
  },
  {
    title: "点击成本",
    width: 60,
    align: "center",
    ellipsis: true,
    resizable: true,
    // dataIndex: "cost",
    scopedSlots: { customRender: "clickCost" },
    key: "clickCost"
  },
  {
    title: "总对话成本",
    width: 60,
    align: "center",
    ellipsis: true,
    resizable: true,
    // dataIndex: "cost",
    scopedSlots: { customRender: "totalConversationCost" },
    key: "totalConversationCost"
  },
  {
    title: "有效对话成本",
    width: 70,
    align: "center",
    ellipsis: true,
    resizable: true,
    // dataIndex: "cost",
    scopedSlots: { customRender: "validConversationCost" },
    key: "validConversationCost"
  },
  {
    title: "创建日期",
    width: 120,
    ellipsis: true,
    align: "center",
    resizable: true,
    scopedSlots: { customRender: "insertTime" },
    key: "insertTime"
  },
  {
    title: "操作",
    fixed: "right",
    align: "center",
    width: 150,
    scopedSlots: { customRender: "operation" }
  }
]

export const SourcePlatformMarketingListColumns = [
  {
    title: "日期",
    width: 110,
    align: "center",
    ellipsis: true,
    resizable: true,
    dataIndex: "dateNo",
    // scopedSlots: { customRender: "dateNo" },
    key: "dateNo"
  },
  {
    title: "平台名",
    width: 120,
    align: "center",
    ellipsis: true,
    resizable: true,
    // dataIndex: "dateNo",
    scopedSlots: { customRender: "sourcePlatformID" },
    key: "sourcePlatformID"
  },
  {
    title: "消费",
    width: 50,
    align: "center",
    ellipsis: true,
    resizable: true,
    dataIndex: "cost",
    key: "cost"
  },
  {
    title: "展现数",
    width: 60,
    align: "center",
    ellipsis: true,
    resizable: true,
    dataIndex: "appearNum",
    key: "appearNum"
  },
  {
    title: "点击数",
    width: 60,
    align: "center",
    ellipsis: true,
    resizable: true,
    dataIndex: "clickNum",
    key: "clickNum"
  },
  {
    title: "抵达数",
    width: 60,
    align: "center",
    ellipsis: true,
    resizable: true,
    dataIndex: "arriveNum",
    key: "arriveNum"
  },
  {
    title: "总对话数",
    width: 70,
    align: "center",
    ellipsis: true,
    resizable: true,
    dataIndex: "conversationTotal",
    key: "conversationTotal"
  },
  {
    title: "有效对话数",
    width: 80,
    align: "center",
    ellipsis: true,
    resizable: true,
    dataIndex: "conversationValid",
    key: "conversationValid"
  },
  {
    title: "总对话率",
    width: 90,
    align: "center",
    ellipsis: true,
    resizable: true,
    // dataIndex: "cost",
    scopedSlots: { customRender: "totalConversationRate" },
    key: "totalConversationRate"
  },
  {
    title: "有效对话率",
    width: 90,
    align: "center",
    ellipsis: true,
    resizable: true,
    // dataIndex: "cost",
    scopedSlots: { customRender: "validConversationRate" },
    key: "validConversationRate"
  },
  {
    title: "抵达率",
    width: 90,
    align: "center",
    ellipsis: true,
    resizable: true,
    // dataIndex: "cost",
    scopedSlots: { customRender: "arriveRate" },
    key: "arriveRate"
  },
  {
    title: "点击率",
    width: 90,
    align: "center",
    ellipsis: true,
    resizable: true,
    // dataIndex: "cost",
    scopedSlots: { customRender: "clickRate" },
    key: "clickRate"
  },
  {
    title: "点击成本",
    width: 90,
    align: "center",
    ellipsis: true,
    resizable: true,
    // dataIndex: "cost",
    scopedSlots: { customRender: "clickCost" },
    key: "clickCost"
  },
  {
    title: "总对话成本",
    width: 90,
    align: "center",
    ellipsis: true,
    resizable: true,
    // dataIndex: "cost",
    scopedSlots: { customRender: "totalConversationCost" },
    key: "totalConversationCost"
  },
  {
    title: "有效对话成本",
    width: 90,
    align: "center",
    ellipsis: true,
    resizable: true,
    // dataIndex: "cost",
    scopedSlots: { customRender: "validConversationCost" },
    key: "validConversationCost"
  },
  {
    title: "创建日期",
    width: 160,
    ellipsis: true,
    align: "center",
    resizable: true,
    scopedSlots: { customRender: "insertTime" },
    key: "insertTime"
  },
  {
    title: "操作",
    fixed: "right",
    align: "center",
    width: 150,
    scopedSlots: { customRender: "operation" }
  }
]

export const SourcePlatformMarketingConversationListColumns = [
  {
    title: "沟通方式",
    width: 300,
    align: "center",
    ellipsis: true,
    resizable: true,
    dataIndex: "name",
    // scopedSlots: { customRender: "dateNo" },
    key: "name"
  },
  {
    title: "沟通次数",
    width: 200,
    align: "center",
    ellipsis: true,
    resizable: true,
    // dataIndex: "dateNo",
    scopedSlots: { customRender: "conversationNum" },
    key: "conversationNum"
  }
]

<template>
  <div>
    <a-form-model
      :label-col="{ span: 6 }"
      :wrapper-col="{ span: 18 }"
      ref="ruleForm"
      :model="currentAttachmentInfo"
      :rules="rules"
    >
      <a-row :gutter="20">
        <a-col :lg="8" :md="24" :sm="24" :xl="8" :xs="24" :xxl="8">
          <a-form-model-item label="附件ID">
            <a-input v-model="currentAttachmentInfo.attachmentID" disabled/>
          </a-form-model-item>
        </a-col>
        <a-col :lg="8" :md="24" :sm="24" :xl="8" :xs="24" :xxl="8">
          <a-form-model-item label="附件类型" prop="attachmentType" >
            <a-select v-model="currentAttachmentInfo.attachmentTypeValue" :disabled="disabled"  placeholder="请选择附件类型">
              <a-select-option v-for="(item) in attachmentType" :key="item.key" :value="item.key">
                {{item.value}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :lg="8" :md="24" :sm="24" :xl="8" :xs="24" :xxl="8">
          <a-form-model-item label="创建日期">
            <a-input :value="fullTimeFilter(currentAttachmentInfo.insertTime)" disabled></a-input>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row :gutter="20">
        <a-col :lg="8" :md="24" :sm="24" :xl="8" :xs="24" :xxl="8">
          <a-form-model-item label="公司名称">
            <a-input v-model="currentAttachmentInfo.customerName" disabled/>
          </a-form-model-item>
        </a-col>
        <a-col :lg="8" :md="24" :sm="24" :xl="8" :xs="24" :xxl="8">
          <a-form-model-item label="合同编号">
            <a-input v-model="currentAttachmentInfo.contractNo" disabled/>
          </a-form-model-item>
        </a-col>
        <a-col :lg="8" :md="24" :sm="24" :xl="8" :xs="24" :xxl="8">
          <a-form-model-item label="产品编号">
            <a-input v-model="currentAttachmentInfo.projectNo" disabled/>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row :gutter="20">
        <a-col :lg="8" :md="24" :sm="24" :xl="8" :xs="24" :xxl="8">
          <a-form-model-item label="创建人">
            <a-input v-model="currentAttachmentInfo.creator" disabled/>
          </a-form-model-item>
        </a-col>

        <a-col :lg="8" :md="24" :sm="24" :xl="8" :xs="24" :xxl="8">
          <a-form-model-item label="更新时间">
            <a-input :value="fullTimeFilter(currentAttachmentInfo.updateTime)" disabled></a-input>
          </a-form-model-item>
        </a-col>
      </a-row>


      <a-row :gutter="20">
        <a-col :span="24">
          <a-form-model-item
            label="上传文件附件"
            :labelCol="{ span: 2 }"
            :wrapperCol="{ span: 22 }"
          >
            <SingleUploadFileAfter
              ref="singleUploadFileAfter"
              :allFileCancelPath="AttachmentAllFileCancel"
              :fileList="fileList"
              :disabled="disabled"
              :fileRemovedPath="AttachmentFileRemoved"
              :fileUploadPath="AttachmentFileUpload"
              :originFileDeletePath="AttachmentOriginFileDelete"
              @update-file-list="updateFileList"
            >
            </SingleUploadFileAfter>
          </a-form-model-item>
        </a-col>

      </a-row>

      <a-row :gutter="20">
<!--        {{currentAttachmentInfo}}-->
      </a-row>
    </a-form-model>
  </div>
</template>

<script>
import { editAttachmentInfo, getAttachmentListByContractId } from "@/api/customer/attachment";
import { fullTimeFilter } from "@/utils/utils"
import { GetDictionary } from "@/api/public-api";
import SingleUploadFileAfter from "@/components/SingleUploadFileAfter/index.vue";
import { FileOperateAPI } from "@/api/upload-file-after"
import { getAttachmentDetailById } from "@/api/customer/attachment"
import { getDifferencesList } from "@/utils/array_util";
export default {
  name: "currentAttachmentInfoC",
  data(){
    return {
      disabled:true,
      currentAttachmentInfo:{},
      attachmentType: [],
      rules: {
        fileName:[
          {required:true, message:'请输入附件名称',trigger:'blur'}
        ],
        attachmentType:[
          {required:true, message:'请输入附件类型',trigger:'blur'}
        ],
        filePath:[
          {required:true, message:'请输入附件地址',trigger:'blur'}
        ]
      },


      AttachmentAllFileCancel: FileOperateAPI.AttachmentAllFileCancel,
      AttachmentFileRemoved: FileOperateAPI.AttachmentFileRemoved,
      AttachmentFileUpload: FileOperateAPI.AttachmentFileUpload,
      AttachmentOriginFileDelete: FileOperateAPI.AttachmentOriginFileDelete,
      fileList:[],
      originFileList: [],
      isSuccess: 1
    }
  },
  async created() {
    // this.getAttachmentList()
    const resultObj = await GetDictionary({ typeName: "attachmentType" })
    this.attachmentType = resultObj.result
  },
  methods:{
    fullTimeFilter,
    getAttachmentList(){
      getAttachmentListByContractId(this.currentAttachmentInfo.contractID)
    },
    updateFileList(fileList) {
      this.fileList = [...fileList]
    },
   async showData(){
      const attachmentDetailRes = await getAttachmentDetailById({attachmentID: this.currentAttachmentInfo.attachmentID})
     if(attachmentDetailRes.code === 0) {
       const data = []
       if(attachmentDetailRes.result.fileName !== ''){
         data.push({
           ...attachmentDetailRes.result,
           filePath: attachmentDetailRes.result.filePath,
           fileName: attachmentDetailRes.result.fileName
         })
         this.fileList = this.$refs.singleUploadFileAfter.parseFileList(data)
         this.originFileList = this.$refs.singleUploadFileAfter.parseFileList(data)
       }
     }
    },
    async subEdit(){
      const updateData = {
        ...this.currentAttachmentInfo,
        files: JSON.stringify(
          this.fileList.map(item => ({
            uid: item.uid,
            fileName: item.name || item.fileName,
            filePath: item.url || item.filePath,
            attachmentID: item.attachmentID || 0
          }))
        ),
        changeTag: this.currentInfo?.newTag == this.currentInfo?.oldTag ? 1 : 2,
        hasUploadFile: (this.fileList || []).length > 0 ? 2 : 1,
        changeFile:
          (getDifferencesList(this.fileList, this.originFileList, "uid") || [])
            .length > 0
      }
      updateData["fileName"] = JSON.parse(updateData.files)[0]?.fileName
      updateData["filePath"] = JSON.parse(updateData.files)[0]?.filePath
      updateData["AttachmentID"] = this.currentAttachmentInfo.attachmentID
      const [delResCode, ] = await Promise.all([
        this.$refs.singleUploadFileAfter.deleteOriginFile(), //删除原来的附件
        editAttachmentInfo(updateData)
      ])
      if(delResCode === 0){
        this.isSuccess = 0
        this.disabled = true
      }else {
        this.isSuccess = 1
      }
    }
  },
  components: {
    SingleUploadFileAfter
  }
};
</script>

<style scoped>

</style>
import { method } from "@/utils/request"

const api = {
  Add: "keyword",
  Del: "keyword",
  Edit: "keyword",
  Get: "keyword/list",
  GetKeyWordAnalysis: "keyword/static-analysis"
}

const url = process.env.VUE_APP_API_URL

/**
 * @param parameter
 * @returns {*}
 */
export function add(parameter) {
  return method(url)({
    url: api.Add,
    method: "post",
    data: {
      ...parameter
    }
  })
}

/**
 * @param {*} parameter
 */
export function del(ids) {
  return method(url)({
    url: api.Del,
    method: "delete",
    data: { keywordIDs: ids }
  })
}

/**
 * @param {*} parameter
 */
export function edit(parameter) {
  return method(url)({
    url: api.Edit,
    method: "put",
    data: parameter
  })
}

/**
 * @param {*} parameter
 */
export function get(parameter) {
  return method(url)({
    url: api.Get,
    method: "post",
    data: parameter
  })
}

export function getKeyWordAnalysis(parameter) {
  return method(url)({
    url: api.GetKeyWordAnalysis,
    method: "get",
    data: {...parameter}
  })
}

export default {
  add,
  del,
  edit,
  get
}

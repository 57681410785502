<template>
  <div class="app-container">
    <div class="edit-approval">
      <!--      <div style="width: 1000px;height:800px;transform: translate(0)">-->
      <div class="fd-nav">
        <div class="fd-nav-left">
          <div class="fd-nav-back" @click="toReturn">
            <!--            <i class="anticon anticon-left"></i>-->
            <img
              src="@/assets/image/refresh.png"
              style="width: 40px;height:40px;margin-top: 7px"
            />
          </div>
          <div class="fd-nav-title">
            <a-input
              v-model="workFlowDef.name"
              :allowClear="true"
              :disabled="operateType == 0"
              placeholder="请输入审批模板名称"
            />
          </div>
        </div>
        <!--      </div>-->
        <!-- <div class="fd-nav-center">
                  <div class="fd-nav-container">
                      <div class="ghost-bar" style="transform: translateX(300px);"></div>
                      <div class="fd-nav-item"><span class="order-num">1</span>基础设置</div>
                      <div class="fd-nav-item"><span class="order-num">2</span>表单设计</div>
                      <div class="fd-nav-item active"><span class="order-num">3</span>流程设计</div>
                      <div class="fd-nav-item"><span class="order-num">4</span>高级设置</div>
                  </div>
              </div> -->
        <div class="fd-nav-right">
          <!-- <button type="button" class="ant-btn button-preview"><span>预 览</span></button> -->
          <button
            v-if="operateType == 0"
            class="ant-btn button-publish"
            type="button"
            @click="toEdit"
          >
            <span>修 改</span>
          </button>
          <button
            v-if="operateType == 1"
            class="ant-btn button-publish"
            type="button"
            @click="saveSet"
          >
            <span>发 布</span>
          </button>
          <button
            v-if="operateType == 2"
            class="ant-btn button-publish"
            type="button"
            @click="toSave"
          >
            <span>保 存</span>
          </button>
        </div>
      </div>
      <div class="fd-nav-content">
        <section class="dingflow-design">
          <div class="zoom">
            <div
              :class="'zoom-out' + (nowVal == 50 ? ' disabled' : '')"
              @click="zoomSize(1)"
            ></div>
            <span>{{ nowVal }}%</span>
            <div
              :class="'zoom-in' + (nowVal == 300 ? ' disabled' : '')"
              @click="zoomSize(2)"
            ></div>
          </div>
          <div
            id="box-scale"
            :style="
              'transform: scale(' +
                nowVal / 100 +
                '); transform-origin: 50% 0px 0px;'
            "
            class="box-scale"
          >
            <nodeWrap
              :flowPermission.sync="flowPermission"
              :nodeConfig.sync="nodeConfig"
            ></nodeWrap>
            <div class="end-node">
              <div class="end-node-circle"></div>
              <div class="end-node-text">流程结束</div>
            </div>
          </div>
        </section>
      </div>
      <errorDialog :list="tipList" :visible.sync="tipVisible" />
      <promoterDrawer />
      <approverDrawer :directorMaxLevel="directorMaxLevel" />
      <copyerDrawer />
      <conditionDrawer />
    </div>
  </div>
</template>
<script>
import errorDialog from "@/components/Approval/dialog/errorDialog"
import promoterDrawer from "@/components/Approval/drawer/promoterDrawer"
import approverDrawer from "@/components/Approval/drawer/approverDrawer"
import copyerDrawer from "@/components/Approval/drawer/copyerDrawer"
import conditionDrawer from "@/components/Approval/drawer/conditionDrawer"
import { getWorkFlowData, setWorkFlowData } from "@/api/approval/api"
import { mapMutations } from "vuex"
import "@/assets/css/Approval/override-element-ui.css"
import { addApprovalConfig } from "@/views/approval/config"

export default {
  components: {
    errorDialog,
    promoterDrawer,
    approverDrawer,
    copyerDrawer,
    conditionDrawer
  },
  data() {
    return {
      show: false,
      tipList: [],
      tipVisible: false,
      nowVal: 100,
      processConfig: {},
      nodeConfig: {},
      workFlowDef: {},
      flowPermission: [],
      directorMaxLevel: 0,
      approvalTemplateID: 0,
      operateType: 0
    }
  },
  async created() {
    this.approvalTemplateID = Number(this.$route.query.approvalTemplateID)
    this.operateType = Number(this.$route.query.operateType)
    if (this.approvalTemplateID > 0 && this.operateType == 0) {
      const res = await getWorkFlowData({
        approvalTemplateID: this.approvalTemplateID
      })
      this.processConfig = res.result
    } else if (this.operateType === 1) {
      this.processConfig = addApprovalConfig
    }
    let {
      nodeConfig,
      flowPermission,
      directorMaxLevel,
      workFlowDef,
      tableId
    } = this.processConfig
    this.nodeConfig = nodeConfig
    this.flowPermission = flowPermission
    this.directorMaxLevel = directorMaxLevel
    this.workFlowDef = workFlowDef
    this.setTableId(tableId)
  },
  methods: {
    ...mapMutations(["setTableId", "setIsTried"]),
    toReturn() {
      location.reload()
    },
    reErr({ childNode }) {
      if (childNode) {
        let { type, error, nodeName, conditionNodes } = childNode
        if (type == 1 || type == 2) {
          if (error) {
            this.tipList.push({
              name: nodeName,
              type: ["", "审核人", "抄送人"][type]
            })
          }
          this.reErr(childNode)
        } else if (type == 3) {
          this.reErr(childNode)
        } else if (type == 4) {
          this.reErr(childNode)
          for (var i = 0; i < conditionNodes.length; i++) {
            if (conditionNodes[i].error) {
              this.tipList.push({
                name: conditionNodes[i].nodeName,
                type: "条件"
              })
            }
            this.reErr(conditionNodes[i])
          }
        }
      } else {
        childNode = null
      }
    },
    async saveSet() {
      this.setIsTried(true)
      this.tipList = []
      this.reErr(this.nodeConfig)
      if (this.tipList.length != 0) {
        this.tipVisible = true
        return
      }
      this.processConfig.flowPermission = this.flowPermission
      // eslint-disable-next-line no-console
      console.log(JSON.stringify(this.processConfig))

      if (!this.processConfig.workFlowDef.name) {
        this.$message.error("请填写审批模板名称")
        return
      }
      if (this.processConfig.nodeConfig.nodeName !== "发起人") {
        this.$message.error("系统错误，请重新刷新页面操作")
        return
      }

      let res = await setWorkFlowData(this.processConfig)
      if (res.code == 12) {
        this.$message.success("设置成功")
        setTimeout(function() {
          window.location.href = `${process.env.VUE_APP_BASE_URL}/#/approval/editApproval?operateType=0&approvalTemplateID=${res.result.approvalTemplateID}`
          location.reload()
        }, 500)
      }
    },
    toEdit() {
      this.operateType = 2
    },
    toSave() {
      this.$message.error(
        "修改保存功能尚未开发完毕，请联系管理员！！！  ღ( ´･ᴗ･` )比心"
      )
    },
    zoomSize(type) {
      if (type == 1) {
        if (this.nowVal == 50) return
        this.nowVal -= 10
      } else {
        if (this.nowVal == 300) return
        this.nowVal += 10
      }
    }
  }
}
</script>
<style>
body {
  background: #eee;
}

@font-face {
  font-family: Chinese Quote;
  src: local("PingFang SC"), local("SimSun");
  unicode-range: u+2018, u+2019, u+201 c, u+201 d;
}

body,
html {
  width: 100%;
  /* height: 100% */
}

/*input::-ms-clear,
input::-ms-reveal {
  display: none;
}*/

.edit-approval :after,
.edit-approval :before {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

@-ms-viewport {
  width: device-width;
}

.edit-approval article,
.edit-approval aside,
.edit-approval dialog,
.edit-approval figcaption,
.edit-approval figure,
.edit-approval footer,
.edit-approval header,
.edit-approval hgroup,
.edit-approval main,
.edit-approval nav,
.edit-approval section {
  display: block;
}

body {
  margin: 0;
  font-family: Chinese Quote, -apple-system, BlinkMacSystemFont, Segoe UI,
    PingFang SC, Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica,
    Arial, sans-serif;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.65);
  background-color: #fff;
}

[tabindex="-1"]:focus {
  outline: none !important;
}

.edit-approval hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

.edit-approval h1,
.edit-approval h2,
.edit-approval h3,
.edit-approval h4,
.edit-approval h5,
.edit-approval h6 {
  margin-top: 0;
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
}

.edit-approval p {
  margin-top: 0;
  margin-bottom: 1em;
}

.edit-approval abbr[data-original-title],
.edit-approval abbr[title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
}

.edit-approval address {
  margin-bottom: 1em;
  font-style: normal;
  line-height: inherit;
}

.edit-approval input[type="number"],
.edit-approval input[type="password"],
.edit-approval input[type="text"],
.edit-approval textarea {
  -webkit-appearance: none;
}

.edit-approval dl,
.edit-approval ol,
.edit-approval ul {
  margin-top: 0;
  margin-bottom: 1em;
}

.edit-approval ol ol,
.edit-approval ol ul,
.edit-approval ul ol,
.edit-approval ul ul {
  margin-bottom: 0;
}

.edit-approval dt {
  font-weight: 500;
}

.edit-approval dd {
  margin-bottom: 0.5em;
  margin-left: 0;
}

.edit-approval blockquote {
  margin: 0 0 1em;
}

.edit-approval dfn {
  font-style: italic;
}

.edit-approval b,
.edit-approval strong {
  font-weight: bolder;
}

.edit-approval small {
  font-size: 80%;
}

.edit-approval sub,
.edit-approval sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

.edit-approval sub {
  bottom: -0.25em;
}

.edit-approval sup {
  top: -0.5em;
}

.edit-approval a {
  color: #1890ff;
  background-color: transparent;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  -webkit-text-decoration-skip: objects;
}

.edit-approval a:focus {
  text-decoration: underline;
  text-decoration-skip: auto;
}

.edit-approval a:hover {
  color: #40a9ff;
}

.edit-approval a:active {
  color: #096dd9;
}

.edit-approval a:active,
.edit-approval a:hover {
  outline: 0;
  text-decoration: none;
}

.edit-approval code,
.edit-approval kbd,
.edit-approval pre,
.edit-approval samp {
  font-family: SFMono-Regular, Consolas, Liberation Mono, Menlo, Courier,
    monospace;
  font-size: 1em;
}

.edit-approval pre {
  margin-top: 0;
  margin-bottom: 1em;
  overflow: auto;
}

.edit-approval figure {
  margin: 0 0 1em;
}

.edit-approval img {
  vertical-align: middle;
  border-style: none;
}

.edit-approval svg:not(:root) {
  overflow: hidden;
}

.edit-approval [role="button"],
.edit-approval a,
.edit-approval area,
.edit-approval button,
.edit-approval input:not([type="range"]),
.edit-approval label,
.edit-approval select,
.edit-approval summary,
.edit-approval textarea {
  touch-action: manipulation;
}

.edit-approval table {
  border-collapse: collapse;
}

.edit-approval caption {
  padding-top: 0.75em;
  padding-bottom: 0.3em;
  color: rgba(0, 0, 0, 0.45);
  text-align: left;
  caption-side: bottom;
}

.edit-approval th {
  text-align: inherit;
}

.edit-approval button,
.edit-approval input,
.edit-approval optgroup,
.edit-approval select,
.edit-approval textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
}

.edit-approval button,
.edit-approval input {
  overflow: visible;
}

.edit-approval button,
.edit-approval select {
  text-transform: none;
}

.edit-approval [type="reset"],
.edit-approval [type="submit"],
.edit-approval button,
.edit-approval html [type="button"] {
  -webkit-appearance: button;
}

[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner,
button::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="date"],
input[type="datetime-local"],
input[type="month"],
input[type="time"] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5em;
  font-size: 1.5em;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

mark {
  padding: 0.2em;
  background-color: #feffe6;
}

::selection {
  background: #1890ff;
  color: #fff;
}

.clearfix {
  zoom: 1;
}

.clearfix:after,
.clearfix:before {
  content: "";
  display: table;
}

.clearfix:after {
  clear: both;
}

@font-face {
  font-family: anticon;
  font-display: fallback;
  src: url("https://at.alicdn.com/t/font_148784_v4ggb6wrjmkotj4i.eot");
  src: url("https://at.alicdn.com/t/font_148784_v4ggb6wrjmkotj4i.woff")
      format("woff"),
    url("https://at.alicdn.com/t/font_148784_v4ggb6wrjmkotj4i.ttf")
      format("truetype"),
    url("https://at.alicdn.com/t/font_148784_v4ggb6wrjmkotj4i.svg#iconfont")
      format("svg");
}

.anticon {
  display: inline-block;
  font-style: normal;
  vertical-align: baseline;
  text-align: center;
  text-transform: none;
  line-height: 1;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.edit-approval .anticon:before {
  display: block;
  font-family: anticon !important;
}

.anticon-close:before {
  content: "\E633";
}

.anticon-right:before {
  content: "\E61F";
}

.anticon-exclamation-circle {
  color: rgb(242, 86, 67);
}

.anticon-exclamation-circle:before {
  content: "\E62C";
}

.anticon-left:before {
  content: "\E620";
}

.anticon-close-circle:before {
  content: "\E62E";
}

.edit-approval .ant-btn {
  line-height: 1.5;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 0 15px;
  font-size: 14px;
  border-radius: 4px;
  height: 32px;
  user-select: none;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  position: relative;
  color: rgba(0, 0, 0, 0.65);
  background-color: #fff;
  border-color: #d9d9d9;
}

.edit-approval .ant-btn > .anticon {
  line-height: 1;
}

.edit-approval .ant-btn,
.edit-approval .ant-btn:active,
.edit-approval .ant-btn:focus {
  outline: 0;
}

.edit-approval .ant-btn > a:only-child {
  color: currentColor;
}

.edit-approval .ant-btn > a:only-child:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
}

.edit-approval .ant-btn:focus,
.edit-approval .ant-btn:hover {
  color: #40a9ff;
  background-color: #fff;
  border-color: #40a9ff;
}

.edit-approval .ant-btn:focus > a:only-child,
.edit-approval .ant-btn:hover > a:only-child {
  color: currentColor;
}

.edit-approval .ant-btn:focus > a:only-child:after,
.edit-approval .ant-btn:hover > a:only-child:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
}

.edit-approval .ant-btn.active,
.edit-approval .ant-btn:active {
  color: #096dd9;
  background-color: #fff;
  border-color: #096dd9;
}

.edit-approval .ant-btn.active > a:only-child,
.edit-approval .ant-btn:active > a:only-child {
  color: currentColor;
}

.edit-approval .ant-btn.active > a:only-child:after,
.edit-approval .ant-btn:active > a:only-child:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
}

.edit-approval .ant-btn.active,
.edit-approval .ant-btn:active,
.edit-approval .ant-btn:focus,
.edit-approval .ant-btn:hover {
  background: #fff;
  text-decoration: none;
}

.edit-approval .ant-btn > i,
.edit-approval .ant-btn > span {
  pointer-events: none;
}

.edit-approval .ant-btn:before {
  position: absolute;
  top: -1px;
  left: -1px;
  bottom: -1px;
  right: -1px;
  background: #fff;
  opacity: 0.35;
  content: "";
  border-radius: inherit;
  z-index: 1;
  transition: opacity 0.2s;
  pointer-events: none;
  display: none;
}

.edit-approval .ant-btn .anticon {
  transition: margin-left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.edit-approval .ant-btn:active > span,
.edit-approval .ant-btn:focus > span {
  position: relative;
}

.edit-approval .ant-btn > .anticon + span,
.edit-approval .ant-btn > span + .anticon {
  margin-left: 8px;
}

.edit-approval .fd-nav-container {
  display: inline-block;
  position: relative;
}

.edit-approval .fd-nav-container .ghost-bar {
  position: absolute;
  width: 150px;
  height: 100%;
  left: 0;
  background: #1583f2;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.edit-approval .fd-nav-container .ghost-bar:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-left: -5px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 5px 6px;
  border-color: transparent transparent #f6f6f6;
}

.edit-approval .fd-nav-item {
  position: relative;
  cursor: pointer;
  display: inline-block;
  line-height: 60px;
  width: 150px;
  text-align: center;
  white-space: nowrap;
}

.edit-approval .fd-nav-item .order-num {
  display: inline-block;
  width: 20px;
  height: 20px;
  line-height: 20px;
  border: 1px solid #fff;
  border-radius: 50%;
  margin-right: 6px;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.edit-approval .fd-nav-item.active .order-num {
  color: #1583f2;
  background: #fff;
}

.edit-approval .ant-input {
  font-family: Chinese Quote, -apple-system, BlinkMacSystemFont, Segoe UI,
    PingFang SC, Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica,
    Arial, sans-serif;
  font-variant: tabular-nums;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  display: inline-block;
  padding: 4px 11px;
  width: 100%;
  height: 32px;
  font-size: 14px;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.65);
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  transition: all 0.3s;
}

.edit-approval .ant-input::-moz-placeholder {
  color: #bfbfbf;
  opacity: 1;
}

.edit-approval .ant-input:-ms-input-placeholder {
  color: #bfbfbf;
}

.edit-approval .ant-input::-webkit-input-placeholder {
  color: #bfbfbf;
}

.edit-approval .ant-input:focus,
.edit-approval .ant-input:hover {
  border-color: #40a9ff;
  border-right-width: 1px !important;
}

.edit-approval .ant-input:focus {
  outline: 0;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}

.edit-approval textarea.ant-input {
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
  min-height: 32px;
}

.edit-approval a,
.edit-approval abbr,
.edit-approval acronym,
.edit-approval address,
.edit-approval applet,
.edit-approval article,
.edit-approval aside,
.edit-approval audio,
.edit-approval b,
.edit-approval big,
.edit-approval blockquote,
.edit-approval body,
.edit-approval canvas,
.edit-approval caption,
.edit-approval center,
.edit-approval cite,
.edit-approval code,
.edit-approval dd,
.edit-approval del,
.edit-approval details,
.edit-approval dfn,
.edit-approval div,
.edit-approval dl,
.edit-approval dt,
.edit-approval em,
.edit-approval fieldset,
.edit-approval figcaption,
.edit-approval figure,
.edit-approval footer,
.edit-approval form,
.edit-approval h1,
.edit-approval h2,
.edit-approval h3,
.edit-approval h4,
.edit-approval h5,
.edit-approval h6,
.edit-approval header,
.edit-approval hgroup,
.edit-approval html,
.edit-approval i,
.edit-approval iframe,
.edit-approval img,
.edit-approval ins,
.edit-approval kbd,
.edit-approval label,
.edit-approval legend,
.edit-approval li,
.edit-approval mark,
.edit-approval menu,
.edit-approval nav,
.edit-approval object,
.edit-approval ol,
.edit-approval p,
.edit-approval pre,
.edit-approval q,
.edit-approval s,
.edit-approval samp,
.edit-approval section,
.edit-approval small,
.edit-approval span,
.edit-approval strike,
.edit-approval strong,
.edit-approval sub,
.edit-approval summary,
.edit-approval sup,
.edit-approval table,
.edit-approval tbody,
.edit-approval td,
.edit-approval tfoot,
.edit-approval th,
.edit-approval thead,
.edit-approval time,
.edit-approval tr,
.edit-approval tt,
.edit-approval u,
.edit-approval ul,
.edit-approval var,
.edit-approval video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

*,
:after,
:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body,
html {
  font-size: 14px;
}

body {
  font-family: Microsoft Yahei, Lucida Grande, Lucida Sans Unicode, Helvetica,
    Arial, Verdana, sans-serif;
  line-height: 1.6;
  background-color: #fff;
  position: static !important;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.edit-approval ol,
.edit-approval ul {
  list-style-type: none;
}

.edit-approval b,
.edit-approval strong {
  font-weight: 700;
}

.edit-approval img {
  border: 0;
}

.edit-approval button,
.edit-approval input,
.edit-approval select,
.edit-approval textarea {
  font-family: inherit;
  font-size: 100%;
  margin: 0;
}

.edit-approval textarea {
  overflow: auto;
  vertical-align: top;
  -webkit-appearance: none;
}

.edit-approval button,
.edit-approval input {
  line-height: normal;
}

.edit-approval button,
.edit-approval select {
  text-transform: none;
}

.edit-approval button,
.edit-approval html input[type="button"],
.edit-approval input[type="reset"],
.edit-approval input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}

.edit-approval input[type="search"] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

.edit-approval input[type="search"]::-webkit-search-cancel-button,
.edit-approval input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

.edit-approval button::-moz-focus-inner,
.edit-approval input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.edit-approval table {
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
}

.edit-approval table,
.edit-approval td,
.edit-approval th {
  border: 0;
}

.edit-approval td,
.edit-approval th {
  padding: 0;
  vertical-align: top;
}

.edit-approval th {
  font-weight: 700;
  text-align: left;
}

.edit-approval thead th {
  white-space: nowrap;
}

.edit-approval a {
  text-decoration: none;
  cursor: pointer;
  color: #3296fa;
}

.edit-approval a:active,
.edit-approval a:hover {
  outline: 0;
  color: #3296fa;
}

.edit-approval small {
  font-size: 80%;
}

body,
html {
  font-size: 12px !important;
  color: #191f25 !important;
  background: #f6f6f6 !important;
}

.wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
}

@font-face {
  font-family: IconFont;
  src: url("//at.alicdn.com/t/font_135284_ph2thxxbzgf.eot");
  src: url("//at.alicdn.com/t/font_135284_ph2thxxbzgf.eot?#iefix")
      format("embedded-opentype"),
    url("//at.alicdn.com/t/font_135284_ph2thxxbzgf.woff") format("woff"),
    url("//at.alicdn.com/t/font_135284_ph2thxxbzgf.ttf") format("truetype"),
    url("//at.alicdn.com/t/font_135284_ph2thxxbzgf.svg#IconFont") format("svg");
}

.iconfont {
  font-family: IconFont !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
}

.fd-nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 997;
  width: 100%;
  height: 60px;
  font-size: 14px;
  color: #fff;
  background: #3296fa;
  display: flex;
  align-items: center;
}

.fd-nav > * {
  flex: 1;
  width: 100%;
}

.fd-nav .fd-nav-left {
  display: -webkit-box;
  display: flex;
  align-items: center;
}

.fd-nav .fd-nav-center {
  flex: none;
  width: 600px;
  text-align: center;
}

.fd-nav .fd-nav-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-align: right;
}

.fd-nav .fd-nav-back {
  display: inline-block;
  width: 60px;
  height: 60px;
  font-size: 22px;
  border-right: 1px solid #1583f2;
  text-align: center;
  cursor: pointer;
}

.fd-nav .fd-nav-back:hover {
  background: #5af;
}

.fd-nav .fd-nav-back:active {
  background: #1583f2;
}

.fd-nav .fd-nav-back .anticon {
  line-height: 60px;
}

.fd-nav .fd-nav-title {
  width: 0;
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 0 15px;
}

.fd-nav a {
  color: #fff;
  margin-left: 12px;
}

.fd-nav .button-publish {
  min-width: 80px;
  margin-left: 4px;
  margin-right: 15px;
  color: #3296fa;
  border-color: #fff;
}

.fd-nav .button-publish.ant-btn:focus,
.fd-nav .button-publish.ant-btn:hover {
  color: #3296fa;
  border-color: #fff;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.3);
}

.fd-nav .button-publish.ant-btn:active {
  color: #3296fa;
  background: #d6eaff;
  box-shadow: none;
}

.fd-nav .button-preview {
  min-width: 80px;
  margin-left: 16px;
  margin-right: 4px;
  color: #fff;
  border-color: #fff;
  background: transparent;
}

.fd-nav .button-preview.ant-btn:focus,
.fd-nav .button-preview.ant-btn:hover {
  color: #fff;
  border-color: #fff;
  background: #59acfc;
}

.fd-nav .button-preview.ant-btn:active {
  color: #fff;
  border-color: #fff;
  background: #2186ef;
}

.fd-nav-content {
  position: fixed;
  top: 60px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  overflow-x: hidden;
  overflow-y: auto;
  padding-bottom: 30px;
}

.error-modal-desc {
  font-size: 13px;
  color: rgba(25, 31, 37, 0.56);
  line-height: 22px;
  margin-bottom: 14px;
}

.error-modal-list {
  height: 200px;
  overflow-y: auto;
  margin-right: -25px;
  padding-right: 25px;
}

.error-modal-item {
  padding: 10px 20px;
  line-height: 21px;
  background: #f6f6f6;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  border-radius: 4px;
}

.error-modal-item-label {
  flex: none;
  font-size: 15px;
  color: rgba(25, 31, 37, 0.56);
  padding-right: 10px;
}

.error-modal-item-content {
  text-align: right;
  flex: 1;
  font-size: 13px;
  color: #191f25;
}

#body.blur {
  -webkit-filter: blur(3px);
  filter: blur(3px);
}

.zoom {
  display: flex;
  position: fixed;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  height: 40px;
  width: 125px;
  right: 40px;
  margin-top: 30px;
  z-index: 10;
}

.zoom .zoom-in,
.zoom .zoom-out {
  width: 30px;
  height: 30px;
  background: #fff;
  color: #c1c1cd;
  cursor: pointer;
  background-size: 100%;
  background-repeat: no-repeat;
}

.zoom .zoom-out {
  background-image: url(https://gw.alicdn.com/tfs/TB1s0qhBHGYBuNjy0FoXXciBFXa-90-90.png);
}

.zoom .zoom-out.disabled {
  opacity: 0.5;
}

.zoom .zoom-in {
  background-image: url(https://gw.alicdn.com/tfs/TB1UIgJBTtYBeNjy1XdXXXXyVXa-90-90.png);
}

.zoom .zoom-in.disabled {
  opacity: 0.5;
}

.auto-judge:hover .editable-title,
.node-wrap-box:hover .editable-title {
  border-bottom: 1px dashed #fff;
}

.auto-judge:hover .editable-title.editing,
.node-wrap-box:hover .editable-title.editing {
  text-decoration: none;
  border: 1px solid #d9d9d9;
}

.auto-judge:hover .editable-title {
  border-color: #15bc83;
}

.editable-title {
  line-height: 15px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  border-bottom: 1px dashed transparent;
}

.editable-title:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 40px;
}

.editable-title:hover {
  border-bottom: 1px dashed #fff;
}

.editable-title-input {
  flex: none;
  height: 18px;
  padding-left: 4px;
  text-indent: 0;
  font-size: 12px;
  line-height: 18px;
  z-index: 1;
}

.editable-title-input:hover {
  text-decoration: none;
}

.ant-btn {
  position: relative;
}

.node-wrap-box {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  width: 220px;
  min-height: 72px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  background: #fff;
  border-radius: 4px;
  cursor: pointer;
}

.node-wrap-box:after {
  pointer-events: none;
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  border-radius: 4px;
  border: 1px solid transparent;
  transition: all 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
}

.node-wrap-box.active:after,
.node-wrap-box:active:after,
.node-wrap-box:hover:after {
  border: 1px solid #3296fa;
  box-shadow: 0 0 6px 0 rgba(50, 150, 250, 0.3);
}

.node-wrap-box.active .close,
.node-wrap-box:active .close,
.node-wrap-box:hover .close {
  display: block;
}

.node-wrap-box.error:after {
  border: 1px solid #f25643;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
}

.node-wrap-box .title {
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 16px;
  padding-right: 30px;
  width: 100%;
  height: 24px;
  line-height: 24px;
  font-size: 12px;
  color: #fff;
  text-align: left;
  background: #576a95;
  border-radius: 4px 4px 0 0;
}

.node-wrap-box .title .iconfont {
  font-size: 12px;
  margin-right: 5px;
}

.node-wrap-box .placeholder {
  color: #bfbfbf;
}

.node-wrap-box .close {
  display: none;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  font-size: 14px;
  color: #fff;
  border-radius: 50%;
  text-align: center;
  line-height: 20px;
}

.node-wrap-box .content {
  position: relative;
  font-size: 14px;
  padding: 16px;
  padding-right: 30px;
}

.node-wrap-box .content .text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.node-wrap-box .content .arrow {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 14px;
  font-size: 14px;
  color: #979797;
}

.start-node.node-wrap-box .content .text {
  display: block;
  white-space: nowrap;
}

.node-wrap-box:before {
  content: "";
  position: absolute;
  top: -12px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 0;
  height: 4px;
  border-style: solid;
  border-width: 8px 6px 4px;
  border-color: #cacaca transparent transparent;
  background: #f5f5f7;
}

.node-wrap-box.start-node:before {
  content: none;
}

.top-left-cover-line {
  left: -1px;
}

.top-left-cover-line,
.top-right-cover-line {
  position: absolute;
  height: 8px;
  width: 50%;
  background-color: #f5f5f7;
  top: -4px;
}

.top-right-cover-line {
  right: -1px;
}

.bottom-left-cover-line {
  left: -1px;
}

.bottom-left-cover-line,
.bottom-right-cover-line {
  position: absolute;
  height: 8px;
  width: 50%;
  background-color: #f5f5f7;
  bottom: -4px;
}

.bottom-right-cover-line {
  right: -1px;
}

.dingflow-design {
  width: 100%;
  background-color: #f5f5f7;
  overflow: auto;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
}

.dingflow-design .box-scale {
  transform: scale(1);
  display: inline-block;
  position: relative;
  width: 100%;
  padding: 54.5px 0;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  min-width: -webkit-min-content;
  min-width: -moz-min-content;
  min-width: min-content;
  background-color: #f5f5f7;
  transform-origin: 50% 0px 0px;
}

.dingflow-design .node-wrap {
  flex-direction: column;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  padding: 0 50px;
  position: relative;
}

.dingflow-design .branch-wrap,
.dingflow-design .node-wrap {
  display: inline-flex;
  width: 100%;
}

.dingflow-design .branch-box-wrap {
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: 270px;
  width: 100%;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.dingflow-design .branch-box {
  display: flex;
  overflow: visible;
  min-height: 180px;
  height: auto;
  border-bottom: 2px solid #ccc;
  border-top: 2px solid #ccc;
  position: relative;
  margin-top: 15px;
}

.dingflow-design .branch-box .col-box {
  background: #f5f5f7;
}

.dingflow-design .branch-box .col-box:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  margin: auto;
  width: 2px;
  height: 100%;
  background-color: #cacaca;
}

.dingflow-design .add-branch {
  border: none;
  outline: none;
  user-select: none;
  justify-content: center;
  font-size: 12px;
  padding: 0 10px;
  height: 30px;
  line-height: 30px;
  border-radius: 15px;
  color: #3296fa;
  background: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  position: absolute;
  top: -16px;
  left: 50%;
  transform: translateX(-50%);
  transform-origin: center center;
  cursor: pointer;
  z-index: 1;
  display: inline-flex;
  align-items: center;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.dingflow-design .add-branch:hover {
  transform: translateX(-50%) scale(1.1);
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
}

.dingflow-design .add-branch:active {
  transform: translateX(-50%);
  box-shadow: none;
}

.dingflow-design .col-box {
  display: inline-flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
}

.dingflow-design .condition-node {
  min-height: 220px;
}

.dingflow-design .condition-node,
.dingflow-design .condition-node-box {
  display: inline-flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-flex: 1;
}

.dingflow-design .condition-node-box {
  padding-top: 30px;
  padding-right: 50px;
  padding-left: 50px;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  flex-grow: 1;
  position: relative;
}

.dingflow-design .condition-node-box:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 2px;
  height: 100%;
  background-color: #cacaca;
}

.dingflow-design .auto-judge {
  position: relative;
  width: 220px;
  min-height: 72px;
  background: #fff;
  border-radius: 4px;
  padding: 14px 19px;
  cursor: pointer;
}

.dingflow-design .auto-judge:after {
  pointer-events: none;
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  border-radius: 4px;
  border: 1px solid transparent;
  transition: all 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
}

.dingflow-design .auto-judge.active:after,
.dingflow-design .auto-judge:active:after,
.dingflow-design .auto-judge:hover:after {
  border: 1px solid #3296fa;
  box-shadow: 0 0 6px 0 rgba(50, 150, 250, 0.3);
}

.dingflow-design .auto-judge.active .close,
.dingflow-design .auto-judge:active .close,
.dingflow-design .auto-judge:hover .close {
  display: block;
}

.dingflow-design .auto-judge.error:after {
  border: 1px solid #f25643;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
}

.dingflow-design .auto-judge .title-wrapper {
  position: relative;
  font-size: 12px;
  color: #15bc83;
  text-align: left;
  line-height: 16px;
}

.dingflow-design .auto-judge .title-wrapper .editable-title {
  display: inline-block;
  max-width: 120px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.dingflow-design .auto-judge .title-wrapper .priority-title {
  display: inline-block;
  float: right;
  margin-right: 10px;
  color: rgba(25, 31, 37, 0.56);
}

.dingflow-design .auto-judge .placeholder {
  color: #bfbfbf;
}

.dingflow-design .auto-judge .close {
  display: none;
  position: absolute;
  right: -10px;
  top: -10px;
  width: 20px;
  height: 20px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  text-align: center;
  line-height: 20px;
  z-index: 2;
}

.dingflow-design .auto-judge .content {
  font-size: 14px;
  color: #191f25;
  text-align: left;
  margin-top: 6px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.dingflow-design .auto-judge .sort-left,
.dingflow-design .auto-judge .sort-right {
  position: absolute;
  top: 0;
  bottom: 0;
  display: none;
  z-index: 1;
}

.dingflow-design .auto-judge .sort-left {
  left: 0;
  border-right: 1px solid #f6f6f6;
}

.dingflow-design .auto-judge .sort-right {
  right: 0;
  border-left: 1px solid #f6f6f6;
}

.dingflow-design .auto-judge:hover .sort-left,
.dingflow-design .auto-judge:hover .sort-right {
  display: flex;
  align-items: center;
}

.dingflow-design .auto-judge .sort-left:hover,
.dingflow-design .auto-judge .sort-right:hover {
  background: #efefef;
}

.dingflow-design .end-node {
  border-radius: 50%;
  font-size: 14px;
  color: rgba(25, 31, 37, 0.4);
  text-align: left;
}

.dingflow-design .end-node .end-node-circle {
  width: 10px;
  height: 10px;
  margin: auto;
  border-radius: 50%;
  background: #dbdcdc;
}

.dingflow-design .end-node .end-node-text {
  margin-top: 5px;
  text-align: center;
}

.approval-setting {
  border-radius: 2px;
  margin: 20px 0;
  position: relative;
  background: #fff;
}

.ant-btn {
  position: relative;
}

.error-modal-list {
  width: 455px;
}
</style>

<template>
  <div class="app-container">
    <div class="head-container">
      <a-form layout="inline">
        <a-row :gutter="20">
          <a-col :md="4" :sm="24">
            <a-form-item label="审批名称">
              <a-input
                v-model="crud.query.templateName"
                :allowClear="true"
                placeholder="请输入审批名称"
              />
            </a-form-item>
          </a-col>

          <a-col :md="4" :sm="24">
            <a-form-item label="审批次数">
              <a-input
                v-model="crud.query.approvalNum"
                :allowClear="true"
                placeholder="请输入审批次数"
                type="number"
                @input="
                  () => {
                    if (crud.query.approvalNum < 0) {
                      crud.query.approvalNum = 0
                    }
                  }
                "
              />
            </a-form-item>
          </a-col>

          <a-col :md="4" :sm="24">
            <a-form-item label="抄送次数">
              <a-input
                v-model="crud.query.ccNum"
                :allowClear="true"
                placeholder="请输入抄送次数"
                type="number"
                @input="
                  () => {
                    if (crud.query.ccNum < 0) {
                      crud.query.ccNum = 0
                    }
                  }
                "
              />
            </a-form-item>
          </a-col>

          <a-col :md="4" :sm="24">
            <a-form-item label="创建者">
              <a-select
                v-model="crud.query.creatorID"
                :allowClear="true"
                :filterOption="filterCategory"
                placeholder="请选择客户分类"
                show-search
              >
                <a-select-option
                  v-for="item in [...allUserListMap]"
                  :key="Number(item.userID)"
                  :value="Number(item.userID)"
                  >{{ item.displayName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24">
            <a-form-item label="创建时间">
              <a-range-picker
                v-model="crud.query.times"
                :allowClear="true"
                format="YYYY-MM-DD"
              />
            </a-form-item>
          </a-col>

          <a-col :md="4" :sm="24">
            <SearchOptions :toggleShowAdvance="false" style="width: 100%" />
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div class="table-container">
      <Operations tableName="审批模板" @toDiyAdd="toDiyAdd" />
      <a-spin :spinning="crud.loading">
        <a-table
          :bordered="true"
          :columns="crud.columns"
          :data-source="crud.data"
          :pagination="false"
          :row-class-name="
            (_record, index) => (index % 2 === 1 ? 'table-striped' : null)
          "
          :row-selection="{
            selectedRowKeys: crud.selections,
            onChange: crud.selectionChangeHandler
          }"
          class="ant-table-striped"
          empty-text="-"
          rowKey="approvalTemplateID"
          @resizeColumn="
            (w, col) => {
              col.width = w
            }
          "
        >
          <template slot="insertTime" slot-scope="record">
            {{ filterTime(record.insertTime) }}
          </template>

          <template slot="updateTime" slot-scope="record">
            {{ filterTime(record.updateTime) }}
          </template>

          <template slot="operation" slot-scope="record">
            <a-button
              :disabled="!$route.meta.code.includes('查看审批模板详情')"
              type="link"
              @click="toInfo(record)"
            >
              详情
            </a-button>
          </template>
        </a-table>
      </a-spin>
      <Pagination />
    </div>
    <!-- 填写表单部分 -->
    <div class="draw-container"></div>
  </div>
</template>

<script>
import SearchOptions from "@/components/Crud/SearchOptions"
import Operations from "@/components/Crud/Operations"
import Pagination from "@/components/Crud/Pagination"
import CRUD, { form, header, presenter } from "@crud/crud"
import Api from "@/api/approval/index"
import { fullTimeFilter } from "@/utils/utils"
import store from "@/store"
import { GetAllUserList } from "@/api/public-api"
import { ApprovalListColumns } from "@/views/approval/list"
import {
  checkMultiTypeOperateDisable,
  checkOperateDisable
} from "@/router/permission"

// 表格的填充字段
const defaultForm = {}
export default {
  name: "Approval",
  // presenter 生成crud对象
  mixins: [presenter(), header(), form(defaultForm)],
  cruds() {
    return CRUD({
      title: "审批",
      roleIDs: [],
      columns: ApprovalListColumns,
      crudMethod: { ...Api },
      idField: "approvalTemplateID" // 设置每一条记录的唯一标失
    })
  },
  data() {
    return {
      allUserListMap: [],
      rules: {
        value: [{ required: true, message: "请输入关键字", trigger: "blur" }]
      }
    }
  },
  components: { SearchOptions, Operations, Pagination },
  async created() {
    this.crud.optShow.edit = false
    this.addOk = false
    const [getAllUserListRes] = await Promise.all([GetAllUserList()])
    if (getAllUserListRes.code === 0) {
      this.allUserListMap = [...getAllUserListRes.result]
    }
    this.roleIDs = store.getters.roleIDs
  },
  methods: {
    checkOperateDisable,
    checkMultiTypeOperateDisable,
    filterTime: timeStr => fullTimeFilter(timeStr),
    startTimeChange(dates, dateStrings) {
      if (dateStrings[0] && dateStrings[1]) {
        let time1 = dateStrings[0]
        let time2 = dateStrings[1]
        if (
          new Date(time2.replace(/-/g, "/")).getTime() / 1000 -
            (new Date(time1.replace(/-/g, "/")).getTime() / 1000 +
              24 * 60 * 60) >
          365 * 24 * 60 * 60
        ) {
          this.$message.info("请选择一年内的时间！")
          this.crud.query.startTimes = [time1, time1]
          return
        }
      }
      this.crud.query.startTimes = dateStrings
    },
    toInfo(record) {
      window.open(
        process.env.VUE_APP_BASE_URL +
          `/#/approval/editApproval?operateType=0&approvalTemplateID=${record.approvalTemplateID}`,
        "_blank"
      )
    },
    toDiyAdd() {
      window.open(
        process.env.VUE_APP_BASE_URL +
          `/#/approval/editApproval?operateType=1&approvalTemplateID=0`,
        "_blank"
      )
    },
    filterCategory: function(value, option) {
      return option.componentOptions.children[0].text.indexOf(value) >= 0
    },
    async editSaveKeyword() {
      const res = await Api.edit({
        keywordID: this.form.keywordID,
        value: this.form.value
      })
      if (res.code === 14) {
        this.$message.success("更改成功")
        this.crud.cancelCU("uptoinfo")
      }
    }
  }
}
</script>

<style scoped>
.ant-table-striped /deep/ .table-striped td {
  background-color: #fcfcfc;
}
</style>

<template>
  <div class="app-container">
    <div class="head-container">
      <a-form layout="inline">
        <a-row :gutter="48">
          <a-col :md="4" :sm="24">
            <a-form-item label="合同编号">
              <a-input
                v-model="crud.query.contractNo"
                allow-clear
                placeholder="请输入合同编号"
              />
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24">
            <a-form-item label="项目编号">
              <a-input
                v-model="crud.query.projectNo"
                allow-clear
                placeholder="请输入项目编号"
              />
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24">
            <a-form-item label="所属企业">
              <a-select
                v-model="crud.query.customerID"
                :filterOption="filterCategory"
                allow-clear
                placeholder="请选择所属企业"
                show-search
              >
                <a-select-option
                  v-for="item in allCustomerMap"
                  :key="item.customerID"
                  :value="item.customerID"
                  >{{ item.customerName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24">
            <a-form-item label="货品名称">
              <a-input
                v-model="crud.query.goodsName"
                allow-clear
                placeholder="请输入货品名称"
              />
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24">
            <a-form-item label="货品规格">
              <a-input
                v-model="crud.query.goodsSpec"
                allow-clear
                placeholder="请输入货品规格"
              />
            </a-form-item>
          </a-col>
          <template v-if="crud.advanced">
            <a-col :md="4" :sm="24">
              <a-form-item label="货品类别">
                <a-select
                  v-model="crud.query.categoryID"
                  :filterOption="filterCategory"
                  allow-clear
                  placeholder="请选择货品类别"
                  show-search
                >
                  <a-select-option
                    v-for="item in [...allCategoryMap]"
                    :key="item.categoryID"
                    :value="item.categoryID"
                    >{{ item.categoryName }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :md="4" :sm="24">
              <a-form-item label="联系人">
                <a-select
                  v-model="crud.query.contactID"
                  :allowClear="true"
                  :filterOption="filterCategory"
                  placeholder="请选择客户分类"
                  show-search
                >
                  <a-select-option
                    v-for="item in [...allContactMap]"
                    :key="Number(item.contactID)"
                    :value="Number(item.contactID)"
                    >{{ item.contactName + "-" + item.contactTel }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :md="4" :sm="24">
              <a-form-item label="销售人员">
                <a-select
                  v-model="crud.query.userID"
                  :allowClear="true"
                  :filterOption="filterCategory"
                  placeholder="请选择客户分类"
                  show-search
                >
                  <a-select-option
                    v-for="item in [...allUserListMap]"
                    :key="Number(item.userID)"
                    :value="Number(item.userID)"
                    >{{ item.displayName }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :md="4" :sm="24">
              <a-form-item label="签约日期">
                <a-range-picker
                  v-model="crud.query.signTimes"
                  :allowClear="true"
                  format="YYYY-MM-DD"
                  @change="signTimeChange"
                />
              </a-form-item>
            </a-col>

            <a-col :md="4" :sm="24">
              <a-form-item label="交付日期">
                <a-range-picker
                  v-model="crud.query.releasedTimes"
                  :allowClear="true"
                  format="YYYY-MM-DD"
                  @change="releasedTimeChange"
                />
              </a-form-item>
            </a-col>

            <a-col :md="4" :sm="24">
              <a-form-item label="创建日期">
                <a-range-picker
                  v-model="crud.query.insertTimes"
                  :allowClear="true"
                  format="YYYY-MM-DD"
                  @change="insertTimeChange"
                />
              </a-form-item>
            </a-col>
          </template>
          <a-col :md="4" :sm="24">
            <SearchOptions
              :queryFuncName="queryFuncName"
              style="width: 100%"
              @toDiyQuery="toDiyQuery"
              @toDiyResetQuery="toDiyResetQuery"
            />
          </a-col>
        </a-row>
      </a-form>
    </div>
    <el-collapse
      :value="showCollapse"
      class="my-collapse"
      @change="changeCollapse($event)"
    >
      <el-collapse-item
        :disabled="checkOperateDisable('项目产品数据统计')"
        :name="1"
        title="项目产品数据统计"
      >
        <div class="item-content">
          <a-card
            :body-style="{ padding: '0px' }"
            :bordered="false"
            class="card1"
          >
            <template slot="title">
              <div class="title">
                货品销售数量排名
              </div>
            </template>
            <a-table
              :bordered="true"
              :columns="projectGoodsListColumns"
              :data-source="projectGoodsNumList"
              :pagination="false"
              :row-class-name="
                (_record, index) => (index % 2 === 1 ? 'table-striped' : null)
              "
              :scroll="{ x: 900, y: 300 }"
              class="ant-table-striped"
              empty-text="-"
              rowKey="goodsID"
              @resizeColumn="
                (w, col) => {
                  col.width = w
                }
              "
            >
              <template slot="index" slot-scope="record">
                <img
                  v-if="record.index == 1"
                  src="@/assets/image/no1.png"
                  style="display: inline-block;width: 20px;height: 20px"
                />
                <img
                  v-else-if="record.index == 2"
                  src="@/assets/image/no2.png"
                  style="display: inline-block;width: 20px;height: 20px"
                />
                <img
                  v-else-if="record.index == 3"
                  src="@/assets/image/no3.png"
                  style="display: inline-block;width: 20px;height: 20px"
                />
                <img
                  v-else-if="record.index == 4"
                  src="@/assets/image/no4.png"
                  style="display: inline-block;width: 20px;height: 20px"
                />
                <img
                  v-else-if="record.index == 5"
                  src="@/assets/image/no5.png"
                  style="display: inline-block;width: 20px;height: 20px"
                />
                <span v-else>
                  {{ record.index }}
                </span>
              </template>
              <template slot="goodsNum" slot-scope="record"
                ><span style="font-weight: bold;color: red;">{{
                  record.goodsNum
                }}</span></template
              >
              <template slot="goodsTotalPrice" slot-scope="record"
                ><span>{{ record.goodsTotalPrice }}</span></template
              >
            </a-table>
          </a-card>
          <a-card
            :body-style="{ padding: '0px' }"
            :bordered="false"
            class="card2"
          >
            <template slot="title">
              <div class="title">
                货品销售金额排名
              </div>
            </template>
            <a-table
              :bordered="true"
              :columns="projectGoodsListColumns"
              :data-source="projectGoodsPriceList"
              :pagination="false"
              :row-class-name="
                (_record, index) => (index % 2 === 1 ? 'table-striped' : null)
              "
              :scroll="{ x: 900, y: 300 }"
              class="ant-table-striped"
              empty-text="-"
              rowKey="goodsID"
              @resizeColumn="
                (w, col) => {
                  col.width = w
                }
              "
            >
              <template slot="index" slot-scope="record">
                <img
                  v-if="record.index == 1"
                  src="@/assets/image/no1.png"
                  style="display: inline-block;width: 20px;height: 20px"
                />
                <img
                  v-else-if="record.index == 2"
                  src="@/assets/image/no2.png"
                  style="display: inline-block;width: 20px;height: 20px"
                />
                <img
                  v-else-if="record.index == 3"
                  src="@/assets/image/no3.png"
                  style="display: inline-block;width: 20px;height: 20px"
                />
                <img
                  v-else-if="record.index == 4"
                  src="@/assets/image/no4.png"
                  style="display: inline-block;width: 20px;height: 20px"
                />
                <img
                  v-else-if="record.index == 5"
                  src="@/assets/image/no5.png"
                  style="display: inline-block;width: 20px;height: 20px"
                />
                <span v-else>
                  {{ record.index }}
                </span>
              </template>
              <template slot="goodsNum" slot-scope="record"
                ><span>{{ record.goodsNum }}</span></template
              >
              <template slot="goodsTotalPrice" slot-scope="record">
                <span style="font-weight: bold;color: red;">{{
                  record.goodsTotalPrice
                }}</span></template
              >
            </a-table>
          </a-card>
        </div>
      </el-collapse-item>
    </el-collapse>
    <div class="table-container">
      <Operations tableName="项目产品" />
      <a-spin :spinning="crud.loading">
        <a-table
          :columns="crud.columns"
          :data-source="crud.data"
          :pagination="false"
          :scroll="{ x: 2000, y: 1000 }"
          rowKey="targetID"
        >
          <template slot="projectNo" slot-scope="record">
            <a @click="toShowProject(record)">{{ record.projectNo }}</a>
          </template>
          <template slot="customerName" slot-scope="record">
            <a @click="toShowCustomer(record)">{{ record.customerName }}</a>
          </template>

          <template slot="releasedTime" slot-scope="record">
            {{ fullTimeFilterYYYYMMDD(record.releasedTime) }}
          </template>

          <template slot="signTime" slot-scope="record">
            {{ fullTimeFilterYYYYMMDD(record.signTime) }}
          </template>

          <template slot="insertTime" slot-scope="record">
            {{ filterTime(record.insertTime) }}
          </template>
        </a-table>
      </a-spin>
      <Pagination />
    </div>
    <ProjectInfo ref="projectInfo"></ProjectInfo>
    <CustomerInfo ref="customerInfo" :addContract="false"></CustomerInfo>
  </div>
</template>

<script>
import SearchOptions from "@/components/Crud/SearchOptions"
import Operations from "@/components/Crud/Operations"
import Pagination from "@/components/Crud/Pagination"
import CRUD, { form, header, presenter } from "@crud/crud"
import Api from "@/api/customer/project"
import { PROJECT_SHOW_TYPE, TargetBelongTypeMap } from "@/config"
import { fullTimeFilter, fullTimeFilterYYYYMMDD } from "@/utils/utils"
import { GetAllContact, GetAllCustomer, GetAllUserList } from "@/api/public-api"
import store from "@/store"
import {
  projectGoodsListColumns,
  projectListColumns
} from "@/views/customer/project-list"
import { GetAllCategory } from "@/api/goods"
import ProjectInfo from "@/components/customerInfo/project-info"
import CustomerInfo from "@/components/customerInfo/index"
import { GetProjectDetail, GetProjectGoodsAnalysisList } from "@/api/project"
import { checkOperateDisable } from "@/router/permission"

export default {
  name: "Project",
  // presenter 生成crud对象
  mixins: [presenter(), header(), form()],
  cruds() {
    return CRUD({
      title: "项目产品",
      roleIDs: [],
      columns: projectListColumns,
      crudMethod: { ...Api },
      idField: "projectID" // 设置每一条记录的唯一标失
    })
  },
  data() {
    return {
      queryFuncName: "查询项目产品列表",
      allCustomerMap: [],
      allUserListMap: [],
      allCategoryMap: [],
      allContactMap: [],
      targetBelongTypeMap: TargetBelongTypeMap,
      showCollapse: [],
      projectGoodsListColumns,
      projectGoodsNumList: [],
      projectGoodsPriceList: [],
      showProjectGoodsCollapse: false,

      roleIDs: [],

      projectListColumns
    }
  },
  components: {
    SearchOptions,
    Operations,
    Pagination,
    ProjectInfo,
    CustomerInfo
  },
  async created() {
    /**
     * 设置按钮权限
     */
    let { code } = this.$route.meta
    this.permissionController()
    // 关闭下载功能
    // this.crud.optShow.download = false
    this.crud.optShow.edit = false
    // 显示以下按钮
    this.crud.permission = {
      add: code.includes("新增项目"),
      del: code.includes("删除项目"),
      edit: code.includes("修改项目")
      // download: true
    }

    const promiseFuncs = [
      GetAllCustomer(),
      GetAllUserList(),
      GetAllCategory(),
      GetAllContact()
    ]

    if (!this.checkOperateDisable("项目产品数据统计")) {
      promiseFuncs.push(GetProjectGoodsAnalysisList())
    } else {
      this.showCollapse = []
    }

    const [
      getAllCustomerRes,
      allUserListRes,
      getAllCategoryRes,
      getAllContactRes,
      getProjectGoodsAnalysisListRes
    ] = await Promise.all(promiseFuncs)

    if (allUserListRes.code === 0) {
      this.allUserListMap = [
        ...allUserListRes.result,
        { userID: 0, displayName: "无" }
      ]
    }

    if (getAllContactRes.code === 0) {
      this.allContactMap = getAllContactRes.result
    }

    if (getAllCategoryRes.code === 0) {
      this.allCategoryMap = getAllCategoryRes.result
    }

    if (getAllCustomerRes.code === 0) {
      this.allCustomerMap = getAllCustomerRes.result
    }

    if (
      !this.checkOperateDisable("项目产品数据统计") &&
      getProjectGoodsAnalysisListRes.code === 0
    ) {
      this.projectGoodsNumList = (
        getProjectGoodsAnalysisListRes.result.goodsNumAnalysisList || []
      ).map((item, index) => ({ index: index + 1, ...item }))
      this.projectGoodsPriceList = getProjectGoodsAnalysisListRes.result.goodsPriceAnalysisList.map(
        (item, index) => ({ index: index + 1, ...item })
      )
    }

    this.roleIDs = store.getters.roleIDs
  },
  methods: {
    filterTime: timeStr => fullTimeFilter(timeStr),
    fullTimeFilterYYYYMMDD,
    checkOperateDisable,
    permissionController() {
      !this.checkOperateDisable("项目产品数据统计")
        ? (this.showCollapse = [1])
        : (this.showCollapse = [])
    },
    changeCollapse(value) {
      this.showCollapse = [...value]
    },
    async toDiyQuery() {
      const that = this
      that.crud.toQuery()
      if (!this.checkOperateDisable("项目产品数据统计")) {
        const queryRes = await GetProjectGoodsAnalysisList({
          ...that.crud.query
        })
        if (queryRes.code === 0) {
          that.projectGoodsNumList = (
            queryRes.result.goodsNumAnalysisList || []
          ).map((item, index) => ({ index: index + 1, ...item }))
          that.projectGoodsPriceList = (
            queryRes.result.goodsPriceAnalysisList || []
          ).map((item, index) => ({ index: index + 1, ...item }))
        }
      }
    },
    async toDiyResetQuery() {
      const that = this
      that.crud.resetQuery()
      if (!this.checkOperateDisable("项目产品数据统计")) {
        const queryRes = await GetProjectGoodsAnalysisList()
        if (queryRes.code === 0) {
          that.projectGoodsNumList = (
            queryRes.result.goodsNumAnalysisList || []
          ).map((item, index) => ({ index: index + 1, ...item }))
          that.projectGoodsPriceList = (
            queryRes.result.goodsPriceAnalysisList || []
          ).map((item, index) => ({ index: index + 1, ...item }))
        }
      }
    },
    async toShowCustomer(record) {
      console.log(record)
      this.$refs.customerInfo.toCustomerInfo(record.customerID)
    },
    async toShowProject(record) {
      const that = this
      that.$refs.projectInfo.toShowFlag = PROJECT_SHOW_TYPE.SHOW_CONTRACT

      const getProjectDetail = await GetProjectDetail({
        projectID: record.projectID,
        contractID: record.contractID
      })

      if (
        getProjectDetail.code === 0 &&
        getProjectDetail.result &&
        getProjectDetail.result.project
      ) {
        that.$refs.projectInfo.currentProject = {
          ...getProjectDetail.result.project
        }
        that.$refs.projectInfo.otherProjects = [
          ...getProjectDetail.result.otherProjects
        ]
      }
    },
    signTimeChange(dates, dateStrings) {
      this.crud.query.signTimes = dateStrings
    },
    releasedTimeChange(dates, dateStrings) {
      this.crud.query.releasedTimes = dateStrings
    },
    insertTimeChange(dates, dateStrings) {
      this.crud.query.insertTimes = dateStrings
    },
    filterCategory: function(value, option) {
      return option.componentOptions.children[0].text.indexOf(value) >= 0
    }
  }
}
</script>

<style lang="scss" scoped>
.my-collapse {
  margin-bottom: 20px;

  .el-collapse-item {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 5px;

    /deep/ .el-collapse-item__content {
      padding-bottom: 0;
    }

    /deep/ .el-collapse-item__header {
      padding-left: 20px;
    }

    .item-content {
      background-color: #f0f2f5;
      padding: 10px;

      .card1 {
        width: 49%;
        display: inline-block;
        border: none;
        padding: 15px;
        border-radius: 10px;
        box-shadow: 4px 4px 8px 0px rgba(201, 201, 201, 0.5);
      }

      .card2 {
        width: 49%;
        padding: 15px;
        display: inline-block;
        border: none;
        margin-left: 1%;
        border-radius: 10px;
        box-shadow: 4px 4px 8px 0px rgba(201, 201, 201, 0.5);
      }
    }
  }
}
</style>

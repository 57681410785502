var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-menu',{attrs:{"theme":_vm.theme,"mode":"inline","default-selected-keys":_vm.defaultSelectedKeys},on:{"click":_vm.menuclick}},[_vm._l((_vm.permission_routers),function(item){return [(
        (!item.hasOwnProperty('children') || item.children.length === 1) &&
          !item.hidden &&
          item.children[0].path === ''
      )?_c('a-menu-item',{key:item.path},[_c('a-icon',{attrs:{"type":item.icon}}),_c('span',[_vm._v(_vm._s(item.name))])],1):_vm._e(),(
        (item.children && item.children.length > 1 && !item.hidden) ||
          (item.children &&
            item.children.length === 1 &&
            item.children[0].path !== '' &&
            !item.hidden)
      )?_c('sub-menu',{key:item.path,attrs:{"menu-info":item}}):_vm._e()]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }
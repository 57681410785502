/*
 * @Description: 服务车
 * @Author: yuyongjie
 * @Date: 2020-12-26 17:51:20
 * @LastEditors: yuyongjie
 * @LastEditTime: 2020-12-29 22:21:53
 */
import { method } from "@/utils/request"

const api = {
  Get: "customer/list",
  Add: "customer",
  Del: "customer",
  Edit: "customer",
  GetChart: "customer/chart",
  DeleteContact: "customer/contact",
  GetCustomerContact: "customer/contact",
  SaveContact: "customer/contact",
  RefreshContact: "customer/contact",
  AssignCustomer: "customer/assign",
  ReceiveCustomer: "customer/receive",
  CustomerIntoOpenSea: "customer/into-open-sea",

  GetCustomerGrowthTrendAnalysis: "analysis/customer/growth",
  GetCustomerAdoptionTrendAnalysis: "analysis/customer/adoption",

  GetCustomerDetailByCustomerID: "customer/detail/customer-id",

  GetCustomerAllName: "customer/all-name",
  GetCustomerAll: "customer/all"
}

const url = process.env.VUE_APP_API_URL

/**
 * @param parameter
 * @returns {*}
 */
export function add(parameter) {
  return method(url)({
    url: api.Add,
    method: "post",
    data: {
      ...parameter
    }
  })
}

/**
 * @param {*} parameter
 */
export function del(ids) {
  return method(url)({
    url: api.Del,
    method: "delete",
    data: { customerIDs: ids }
  })
}

/**
 * @param {*} parameter
 */
export function edit(parameter) {
  return method(url)({
    url: api.Edit,
    method: "put",
    data: { ...parameter }
  })
}

/**
 * @param {*} parameter
 */
export function get(parameter) {
  return method(url)({
    url: api.Get,
    method: "post",
    data: parameter
  })
}

export function GetChart() {
  return method(url)({
    url: api.GetChart,
    method: "get"
  })
}

export function GetCustomerContact(data) {
  return method(url)({
    url: api.GetCustomerContact,
    method: "get",
    data
  })
}

export function deleteContact(data) {
  return method(url)({
    url: api.DeleteContact,
    method: "delete",
    data: {
      contactID: Number(data.contactID),
      customerID: Number(data.customerID)
    }
  })
}

export function saveContact(data) {
  return method(url)({
    url: api.SaveContact,
    method: "post",
    data
  })
}

export function refreshContact(data) {
  return method(url)({
    url: api.RefreshContact,
    method: "get",
    data: {
      customerID: data.customerID
    }
  })
}

export function AssignCustomer(data) {
  return method(url)({
    url: api.AssignCustomer,
    method: "post",
    data
  })
}

export function ReceiveCustomer(data) {
  return method(url)({
    url: api.ReceiveCustomer,
    method: "post",
    data
  })
}

export function CustomerIntoOpenSea(data) {
  return method(url)({
    url: api.CustomerIntoOpenSea,
    method: "post",
    data
  })
}

export function GetCustomerGrowthTrendAnalysis(data) {
  return method(url)({
    url: api.GetCustomerGrowthTrendAnalysis,
    method: "get",
    data
  })
}

export function GetCustomerAdoptionTrendAnalysis(data) {
  return method(url)({
    url: api.GetCustomerAdoptionTrendAnalysis,
    method: "get",
    data
  })
}

export function GetCustomerDetailByCustomerID(customerID) {
  return method(url)({
    url: api.GetCustomerDetailByCustomerID,
    method: "get",
    data: {
      customerID
    }
  })
}

export function GetCustomerAllName() {
  return method(url)({
    url: api.GetCustomerAllName,
    method: "get",
    data: {}
  })
}

export function GetCustomerAll() {
  return method(url)({
    url: api.GetCustomerAll,
    method: "get",
    data: {}
  })
}
export default {
  get,
  add,
  del,
  edit,
  GetCustomerContact,
  deleteContact,
  saveContact,
  refreshContact,
  GetChart,
  AssignCustomer,
  ReceiveCustomer,
  CustomerIntoOpenSea,
  GetCustomerGrowthTrendAnalysis,
  GetCustomerAdoptionTrendAnalysis,
  GetCustomerDetailByCustomerID
}

import { method } from "@/utils/request"

export const FileOperateAPI = {
  FollowUpFileUpload: "file-upload/follow-up",
  FollowUpFileRemoved: "file-upload/follow-up",
  FollowUpAllFileCancel: "file-upload/follow-up/cancel", //取消所有跟进上传的文件
  FollowUpFileUpdate: "file-upload/follow-up/update",
  FollowUpOriginFileDelete: "file-upload/follow-up/origin-file", //删除跟进上传的旧文件

  ContractFileUpload: "file-upload/contract",
  ContractFileRemoved: "file-upload/contract",
  ContractAllFileCancel: "file-upload/contract/cancel",

  InvoiceAllFileCancel: "file-upload/invoice/cancel",
  InvoiceFileRemoved: "file-upload/invoice",
  InvoiceFileUpload: "file-upload/invoice",
  InvoiceOriginFileDelete: "file-upload/invoice/origin-file",

  AttachmentAllFileCancel: "file-upload/attachment/cancel",
  AttachmentFileRemoved: "file-upload/attachment",
  AttachmentFileUpload: "file-upload/attachment",
  AttachmentOriginFileDelete: "file-upload/attachment/origin-file"
}

const url = process.env.VUE_APP_API_URL

export function FileUpload(path, parameter) {
  return method(
    url,
    true,
    "json",
    "form"
  )({
    url: path,
    method: "post",
    data: parameter
  })
}

export function FileRemoved(path, parameter) {
  return method(url)({
    url: path,
    method: "delete",
    data: parameter
  })
}

export function AllFileCancel(path, parameter) {
  return method(url)({
    url: path,
    method: "delete",
    data: parameter
  })
}

export function FileUpdate(path, parameter) {
  return method(url)({
    url: path,
    method: "put",
    data: parameter
  })
}

export function DeleteOriginFile(path, parameter) {
  return method(url)({
    url: path,
    method: "delete",
    data: parameter
  })
}

export default {
  FileOperateAPI,
  FileUpload,
  FileRemoved,
  AllFileCancel,
  FileUpdate,
  DeleteOriginFile
}

<template>
  <div>
    <a-form-model
      ref="ruleForm"
      :model="addInvoiceInfo"
      :rules="rules"
      :label-col="{ span: 6 }"
      :wrapper-col="{ span: 18 }"
    >
      <a-row>
        <a-col :span="8">
          <a-form-model-item label="公司名称" prop="customerID" ref="customerID">
            <a-select
              showSearch
              allowClear
              v-model="addInvoiceInfo.customerID"
              placeholder="请选择公司名称"
              :filter-option="filterOption"
              @change="toGetContract"
            >
              <a-select-option v-for="item in allCustomer" :key="item.customerID" :value="item.customerID">
                {{item.customerName}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="8">
          <a-form-model-item label="合同ID" prop="contractID" ref="contractID">
            <a-select
              showSearch
              allowClear
              v-model="addInvoiceInfo.contractID"
              placeholder="请选择合同ID"
              :filter-option="filterOption"
            >
              <a-select-option v-for="item in connectContract" :key="item.contractID" :value="item.contractID">
                {{item.contactId}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="8">
          <a-form-model-item label="产品ID" prop="projectID" ref="projectID">
            <a-select
              showSearch
              allowClear
              v-model="addInvoiceInfo.projectID"
              placeholder="请选择合同ID"
              :filter-option="filterOption"
            >
              <a-select-option v-for="item in currentAllProject" :key="item.projectID" :value="item.projectID">
                {{item.projectID}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="8">
          <a-form-model-item ref="invoiceMoney" label="发票金额" prop="invoiceMoney">
            <a-input
              type="number"
              placeholder="请输入金额"
              v-model.number="addInvoiceInfo.invoiceMoney"
              @blur="
          () => {
            $refs.invoiceMoney.onFieldBlur();
          }
        "
            />
          </a-form-model-item>
        </a-col>
        <a-col :span="8">
          <a-form-model-item ref="number" label="产品数量" prop="number">
            <a-input
              type="number"
              placeholder="请输入产品数量"
              v-model.number="addInvoiceInfo.number"
              @blur="
          () => {
            $refs.number.onFieldBlur();
          }
        "
            />
          </a-form-model-item>
        </a-col>
        <a-col :span="8">
          <a-form-model-item ref="invoiceNo" label="发票编号" prop="invoiceNo">
            <a-input
              placeholder="发票编号"
              v-model="addInvoiceInfo.invoiceNo"
              @blur="
          () => {
            $refs.invoiceNo.onFieldBlur();
          }
        "
            />
          </a-form-model-item>
        </a-col>
        <a-col :span="8">
          <a-form-model-item label="发票时间" prop="invoiceTime">
            <a-date-picker
              v-model="addInvoiceInfo.receiptTime"
              show-time
              type="date"
              placeholder="选择时间"
              style="width: 100%;"
            />
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="24">
          <a-form-model-item
            label="上传文件"
            prop="uploadFile"
            :labelCol="{span: 2}"
            :wrapperCol="{span: 22}"
          >
            <SingleUploadFile
              :customerID="addInvoiceInfo.customerID"
              fileUploadPath="file-upload/invoice"
              fileRemovedPath="file-upload/invoice"
              allFileCancelPath="file-upload/attachment/cancel"
              ref="singleUploadFile"
            ></SingleUploadFile>
          </a-form-model-item>
        </a-col>
      </a-row>

    </a-form-model>
  </div>
</template>

<script>
import { GetCustomerAll } from "@/api/customer/customer";
import { GetContractByCustomer } from "@/api/customer/contract";
import { GetProjectByCustomer } from "@/api/customer/project"
import SingleUploadFile from "@/components/SingleUploadFile/index.vue"

export default {
  name: "add-invoice",
  data(){
    return {
      rules: {
        customerID: [
          { required: true, message: '请选择公司名称', trigger: 'blur' }
        ],
        contractID: [
          { required: true, message: '请选择关联合同', trigger: 'blur' }
        ],
        projectID: [
          { required: true, message: '请选择产品ID', trigger: 'blur' }
        ],
        invoiceMoney: [
          { required: true, message: '请输入发票金额', trigger: 'blur' }
        ],
        invoiceNo: [
          { required: true, message: '请选择发票编号', trigger: 'blur' }
        ]
      },
      allCustomer: [],
      addInvoiceInfo: {},
      connectContract: [],
      currentAllProject:[]
    }
  },
  async created() {
    const customerAllNameObj = await GetCustomerAll()
    this.allCustomer = customerAllNameObj.result
  },
  components: {
    SingleUploadFile
  },
  methods:{
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    async toGetContract(){
      const contractListObj = await GetContractByCustomer({customerID: this.addInvoiceInfo.customerID})
      const projectListObj = await GetProjectByCustomer({customerID: this.addInvoiceInfo.customerID})
      if(contractListObj.code === 0) {
        this.connectContract = contractListObj.result
      }
      if(projectListObj.code === 0) {
        this.currentAllProject = projectListObj.result
      }
    },
  }
};
</script>

<style scoped>

</style>
<template>
  <div class="app-container">
    <el-collapse
      :value="showCollapse"
      class="my-collapse"
      @change="changeCollapse($event)"
    >
      <el-collapse-item :name="1" title="数量统计分析">
        <div class="item-content">
          <el-radio-group
            v-model="analysisType"
            style="margin-bottom: 10px"
            @change="changeAnalysisType"
          >
            <el-radio-button
              :disabled="checkOperateDisable('全部的统计分析合同数据')"
              label="0"
              >全部
            </el-radio-button>
            <el-radio-button label="1">个人</el-radio-button>
          </el-radio-group>
          <div class="top-card">
            <a-card
              class="card card1"
              hoverable
              @click="
                () => {
                  selectContractType = 0
                  getCustomerList()
                }
              "
            >
              <template slot="title">
                <div class="title">
                  {{ followUpTypeText[0] }}
                </div>
              </template>
              <span slot="extra">截止{{ parseTime(new Date()) }}</span>
              <div class="_content">
                <div class="_top">
                  <div class="_l">
                    <span style="display: block">
                      {{ currentDayFollowNum }}
                    </span>
                    <span> 金额 : {{ currentDayTotalPrice }} </span>
                  </div>
                  <div class="_r">
                    <div class="HB">
                      <div class="_title">
                        环比昨日【签单量】
                      </div>
                      <div class="_right">
                        <span :class="[dayFollowRate < 0 ? 'reduce' : 'add']">
                          <span></span>
                          <span>{{ dayFollowRate }}%</span>
                        </span>
                      </div>
                    </div>
                    <div class="HB">
                      <div class="_title">
                        环比昨日【合同金额】
                      </div>
                      <div class="_right">
                        <span
                          :class="[
                            currentDayTotalPriceRate < 0 ? 'reduce' : 'add'
                          ]"
                        >
                          <span></span>
                          <span>{{ currentDayTotalPriceRate }}%</span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="_bottom"></div>
              </div>
            </a-card>
            <a-card
              class="card card1"
              hoverable
              @click="
                () => {
                  selectContractType = 1
                  getCustomerList()
                }
              "
            >
              <template slot="title">
                <div class="title">
                  {{ followUpTypeText[1] }}
                </div>
              </template>
              <span slot="extra">截止{{ parseTime(new Date()) }}</span>
              <div class="_content">
                <div class="_top">
                  <div class="_l">
                    <span style="display: block">
                      {{ currentWeekFollowNum }}
                    </span>
                    <span> 金额 : {{ currentWeekTotalPrice }} </span>
                  </div>
                  <div class="_r">
                    <div class="HB">
                      <div class="_title">
                        环比上周【签单量】
                      </div>
                      <div class="_right">
                        <span :class="[weekFollowRate < 0 ? 'reduce' : 'add']">
                          <span></span>
                          <span>{{ weekFollowRate }}%</span>
                        </span>
                      </div>
                    </div>
                    <div class="HB">
                      <div class="_title">
                        环比上周【合同金额】
                      </div>
                      <div class="_right">
                        <span
                          :class="[
                            currentWeekTotalPriceRate < 0 ? 'reduce' : 'add'
                          ]"
                        >
                          <span></span>
                          <span>{{ currentWeekTotalPriceRate }}%</span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="_bottom"></div>
              </div>
            </a-card>
            <a-card
              class="card card1"
              hoverable
              @click="
                () => {
                  selectContractType = 2
                  getCustomerList()
                }
              "
            >
              <template slot="title">
                <div class="title">
                  {{ followUpTypeText[2] }}
                </div>
              </template>
              <span slot="extra">截止{{ parseTime(new Date()) }}</span>
              <div class="_content">
                <div class="_top">
                  <div class="_l">
                    <span style="display: block">
                      {{ currentMonthFollowNum }}
                    </span>
                    <span> 金额 : {{ currentMonthTotalPrice }} </span>
                  </div>
                  <div class="_r">
                    <div class="HB">
                      <div class="_title">
                        环比上月【合同量】
                      </div>
                      <div class="_right">
                        <span :class="[monthFollowRate < 0 ? 'reduce' : 'add']">
                          <span></span>
                          <span>{{ monthFollowRate }}%</span>
                        </span>
                      </div>
                    </div>
                    <div class="HB">
                      <div class="_title">
                        环比上月【合同金额】
                      </div>
                      <div class="_right">
                        <span
                          :class="[
                            currentMonthTotalPriceRate < 0 ? 'reduce' : 'add'
                          ]"
                        >
                          <span></span>
                          <span>{{ currentMonthTotalPriceRate }}%</span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="_bottom"></div>
              </div>
            </a-card>
            <a-card
              class="card card1"
              hoverable
              @click="
                () => {
                  selectContractType = 3
                  getCustomerList()
                }
              "
            >
              <template slot="title">
                <div class="title">
                  {{ followUpTypeText[3] }}
                </div>
              </template>
              <span slot="extra">截止{{ parseTime(new Date()) }}</span>
              <div class="_content">
                <div class="_top">
                  <div class="_l">
                    <span style="display: block">
                      {{ currentSeasonFollowNum }}
                    </span>
                    <span> 金额 : {{ currentSeasonTotalPrice }} </span>
                  </div>
                  <div class="_r">
                    <div class="HB">
                      <div class="_title">
                        环比上季度
                      </div>
                      <div class="_right">
                        <span
                          :class="[seasonFollowRate < 0 ? 'reduce' : 'add']"
                        >
                          <span></span>
                          <span>{{ seasonFollowRate }}%</span>
                        </span>
                      </div>
                    </div>
                    <div class="HB">
                      <div class="_title">
                        环比上季度
                      </div>
                      <div class="_right">
                        <span
                          :class="[
                            currentSeasonTotalPriceRate < 0 ? 'reduce' : 'add'
                          ]"
                        >
                          <span></span>
                          <span>{{ currentSeasonTotalPriceRate }}%</span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="_bottom"></div>
              </div>
            </a-card>
          </div>
          <div class="second-line-card">
            <div class="second-line-card-1">
              <a-card
                :body-style="{ padding: '0px' }"
                :bordered="false"
                class="card211"
              >
                <div class="customer-table">
                  <span
                    style="font-size: 18px;margin-right: 20px;margin-left: 10px"
                  >
                    {{
                      "合同关联企业 - " + followUpTypeText[selectContractType]
                    }}
                  </span>
                </div>
                <a-table
                  :columns="customerListColumns"
                  :dataSource="customerList"
                  :pagination="false"
                  :scroll="{
                    y: 240,
                    scrollToFirstRowOnChange: true
                  }"
                  rowKey="customerID"
                  style="width:99%;height:99%;margin:0 auto"
                >
                  <template slot="customerName" slot-scope="record">
                    <a @click="showCustomer(record)">{{
                      record.customerName
                    }}</a>
                  </template>

                  <template slot="contractTotalPrice" slot-scope="record">
                    {{ parseInt(record.contractTotalPrice) }}
                  </template>

                  <template slot="contractTime" slot-scope="record">
                    {{ fullTimeFilterYYYYMMDD(record.contractTime) }}
                  </template>
                </a-table>
              </a-card>
              <a-card
                :body-style="{ padding: '0px' }"
                :bordered="false"
                class="card221"
              >
                <div slot="title">
                  <span style="display: inline-block">
                    {{ "商务对接方式占比" }}
                  </span>
                  <el-radio-group
                    v-model="connectTypeRateTimeUnit"
                    style="margin-left: 20px"
                    @change="changeConnectType"
                  >
                    <el-radio-button :label="ANALYSIS_TIME_UNIT.MONTH"
                      >月
                    </el-radio-button>
                    <el-radio-button :label="ANALYSIS_TIME_UNIT.HALF_YEAR"
                      >半年
                    </el-radio-button>
                    <el-radio-button :label="ANALYSIS_TIME_UNIT.YEAR"
                      >年
                    </el-radio-button>
                  </el-radio-group>
                </div>
                <div
                  ref="connectTypeRate"
                  style="width:98%;height:310px;margin-top: 10px"
                ></div>
              </a-card>
              <a-card
                :body-style="{ padding: '0px' }"
                :bordered="false"
                class="card222"
              >
                <div slot="title">
                  <span style="display: inline-block">
                    {{ "商务成交方式占比" }}
                  </span>
                  <el-radio-group
                    v-model="cooperationTypeRateTimeUnit"
                    style="margin-left: 20px"
                    @change="changeCooperationType"
                  >
                    <el-radio-button :label="ANALYSIS_TIME_UNIT.MONTH"
                      >月
                    </el-radio-button>
                    <el-radio-button :label="ANALYSIS_TIME_UNIT.HALF_YEAR"
                      >半年
                    </el-radio-button>
                    <el-radio-button :label="ANALYSIS_TIME_UNIT.YEAR"
                      >年
                    </el-radio-button>
                  </el-radio-group>
                </div>
                <div
                  ref="cooperationTypeRate"
                  style="width:98%;height:310px;margin-top: 10px"
                ></div>
              </a-card>
            </div>
            <a-card
              :body-style="{ padding: '0px' }"
              :bordered="false"
              class="card22"
            >
              <div slot="title">
                <span style="display: inline-block">
                  {{ "签约走势图 - " + followUpTypeText[selectContractType] }}
                </span>
                <el-radio-group
                  v-model="trendType"
                  style="margin-left: 20px"
                  @change="changeTrendType"
                >
                  <el-radio-button :label="ANALYSIS_TIME_UNIT.DAY"
                    >日
                  </el-radio-button>
                  <el-radio-button :label="ANALYSIS_TIME_UNIT.MONTH"
                    >月
                  </el-radio-button>
                  <el-radio-button :label="ANALYSIS_TIME_UNIT.SEASON"
                    >季
                  </el-radio-button>
                </el-radio-group>
              </div>

              <span slot="extra">截止{{ parseTime(new Date()) }}</span>
              <div style="margin-left:20px; margin-top: 20px;font-size: 15px">
                <span>签约量走势</span>
              </div>
              <div
                ref="signTrade"
                style="width:98%;height:280px;margin-top: 10px"
              ></div>
              <div style="margin-left:20px; margin-top: 20px;font-size: 15px">
                <span>签约金额走势</span>
              </div>
              <div
                ref="signTotalPriceTrade"
                style="width:98%;height:280px;margin-top: 10px"
              ></div>
            </a-card>
          </div>
        </div>
      </el-collapse-item>
      <el-collapse-item
        :disabled="checkOperateDisable('全部销售人员合同签署统计分析')"
        :name="2"
        title="销售人员合同签署统计分析"
      >
        <div class="item-content">
          <a-card class="analysis-contract-sale" hoverable>
            <div slot="title">
              <a-range-picker
                v-model="signTimeRange"
                :allowClear="true"
                allow-clear
                format="YYYY-MM-DD"
                style="display: inline-block"
              />
              <a-button
                style="display: inline-block;margin-left: 20px"
                type="primary"
                @click="searchUserContractAnalysis"
                >搜索统计
              </a-button>
            </div>
            <a-table
              :columns="contractUserSignColumns"
              :data-source="userSignAnalysisData"
              :scroll="{
                y: 280,
                x: 210
              }"
              :sorter="false"
              class="user-sign-analysis-table"
            >
              <template slot="no" slot-scope="record">
                <img
                  v-if="record.no == 1"
                  src="@/assets/image/no1.png"
                  style="display: inline-block;width: 20px;height: 20px"
                />
                <img
                  v-else-if="record.no == 2"
                  src="@/assets/image/no2.png"
                  style="display: inline-block;width: 20px;height: 20px"
                />
                <img
                  v-else-if="record.no == 3"
                  src="@/assets/image/no3.png"
                  style="display: inline-block;width: 20px;height: 20px"
                />
                <img
                  v-else-if="record.no == 4"
                  src="@/assets/image/no4.png"
                  style="display: inline-block;width: 20px;height: 20px"
                />
                <img
                  v-else-if="record.no == 5"
                  src="@/assets/image/no5.png"
                  style="display: inline-block;width: 20px;height: 20px"
                />
                <span v-else>
                  {{ record.no }}
                </span>
              </template>
            </a-table>
            <div
              ref="contractSignUserRecordAnalysis"
              class="user-sign-analysis-trend"
            ></div>
          </a-card>
        </div>
      </el-collapse-item>
    </el-collapse>

    <div class="head-container">
      <a-form layout="inline">
        <a-row :gutter="30">
          <a-col :md="4" :sm="24">
            <a-form-item label="合同编号">
              <a-input
                v-model="crud.query.contractNo"
                allow-clear
                placeholder="请输入合同编号"
              />
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24">
            <a-form-item label="所属企业">
              <a-select
                v-model="crud.query.customerID"
                :filterOption="filterCategory"
                allow-clear
                placeholder="请选择所属企业"
                show-search
              >
                <a-select-option
                  v-for="item in allCustomerMap"
                  :key="item.customerID"
                  :value="item.customerID"
                  >{{ item.customerName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24">
            <a-form-item label="员工姓名">
              <a-select
                v-model="crud.query.userID"
                :allowClear="true"
                :filterOption="filterCategory"
                :not-found-content="null"
                :show-arrow="false"
                placeholder="请输入员工姓名"
                show-search
              >
                <a-select-option
                  v-for="item in allUserListMap"
                  :key="Number(item.userID)"
                  :value="Number(item.userID)"
                  >{{ item.displayName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24">
            <a-form-item label="客户联系人">
              <a-input
                v-model="crud.query.contactName"
                allow-clear
                placeholder="请输入客户联系人"
                style="width: 100%"
              />
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24">
            <a-form-item label="客户联系方式">
              <a-input
                v-model="crud.query.contactTel"
                allow-clear
                placeholder="请输入客户联系方式"
                style="width: 100%"
              />
            </a-form-item>
          </a-col>
          <template v-if="crud.advanced">
            <a-col :md="4" :sm="24">
              <a-form-item label="签署时间">
                <a-range-picker
                  v-model="crud.query.signTimes"
                  :allowClear="true"
                  allow-clear
                  format="YYYY-MM-DD"
                />
              </a-form-item>
            </a-col>

            <a-col :md="4" :sm="24">
              <a-form-item label="交付时间">
                <a-range-picker
                  v-model="crud.query.releasedTimes"
                  :allowClear="true"
                  allow-clear
                  format="YYYY-MM-DD"
                />
              </a-form-item>
            </a-col>

            <a-col :md="16" :sm="24"></a-col>
          </template>
          <a-col :md="4" :sm="24">
            <SearchOptions
              :queryFuncName="queryFuncName"
              style="width: 100%"
              @toDiyQuery="toDiyQuery"
              @toDiyResetQuery="toDiyResetQuery"
            />
          </a-col>
        </a-row>
      </a-form>
    </div>

    <div class="table-container">
      <Operations tableName="合同" />
      <a-spin :spinning="crud.loading">
        <a-table
          :columns="crud.columns"
          :data-source="crud.data"
          :pagination="false"
          :scroll="{ x: 2000, y:1000 }"
          rowKey="contactId"
        >
          <template slot="contractNo" slot-scope="record">
            <a
              :disabled="
                checkMultiTypeOperateDisable([record.operator], '查看合同详情')
              "
              @click="showContract(record)"
              >{{ record.contractNo }}</a
            >
          </template>
          <template slot="customerName" slot-scope="record">
            <a
              :disabled="
                checkMultiTypeOperateDisable(
                  [record.creatorID],
                  '查看合同关联的客户详情'
                )
              "
              @click="showCustomer(record)"
              >{{ record.customerName }}</a
            >
          </template>
          <template slot="releasedTime" slot-scope="record">
            {{ fullTimeFilterYYYYMMDD(record.releasedTime) }}
          </template>
          <template slot="signTime" slot-scope="record">
            {{ fullTimeFilterYYYYMMDD(record.signTime) }}
          </template>
          <template slot="insertTime" slot-scope="record">
            {{ filterTime(record.insertTime) }}
          </template>
          <template slot="updateTime" slot-scope="record">
            {{ filterTime(record.updateTime) }}
          </template>
        </a-table>
      </a-spin>
      <Pagination />
    </div>
    <CustomerInfo ref="customerInfo"></CustomerInfo>
    <ContractInfo ref="contractInfo"></ContractInfo>
  </div>
</template>

<script>
import SearchOptions from "@/components/Crud/SearchOptions"
import Operations from "@/components/Crud/Operations"
import Pagination from "@/components/Crud/Pagination"
import CustomerInfo from "@/components/customerInfo"
import ContractInfo from "@/components/customerInfo/contract-info"
import CRUD, { form, header, presenter } from "@crud/crud"
import { GetFollowUpRecordAnalysis } from "@/api/customer/follow-up"
import Api, {
  analysis,
  AnalysisConnectRate,
  AnalysisCooperationRate,
  analysisTrend,
  GetAllUserSignAnalysis,
  GetContractAttachment,
  getCustomerListByContractUserID
} from "@/api/customer/contract"
import {
  ANALYSIS_TIME_UNIT,
  CONTRACT_ANALYSIS_TYPE_TEXT,
  CONTRACT_SHOW_TYPE
} from "@/config"
import { fullTimeFilter, fullTimeFilterYYYYMMDD } from "@/utils/utils"
import store from "@/store"
import {
  GetAllContact,
  GetAllCustomer,
  GetAllUserList,
  GetDictionary
} from "@/api/public-api"
import {
  drawColumnsAndTrendsChart,
  drawColumnsChart,
  drawGeneralStatisticsChart
} from "@/views/customer/char"
import { contractCustomerListColumns } from "@/views/customer/contact-list"
import {
  contractListColumns,
  contractUserSignColumns
} from "@/views/customer/contract-list"
import { GetContractProjects } from "@/api/project"
import {
  checkMultiTypeOperateDisable,
  checkOperateDisable
} from "@/router/permission"

// 表格的填充字段
const defaultForm = {
  contactName: "",
  contactTel: ""
}
export default {
  name: "ContractID",
  // presenter 生成crud对象
  mixins: [presenter(), header(), form(defaultForm)],
  cruds() {
    return CRUD({
      title: "合同",
      roleIDs: [],
      columns: contractListColumns,
      crudMethod: { ...Api },
      idField: "contactId" // 设置每一条记录的唯一标失
    })
  },
  data() {
    return {
      queryFuncName: "查询合同列表",
      showAnalysis: false,
      roleIDs: [],

      contractListColumns,
      allContactMap: [],
      allCustomerMap: [],
      allCustomerTagMap: [],
      allUserListMap: [],

      analysisType: 1,
      currentDayFollowNum: 0,
      dayFollowRate: 0,
      currentWeekFollowNum: 0,
      weekFollowRate: 0,
      currentMonthFollowNum: 0,
      monthFollowRate: 0,
      currentSeasonFollowNum: 0,
      seasonFollowRate: 0,

      selectContractType: 2,
      followUpTypeText: CONTRACT_ANALYSIS_TYPE_TEXT,
      customerListColumns: contractCustomerListColumns,
      customerList: [],

      allCustomerAssignTypeMap: [],
      showCustomerID: null,
      showCollapse: [1],
      trendType: ANALYSIS_TIME_UNIT.MONTH,
      ANALYSIS_TIME_UNIT,
      signTimeRange: [],
      contractUserSignColumns,
      userSignAnalysisData: [],
      connectTypeRateTimeUnit: ANALYSIS_TIME_UNIT.YEAR,
      cooperationTypeRateTimeUnit: ANALYSIS_TIME_UNIT.YEAR,
      connectRate: [],
      cooperationRate: [],

      currentDayTotalPrice: 0,
      currentWeekTotalPrice: 0,
      currentMonthTotalPrice: 0,
      currentSeasonTotalPrice: 0,

      currentDayTotalPriceRate: 0,
      currentWeekTotalPriceRate: 0,
      currentMonthTotalPriceRate: 0,
      currentSeasonTotalPriceRate: 0
    }
  },
  components: {
    SearchOptions,
    Operations,
    Pagination,
    CustomerInfo,
    ContractInfo
  },
  mounted() {
    const that = this
    that.getAnalysisData()
    that.getCustomerList()
  },
  async created() {
    this.crud.optShow.edit = false
    this.crud.permission = {
      add: false,
      del: false,
      edit: false
    }
    const [
      getAllContactRes,
      getAllCustomerRes,
      getCustomerTagRes,
      getCustomerAssignTypeRes,
      getAllUserListRes
    ] = await Promise.all([
      GetAllContact(),
      GetAllCustomer(),
      GetDictionary({ typeName: "customerTag" }),
      GetDictionary({ typeName: "customerAssignType" }),
      GetAllUserList()
    ])

    if (getAllContactRes.code === 0) {
      this.allContactMap = getAllContactRes.result
    }

    if (getAllCustomerRes.code === 0) {
      this.allCustomerMap = getAllCustomerRes.result
    }

    if (getCustomerTagRes.code === 0) {
      this.allCustomerTagMap = getCustomerTagRes.result
    }

    if (getCustomerAssignTypeRes.code === 0) {
      this.allCustomerAssignTypeMap = getCustomerAssignTypeRes.result
    }

    if (getAllUserListRes.code === 0) {
      this.allUserListMap = [
        { userID: 0, displayName: "暂不指定" },
        ...getAllUserListRes.result
      ]
    }

    this.roleIDs = store.getters.roleIDs
  },
  methods: {
    checkOperateDisable,
    checkMultiTypeOperateDisable,
    filterTime: timeStr => fullTimeFilter(timeStr),
    fullTimeFilterYYYYMMDD,
    drawColumnsAndTrendsChart: drawColumnsAndTrendsChart,
    drawChart: drawGeneralStatisticsChart,
    async searchUserContractAnalysis() {
      const that = this
      if (that.signTimeRange && that.signTimeRange.length === 2) {
        const getAllUserSignAnalysisRes = await GetAllUserSignAnalysis({
          times: that.signTimeRange
        })
        if (getAllUserSignAnalysisRes.code === 0) {
          that.userSignAnalysisData = [...getAllUserSignAnalysisRes.result]
            .filter(item => item.userID)
            .sort((a, b) => b.count - a.count)
            .map((item, index) => ({ no: index + 1, ...item }))
          that.drawAnalysisColumnsChart(that.userSignAnalysisData)
        } else {
          that.$message.error("统计失败，请联系开发工程师")
        }
      } else {
        that.$message.error("请选择统计的时间范围")
      }
    },
    changeAnalysisType(val) {
      this.analysisType = val
      this.getAnalysisData()
      this.getCustomerList()
    },
    changeTrendType(val) {
      this.trendType = val
      this.getTrendData()
    },
    changeConnectType(val) {
      this.connectTypeRateTimeUnit = val
      this.getConnectTypeRateData()
    },
    changeCooperationType(val) {
      this.cooperationTypeRateTimeUnit = val
      this.getCooperationTypeRateData()
    },
    async getAnalysisData() {
      const that = this
      const [getAnalysisRes] = await Promise.all([
        analysis({ analysisType: that.analysisType }),
        that.getTrendData(),
        that.getConnectTypeRateData(),
        that.getCooperationTypeRateData()
      ])
      if (getAnalysisRes.code === 0) {
        this.currentDayFollowNum = getAnalysisRes.result.currentDayFollowNum
        this.dayFollowRate = getAnalysisRes.result.dayFollowRate
        this.currentWeekFollowNum = getAnalysisRes.result.currentWeekFollowNum
        this.weekFollowRate = getAnalysisRes.result.weekFollowRate
        this.currentMonthFollowNum = getAnalysisRes.result.currentMonthFollowNum
        this.monthFollowRate = getAnalysisRes.result.monthFollowRate
        this.currentSeasonFollowNum =
          getAnalysisRes.result.currentSeasonFollowNum
        this.seasonFollowRate = getAnalysisRes.result.seasonFollowRate

        this.currentDayTotalPrice = getAnalysisRes.result.currentDayTotalPrice
        this.currentWeekTotalPrice = getAnalysisRes.result.currentWeekTotalPrice
        this.currentMonthTotalPrice =
          getAnalysisRes.result.currentMonthTotalPrice
        this.currentSeasonTotalPrice =
          getAnalysisRes.result.currentSeasonTotalPrice

        this.currentDayTotalPriceRate =
          getAnalysisRes.result.currentDayTotalPriceRate
        this.currentWeekTotalPriceRate =
          getAnalysisRes.result.currentWeekTotalPriceRate
        this.currentMonthTotalPriceRate =
          getAnalysisRes.result.currentMonthTotalPriceRate
        this.currentSeasonTotalPriceRate =
          getAnalysisRes.result.currentSeasonTotalPriceRate
      }
    },

    async getConnectTypeRateData() {
      const that = this
      const getConnectRateAnalysisRes = await AnalysisConnectRate({
        analysisType: that.analysisType,
        connectTypeRateTimeType: that.connectTypeRateTimeUnit
      })

      if (getConnectRateAnalysisRes.code === 0) {
        that.connectRate = [...getConnectRateAnalysisRes.result]
        this.drawChart(
          "connectTypeRate",
          that.connectRate.map(item => ({
            value: item.count,
            name: item.name
          }))
        )
      }
    },
    async getCooperationTypeRateData() {
      const that = this
      const getCooperationRateAnalysisRes = await AnalysisCooperationRate({
        analysisType: that.analysisType,
        cooperationTypeRateTimeType: that.cooperationTypeRateTimeUnit
      })

      if (getCooperationRateAnalysisRes.code === 0) {
        that.cooperationRate = [...getCooperationRateAnalysisRes.result]
        this.drawChart(
          "cooperationTypeRate",
          that.cooperationRate.map(item => ({
            value: item.count,
            name: item.name
          }))
        )
      }
    },
    async getTrendData() {
      const that = this
      const getTrendAnalysisRes = await analysisTrend({
        analysisType: that.analysisType,
        trendType: that.trendType
      })

      if (getTrendAnalysisRes.code === 0) {
        switch (that.trendType) {
          case ANALYSIS_TIME_UNIT.DAY:
            that.drawColumnsAndTrendsChart("signTrade", [
              ...getTrendAnalysisRes.result.map(item => ({
                dateNo: item.dateNo,
                count: item.count
              }))
            ])
            that.drawColumnsAndTrendsChart("signTotalPriceTrade", [
              ...getTrendAnalysisRes.result.map(item => ({
                dateNo: item.dateNo,
                count: parseInt(item.totalPrice)
              }))
            ])

            break
          case ANALYSIS_TIME_UNIT.MONTH:
            that.drawColumnsAndTrendsChart("signTrade", [
              ...getTrendAnalysisRes.result.map(item => ({
                dateNo: item.year + "-" + item.month,
                count: item.count
              }))
            ])
            that.drawColumnsAndTrendsChart("signTotalPriceTrade", [
              ...getTrendAnalysisRes.result.map(item => ({
                dateNo: item.year + "-" + item.month,
                count: parseInt(item.totalPrice)
              }))
            ])
            break
          case ANALYSIS_TIME_UNIT.SEASON:
            that.drawColumnsAndTrendsChart("signTrade", [
              ...getTrendAnalysisRes.result.map(item => ({
                dateNo: item.year + "-" + item.season + "季度",
                count: item.count
              }))
            ])
            that.drawColumnsAndTrendsChart("signTotalPriceTrade", [
              ...getTrendAnalysisRes.result.map(item => ({
                dateNo: item.year + "-" + item.season + "季度",
                count: parseInt(item.totalPrice)
              }))
            ])
            break
        }
      }
    },
    changeCollapse(value) {
      this.showCollapse = [...value]
    },
    async getCustomerList() {
      const that = this
      const getCustomerListRes = await getCustomerListByContractUserID({
        analysisType: that.analysisType,
        selectContractType: that.selectContractType
      })
      that.customerList = [...getCustomerListRes.result]
    },
    showCustomer(record) {
      this.$refs.customerInfo.toCustomerInfo(record.customerID)
    },
    async showContract(record) {
      console.log(record)
      const that = this
      const [
        getContractProjectsRes,
        getContractAttachmentRes
      ] = await Promise.all([
        GetContractProjects({
          contractID: record.contractID
        }),
        GetContractAttachment({
          contractID: record.contractID
        })
      ])
      that.$refs.contractInfo.toShowFlag = CONTRACT_SHOW_TYPE.SHOW_CONTRACT
      that.$refs.contractInfo.currentContract = {
        ...record,
        userID: store.getters.user.userId
      }
      that.$refs.contractInfo.customerID = record.customerID
      if (
        getContractProjectsRes.code === 0 &&
        getContractProjectsRes.result &&
        getContractProjectsRes.result.length > 0
      ) {
        that.$refs.contractInfo.projectGoodsData = [
          ...getContractProjectsRes.result
        ]
      }
      if (
        getContractAttachmentRes.code === 0 &&
        getContractAttachmentRes.result &&
        getContractAttachmentRes.result.length > 0
      ) {
        that.$refs.contractInfo.uploadFilePath = [
          ...getContractAttachmentRes.result
        ]
      }
    },
    findCustomerValueByKey(filed, list) {
      const target = list.find(item => Number(item.key) === filed)
      if (target && target.value) {
        return target.value
      } else {
        return "-"
      }
    },
    toInfo(record) {
      this.crud.toInfo(record)
    },
    filterCategory: function(value, option) {
      return option.componentOptions.children[0].text.indexOf(value) >= 0
    },
    drawColumnsChart,
    drawAnalysisColumnsChart(arrData) {
      const data = {}
      const queryResult = [...arrData]
      data.xAxis = queryResult.map(item => item.userName)
      data.series = queryResult.map(item => item.count)

      const maxValue = queryResult.sort((a, b) => b.count - a.count)[0].count
      const minValue = queryResult.sort((a, b) => a.count - b.count)[0].count

      const maxIndex = data.series.findIndex(item => item == maxValue)
      const minIndex = data.series.findIndex(item => item == minValue)

      data.markPoint = [
        { name: "Max", value: maxValue, xAxis: maxIndex, yAxis: maxValue },
        { name: "Min", value: minValue, xAxis: minIndex, yAxis: minValue }
      ]
      this.drawColumnsChart(
        "contractSignUserRecordAnalysis",
        data,
        ["签约数据"],
        "合同签约量"
      )
    },
    async toDiyQuery() {
      const that = this
      this.crud.toQuery()
      const queryRes = await GetFollowUpRecordAnalysis({ ...that.crud.query })
      if (
        queryRes.code === 0 &&
        queryRes.result &&
        queryRes.result.length > 0
      ) {
        that.drawAnalysisColumnsChart(queryRes.result)
      }
    },
    async toDiyResetQuery() {
      const that = this
      that.crud.resetQuery()
      const queryRes = await GetFollowUpRecordAnalysis({})
      if (
        queryRes.code === 0 &&
        queryRes.result &&
        queryRes.result.length > 0
      ) {
        that.drawAnalysisColumnsChart(queryRes.result)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.my-collapse {
  margin-bottom: 20px;

  .el-collapse-item {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 5px;

    /deep/ .el-collapse-item__content {
      padding-bottom: 0;
    }

    /deep/ .el-collapse-item__header {
      padding-left: 20px;
    }

    .item-content {
      background-color: #f0f2f5;
      padding: 10px;
    }
  }
}

.analysis-contract-sale {
  display: inline-block;
  width: 99%;
  height: 500px;
  margin-bottom: 20px;
  margin-left: 0.5%;
  border: none;
  border-radius: 10px;
  box-shadow: 4px 4px 8px 0px rgba(201, 201, 201, 0.5);

  .user-sign-analysis-table {
    width: 20%;
    float: left;
    display: inline-block;
  }

  .user-sign-analysis-trend {
    width: 80%;
    float: left;
    height: 430px;
    padding: 15px;
    display: inline-block;
  }
}

.top-card {
  min-width: 1156px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  .card {
    margin-right: 18px;
    // height: 232px;
    &:last-child {
      margin-right: 0;
    }

    flex: 1;
    min-width: 275px;
    border: none;
    border-radius: 10px;
    box-shadow: 4px 4px 8px 0px rgba(201, 201, 201, 0.5);

    /deep/ .ant-card-head,
    /deep/ .ant-card-body {
      padding-left: 19px;
      padding-right: 19px;
    }

    /deep/ .ant-card-head {
      .ant-card-head-title {
        padding: 0;
      }

      .title {
        height: 30px;
        font-size: 22px;
        font-weight: 500;
        text-align: left;
        color: #3d3d3d;
        line-height: 30px;
      }

      .ant-card-extra {
        font-size: 14px;
        font-weight: 400;
        text-align: left;
        color: #afafaf;
      }
    }

    /deep/ .ant-card-body {
      padding-top: 12px;
      padding-bottom: 16px;

      ._content {
        ._top {
          display: flex;
          align-items: center;
          justify-content: space-between;
          // margin-bottom: 18px;
          ._l {
            display: flex;
            align-items: center;

            span {
              display: block;

              &:first-child {
                height: 47px;
                font-size: 40px;
                font-weight: 700;
                text-align: left;
                color: #3d3d3d;
                line-height: 47px;
                letter-spacing: 2px;
              }

              &:last-child {
                height: 20px;
                font-size: 14px;
                font-weight: 400;
                text-align: left;
                color: #afafaf;
                line-height: 20px;
                margin-top: 10px;
                margin-left: 9px;
              }
            }
          }

          ._r {
            & > div {
              &.HB {
                margin-top: 9px;
              }

              display: flex;
              align-items: center;

              ._title {
                height: 20px;
                font-size: 14px;
                font-weight: 400;
                text-align: left;
                color: #afafaf;
                line-height: 20px;
                margin-right: 22px;
              }
            }
          }
        }
      }
    }

    &.card1 {
      .ant-card-body {
        ._content {
          ._top {
            ._r {
              & > div {
                &.HB {
                  ._right {
                    span {
                      display: flex;
                      align-items: center;

                      & > * {
                        display: block;
                      }

                      span {
                        &:last-child {
                          margin-left: -8px;
                        }
                      }

                      &.add {
                        span {
                          color: #ff4343;

                          &:first-child {
                            width: 0;
                            height: 0;
                            border-left: 9px solid transparent;
                            border-right: 9px solid transparent;
                            border-bottom: 10px solid red;
                          }
                        }
                      }

                      &.reduce {
                        span {
                          color: #05c065;

                          &:first-child {
                            width: 0;
                            height: 0;
                            border-left: 9px solid transparent;
                            border-right: 9px solid transparent;
                            border-top: 10px solid #05c065;
                          }
                        }
                      }
                    }
                  }
                }

                ._right {
                  span {
                    &:last-child {
                      color: #afafaf;
                    }
                  }
                }
              }

              ._right {
                display: flex;
                align-items: center;

                span {
                  display: block;
                  max-width: 58px;

                  &:first-child {
                    height: 22px;
                    font-size: 16px;
                    font-weight: 500;
                    text-align: left;
                    color: #3b3b3b;
                    line-height: 22px;
                    margin-right: 16px;
                  }

                  &:last-child {
                    height: 20px;
                    font-size: 14px;
                    font-weight: 500;
                    text-align: left;
                    color: #6f7fff;
                    line-height: 20px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.second-line-card {
  width: 100%;
  height: 750px;
  margin-top: 10px;

  .second-line-card-1 {
    display: inline-block;
    float: left;
    width: 40%;
    height: 750px;

    .card211 {
      display: inline-block;
      float: left;
      width: 100%;
      height: 360px;
      border: none;
      border-radius: 10px;
      box-shadow: 4px 4px 8px 0px rgba(201, 201, 201, 0.5);

      .customer-table {
        justify-content: flex-start;
        align-items: center;
        margin-right: 10px;
        margin-bottom: 10px;
        margin-top: 10px;
      }
    }

    .card221 {
      display: inline-block;
      float: left;
      width: 49%;
      height: 360px;
      border: none;
      border-radius: 10px;
      box-shadow: 4px 4px 8px 0px rgba(201, 201, 201, 0.5);
      margin-top: 20px;
    }

    .card222 {
      display: inline-block;
      float: left;
      width: 49%;
      height: 360px;
      margin-left: 10px;
      border: none;
      border-radius: 10px;
      box-shadow: 4px 4px 8px 0px rgba(201, 201, 201, 0.5);
      margin-top: 20px;
    }
  }

  .card22 {
    display: inline-block;
    float: left;
    width: 59%;
    height: 740px;
    margin-left: 1%;
    border: none;
    border-radius: 10px;
    box-shadow: 4px 4px 8px 0px rgba(201, 201, 201, 0.5);
  }
}
</style>

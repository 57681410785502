<template>
  <a-modal
    :afterClose="cancelIntoOpenSea"
    :visible="approvalRecordFormShow"
    okText="提交审核"
    title="将客户移入公海"
    width="1000px"
    wrapClassName="stationListDialog"
    @cancel="cancelIntoOpenSea"
  >
    <a-form-model
      ref="approvalRecordForm"
      :label-col="{ span: 4 }"
      :model="approvalRecordForm"
      :wrapper-col="{ span: 20 }"
      labelAlign="left"
    >
      <a-form-model-item label="客户名称" prop="customerName">
        {{ approvalRecordForm.customerName }}
      </a-form-model-item>
      <a-form-model-item label="移入公海原因" prop="content">
        <a-textarea
          v-model="approvalRecordForm.content"
          :allowClear="true"
          :disabled="true"
          placeholder="请填写移入公海原因"
        />
      </a-form-model-item>
    </a-form-model>

    <template slot="footer">
      <div>
        <a-button key="back" @click="cancelIntoOpenSea">
          取消
        </a-button>
        <a-popconfirm
          v-if="passApproval"
          cancel-text="取消"
          ok-text="确认"
          placement="topRight"
          style="margin-left: 20px;width:200px"
          @confirm="submitEdit(2)"
        >
          <template slot="title">
            你确定要审核通过吗？
          </template>
          <a-button key="submit" type="primary">
            审核通过
          </a-button>
        </a-popconfirm>
        <a-popconfirm
          v-if="passApproval"
          cancel-text="取消"
          ok-text="确认"
          placement="topRight"
          style="margin-left: 20px;width:200px"
          @confirm="submitEdit(3)"
        >
          <template slot="title">
            你确定要驳回审核吗？
          </template>
          <a-button key="submit" type="danger">
            审核驳回
          </a-button>
        </a-popconfirm>
      </div>
    </template>
  </a-modal>
</template>

<script>
import { fullTimeFilter } from "@/utils/utils"
import { getApprovalRecordDetail, passApprovalRecord } from "@/api/todo-list"
import { APPROVAL_TYPE_MAP, BASE_OPERATE_TYPE } from "@/config"

export default {
  name: "IntoOpenSea",
  data() {
    return {
      approvalRecordFormShow: false,
      approvalRecordForm: {},
      operateType: "",
      BASE_OPERATE_TYPE,
      APPROVAL_TYPE_MAP,
      passApproval: false
    }
  },
  async created() {},
  methods: {
    filterTime: timeStr => fullTimeFilter(timeStr),
    async showData(recordID, passApproval) {
      this.passApproval = passApproval
      this.approvalRecordFormShow = true
      this.operateType = BASE_OPERATE_TYPE.INFO
      const getDetailRes = await getApprovalRecordDetail({
        approvalRecordID: recordID
      })
      if (getDetailRes.code === 0) {
        this.approvalRecordForm = getDetailRes.result
      }
    },
    async toShowCustomer() {},
    async getUserIntoOpenSeaCopyRecordList() {},
    async getUserIntoOpenSeaApproval() {},
    cancelIntoOpenSea() {
      this.approvalRecordFormShow = false
      this.approvalRecordForm = {}
    },
    async submitEdit(approvalStatus) {
      const that = this
      const passRes = await passApprovalRecord({
        ...that.approvalRecordForm,
        approvalStatus
      })
      if (passRes.code === 0) {
        that.$emit("pass-approval-finish")
        that.cancelIntoOpenSea()
      }
    }
  }
}
</script>

<style scoped>
.card-line {
  padding: 20px;
}

.ant-table-striped /deep/ .table-striped td {
  background-color: #fcfcfc;
}

.table-operations {
  display: flex;
  justify-content: flex-start;
  vertical-align: middle;
  align-items: center;
  margin-right: 10px;
  margin-bottom: 10px;
  margin-top: 10px;
  height: 50px;
}

.table-operations > button {
  margin-right: 8px;
}
</style>

<template>
  <div>
    <a-form-model
      ref="ruleForm"
      :model="receiptAdd"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-row>
        <a-col :span="12">
          <a-form-model-item label="公司名称" prop="customerID" ref="customerID">
            <a-select
              showSearch
              allowClear
              v-model="receiptAdd.customerID"
              placeholder="请选择公司名称"
              :filter-option="filterOption"
              @change="toGetContract"
            >
              <a-select-option v-for="item in allCustomer" :key="item.customerID" :value="item.customerID">
                {{item.customerName}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
            <a-form-model-item label="合同ID" prop="contractID" ref="contractID">
              <a-select
                showSearch
                allowClear
                v-model="receiptAdd.contractID"
                placeholder="请选择合同ID"
                :filter-option="filterOption"
              >
                <a-select-option v-for="item in connectContract" :key="item.contractID" :value="item.contractID">
                  {{item.contactId}}
                </a-select-option>
              </a-select>
            </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item ref="receiptMoney" label="收款金额" prop="receiptMoney">
            <a-input
              type="number"
              placeholder="请输入金额"
              v-model.number="receiptAdd.receiptMoney"
              @blur="
          () => {
            $refs.receiptMoney.onFieldBlur();
          }
        "
            />
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="收款方式" prop="receiptType">
            <a-select v-model="receiptAdd.receiptType"  placeholder="请选择支付方式">
              <a-select-option v-for="item in contractReceiptType" :key="item.key" :value="Number(item.key)">
                {{item.value}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="收款时间" required prop="receiptTime">
            <a-date-picker
              v-model="receiptAdd.receiptTime"
              show-time
              type="date"
              placeholder="选择时间"
              style="width: 100%;"
            />
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
  </div>
</template>

<script>
import { GetDictionary } from "@/api/public-api";
import {GetCustomerAll} from "@/api/customer/customer"
import { GetContractByCustomer } from "@/api/customer/contract"

export default {
  name: "receipt-tab-pane-add",
  data(){
    return {
      receiptAdd: {},
      rules: {
        customerID: [
          { required: true, message: '请选择公司名称', trigger: 'blur' },
        ],
        contractID: [
          { required: true, message: '请输入合同id', trigger: 'blur' },
        ],
        receiptMoney: [
          { required: true, message: '请输入收款金额', trigger: 'blur' },
        ],
        region: [{ required: true, message: '请选择支付方式', trigger: 'change' }],
        receiptTime: [{ required: true, message: '请选择一个时间', trigger: 'change' }],
      },
      labelCol: { span: 8},
      wrapperCol: { span: 10 },
      contractReceiptType:[],
      allCustomer:[],
      connectContract:[]
    }
  },
  async created() {
    const resultObj = await GetDictionary({ typeName: "contractReceiptType" })
    const customerAllNameObj = await GetCustomerAll()
    this.allCustomer = customerAllNameObj.result
    this.contractReceiptType = resultObj.result
  },
  methods:{
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    async toGetContract(){
      const contractListObj = await GetContractByCustomer({customerID: this.receiptAdd.customerID})
      if(contractListObj.code === 0) {
        this.connectContract = contractListObj.result
      }
    }
  }
};
</script>

<style scoped>

</style>
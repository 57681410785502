<template>
  <a-col :md="(!crud.advanced && grid) || 24" :sm="24" class="search-col">
    <span
      :style="
        (crud.advanced && { 'margin-bottom': '24px', overflow: 'hidden' }) || {}
      "
      class="search-buttons"
    >
      <a-button
        :disabled="queryFuncName !== '' && checkOperateDisable(queryFuncName)"
        :loading="crud.loading"
        type="primary"
        @click="toDiyQuery"
        >查询</a-button
      >
      <a-button
        v-if="crud.optShow.reset"
        :disabled="queryFuncName !== '' && checkOperateDisable(queryFuncName)"
        style="margin-left: 8px"
        @click="toDiyResetQuery"
        >重置</a-button
      >
      <a
        v-show="toggleShowAdvance"
        style="margin-left: 8px"
        @click="crud.toggleAdvanced()"
      >
        {{ crud.advanced ? "收起" : "展开" }}
        <a-icon :type="crud.advanced ? 'up' : 'down'" />
      </a>
    </span>
  </a-col>
</template>
<script>
import { crud } from "@crud/crud"
import store from "@/store"
import {
  checkMultiTypeOperateDisable,
  checkOperateDisable
} from "@/router/permission"

export default {
  mixins: [crud()],
  props: {
    toggleShowAdvance: {
      type: Boolean,
      default() {
        return true
      }
    },
    grid: {
      type: Number,
      default() {
        return 8
      }
    },
    queryFuncName: {
      type: String,
      default() {
        return ""
      }
    }
  },
  data() {
    return {
      store
    }
  },
  created() {},
  methods: {
    checkMultiTypeOperateDisable,
    checkOperateDisable,
    toDiyQuery() {
      let _this = this
      if (_this.$listeners["toDiyQuery"]) {
        _this.$emit("toDiyQuery")
      } else {
        this.crud.toQuery()
      }
    },
    toDiyResetQuery() {
      let _this = this
      if (_this.$listeners["toDiyResetQuery"]) {
        _this.$emit("toDiyResetQuery")
      } else {
        this.crud.resetQuery()
      }
    }
  }
}
</script>
<style lang="scss" scoped></style>

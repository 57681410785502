export const contractColumns = [
  {
    title: "合同编号",
    width: 180,
    align: "center",
    ellipsis: true,
    resizable: true,
    scopedSlots: { customRender: "contractNo" }
    // dataIndex: "contractNo",
    // key: "contractNo"
  },
  {
    title: "签约日期",
    width: 120,
    align: "center",
    ellipsis: true,
    resizable: true,
    // dataIndex: "insertTime",
    scopedSlots: { customRender: "insertTime" }
    // key: "contactName"
  },
  {
    title: "交付日期",
    width: 120,
    align: "center",
    ellipsis: true,
    resizable: true,
    // dataIndex: "releasedTime",
    scopedSlots: { customRender: "releasedTime" }
    // key: "creatorName"
  },
  {
    title: "销售负责人",
    width: 120,
    align: "center",
    ellipsis: true,
    resizable: true,
    dataIndex: "creator",
    // scopedSlots: { customRender: "changeTag" },
    key: "creator"
  },
  {
    title: "客户联系人",
    width: 120,
    align: "center",
    ellipsis: true,
    resizable: true,
    dataIndex: "contactName",
    key: "contactName"
  },
  {
    title: "合计金额",
    width: 100,
    align: "center",
    ellipsis: true,
    resizable: true,
    dataIndex: "totalPrice",
    // scopedSlots: { customRender: "tag" },
    key: "totalPrice"
  }
]

export const projectColumns = [
  {
    title: "产品编号",
    width: 200,
    align: "center",
    ellipsis: true,
    resizable: true,
    scopedSlots: { customRender: "projectNo" }
    // key: "projectNo",
    // dataIndex: "projectNo"
  },
  {
    title: "合同编号",
    width: 200,
    align: "center",
    ellipsis: true,
    resizable: true,
    // scopedSlots: { customRender: "contractNo" }
    dataIndex: "contractNo",
    key: "contractNo"
  },
  {
    title: "货品名称",
    width: 180,
    align: "center",
    ellipsis: true,
    resizable: true,
    key: "goodsName",
    dataIndex: "goodsName"
  },
  // {
  //   title: "货品规格",
  //   width: 180,
  //   align: "center",
  //   ellipsis: true,
  //   resizable: true,
  //   key: "goodsSpec",
  //   dataIndex: "goodsSpec"
  // },
  {
    title: "单价",
    width: 100,
    align: "center",
    ellipsis: true,
    resizable: true,
    dataIndex: "unitPrice",
    key: "unitPrice"
  },
  {
    title: "数量",
    width: 70,
    align: "center",
    ellipsis: true,
    resizable: true,
    dataIndex: "number",
    key: "number"
  },
  {
    title: "总价",
    width: 100,
    align: "center",
    ellipsis: true,
    resizable: true,
    dataIndex: "totalPrice",
    // scopedSlots: { customRender: "changeTag" },
    key: "totalPrice"
  },
  {
    title: "交付日期",
    width: 120,
    align: "center",
    ellipsis: true,
    resizable: true,
    // dataIndex: "releasedTime",
    scopedSlots: { customRender: "releasedTime" }
    // key: "creatorName"
  },
  {
    title: "支付状态",
    width: 100,
    align: "center",
    ellipsis: true,
    resizable: true,
    dataIndex: "paymentStatusName",
    // scopedSlots: { customRender: "changeTag" },
    key: "paymentStatusName"
  }
]

export const projectGoodsColumns = [
  {
    title: "产品编号",
    width: 120,
    align: "center",
    ellipsis: true,
    resizable: true,
    // dataIndex: "projectNo",
    key: "projectNo",
    scopedSlots: { customRender: "projectNo" }
  },
  {
    title: "货品名称",
    width: 180,
    align: "center",
    ellipsis: true,
    resizable: true,
    key: "goodsID",
    // dataIndex: "goodsID"
    scopedSlots: { customRender: "goodsID" }
  },
  {
    title: "货品规格",
    width: 180,
    align: "center",
    ellipsis: true,
    resizable: true,
    key: "goodsSpec",
    // dataIndex: "goodsSpec"
    scopedSlots: { customRender: "goodsSpec" }
  },
  {
    title: "货品类型",
    width: 180,
    align: "center",
    ellipsis: true,
    resizable: true,
    key: "categoryName",
    // dataIndex: "categoryName"
    scopedSlots: { customRender: "categoryName" }
  },
  {
    title: "单价",
    width: 100,
    align: "center",
    ellipsis: true,
    resizable: true,
    // dataIndex: "unitPrice",
    key: "unitPrice",
    scopedSlots: { customRender: "unitPrice" }
  },
  {
    title: "数量",
    width: 70,
    align: "center",
    ellipsis: true,
    resizable: true,
    // dataIndex: "number",
    key: "number",
    scopedSlots: { customRender: "number" }
  },
  {
    title: "总价",
    width: 100,
    align: "center",
    ellipsis: true,
    resizable: true,
    // dataIndex: "totalPrice",
    key: "totalPrice",
    scopedSlots: { customRender: "totalPrice" }
  },
  {
    title: "交付日期",
    width: 120,
    align: "center",
    ellipsis: true,
    resizable: true,
    scopedSlots: { customRender: "releasedTime" },
    key: 'releasedTime'
  },
  {
    title: "支付状态",
    width: 100,
    align: "center",
    ellipsis: true,
    resizable: true,
    scopedSlots: { customRender: "paymentStatus" },
    // dataIndex: "paymentStatusName",
    key: "paymentStatusName"
  },
  {
    title: "操作",
    width: 100,
    align: "center",
    ellipsis: true,
    resizable: true,
    // dataIndex: "operation",
    key: "operation",
    scopedSlots: { customRender: "operation" }
  }
]

// const renderContent = value => {
//   const obj = {
//     children: value,
//     attrs: {}
//   }
//   return obj
// }
export const receiptColumns = [
  {
    title: "收款单编号",
    // dataIndex: "date",
    key: "receiptNo",
    scopedSlots: { customRender: "receiptNo" }
  },
  {
    title: "金额",
    className: "amount",
    scopedSlots: { customRender: "amount" }
  },
  {
    title: "付款时间",
    className: "time",
    scopedSlots: { customRender: "time" }
  },
  {
    title: "操作",
    key: "operation",
    scopedSlots: { customRender: "operation" }
  },
]

export const invoiceColumns = [
  {
    title: "发票编号",
    key: "invoiceNum",
    scopedSlots: { customRender: "invoiceNum" }
  },
  {
    title: "开票金额",
    key: "amount",
    scopedSlots: { customRender: "amount" }
  },
  {
    title: "开票时间",
    key: "time",
    scopedSlots: { customRender: "time" }
  },
  {
    title: "上传文件",
    key: "uploadFile",
    scopedSlots: { customRender: "uploadFile" }
  },
  // {
  //   title: '操作',
  //   key:'operation',
  //   scopedSlots: {customRender: 'operation'}
  // }
]
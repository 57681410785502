<template>
  <div class="app-container">
    <el-collapse :value="showCollapse" class="my-collapse">
      <el-collapse-item
        :disabled="checkOperateDisable('获取客户数据分析')"
        :name="1"
        title="获取客户数据分析"
      >
        <div style="margin: 5px;margin-left: 20px">
          <el-radio-group v-model="radio1" @change="changeTrendType">
            <el-radio-button label="month">月</el-radio-button>
            <el-radio-button label="season">季度</el-radio-button>
            <el-radio-button label="year">年</el-radio-button>
          </el-radio-group>
        </div>
        <div class="header-chart">
          <a-card class="card11" hoverable title="指派状态占比">
            <span slot="extra">截止{{ parseTime(new Date()) }}</span>
            <div class="_box">
              <div class="_t"></div>
              <div ref="chart1" style="width:100%;height:400px"></div>
            </div>
          </a-card>

          <a-card
            class="card31"
            hoverable
            title="客户收款单走势"
          >
            <span slot="extra">截止{{ parseTime(new Date()) }}</span>
            <div ref="chart3_1" style="width:100%;height:300px"></div>
          </a-card>
        </div>
      </el-collapse-item>
    </el-collapse>
    <div class="head-container">
      <a-form layout="inline">
        <a-row :gutter="40">
          <a-col :md="5" :sm="24">
            <a-form-item label="收款时间">
              <a-range-picker
                v-model="crud.query.receiptTime"
                :allowClear="true"
                allow-clear
                format="YYYY-MM-DD"
              />
            </a-form-item>
          </a-col>
          <a-col :md="5" :sm="24">
            <a-form-model-item label="收款方式" prop="receiptType">
              <a-select v-model="crud.query.receiptType"  placeholder="请选择支付方式">
                <a-select-option v-for="item in receiptType" :key="item.key" :value="Number(item.key)">
                  {{item.value}}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <template v-if="crud.advanced">
          </template>

          <a-col :md="9"></a-col>

          <a-col :md="5" :sm="24">
            <SearchOptions
              :toggleShowAdvance="false"
              :queryFuncName="queryFuncName"
              style="width: 100%"
              @toDiyQuery="toDiyQuery"
              @toDiyResetQuery="toDiyResetQuery"
            />
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div class="table-container">
      <Operations tableName="收款单" @toDiyAdd="toDiyAdd"/>
      <a-spin :spinning="crud.loading">
        <a-table
          :columns="crud.columns"
          :data-source="crud.data"
          :pagination="false"
          :row-selection="{
            selectedRowKeys: crud.selections,
            onChange: crud.selectionChangeHandler,
            getCheckboxProps: getCheckboxProps
          }"
          :scroll="{ y: 1000 }"
          rowKey="contactReceiptID"
        >
          <template slot="date" slot-scope="record">
            {{ fullTimeFilterYYYYMMDD(record.receiptTime) }}
          </template>
          <template slot="amount" slot-scope="record">
            {{ record.receiptMoney }}
          </template>
          <template slot="time" slot-scope="record">
            {{ fullTimeFilter(record.receiptTime) }}
          </template>
          <template slot="receiptTypeName" slot-scope="record">
            {{ record.receiptTypeName }}
          </template>
          <template slot="contractNo" slot-scope="record">
            {{ record.contractNo }}
          </template>
          <template slot="operation" slot-scope="record">
            <a-button type="link" @click="toInfo(record)">
              详情
            </a-button>
          </template>
        </a-table>
      </a-spin>
      <Pagination />
    </div>
    <div class="form-container">
      <a-drawer
        :title="crud.status.title"
        :visible="
          (crud.status.cu > 0 || crud.status.in > 0) && crud.status.add == 0
        "
        :width="1200"
        placement="right"
        @close="crud.cancelCU()"
      >
        <a-tabs
          :activeKey="activeKey"
          type="card"
          @change="
            key => {
              activeKey = key
            }
          "
        >
          <a-tab-pane :key="1" tab="收款单相关信息">
            <a-card :body-style="{ padding: '0px' }" :bordered="false">
              <a-form-model
                ref="updateForm"
                :class="
                  crud.status.in > 0 && crud.status.cu === 0
                    ? 'disabled-form'
                    : ''
                "
                :label-col="{ span: 6 }"
                :wrapper-col="{ span: 18 }"
                labelAlign="left"
              >
                <CurrentReceiptInfo ref="currentReceiptInfo"></CurrentReceiptInfo>
              </a-form-model>
            </a-card>
            <div
              style="display: flex;justify-content: flex-start;vertical-align: middle;align-items: center"
            >
              <h3 style="color: #1890ff;font-size: 14px;margin-left: 10px">该合同关联的收款单列表</h3>
            </div>
<!--            <Operations tableName="合同关联收款单列表" />-->
            <a-spin :spinning="crud.loading">
              <a-table
                :columns="crud.columns"
                :data-source="currentContractReceipt"
                :pagination="false"
                rowKey="contactReceiptID"
              >
                <template slot="date" slot-scope="record">
                  {{ fullTimeFilterYYYYMMDD(record.receiptTime) }}
                </template>
                <template slot="amount" slot-scope="record">
                  {{ record.receiptMoney }}
                </template>
                <template slot="time" slot-scope="record">
                  {{ fullTimeFilter(record.receiptTime).substring(10) }}
                </template>
                <template slot="receiptTypeName" slot-scope="record">
                  {{ record.receiptTypeName }}
                </template>
                <template slot="contractNo" slot-scope="record">
                  {{ record.contractNo }}
                </template>
                <template slot="operation" slot-scope="record">
                  <a-button type="link" @click="contactToInfo(record)">
                    详情
                  </a-button>
                </template>
              </a-table>
            </a-spin>
          </a-tab-pane>
        </a-tabs>
        <div class="bottom-button-area">
          <a-button
            v-if="isEdit"
            class="bottom-button"
            type="primary"
            @click="toEdit"
          >
            编辑
          </a-button>
          <div class="edit" v-else>
            <a-button
              class="bottom-button1"
              @click="toCancelEdit"
            >
              取消
            </a-button>

            <a-button
              class="bottom-button2"
              type="primary"
              @click="toSubmitEdit"
            >
              提交
            </a-button>
          </div>
        </div>
      </a-drawer>
      <a-modal
        :footer="null"
        :visible="visible"
        title="收款单详情"
        width="50%"
        @cancel="handleCancel"
      >
        <CurrentReceiptInfo ref="reCurrentReceiptInfo"></CurrentReceiptInfo>
        <div class="bottom-button-area">
          <a-button
            v-if="contractRelationIsEdit"
            class="bottom-button"
            type="primary"
            @click="contractRelationToEdit"
          >
            编辑
          </a-button>
          <div class="edit" v-else>
            <a-button
              class="bottom-button1"
              @click="contractRelationToCancelEdit"
            >
              取消
            </a-button>

            <a-button
              class="bottom-button2"
              type="primary"
              @click="contractRelationToSubmitEdit"
            >
              提交
            </a-button>
          </div>
        </div>
      </a-modal>
    </div>

    <a-modal
      title="新增收款单"
      :visible="toShowFlag"
      width="60%"
      @ok="toSubAdd"
      @cancel="cancelAdd"
    >
      <ReceiptTabPaneAdd ref="receiptTabPaneAdd"/>
    </a-modal>
  </div>
</template>

<script>
import {
  GetAllContact,
  GetAllCustomer,
  GetAllProject,
  GetDictionary
} from "@/api/public-api"
import CurrentReceiptInfo from "@/components/customerInfo/current-receipt-Info.vue"
import { GetReceiptByContractId, editReceipt, getType, getTypeCollect, add } from "@/api/customer/receipt";
import Pagination from "@/components/Crud/Pagination"
import SearchOptions from "@/components/Crud/SearchOptions"
import CRUD, { form, header, presenter } from "@crud/crud"
import Api from "@/api/customer/receiptCrud"
import { receiptColumns } from "@/views/customer/receipt-list"
import { ADMIN_ROLE_ID } from "@/config"
import { fullTimeFilter, fullTimeFilterYYYYMMDD } from "@/utils/utils"
import store from "@/store"
import {
  checkMultiTypeOperateDisable,
  checkOperateDisable
} from "@/router/permission"
import {
  drawColumnsAndTrendsChart,
  drawGeneralStatisticsChart
} from "@/views/customer/char"
import { GetProjectGoodsAnalysisList } from "@/api/project";

import ReceiptTabPaneAdd from "@/components/customerInfo/receipt-tab-pane-add.vue"
import Operations from "@/components/Crud/Operations"



const defaultForm = {
  contactName: "",
  contactTel: ""
}
export default {
  name: "Receipt",
  // presenter 生成crud对象
  mixins: [presenter(), header(), form(defaultForm)],
  cruds() {
    return CRUD({
      title: "收款单",
      roleIDs: [],
      columns: receiptColumns,
      crudMethod: { ...Api },
      idField: "contactReceiptID" // 设置每一条记录的唯一标失
    })
  },
  data() {
    return {
      contractRelationIsEdit: true,
      isEdit: true,
      roleIDs: [],
      visible: false,
      currentContractReceipt: [],
      receiptColumns,
      allCustomerMap: [],
      allProjectMap: [],
      activeKey: 1,
      receiptType: [],
      queryFuncName: "查询收款单列表",

      showCollapse: [1],
      radio1: 'month',

      toShowFlag: false
    }
  },
  computed: {},
  components: {
    SearchOptions,
    Pagination,
    CurrentReceiptInfo,
    ReceiptTabPaneAdd,
    Operations
  },
  methods: {
    contractRelationToSubmitEdit(){
      this.$refs.reCurrentReceiptInfo.$refs.ruleForm.validate(async valid => {
        if (valid) {
          const submitInfo = this.$refs.reCurrentReceiptInfo.currentReceipt
          const editObj = await editReceipt({
            contractID: submitInfo.contractId,
            contractReceiptID:submitInfo.contactReceiptID,
            receiptMoney:submitInfo.receiptMoney,
            receiptTime:submitInfo.receiptTime,
            receiptType:submitInfo.receiptType
          })
          if(editObj.code === 14) {
            this.contractRelationIsEdit = true

          }
        } else {
          return false;
        }
      });
    },
    contractRelationToCancelEdit(){
      this.$refs.reCurrentReceiptInfo.disabled = true
      this.contractRelationIsEdit = true
    },
    contractRelationToEdit(){
      this.contractRelationIsEdit = false
      this.$refs.reCurrentReceiptInfo.disabled = false
    },
    toSubmitEdit(){
      this.$refs.currentReceiptInfo.$refs.ruleForm.validate(async valid => {
        if (valid) {
          const submitInfo = this.$refs.currentReceiptInfo.currentReceipt
          const editObj = await editReceipt({
            contractID: submitInfo.contractId,
            contractReceiptID:submitInfo.contactReceiptID,
            receiptMoney:submitInfo.receiptMoney,
            receiptTime:submitInfo.receiptTime,
            receiptType:submitInfo.receiptType
          })
          if(editObj.code === 14) {
            this.isEdit = true

          }
        } else {
          return false;
        }
      });
    },
    toCancelEdit(){
      this.$refs.currentReceiptInfo.disabled = true
      this.isEdit = true
    },
    toEdit(){
      this.isEdit = false
      this.$refs.currentReceiptInfo.disabled = false
    },
    contactToInfo(record) {
      this.visible = true
      this.$nextTick(()=>{
        this.$refs.reCurrentReceiptInfo.currentReceipt = record
      })
    },
    toInfo(record) {
      console.log(record)
      const that = this
      this.$nextTick( ()=>{
        that.crud.toInfo(record)
        that.$nextTick(async()=>{
          that.$refs.currentReceiptInfo.currentReceipt = record
          const currentContractObj = await GetReceiptByContractId({
            contractID: that.form.contractId
          })
          that.currentContractReceipt = currentContractObj.result
        })

      })
    },
    getCheckboxProps(record) {
      if (record.roleID === 0 && !this.roleIDs.includes(ADMIN_ROLE_ID)) {
        return { props: { disabled: true } }
      }
      return { props: { disabled: false } }
    },
    filterCategory: function(value, option) {
      return option.componentOptions.children[0].text.indexOf(value) >= 0
    },
    fullTimeFilterYYYYMMDD,
    fullTimeFilter,
    checkOperateDisable,
    checkMultiTypeOperateDisable,
    handleCancel() {
      this.visible = false
    },

    drawChart: drawGeneralStatisticsChart,
    drawColumnsAndTrendsChart: drawColumnsAndTrendsChart,
    async changeTrendType(val){
      val ? val : val='month'
      const getTypeCollectObj = await getType({analysisType :val})
      const getTypeObj = await getTypeCollect({analysisType :val})
      if(getTypeObj.code === 0) {
        this.drawChart('chart1',
          getTypeObj.result.map((item)=>({
            value:item.count,
            name: item.receiptType === 1 ? '电汇' : '银行承兑'
          }))
        )
      }
      if(getTypeCollectObj.code === 0) {
        this.drawColumnsAndTrendsChart('chart3_1', getTypeCollectObj.result.map((item=>({
          dateNo:item.key,
          count:item.count
        }))))
      }
    },
    async toDiyQuery() {
      const that = this
      that.crud.toQuery()
      if (!this.checkOperateDisable("项目产品数据统计")) {
        const queryRes = await GetProjectGoodsAnalysisList({
          ...that.crud.query
        })
        if (queryRes.code === 0) {
          that.projectGoodsNumList = (
            queryRes.result.goodsNumAnalysisList || []
          ).map((item, index) => ({ index: index + 1, ...item }))
          that.projectGoodsPriceList = (
            queryRes.result.goodsPriceAnalysisList || []
          ).map((item, index) => ({ index: index + 1, ...item }))
        }
      }
    },
    async toDiyResetQuery() {
      const that = this
      that.crud.resetQuery()
      if (!this.checkOperateDisable("项目产品数据统计")) {
        const queryRes = await GetProjectGoodsAnalysisList()
        if (queryRes.code === 0) {
          that.projectGoodsNumList = (
            queryRes.result.goodsNumAnalysisList || []
          ).map((item, index) => ({ index: index + 1, ...item }))
          that.projectGoodsPriceList = (
            queryRes.result.goodsPriceAnalysisList || []
          ).map((item, index) => ({ index: index + 1, ...item }))
        }
      }
    },

    toDiyAdd(){
      this.toShowFlag = true
    },

    toSubAdd(){
      const that = this.$refs.receiptTabPaneAdd
      that.$refs.ruleForm.validate(async valid => {
        if (valid) {
          const result = await add(that.receiptAdd)
          if(result.code===12){
            that.receiptAdd = {}
            this.toShowFlag = false
            this.crud.refresh()
          }
        } else {
          return false;
        }
      });
    },
    cancelAdd(){
      this.$refs.receiptTabPaneAdd.$refs.ruleForm.clearValidate()
      this.toShowFlag = false
    }
  },
  async created() {
    const receiptTypeObj = await GetDictionary({
      typeName: "contractReceiptType"
    })
    this.receiptType = receiptTypeObj.result
    // 关闭下载功能
    this.crud.optShow.download = false
    this.crud.optShow.edit = false
    // 显示以下按钮
    this.crud.permission = {
      add: !checkOperateDisable("新增收款单"),
      del: !checkOperateDisable("删除收款单"),
      edit: !checkOperateDisable("修改收款单")
      // download: true
    }

    const [
      getAllContactRes,
      getAllCustomerRes,
      getAllProjectRes,
      getUserTypeRes,
      getCustomerTagRes
    ] = await Promise.all([
      GetAllContact(),
      GetAllCustomer(),
      GetAllProject(),
      GetDictionary({ typeName: "customerUserType" }),
      GetDictionary({ typeName: "customerTag" })
    ])

    if (getAllContactRes.code === 0) {
      this.allContactMap = getAllContactRes.result
    }

    if (getAllCustomerRes.code === 0) {
      this.allCustomerMap = getAllCustomerRes.result
    }

    if (getAllProjectRes.code === 0) {
      this.allProjectMap = getAllProjectRes.result
    }

    if (getUserTypeRes.code === 0) {
      this.allUserTypeMap = getUserTypeRes.result
    }

    if (getCustomerTagRes.code === 0) {
      this.allCustomerTagMap = getCustomerTagRes.result
    }

    this.roleIDs = store.getters.roleIDs
    this.changeTrendType()

  }
}
</script>
<style lang="scss" scoped>
.ant-table-striped /deep/ .table-striped td {
  background-color: #fcfcfc;
}
.my-collapse {
  margin-bottom: 20px;

  .el-collapse-item {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 5px;

    /deep/ .el-collapse-item__content {
      padding-bottom: 0;
    }

    /deep/ .el-collapse-item__header {
      padding-left: 20px;
    }

    .item-content {
      background-color: #f0f2f5;
      padding: 10px;
    }
  }

  .header-chart {
    width: 100%;
    margin-top: 10px;

    .card11 {
      float: left;
      border: none;
      border-radius: 10px;
      box-shadow: 4px 4px 8px 0px rgba(201, 201, 201, 0.5);
      width: 36%;
      height: 430px;
    }

    .card22 {
      float: left;
      border: none;
      border-radius: 10px;
      box-shadow: 4px 4px 8px 0px rgba(201, 201, 201, 0.5);
      margin-bottom: 10px;
      margin-left: 1%;
      width: 24%;
      height: 430px;
    }
  }

  .card31 {
    margin-left: 50px;
    display: inline-block;
    border: none;
    border-radius: 10px;
    box-shadow: 4px 4px 8px 0px rgba(201, 201, 201, 0.5);
    //margin-bottom: 10px;
    width: 59%;
    height: 430px;
  }

  .card32 {
    display: inline-block;
    border: none;
    border-radius: 10px;
    box-shadow: 4px 4px 8px 0px rgba(201, 201, 201, 0.5);
    margin-bottom: 10px;
    margin-left: 1%;
    width: 49%;
    height: 380px;
  }
}

.bottom-button-area {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center; /* 水平居中 */
  padding-top: 200px;

  .bottom-button {
    width: 200px;
    line-height: 30px;
    height: 30px;
  }

  .edit {
    width: 500px;
    height: 40px;

    .bottom-button1 {
      display: inline-block;
      float: left;
      width: 200px;
      line-height: 30px;
      height: 30px;
      margin-right: 100px;
    }

    .bottom-button2 {
      display: inline-block;
      float: left;
      width: 200px;
      line-height: 30px;
      height: 30px;
    }
  }
}
</style>
<template>
  <div>
    <a-tree
      v-model="functionSelect"
      :auto-expand-parent="autoExpandParent"
      :tree-data="resolvePermissionList"
      checkable
      @check="functionSelectCheck"
    />
  </div>
</template>

<script>
import { BASE_OPERATE_TYPE } from "@/config"
import { GetRoleFunctions } from "@/api/system/menu"
import { GetUserFunctions } from "@/api/user"

export default {
  name: "UserFunctions",
  components: {},
  props: {
    userID: {
      type: Number,
      default: () => 0
    },
    roleID: {
      type: Number,
      default: () => 0
    }
  },
  data() {
    return {
      BASE_OPERATE_TYPE,
      functionSelect: [], // 功能权限列表
      autoExpandParent: true, // 展开父级TREE
      resolvePermissionList: [], // 处理完的权限列表树
      halfFunctionSelect: [],
      permissionList: [],
      PPpermissionList: [],
      operateType: BASE_OPERATE_TYPE.INFO
    }
  },
  async mounted() {
    const getAllRoleFunctionRes = await GetRoleFunctions({ roleId: 1 })
    this.PPpermissionList = this.jsonToArray(
      JSON.parse(
        JSON.stringify(
          [...getAllRoleFunctionRes.result].sort((a, b) => a.index - b.index)
        )
      )
    )
    this.permissionList = [...getAllRoleFunctionRes.result].sort(
      (a, b) => a.index - b.index
    )
  },
  watch: {
    operateType: {
      handler(value) {
        if (value === BASE_OPERATE_TYPE.INFO) {
          this.resolvePermissionList = this.resolvePermission(
            this.permissionList
          )
        }
      },
      deep: true
    }
  },

  methods: {
    async showData(userID, roleID, operateType = BASE_OPERATE_TYPE.INFO) {
      this.$parent.userID = userID
      this.$parent.roleID = roleID
      this.operateType = operateType

      if (!userID) {
        this.$message.error("请先创建用户")
        return
      }

      this.clearChecked()

      let getUserFunctions
      if (operateType === BASE_OPERATE_TYPE.ADD) {
        getUserFunctions = await GetRoleFunctions({ roleId: roleID })
      } else {
        getUserFunctions = await GetUserFunctions({ userID })
      }

      const resultList = [...getUserFunctions.result]
      // this.permissionList = [...resultList].sort((a, b) => a.index - b.index)
      this.$nextTick(() => {
        this.resolvePermissionList = this.resolvePermission(this.permissionList)
        this.choosePermisson([...resultList].sort((a, b) => a.index - b.index))
        this.filterSelect()
      })
    },
    // 处理权限未勾选
    resolvePermission(list) {
      let that = this
      return list.map(item => {
        let obj = {
          title: item.functionName,
          key: item.functionID
        }
        if (item.parentFunctionCode === 0 && item.systemCode === 1) {
          obj["disableCheckbox"] = true
        }
        if (that.operateType === BASE_OPERATE_TYPE.INFO) {
          obj["disableCheckbox"] = true
        }
        if (item.children && item.children.length) {
          obj["children"] = that.resolvePermission(item.children)
        }
        return obj
      })
    },
    // 勾选
    choosePermisson(list) {
      let that = this
      list.map(item => {
        let childLength = that.getChildren(item)
        if (childLength < (item.children === null ? 0 : item.children.length)) {
          this.halfFunctionSelect.push(item.functionID)
          this.getParentFunctionID(item.parentFunctionCode)
        } else {
          this.functionSelect.push(item.functionID)
        }
        if (item.children && item.children.length) {
          that.choosePermisson(item.children)
        }
      })
    },
    getChildren(item) {
      return this.PPpermissionList.filter(it => {
        return it.parentFunctionCode === item.functionCode
      }).length
    },

    jsonToArray(nodes) {
      let that = this
      let r = []
      if (Array.isArray(nodes)) {
        for (let i = 0, l = nodes.length; i < l; i++) {
          r.push(nodes[i]) // 取每项数据放入一个新数组
          if (
            Array.isArray(nodes[i]["children"]) &&
            nodes[i]["children"].length > 0
          )
            // 若存在children则递归调用，把数据拼接到新数组中，并且删除该children
            r = r.concat(that.jsonToArray(nodes[i]["children"]))
          delete nodes[i]["children"]
        }
      }
      return r
    },
    getParentFunctionID(functionCode) {
      let list = this.PPpermissionList.filter(it => {
        return it.functionCode === functionCode
      })
      if (list.length !== 0) {
        this.halfFunctionSelect.push(list[0].functionID)
        if (list[0].parentFunctionCode !== "0") {
          this.getParentFunctionID(list[0].parentFunctionCode)
        }
      }
    },
    filterSelect() {
      for (let i = 0; i < this.functionSelect.length; i++) {
        const element = this.functionSelect[i]
        for (let j = 0; j < this.halfFunctionSelect.length; j++) {
          const elementj = this.halfFunctionSelect[j]
          if (elementj === element) {
            this.functionSelect.splice(i, 1)
            i = i - 1
          }
        }
      }
    },

    updatePermission() {
      const that = this
      that.operateType = BASE_OPERATE_TYPE.EDIT
      that.$nextTick(() => {
        that.resolvePermissionList = that.resolvePermission(that.permissionList)
      })
    },
    cancelSavePermission() {
      this.operateType = BASE_OPERATE_TYPE.INFO
      this.showData(this.userID, this.roleID)
    },

    clearChecked() {
      this.functionSelect = []
      this.halfFunctionSelect = []
    },

    functionSelectCheck(checkedKeys, e) {
      this.halfFunctionSelect = e.halfCheckedKeys
    },
    getSelectedFunctionIDs() {
      let functionIDs = []
      const functionIdList = [
        ...this.functionSelect,
        ...this.halfFunctionSelect
      ]
      for (let i = 0; i < functionIdList.length; i++) {
        functionIDs.push(functionIdList[i])
      }
      return functionIDs
    }
  }
}
</script>

<style lang="scss" scoped>
._bottom {
  display: flex;
  justify-content: flex-end;
}
</style>

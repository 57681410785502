<template>
  <div class="app-container">
    <el-collapse :value="showCollapse" class="my-collapse" @change="openChart">
      <el-collapse-item
        :disabled="checkOperateDisable('获取客户数据分析')"
        :name="1"
        title="获取客户数据分析"
      >
        <div class="header-chart">
          <a-card class="card11" hoverable title="指派状态占比">
            <!-- <span slot="extra">截止{{ parseTime(new Date()) }}</span> -->
            <div class="title-box">
              <span>日期查询:</span>
              <a-range-picker
                v-model="chartTime"
                :allowClear="true"
                :show-time="showTime()"
                allow-clear
                format="YYYY-MM-DD HH:mm:ss"
              />
            </div>

            <div class="_box">
              <div class="_t"></div>
              <div ref="chart1" style="width:100%;height:400px"></div>
            </div>
          </a-card>
          <a-card class="card22" hoverable title="客户类型占比">
            <div class="title-box">
              <!-- <span slot="extra">截止{{ parseTime(new Date()) }}</span> -->
              <span>日期查询:</span>
              <a-range-picker
                v-model="chartTime"
                :allowClear="true"
                :show-time="showTime()"
                allow-clear
                format="YYYY-MM-DD HH:mm:ss"
              />
            </div>
            <div class="_box">
              <div class="_t"></div>
              <div ref="chart2" style="width:100%;height:400px"></div>
            </div>
          </a-card>
          <a-card class="card22" hoverable title="访问方式占比">
            <div class="title-box">
              <!-- <span slot="extra">截止{{ parseTime(new Date()) }}</span> -->
              <span>日期查询:</span>
              <a-range-picker
                v-model="chartTime"
                :allowClear="true"
                :show-time="showTime()"
                allow-clear
                format="YYYY-MM-DD HH:mm:ss"
              />
            </div>
            <div class="_box">
              <div class="_t"></div>
              <div ref="chart3" style="width:100%;height:400px"></div>
            </div>
          </a-card>
          <a-card class="card22" hoverable title="推广平台占比">
            <div class="title-box">
              <!-- <span slot="extra">截止{{ parseTime(new Date()) }}</span> -->
              <span>日期查询:</span>
              <a-range-picker
                v-model="chartTime"
                :allowClear="true"
                :show-time="showTime()"
                allow-clear
                format="YYYY-MM-DD HH:mm:ss"
              />
            </div>
            <div class="_box">
              <div class="_t"></div>
              <div ref="chart4" style="width:100%;height:400px"></div>
            </div>
          </a-card>
        </div>
      </el-collapse-item>
      <el-collapse-item
        :disabled="checkOperateDisable('客户趋势分析')"
        :name="2"
        title="客户趋势分析"
      >
        <a-card
          v-if="!checkOperateDisable('获取客户增长趋势分析')"
          class="card31"
          hoverable
          title="客户增长走势"
        >
          <span slot="extra">截止{{ parseTime(new Date()) }}</span>
          <div ref="chart3_1" style="width:100%;height:300px"></div>
        </a-card>
        <a-card
          v-if="!checkOperateDisable('获取客户领用增长趋势分析')"
          class="card32"
          hoverable
          title="客户领用走势"
        >
          <span slot="extra">截止{{ parseTime(new Date()) }}</span>
          <div ref="chart3_2" style="width:100%;height:300px"></div>
        </a-card>
      </el-collapse-item>
      <el-collapse-item title="今日需回访客户"> </el-collapse-item>
    </el-collapse>
    <div class="head-container">
      <a-form layout="inline">
        <a-row :gutter="48">
          <a-col :md="6" :sm="24">
            <a-form-item label="复合查询">
              <a-input
                v-model="crud.query.compoundQuery"
                :allowClear="true"
                placeholder="请输入查询条件"
              />
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="公司名称">
              <a-input
                v-model="crud.query.customerName"
                :allowClear="true"
                placeholder="请输入公司名称"
              />
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="联系人电话">
              <a-input
                v-model="crud.query.contactTel"
                :allowClear="true"
                placeholder="请输入联系人电话"
              />
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="客户编号">
              <a-input
                v-model="crud.query.customerNo"
                :allowClear="true"
                placeholder="请输入客户编号"
              />
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="客户状态">
              <a-select
                v-model="crud.query.tag"
                :allowClear="true"
                :filterOption="filterCategory"
                placeholder="请选择客户状态"
                show-search
              >
                <a-select-option
                  v-for="item in [...allCustomerTagMap]"
                  :key="Number(item.key)"
                  :value="Number(item.key)"
                  >{{ item.value }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <template v-if="crud.advanced">
            <a-col :md="6" :sm="24">
              <a-form-item label="指派状态">
                <a-select
                  v-model="crud.query.assignType"
                  :allowClear="true"
                  :filterOption="filterCategory"
                  placeholder="请选择客户指派状态"
                  show-search
                >
                  <a-select-option
                    v-for="item in [...allCustomerAssignTypeMap]"
                    :key="Number(item.key)"
                    :value="Number(item.key)"
                    >{{ item.value }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :md="6" :sm="24">
              <a-form-item label="公司行业">
                <a-select
                  v-model="crud.query.business"
                  :allowClear="true"
                  :filterOption="filterCategory"
                  placeholder="请选择客户分类"
                  show-search
                >
                  <a-select-option
                    v-for="item in [...allCustomerBusinessMap]"
                    :key="Number(item.key)"
                    :value="Number(item.key)"
                    >{{ item.value }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :md="6" :sm="24">
              <a-form-item label="公司性质">
                <a-select
                  v-model="crud.query.nature"
                  :allowClear="true"
                  :filterOption="filterCategory"
                  placeholder="请选择公司性质"
                  show-search
                >
                  <a-select-option
                    v-for="item in [...allCustomerNatureMap]"
                    :key="Number(item.key)"
                    :value="Number(item.key)"
                    >{{ item.value }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :md="6" :sm="24">
              <a-form-item label="访问方式">
                <a-select
                  v-model="crud.query.accessType"
                  :allowClear="true"
                  :filterOption="filterCategory"
                  placeholder="请选择访问方式"
                  show-search
                >
                  <a-select-option
                    v-for="item in [...allCustomerAccessTypeMap]"
                    :key="Number(item.key)"
                    :value="Number(item.key)"
                    >{{ item.value }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :md="6" :sm="24">
              <a-form-item label="来源关键字">
                <a-select
                  v-model="crud.query.keywordID"
                  :allowClear="true"
                  :filterOption="filterCategory"
                  placeholder="请选择来源关键字"
                  show-search
                >
                  <a-select-option
                    v-for="item in [...allKeywordMap]"
                    :key="Number(item.keywordID)"
                    :value="Number(item.keywordID)"
                    >{{ item.value }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :md="6" :sm="24">
              <a-form-item label="来源平台">
                <a-select
                  v-model="crud.query.sourcePlatformID"
                  :allowClear="true"
                  :filterOption="filterCategory"
                  placeholder="请选择来源平台"
                  show-search
                >
                  <a-select-option
                    v-for="item in [...allSourcePlatformMap]"
                    :key="Number(item.sourcePlatformID)"
                    :value="Number(item.sourcePlatformID)"
                    >{{ item.platformName }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :md="6" :sm="24">
              <a-form-item label="省">
                <a-input
                  v-model="crud.query.province"
                  :allowClear="true"
                  placeholder="请输入公司所属省"
                />
              </a-form-item>
            </a-col>

            <a-col :md="6" :sm="24">
              <a-form-item label="市">
                <a-input
                  v-model="crud.query.city"
                  :allowClear="true"
                  placeholder="请输入公司所属市"
                />
              </a-form-item>
            </a-col>

            <a-col :md="6" :sm="24">
              <a-form-item label="区">
                <a-input
                  v-model="crud.query.area"
                  :allowClear="true"
                  placeholder="请输入公司所属区"
                />
              </a-form-item>
            </a-col>

            <a-col :md="6" :sm="24">
              <a-form-item label="详细地址">
                <a-input
                  v-model="crud.query.address"
                  :allowClear="true"
                  placeholder="请输入公司详细地址"
                />
              </a-form-item>
            </a-col>

            <a-col :md="6" :sm="24">
              <a-form-item label="业务人员姓名">
                <a-select
                  v-model="crud.query.userID"
                  :allowClear="true"
                  :filterOption="filterCategory"
                  :not-found-content="null"
                  :show-arrow="false"
                  placeholder="请选择业务员"
                  show-search
                >
                  <a-select-option
                    v-for="item in allUserListMap"
                    :key="item.userID"
                    :value="item.userID"
                    >{{ item.displayName }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :md="6" :sm="24"></a-col>
            <!-- <a-col :md="6" :sm="24"></a-col> -->
          </template>
          <a-col :md="24" :sm="24">
            <SearchOptions :queryFuncName="queryFuncName" style="width: 100%" />
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div class="table-container">
      <Operations tableName="客户" @toDiyAdd="toDiyAdd" />
      <a-spin :spinning="crud.loading">
        <a-table
          style="width: 100%"
          @change="onChange"
          :bordered="true"
          :columns="crud.columns"
          :data-source="crud.data"
          :pagination="false"
          :row-class-name="
            (_record, index) => (index % 2 === 1 ? 'table-striped' : null)
          "
          :row-selection="{
            selectedRowKeys: crud.selections,
            onChange: crud.selectionChangeHandler
          }"
          :scroll="{ x: 3650 }"
          class="ant-table-striped"
          empty-text="-"
          rowKey="customerID"
          @resizeColumn="
            (w, col) => {
              col.width = w
            }
          "
        >
          <template slot="customerName" slot-scope="record">
            <a
              v-if="
                !checkMultiTypeOperateDisable(record.userIDList, '查看客户详情')
              "
              @click="showCustomer(record)"
              >{{ record.customerName }}</a
            >
            <span v-else>
              {{ record.customerName }}
            </span>
          </template>

          <template slot="assignType" slot-scope="record">
            <el-tag
              :type="
                record.assignType === 1
                  ? ''
                  : record.assignType === 2
                  ? 'success'
                  : record.assignType === 3
                  ? 'danger'
                  : 'info'
              "
            >
              {{
                findCustomerValueByKey(
                  record.assignType,
                  allCustomerAssignTypeMap
                )
              }}
            </el-tag>
          </template>

          <!--          <template slot="users" slot-scope="record">-->
          <!--            {{ record.users.map(item => item.displayName).join(",") }}-->
          <!--          </template>-->

          <!--          <template slot="projects" slot-scope="record">-->
          <!--            {{ record.projects.length }}-->
          <!--          </template>-->

          <template slot="tag" slot-scope="record">
            {{ findCustomerValueByKey(record.tag, allCustomerTagMap) }}
          </template>
          <template slot="customerType" slot-scope="record">
            <span v-if="record.customerType">
              {{
                customerType.find(item => item.value == record.customerType).key
              }}
            </span>
          </template>
          <template slot="business" slot-scope="record">
            {{
              findCustomerValueByKey(record.business, allCustomerBusinessMap)
            }}
          </template>

          <template slot="nature" slot-scope="record">
            {{ findCustomerValueByKey(record.nature, allCustomerNatureMap) }}
          </template>

          <template slot="accessType" slot-scope="record">
            {{
              findCustomerValueByKey(
                record.accessType,
                allCustomerAccessTypeMap
              )
            }}
          </template>

          <!--          <template slot="userName" slot-scope="record">-->
          <!--            <a-->
          <!--              v-if="-->
          <!--                !checkMultiTypeOperateDisable(-->
          <!--                  (record.users || []).map(item => item.userID),-->
          <!--                  '查看客户详情'-->
          <!--                )-->
          <!--              "-->
          <!--              @click="clickUser(record)"-->
          <!--              >{{ record.contacts.map(item => item.contactName).join(",") }}</a-->
          <!--            >-->
          <!--            <span v-else>-->
          <!--              {{ record.contacts.map(item => item.contactName).join(",") }}-->
          <!--            </span>-->
          <!--          </template>-->
          <template slot="userTel" slot-scope="record">
            <a
              v-if="
                !checkMultiTypeOperateDisable(record.userIDList, '查看客户详情')
              "
              @click="clickUser(record)"
              >{{ record.contacts.map(item => item.contactTel).join(",") }}</a
            >
            <span v-else>
              {{ record.contacts.map(item => item.contactTel).join(",") }}
            </span>
          </template>
          <template slot="insertTime" slot-scope="record">
            {{ filterTime(record.insertTime) }}
          </template>

          <template slot="updateTime" slot-scope="record">
            {{ filterTime(record.updateTime) }}
          </template>

          <template slot="operation" slot-scope="record">
            <a-button
              v-if="
                record.assignType === 1 ||
                  record.assignType === 3 ||
                  record.assignType === 2 ||
                  record.assignType === 4
              "
              :disabled="
                checkMultiTypeOperateDisable(record.userIDList, '指派客户')
              "
              type="link"
              @click="toAssign(record.customerID)"
            >
              指派
            </a-button>
            <a-button
              v-if="record.assignType === 1 || record.assignType === 3"
              :disabled="![1, 3].includes(record.assignType)"
              type="link"
              @click="toReceive(record.customerName, record.customerID)"
            >
              领取
            </a-button>
            <a-button
              v-if="record.assignType === 2"
              :disabled="
                checkMultiTypeOperateDisable(record.userIDList, '移入公海')
              "
              type="link"
              @click="toIntoOpenSea(record)"
            >
              移入公海
            </a-button>
            <a-button
              v-if="record.assignType === 4"
              :disabled="
                checkMultiTypeOperateDisable(record.userIDList, '审核移入公海')
              "
              type="link"
              @click="process(record)"
            >
              公海审核
            </a-button>
            <a-button
              :disabled="
                record.assignType === 2 &&
                  checkMultiTypeOperateDisable(
                    record.userIDList,
                    '查看客户详情'
                  )
              "
              type="link"
              @click="showCustomer(record)"
            >
              详情
            </a-button>
          </template>
        </a-table>
      </a-spin>
      <Pagination />
    </div>
    <div class="draw-container">
      <CustomerInfo
        ref="customerInfo"
        @close-drawer="closeDrawer"
      ></CustomerInfo>
      <ContractInfo
        ref="contractInfo"
        @close-drawer="closeDrawer"
      ></ContractInfo>
      <a-modal
        :afterClose="cancelAssign"
        :visible="assignForm.show"
        title="指派客户给业务员"
        wrapClassName="stationListDialog"
        @cancel="cancelAssign"
        @ok="assignCustomer"
      >
        <a-form-model
          ref="assignForm"
          :label-col="{ span: 6 }"
          :model="assignForm"
          :rules="assignRules"
          :wrapper-col="{ span: 18 }"
          labelAlign="left"
        >
          <a-form-model-item label="业务人员姓名" prop="userID">
            <a-select
              v-model="assignForm.userID"
              :allowClear="true"
              :filterOption="filterCategory"
              :not-found-content="null"
              :show-arrow="false"
              placeholder="请选择业务员"
              show-search
            >
              <a-select-option
                v-for="item in allUserListMap"
                :key="item.userID"
                :value="item.userID"
                >{{ item.displayName }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="用户类型" prop="userType">
            <a-select
              v-model="assignForm.userType"
              :allowClear="true"
              :filterOption="filterCategory"
              :not-found-content="null"
              :show-arrow="false"
              placeholder="请选择业务人员负责类型"
              show-search
            >
              <a-select-option
                v-for="item in allCustomerUserTypeMap"
                :key="Number(item.key)"
                :value="Number(item.key)"
                >{{ item.value }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-form-model>
      </a-modal>
      <a-modal
        :afterClose="cancelReceive"
        :visible="receiveForm.show"
        title="领取客户"
        wrapClassName="stationListDialog"
        @cancel="cancelReceive"
        @ok="receiveCustomer"
      >
        <p>
          您确定要领取客户:&nbsp;&nbsp;
          <span style="font-weight: bold;font-size:20px">
            {{ receiveForm.customerName }}
          </span>
          &nbsp;&nbsp;?
        </p>
        <a-form-model
          ref="receiveForm"
          :label-col="{ span: 6 }"
          :model="receiveForm"
          :rules="receiveRules"
          :wrapper-col="{ span: 18 }"
          labelAlign="left"
        >
          <a-form-model-item label="用户类型" prop="userType">
            <a-select
              v-model="receiveForm.userType"
              :allowClear="true"
              :filterOption="filterCategory"
              :not-found-content="null"
              :show-arrow="false"
              placeholder="请选择业务人员负责类型"
              show-search
            >
              <a-select-option
                v-for="item in allCustomerUserTypeMap"
                :key="Number(item.key)"
                :value="Number(item.key)"
                >{{ item.value }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-form-model>
      </a-modal>
      <a-modal
        :afterClose="cancelIntoOpenSea"
        :visible="intoOpenSeaForm.show"
        okText="提交审核"
        title="将客户移入公海"
        width="1000px"
        wrapClassName="stationListDialog"
        @cancel="cancelIntoOpenSea"
        @ok="intoOpenSea"
      >
        <a-form-model
          ref="intoOpenSeaForm"
          :label-col="{ span: 4 }"
          :model="intoOpenSeaForm"
          :rules="intoOpenSeaFormRules"
          :wrapper-col="{ span: 20 }"
          labelAlign="left"
        >
          <a-form-model-item label="客户名称" prop="customerName">
            {{ intoOpenSeaForm.customerName }}
          </a-form-model-item>
          <a-form-model-item label="移入公海原因" prop="content">
            <a-textarea
              v-model="intoOpenSeaForm.content"
              :allowClear="true"
              placeholder="请填写移入公海原因"
            />
          </a-form-model-item>

          <a-form-model-item label="审核流程名称" prop="approval"
            >{{ intoOpenSeaForm.approvalName }}
          </a-form-model-item>
          <a-form-model-item label="审核流程" prop="approval">
            <a-steps
              :current="0"
              size="small"
              status="error"
              style="margin-bottom: 25px;margin-top: 10px"
            >
              <a-step
                v-for="(el, index) in intoOpenSeaForm.approvalList"
                :key="index"
                :title="el"
              />
            </a-steps>
          </a-form-model-item>

          <a-form-model-item label="抄送">
            <span
              v-for="(item, index) in intoOpenSeaForm.ccList"
              :key="index"
              style="margin-right: 10px;color: #0b7ef8"
              >{{ item }}</span
            >
          </a-form-model-item>
        </a-form-model>
      </a-modal>
    </div>
  </div>
</template>

<script>
import ContractInfo from "@/components/customerInfo/contract-info.vue"
import SearchOptions from "@/components/Crud/SearchOptions"
import Operations from "@/components/Crud/Operations"
import Pagination from "@/components/Crud/Pagination"
import CRUD, { form, header, presenter } from "@crud/crud"
import { customerListColumns } from "./customer-list"
import Api, { CustomerIntoOpenSea, GetChart } from "@/api/customer/customer"
import {
  ACTIVITY_KEY_MAP,
  APPROVAL_TEMPLATE_ID_MAP,
  customerType
} from "@/config"
import { fullTimeFilter } from "@/utils/utils"
import store from "@/store"
import {
  GetAllKeyWord,
  GetAllSourcePlatform,
  GetAllUserList,
  GetDictionary
} from "@/api/public-api"
import { getWorkFlowData } from "@/api/approval/api"
import {
  drawColumnsAndTrendsChart,
  drawGeneralStatisticsChart,
  chartsResize
} from "@/views/customer/char"
import {
  checkMultiTypeOperateDisable,
  checkOperateDisable
} from "@/router/permission"
import CustomerInfo from "@/components/customerInfo/index"
import { PageSetting } from "@/config"
import dayjs from "dayjs"

const defaultForm = {}
export default {
  name: "Customer",
  mixins: [presenter(), header(), form(defaultForm)],
  components: {
    SearchOptions,
    Operations,
    Pagination,
    CustomerInfo,
    ContractInfo
  },
  cruds() {
    return CRUD({
      title: "企业客户",
      columns: customerListColumns,
      crudMethod: { ...Api },
      idField: "customerID" // 设置每一条记录的唯一标失
    })
  },
  data() {
    return {
      chartTime: [],
      headerHeight: PageSetting.headerHeight,
      queryFuncName: "查询客户列表",
      allKeywordMap: [],
      allSourcePlatformMap: [],
      allCustomerTagMap: [],
      allCustomerAccessTypeMap: [],
      allCustomerBusinessMap: [],
      allCustomerNatureMap: [],

      allCustomerUserTypeMap: [],
      allUserListMap: [],
      allCustomerAssignTypeMap: [],
      customerType,
      showCollapse: [],

      ACTIVITY_KEY_MAP,
      loading: false,
      addOk: false,
      addContactOk: false,

      assignForm: {
        customerID: 0,
        userType: 0,
        userID: 0,
        show: false
      },
      assignRules: {
        userID: [
          { required: true, message: "请选择指派业务员", trigger: "change" }
        ],
        userType: [
          { required: true, message: "请选择业务员类型", trigger: "change" }
        ]
      },

      receiveForm: {
        show: false,
        customerID: 0,
        customerName: "",
        userType: 0
      },
      receiveRules: {
        userType: [
          { required: true, message: "请选择业务员类型", trigger: "change" }
        ]
      },

      intoOpenSeaForm: {
        show: false,
        customerID: 0,
        content: "",
        customerName: "",
        approvalName: "",
        approvalList: [],
        ccList: []
      },
      intoOpenSeaFormRules: {
        content: [
          { required: true, message: "请填写移入公海原因", trigger: "blur" }
        ]
      }
    }
  },
  watch: {},
  async created() {
    /**
     * 设置按钮权限
     */
    let { code } = this.$route.meta
    this.permissionController()

    this.crud.optShow.edit = false
    // 显示以下按钮
    this.crud.permission = {
      add: code.includes("新增客户"),
      del: code.includes("删除客户"),
      edit: code.includes("修改客户")
      // download: true
    }

    this.addOk = false

    const that = this
    const promiseFuncArr = [
      GetAllKeyWord(),
      GetAllSourcePlatform(),
      GetDictionary({ typeName: "customerTag" }),
      GetDictionary({ typeName: "customerAccessType" }),
      GetDictionary({ typeName: "customerBusiness" }),
      GetDictionary({ typeName: "customerNature" }),

      GetAllUserList(),
      GetDictionary({ typeName: "customerUserType" }),
      GetChart(),
      GetDictionary({ typeName: "customerAssignType" }),
      getWorkFlowData({
        approvalTemplateID: APPROVAL_TEMPLATE_ID_MAP.intoOpenSea
      })
    ]

    if (!that.checkOperateDisable("客户趋势分析")) {
      if (!that.checkOperateDisable("获取客户增长趋势分析")) {
        promiseFuncArr.push(Api.GetCustomerGrowthTrendAnalysis())
      }
      if (!that.checkOperateDisable("获取客户领用增长趋势分析")) {
        promiseFuncArr.push(Api.GetCustomerAdoptionTrendAnalysis())
      }
    }

    const [
      allKeywordRes,
      allSourcePlatformRes,
      getAllCustomerTagRes,
      getAllCustomerAccessTypeRes,
      getAllCustomerBusinessRes,
      getAllCustomerNatureRes,

      getAllUserListRes,
      getCustomerUserTypeRes,
      getChartRes,
      getCustomerAssignTypeRes,
      approvalTemplateRes,
      customerGrowthTrendRes,
      customerAdoptionTrendRes
    ] = await Promise.all(promiseFuncArr)

    if (allKeywordRes.code === 0) {
      this.allKeywordMap = allKeywordRes.result
    }
    if (allSourcePlatformRes.code === 0) {
      this.allSourcePlatformMap = allSourcePlatformRes.result
    }

    if (getAllCustomerTagRes.code === 0) {
      this.allCustomerTagMap = getAllCustomerTagRes.result
    }

    if (getAllCustomerAccessTypeRes.code === 0) {
      this.allCustomerAccessTypeMap = getAllCustomerAccessTypeRes.result
    }

    if (getAllCustomerBusinessRes.code === 0) {
      this.allCustomerBusinessMap = getAllCustomerBusinessRes.result
    }

    if (getAllCustomerNatureRes.code === 0) {
      this.allCustomerNatureMap = getAllCustomerNatureRes.result
    }

    if (getAllUserListRes.code === 0) {
      this.allUserListMap = [
        { userID: 0, displayName: "暂不指定" },
        ...getAllUserListRes.result
      ]
    }

    if (getCustomerUserTypeRes.code === 0) {
      this.allCustomerUserTypeMap = [
        { key: 0, value: "无" },
        ...getCustomerUserTypeRes.result
      ]
    }

    if (getCustomerAssignTypeRes.code === 0) {
      this.allCustomerAssignTypeMap = getCustomerAssignTypeRes.result
    }

    if (approvalTemplateRes.code === 0) {
      const approvalData = approvalTemplateRes.result
      this.intoOpenSeaForm.approvalName = approvalData.workFlowDef.name
      const approvalPersonData = approvalData.nodeConfig.childNode
      this.receiveOpenSeaApproval(approvalPersonData)
    } else {
      this.$message.error(
        "未找到移入公海的审批流程，请联系技术人员!!! ღ( ´･ᴗ･` )比心"
      )
    }

    if (getChartRes.code === 0) {
      const dataMap = getChartRes.result
      this.drawChart(
        "chart1",
        dataMap.assignType.map(item => ({
          value: item.count,
          name: item.keyName
        }))
      )
      this.drawChart(
        "chart2",
        dataMap.tag.map(item => ({ value: item.count, name: item.keyName }))
      )
      this.drawChart(
        "chart3",
        dataMap.accessType.map(item => ({
          value: item.count,
          name: item.keyName
        }))
      )
      this.drawChart(
        "chart4",
        dataMap.sourcePlatform.map(item => ({
          value: item.count,
          name: item.keyName
        }))
      )
    }

    if (
      !that.checkOperateDisable("获取客户增长趋势分析") &&
      customerGrowthTrendRes &&
      customerGrowthTrendRes.result &&
      customerGrowthTrendRes.result.length > 0
    ) {
      that.drawColumnsAndTrendsChart("chart3_1", customerGrowthTrendRes.result)
    }
    if (
      !that.checkOperateDisable("获取客户领用增长趋势分析") &&
      customerAdoptionTrendRes &&
      customerAdoptionTrendRes.result &&
      customerAdoptionTrendRes.result.length > 0
    ) {
      that.drawColumnsAndTrendsChart(
        "chart3_2",
        customerAdoptionTrendRes.result
      )
    }
  },

  methods: {
    checkOperateDisable,
    checkMultiTypeOperateDisable,
    openChart() {
      this.chartsResize("chart3_1")
      this.chartsResize("chart3_2")
      this.chartsResize("chart1")
      this.chartsResize("chart2")
      this.chartsResize("chart3")
      this.chartsResize("chart4")
    },
    permissionController() {
      this.subordinateUserIDs = store.getters.subordinate
      // !this.checkOperateDisable("获取客户数据分析")
      //   ? (this.showCollapse = [1])
      //   : (this.showCollapse = [])
      // if (!this.checkOperateDisable("客户趋势分析")) {
      //   this.showCollapse.push(2)
      // }
    },
    findCustomerValueByKey(filed, list) {
      const target = list.find(item => Number(item.key) === filed)
      if (target && target.value) {
        return target.value
      } else {
        return "-"
      }
    },
    showTime() {
      return { defaultValue: dayjs("00:00:00", "HH:mm:ss") }
    },
    toDiyAdd() {
      this.$refs.customerInfo.toAddCustomer()
    },
    clickUser(record) {
      this.showCustomer(record, ACTIVITY_KEY_MAP.CONTACT)
    },
    filterTime: timeStr => fullTimeFilter(timeStr),
    showCustomer(record, activityKey = ACTIVITY_KEY_MAP.CUSTOMER) {
      this.$refs.customerInfo.toCustomerInfo(record.customerID, activityKey)
    },
    filterCategory: function(value, option) {
      return option.componentOptions.children[0].text.indexOf(value) >= 0
    },
    closeDrawer() {
      this.addOk = false
      this.addContactOk = false
      if (this.$refs.followUpForm) {
        this.$refs.followUpForm.clearValidate()
      }
      this.crud.refresh()
      this.crud.cancelCU()
    },
    cancelAssign() {
      this.assignForm.customerID = 0
      this.assignForm.show = false
      this.$forceUpdate()
    },
    cancelReceive() {
      this.receiveForm.show = false
      this.receiveForm.customerName = ""
      this.receiveForm.customerID = 0
      this.receiveForm.userType = 0
      this.$forceUpdate()
    },
    cancelIntoOpenSea() {
      this.intoOpenSeaForm.show = false
      this.intoOpenSeaForm.customerID = 0
      this.intoOpenSeaForm.customerName = ""
      this.crud.refresh()
      this.$forceUpdate()
    },
    toAssign(customerID) {
      this.assignForm.customerID = customerID
      this.assignForm.show = true
      this.$forceUpdate()
    },
    toReceive(customerName, customerID) {
      this.receiveForm.show = true
      this.receiveForm.customerName = customerName
      this.receiveForm.customerID = customerID
      this.$forceUpdate()
    },
    receiveOpenSeaApproval(data) {
      if (data.nodeUserList && data.nodeUserList.length > 0) {
        if (data.type === 1) {
          this.intoOpenSeaForm.approvalList.push(data.nodeUserList[0].name)
        } else if (data.type === 2) {
          this.intoOpenSeaForm.ccList.push(data.nodeUserList[0].name)
        }
      }
      if (data.childNode) {
        this.receiveOpenSeaApproval(data.childNode)
      }
    },
    async toIntoOpenSea(record) {
      this.intoOpenSeaForm.show = true
      this.intoOpenSeaForm.customerID = record.customerID
      this.intoOpenSeaForm.customerName = record.customerName
      this.$forceUpdate()
    },
    assignCustomer() {
      const that = this
      that.$refs["assignForm"].validate(async valid => {
        if (!valid) {
          return
        }
        if (!that.assignForm.customerID) {
          that.$message.error("请重新点击指派功能")
          return
        }
        if (!that.assignForm.userType) {
          that.$message.error("请选择业务人员类型")
          return
        }
        if (!that.assignForm.userID) {
          that.$message.error("请选择业务人员")
        }
        const assignRes = await Api.AssignCustomer({ ...that.assignForm })
        if (assignRes.code === 17) {
          that.$message.success("指派成功")
          that.cancelAssign()
          that.crud.refresh()
        }
      })
    },
    receiveCustomer() {
      const that = this
      that.$refs["receiveForm"].validate(async valid => {
        if (!valid) {
          return
        }
        if (!that.receiveForm.customerID) {
          that.$message.error("请重新点击指派功能")
          return
        }
        if (!that.receiveForm.userType) {
          that.$message.error("请选择业务人员类型")
          return
        }
        const receiveeRes = await Api.ReceiveCustomer({ ...that.receiveForm })
        if (receiveeRes.code === 18) {
          that.$message.success("领取成功")
          that.cancelReceive()
          that.crud.refresh()
        }
      })
    },
    async intoOpenSea() {
      const that = this
      that.$refs["intoOpenSeaForm"].validate(async valid => {
        if (!valid) {
          this.$message.error("请输入必填信息")
          return
        }
        const intoOpenSeaRes = await CustomerIntoOpenSea({
          customerID: this.intoOpenSeaForm.customerID,
          approvalTemplateID: APPROVAL_TEMPLATE_ID_MAP.intoOpenSea,
          content: this.intoOpenSeaForm.content
        })

        if (intoOpenSeaRes.code === 19) {
          this.$message.success("申请企业客户移入公海成功，等待审核")
        }

        this.cancelIntoOpenSea()
      })
    },
    drawChart: drawGeneralStatisticsChart,
    drawColumnsAndTrendsChart: drawColumnsAndTrendsChart,
    chartsResize: chartsResize,
    setRowHeight() {
      // 根据当前行的数据，返回一个样式类名
      return "small-row"
    },
    onChange(pagination, filters, sorter) {
      if (sorter && sorter.columnKey == "followUpTime")
        this.crud.query.orderKey = "cfr.insert_time"
      else if (sorter && sorter.columnKey == "insertTime")
        this.crud.query.orderKey = "cu.insert_time"

      if (sorter && sorter.order == "ascend") this.crud.query.orderType = "asc"
      else if (sorter && sorter.order == "descend")
        this.crud.query.orderType = "desc"
      else delete this.crud.query.orderType
      if (this.crud.query.orderType) this.crud.toQuery()
    },
    process(record) {
      console.log(record)
      let that = this
      // 显示弹窗提示是否审核通过，如果审核通过，调用审核接口，否则关闭弹窗
      this.$confirm({
        title: "审核",
        content: "是否审核通过审核",
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        async onOk() {
          const intoOpenSeaRes = await CustomerIntoOpenSea({
            customerID: record.customerID,
            // approvalTemplateID: APPROVAL_TEMPLATE_ID_MAP.intoOpenSea,
            // content: this.intoOpenSeaForm.content,
            assignType: 3
          })

          if (intoOpenSeaRes.code === 19) {
            that.$message.success("企业客户移入公海成功")
          }
          // 调用审核接口
          that.crud.refresh()
        },
        onCancel() {}
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.ant-table-striped /deep/ .table-striped td {
  background-color: #fcfcfc;
}

.my-collapse {
  margin-bottom: 20px;

  .el-collapse-item {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 5px;

    /deep/ .el-collapse-item__content {
      padding-bottom: 0;
    }

    /deep/ .el-collapse-item__header {
      padding-left: 20px;
    }

    .item-content {
      background-color: #f0f2f5;
      padding: 10px;
    }
  }

  .header-chart {
    width: 100%;
    margin-top: 10px;

    .card11 {
      float: left;
      border: none;
      border-radius: 10px;
      box-shadow: 4px 4px 8px 0px rgba(201, 201, 201, 0.5);
      width: 24%;
      height: 430px;
    }

    .card22 {
      float: left;
      border: none;
      border-radius: 10px;
      box-shadow: 4px 4px 8px 0px rgba(201, 201, 201, 0.5);
      margin-bottom: 10px;
      margin-left: 1%;
      width: 24%;
      height: 430px;
    }
  }

  .card31 {
    display: inline-block;
    border: none;
    border-radius: 10px;
    box-shadow: 4px 4px 8px 0px rgba(201, 201, 201, 0.5);
    margin-bottom: 10px;
    width: 49%;
    height: 380px;
  }

  .card32 {
    display: inline-block;
    border: none;
    border-radius: 10px;
    box-shadow: 4px 4px 8px 0px rgba(201, 201, 201, 0.5);
    margin-bottom: 10px;
    margin-left: 1%;
    width: 49%;
    height: 380px;
  }
}

.title-box {
  display: flex;
  align-items: center;

  span {
    white-space: nowrap;
    padding-right: 10px;
  }
}
</style>

<template>
  <div>
    <a-card :body-style="{ padding: '0px' }" :bordered="false" class="card">
      <div
        style="display: flex;justify-content: flex-end;align-items: center;margin-bottom: 5px"
      >
        <a-button key="submit" type="primary" @click="AddBtn"
          >新增附件</a-button
        >
      </div>
      <div v-if="attachmentInfo.length > 0">
        <a-tabs size="small" type="card">
          <template v-for="item in attachmentType">
            <a-tab-pane
              v-if="
                attachmentInfo.find(
                  subItem => subItem[0].attachmentType === Number(item.key)
                ) &&
                  attachmentInfo.find(
                    subItem => subItem[0].attachmentType === Number(item.key)
                  ).length > 0
              "
              :key="item.key"
              :tab="item.value"
            >
              <template
                v-if="
                  attachmentInfo
                    .find(
                      subItem => subItem[0].attachmentType === Number(item.key)
                    )
                    .filter(groupItem => groupItem.projectID > 0).length > 0
                "
              >
                <AttachmentTabPaneItem
                  :attachmentInfo="
                    arrGroup(
                      attachmentInfo.find(
                        subItem =>
                          subItem[0].attachmentType === Number(item.key)
                      ),
                      item2 => item2.projectID
                    )
                  "
                ></AttachmentTabPaneItem>
              </template>

              <template
                v-else-if="
                  attachmentInfo
                    .find(
                      subItem => subItem[0].attachmentType === Number(item.key)
                    )
                    .filter(groupItem => groupItem.contractID > 0).length > 0
                "
              >
                <AttachmentTabPaneItem
                  :attachmentInfo="
                    arrGroup(
                      attachmentInfo.find(
                        subItem =>
                          subItem[0].attachmentType === Number(item.key)
                      ),
                      item2 => item2.contractID
                    )
                  "
                ></AttachmentTabPaneItem>
              </template>

              <template v-else>
                <AttachmentTabPaneItem
                  :attachmentInfo="
                    arrGroup(
                      attachmentInfo.find(
                        subItem =>
                          subItem[0].attachmentType === Number(item.key)
                      ),
                      item2 => item2.customerID
                    )
                  "
                ></AttachmentTabPaneItem>
              </template>
            </a-tab-pane>
          </template>
        </a-tabs>
      </div>
      <div v-else>
        <AttachmentTabPaneItem :attachmentInfo="[]"></AttachmentTabPaneItem>
      </div>
      <a-modal
        :visible="visible"
        title="新增附件"
        width="60%"
        @cancel="handleCancel"
      >
        <a-form-model
          ref="ruleForm"
          :model="attachmentAdd"
          :rules="rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <a-row>
            <a-col :span="12">
              <a-form-model-item label="关联合同" prop="contractID">
                <a-select
                  v-model="attachmentAdd.contractID"
                  placeholder="请选择关联合同"
                >
                  <a-select-option
                    v-for="(item, idx) in contractList"
                    :key="idx"
                    :value="item"
                  >
                    {{ item }}
                  </a-select-option>
                  <a-select-option :value="0">
                    暂无数据
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="附件类型" prop="attachmentType">
                <a-select
                  v-model.number="attachmentAdd.attachmentType"
                  placeholder="请选择附件类型"
                >
                  <a-select-option
                    v-for="item in attachmentType"
                    :key="item.key"
                    :value="item.key"
                  >
                    {{ item.value }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="12">
              <a-form-model-item label="关联产品" prop="projectID">
                <a-select
                  v-model="attachmentAdd.projectID"
                  placeholder="请选择关联产品"
                >
                  <a-select-option
                    v-for="(item, idx) in projectList"
                    :key="idx"
                  >
                    {{ item }}
                  </a-select-option>
                  <a-select-option :value="0">
                    暂无数据
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item
                ref="customerID"
                label="客户ID"
                prop="customerID"
              >
                <a-input
                  disabled
                  v-model.number="attachmentAdd.customerID"
                  @blur="
                    () => {
                      $refs.customerID.onFieldBlur()
                    }
                  "
                />
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="24">
              <a-form-model-item
                ref="attachment"
                label="附件"
                prop="attachment"
                :labelCol="{ span: 4 }"
                :wrapperCol="{ span: 17 }"
              >
                <SingleUploadFile
                  fileUploadPath="file-upload/attachment"
                  fileRemovedPath="file-upload/attachment"
                  allFileCancelPath="file-upload/attachment/cancel"
                  ref="singleUploadFile"
                ></SingleUploadFile>
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
        <template slot="footer">
          <div>
            <a-button key="back" @click="handleCancel">
              取消
            </a-button>
            <a-button key="submit" type="primary" @click="handleOk">
              提交
            </a-button>
          </div>
        </template>
      </a-modal>
    </a-card>
  </div>
</template>

<script>
import { postAttachmentInfo } from "@/api/customer/attachment"
import { get as getProjectList } from "@/api/customer/project"
import { get } from "@/api/customer/contract"
import { getAttachmentListByCustomerId } from "@/api/customer/attachment"
import AttachmentTabPaneItem from "@/components/customerInfo/attchment-tab-pane-Item.vue"
import { GetDictionary } from "@/api/public-api"
import { arrGroup } from "@/utils/utils"
import SingleUploadFile from "@/components/SingleUploadFile/index.vue"
export default {
  name: "Attachment",
  props: {
    customerID: {
      type: Number
    }
  },
  data() {
    return {
      projectList: [],
      contractList: [],
      visible: false,
      headers: {
        authorization: "authorization-text"
      },
      attachmentType: [],
      attachmentInfo: [],
      attachmentAdd: {},
      labelCol: { span: 8 },
      wrapperCol: { span: 10 },
      rules: {
        attachmentType: [
          { required: true, message: "请选择附件类型", trigger: "blur" }
        ],
        contractID: [
          { required: true, message: "请选择关联ID", trigger: "blur" }
        ],
        projectID: [
          { required: true, message: "请选择关联产品", trigger: "blur" }
        ]
      }
    }
  },
  async created() {
    // this.getAttachmentList()
    const resultObj = await GetDictionary({ typeName: "attachmentType" })
    this.attachmentType = resultObj.result
    this.attachmentType.forEach(item => {
      item.key = Number(item.key)
    })
  },
  components: {
    AttachmentTabPaneItem,
    SingleUploadFile
  },
  computed: {},
  methods: {
    async getAttachmentList() {
      const attachmentObj = await getAttachmentListByCustomerId({
        customerID: this.customerID
      })
      this.attachmentInfo = arrGroup(
        attachmentObj.result,
        item => item.attachmentType
      )
    },
    arrGroup,
    AddBtn() {
      this.visible = true
      this.attachmentAdd.customerID = this.customerID
      const contractObj = get({ customerID: this.customerID })
      const projectObj = getProjectList({ customerID: this.customerID })
      this.contractList = contractObj.result
      this.projectList = projectObj.result
    },
    handleCancel() {
      this.$refs.ruleForm.clearValidate()
      this.visible = false
    },
    handleOk() {
      this.$refs.ruleForm.validate(async valid => {
        if (valid) {
          this.attachmentAdd.filePath = this.$refs.singleUploadFile.fileList[0].filePathUrl
          this.attachmentAdd.fileName = this.$refs.singleUploadFile.fileList[0].fileName
          console.log(
            this.attachmentAdd,
            1,
            this.$refs.singleUploadFile.fileList
          )
          const addAttachObj = await postAttachmentInfo(this.attachmentAdd)
          if (addAttachObj.code === 12) {
            this.$message.success("新增成功")
            this.getAttachmentList()
            this.visible = false
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>

<template>
  <div class="form-container">
    <a-drawer
      :title="getDrawerTitle()"
      :visible="operateTypeAccount !== ''"
      :width="800"
      class="account-modal"
      placement="right"
      @close="closeDrawer"
    >
      <a-tabs
        :activeKey="activeKey"
        type="card"
        @change="
          key => {
            activeKey = key
          }
        "
      >
        <a-tab-pane :key="1" tab="基础信息">
          <AccountPane
            ref="accountPane"
            @change-role-id="changeRoleID"
            @submit-success-after="submitSuccessAfter"
          ></AccountPane>
        </a-tab-pane>
        <a-tab-pane :key="2" tab="组织架构关系">
          <RolePane
            ref="rolePane"
            @submit-role-success-after="submitRoleSuccessAfter"
          ></RolePane>
        </a-tab-pane>
      </a-tabs>
    </a-drawer>
  </div>
</template>

<script>
import { fullTimeFilter } from "@/utils/utils"
import AccountPane from "@/components/account/account-pane"
import RolePane from "@/components/account/role-pane"
import { BASE_OPERATE_TYPE } from "@/config"

export default {
  name: "AccountInfo",
  components: { AccountPane, RolePane },
  data() {
    return {
      userID: 0,
      roleID: 0,
      roleIDs: [],
      activeKey: 0,
      operateTypeAccount: "",
      operateTypeRole: "",
      BASE_OPERATE_TYPE
    }
  },
  created() {},
  watch: {
    activeKey(newVal) {
      const that = this
      switch (newVal) {
        case 1:
          that.$nextTick(() => {
            this.$refs["accountPane"].showData(
              this.userID,
              this.operateTypeAccount
            )
          })
          break
        case 2:
          that.$nextTick(() => {
            that.$refs["rolePane"].showData(
              that.userID,
              that.roleID,
              that.operateTypeRole
            )
          })
          break
      }
    }
  },
  methods: {
    fullTimeFilter: fullTimeFilter,
    getDrawerTitle() {
      const that = this
      const titleMap = Object.freeze({
        [BASE_OPERATE_TYPE.INFO]: "账号详情",
        [BASE_OPERATE_TYPE.ADD]: "新增账号",
        [BASE_OPERATE_TYPE.EDIT]: "编辑账号"
      })
      return titleMap[that.operateTypeAccount]
    },
    showData(userID, operateType) {
      const that = this
      that.operateTypeAccount = operateType
      that.operateTypeRole = operateType
      that.userID = userID
      that.activeKey = 1
      that.$nextTick(() => {
        that.$refs["accountPane"].showData(that.userID, that.operateTypeAccount)
      })
    },
    closeDrawer: async function() {
      this.operateTypeAccount = ""
      this.operateTypeRole = ""
      this.activeKey = 0
      this.userID = 0
      this.roleID = 0
      this.$refs["accountPane"].closeDrawer()
    },
    submitSuccessAfter(userID, roleID) {
      const that = this
      that.userID = userID
      that.roleID = roleID
      that.operateTypeAccount = BASE_OPERATE_TYPE.INFO
      this.$emit("add-success-after")
    },
    submitRoleSuccessAfter() {
      this.operateTypeRole = BASE_OPERATE_TYPE.INFO
    },
    changeRoleID(roleID) {
      const that = this
      that.roleID = roleID
    }
  }
}
</script>

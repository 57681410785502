export const LogListColumns = [
  {
    title: "操作日期",
    width: 70,
    align: "center",
    ellipsis: true,
    resizable: true,
    // dataIndex: "value",
    scopedSlots: { customRender: "operateTime" },
    key: "operateTime"
  },
  {
    title: "操作模块",
    width: 50,
    align: "center",
    ellipsis: true,
    resizable: true,
    dataIndex: "domainName",
    key: "domainName"
  },
  {
    title: "功能模块",
    width: 60,
    align: "center",
    ellipsis: true,
    resizable: true,
    dataIndex: "functionName",
    key: "functionName"
  },
  {
    title: "操作类型",
    width: 40,
    align: "center",
    ellipsis: true,
    resizable: true,
    dataIndex: "operateType",
    key: "operateType"
  },
  {
    title: "操作人账号",
    width: 60,
    align: "center",
    ellipsis: true,
    resizable: true,
    dataIndex: "userName",
    key: "userName"
  },
  {
    title: "操作人姓名",
    width: 60,
    align: "center",
    ellipsis: true,
    resizable: true,
    dataIndex: "displayName",
    key: "displayName"
  },
  {
    title: "操作内容",
    width: 300,
    ellipsis: true,
    align: "center",
    resizable: true,
    // scopedSlots: { customRender: "insertTime" },
    dataIndex: "content",
    key: "content"
  },
  {
    title: "旧数据",
    width: 300,
    ellipsis: true,
    align: "center",
    resizable: true,
    // scopedSlots: { customRender: "insertTime" },
    dataIndex: "oldContent",
    key: "oldContent"
  }
]

export const followUpListColumns = [
  {
    title: "公司名称",
    width: 220,
    align: "center",
    ellipsis: true,
    resizable: true,
    key: "customerName",
    scopedSlots: { customRender: "customerName" }
  },
  {
    title: "联系人",
    width: 100,
    align: "center",
    ellipsis: true,
    resizable: true,
    dataIndex: "contactName",
    // scopedSlots: { customRender: "assignType" },
    key: "contactName"
  },
  {
    title: "联系电话",
    width: 150,
    align: "center",
    ellipsis: true,
    resizable: true,
    dataIndex: "contactTel",
    // scopedSlots: { customRender: "tag" },
    key: "contactTel"
  },
  {
    title: "跟进人",
    width: 100,
    align: "center",
    ellipsis: true,
    resizable: true,
    dataIndex: "creatorName",
    // scopedSlots: { customRender: "userName" },
    key: "creatorName"
  },
  {
    title: "跟进内容",
    width: 400,
    // ellipsis: true,
    resizable: true,
    align: "center",
    dataIndex: "note",
    key: "note"
  },
  {
    title: "客户状态改变",
    width: 120,
    align: "center",
    ellipsis: true,
    resizable: true,
    dataIndex: "changeTagName",
    // scopedSlots: { customRender: "changeTag" },
    key: "changeTagName"
  },
  {
    title: "跟进前状态",
    width: 120,
    align: "center",
    ellipsis: true,
    resizable: true,
    dataIndex: "oldTagName",
    // scopedSlots: { customRender: "changeTag" },
    key: "oldTagName"
  },
  {
    title: "跟进后状态",
    width: 120,
    align: "center",
    ellipsis: true,
    resizable: true,
    dataIndex: "newTagName",
    key: "newTagName"
  },
  {
    title: "存在附件",
    width: 100,
    align: "center",
    ellipsis: true,
    resizable: true,
    dataIndex: "hasUploadFileName",
    // scopedSlots: { customRender: "hasUploadFile" },
    key: "hasUploadFileName"
  },
  {
    title: "跟进日期",
    width: 180,
    ellipsis: true,
    align: "center",
    resizable: true,
    scopedSlots: { customRender: "insertTime" },
    key: "insertTime"
  },
  {
    title: "回访日期",
    width: 180,
    ellipsis: true,
    resizable: true,
    align: "center",
    scopedSlots: { customRender: "returnVisitTime" },
    key: "returnVisitTime"
  },
  {
    title:"操作",
    width: 200,
    fixed: "right",
    align: "center",
    key:"operation",
    scopedSlots: { customRender: "operation" },
  }
]

export const followUpCustomerListColumns = [
  {
    title: "公司名称",
    width: 280,
    align: "center",
    ellipsis: true,
    resizable: true,
    // dataIndex: "customerName",
    key: "customerName",
    scopedSlots: { customRender: "customerName" }
  },
  {
    title: "指派状态",
    width: 120,
    align: "center",
    ellipsis: true,
    resizable: true,
    scopedSlots: { customRender: "assignType" },
    key: "assignType"
  },
  {
    title: "客户状态",
    width: 150,
    align: "center",
    ellipsis: true,
    resizable: true,
    scopedSlots: { customRender: "tag" },
    key: "tag"
  },
  {
    title: "跟进次数",
    width: 150,
    align: "center",
    ellipsis: true,
    resizable: true,
    dataIndex: "followUpNum",
    key: "followUpNum"
  },
  {
    title: "上次跟进时间",
    width: 150,
    align: "center",
    ellipsis: true,
    resizable: true,
    scopedSlots: { customRender: "followUpTime" },
    key: "followUpTime"
  },
  {
    title: "创建时间",
    width: 150,
    align: "center",
    ellipsis: true,
    resizable: true,
    scopedSlots: { customRender: "insertTime" },
    key: "insertTime"
  }
]

<template>
  <ul class="select-box">
    <template v-for="elem in list">
      <template v-if="elem.type === 'department'">
        <li
          v-for="item in elem.data"
          :key="item.id"
          :class="{ not: !elem.isDepartment }"
          class="check_box"
        >
          <a :class="elem.isActive(item) && 'active'">
            <img src="@/assets/image/icon_file.png" />{{
              item.departmentName
            }}</a
          >
          <img
            src="@/assets/image/next_level_active.png"
            style="float: right"
            @click="elem.next(item.id)"
          />
        </li>
      </template>
      <template v-if="elem.type === 'employee'">
        <li v-for="item in elem.data" :key="item.id" class="check_box">
          <a
            :class="elem.isActive(item) && 'active'"
            :title="item.departmentNames"
            @click="elem.change(item)"
          >
            <img src="@/assets/image/icon_people.png" />{{ item.employeeName }}
          </a>
        </li>
      </template>
    </template>
  </ul>
</template>
<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => []
    },
    currentRoleID: {
      type: Number,
      default: () => 0
    }
  }
}
</script>
<style lang="less" scoped>
.select-box {
  height: 420px;
  overflow-y: auto;

  li {
    padding: 5px 0;

    i {
      float: right;
      padding-left: 24px;
      padding-right: 10px;
      color: #3195f8;
      font-size: 12px;
      cursor: pointer;
      border-left: 1px solid rgb(238, 238, 238);
    }

    a.active + i {
      color: rgb(197, 197, 197);
      background-image: url(~@/assets/image/next_level.png);
      pointer-events: none;
    }

    img {
      width: 14px;
      vertical-align: middle;
      margin-right: 5px;
    }
  }
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-line"},[_c('a-card',{staticStyle:{"width":"49%","height":"480px","margin-bottom":"10px","margin-right":"1%","float":"left","border":"none","border-radius":"10px","box-shadow":"4px 4px 8px 0px rgba(201, 201, 201, 0.5)"},attrs:{"body-style":{ padding: '0px' },"bordered":false}},[_c('div',{staticClass:"table-operations"},[_c('span',{staticStyle:{"font-size":"18px","margin-right":"20px","margin-left":"10px"}},[_vm._v(" 公海审批抄送 ")]),_c('a-button',{attrs:{"icon":"redo","type":"link"},on:{"click":_vm.getUserIntoOpenSeaCopyRecordList}})],1),_c('a-table',{staticStyle:{"width":"99%","height":"99%","margin":"0 auto"},attrs:{"columns":_vm.IntoOpenSeaCopyInColumns,"dataSource":_vm.allIntoOpenSeaCopyInMap,"pagination":false,"scroll":{
        y: 340,
        x: 600,
        scrollToFirstRowOnChange: true
      },"rowKey":"sourcePlatformID"},scopedSlots:_vm._u([{key:"customerName",fn:function(record){return [(record.customerID)?_c('a',{on:{"click":function($event){return _vm.toShowCustomer(record)}}},[_vm._v(_vm._s(record.customerName))]):_c('span',[_vm._v("-")])]}},{key:"insertTime",fn:function(record){return [_vm._v(" "+_vm._s(_vm.filterTime(record.insertTime))+" ")]}},{key:"status",fn:function(text, record){return [_vm._v(" "+_vm._s(_vm.approvalStatusMap.find(function (item) { return Number(item.key) === record.status; }) ? _vm.approvalStatusMap.find( function (item) { return Number(item.key) === record.status; } ).value : "-")+" ")]}},{key:"operation",fn:function(text, record){return [_c('a-button',{attrs:{"size":"small","type":"link"},on:{"click":function($event){return _vm.toInfo(record, false)}}},[_vm._v(" 详情 ")])]}}])})],1),_c('a-card',{staticStyle:{"width":"49%","height":"480px","margin-bottom":"10px","float":"left","border":"none","border-radius":"10px","box-shadow":"4px 4px 8px 0px rgba(201, 201, 201, 0.5)"},attrs:{"body-style":{ padding: '0px' },"bordered":false}},[_c('div',{staticClass:"table-operations"},[_c('span',{staticStyle:{"font-size":"18px","margin-right":"20px","margin-left":"10px"}},[_vm._v(" 移入公海审批 ")]),_c('a-button',{attrs:{"icon":"redo","type":"link"},on:{"click":_vm.getUserIntoOpenSeaApproval}})],1),_c('a-table',{staticStyle:{"width":"99%","height":"99%","margin":"0 auto"},attrs:{"columns":_vm.IntoOpenSeaApprovalColumns,"dataSource":_vm.allIntoOpenSeaApprovalMap,"pagination":false,"scroll":{
        y: 340,
        x: 600
      },"rowKey":"conversationID"},scopedSlots:_vm._u([{key:"customerName",fn:function(record){return [_c('a',{on:{"click":function($event){return _vm.toShowCustomer(record)}}},[_vm._v(_vm._s(record.customerName))])]}},{key:"status",fn:function(text, record){return [_vm._v(" "+_vm._s(_vm.approvalStatusMap.find(function (item) { return Number(item.key) === record.status; }) ? _vm.approvalStatusMap.find( function (item) { return Number(item.key) === record.status; } ).value : "-")+" ")]}},{key:"insertTime",fn:function(record){return [_vm._v(" "+_vm._s(_vm.filterTime(record.insertTime))+" ")]}},{key:"operation",fn:function(text, record){return [_c('a-button',{attrs:{"size":"small","type":"link"},on:{"click":function($event){return _vm.toInfo(record, true)}}},[_vm._v(" 详情 ")])]}}])})],1),_c('CustomerInfo',{ref:"customerInfo",attrs:{"addContract":false}}),_c('IntoOpenSeaModal',{ref:"intoOpenSeaModal",attrs:{"addContract":false},on:{"pass-approval-finish":_vm.passApprovalFinish}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
export const dictionaryListColumns = [
  {
    title: "字典值",
    // width: 250,
    align: "center",
    dataIndex: "value"
  },
  {
    title: "字典Key",
    // width: 150,
    align: "center",
    dataIndex: "key"
  },
  {
    title: "字典类型名",
    width: 300,
    // width: 350,
    align: "center",
    dataIndex: "typeName"
  },
  {
    title: "字典类型描述",
    width: 300,
    align: "center",
    dataIndex: "description"
  },
  {
    title: "创建日期",
    width: 250,
    align: "center",
    // dataIndex: "insertTime"
    scopedSlots: { customRender: "insertTime" }
  },
  {
    title: "近期修改日期",
    width: 250,
    align: "center",
    // dataIndex: "updateTime"
    scopedSlots: { customRender: "updateTime" }
  },
  {
    title: "操作",
    fixed: "right",
    align: "center",
    scopedSlots: { customRender: "operation" }
  }
]

import { method } from "@/utils/request"

const api = {
  Get: "contract-receipt/list/customer-id",
  ContractIdToGet: "contract-receipt/list/contract-id",
  Add: "contract-receipt",
  Put:'contract-receipt',
  Del:"contract-receipt",
  GetType:"contract-receipt/static/trend",
  getTypeCollect:"contract-receipt/static/type-rate"
}

const url = process.env.VUE_APP_API_URL
export function GetContractReceiptByCustomerID(parameter) {
  return method(url)({
    url: api.Get,
    method: "get",
    data: { ...parameter }
  })
}
export function GetReceiptByContractId(parameter) {
  return method(url)({
    url: api.ContractIdToGet,
    method: "get",
    data: { ...parameter }
  })
}

//根据合同id增加收款单
export function add(parameter) {
  return method(url)({
    url: api.Add,
    method: "post",
    data: {
      ...parameter
    }
  })
}

export function delReceipt(parameter){
  return method(url)({
    url: api.Del,
    method: "delete",
    data: { ...parameter }
  })
}
export function editReceipt(parameter){
  return method(url)({
    url:api.Put,
    method:"put",
    data:{...parameter}
  })
}

export function getType(parameter){
  return method(url)({
    url:api.GetType,
    method:"get",
    data:{...parameter}
  })
}

export function getTypeCollect(parameter){
  return method(url)({
    url:api.getTypeCollect,
    method:"get",
    data:{...parameter}
  })
}
<template>
  <div :countEnterNum="countEnterNum" class="centerContent">
    <div class="chartNum">
      <div class="box-item">
        <li
            v-for="(item, index) in orderNum"
            :key="index"
            :class="{ 'number-item': !isNaN(item), 'mark-item': isNaN(item) }"
        >
          <span v-if="!isNaN(item)">
            <i ref="numberItem">0123456789</i>
          </span>
          <span v-else class="comma">{{ item }}</span>
        </li>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    countEnterNum: {
      type: Number,
      default: () => {
        return 0
      }
    }
  },
  data: function () {
    return {
      orderNum: ["0", "0", "0", ",", "0", "0", "0"] // 默认
    }
  },
  components: {},
  created() {
  },
  mounted() {
    this.getCountEnterNum()
  },
  watch: {
    countEnterNum() {
      this.getCountEnterNum()
    }
  },
  methods: {
    getCountEnterNum() {
      this.$nextTick(() => {
        this.toOrderNum(this.countEnterNum) // 这里输入数字即可调用
        this.setNumberTransform()
      })
    },
    // 设置文字滚动
    setNumberTransform() {
      const numberItems = this.$refs.numberItem // 拿到数字的ref，计算元素数量
      const numberArr = this.orderNum.filter(item => !isNaN(item))
      // 结合CSS 对数字字符进行滚动,显示订单数量
      for (let index = 0; index < numberItems.length; index++) {
        const elem = numberItems[index]
        elem.style.transform = `translate(-50%, -${numberArr[index] * 10}%)`
      }
    },
    // 处理总数字
    toOrderNum(num) {
      num = num.toString()
      // 把数变成字符串
      if (num.length < 6) {
        num = "0" + num // 如未满6位数，添加"0"补位
        this.toOrderNum(num) // 递归添加"0"补位
      } else if (num.length === 6) {
        // 订单数中加入逗号
        num = num.slice(0, 3) + "," + num.slice(3, 6)
        this.orderNum = num.split("") // 将其便变成数据，渲染至滚动数组
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.centerContent {
  margin: 0 0px;

  .cont {
    margin: 0 0;
  }
}

.box-item {
  position: relative;
  height: 60px;
  font-size: 30px;
  line-height: 41px;
  list-style: none;
  color: #2d7cff;
  writing-mode: vertical-lr;
  text-orientation: upright;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -khtml-user-select: none;
  user-select: none;
  margin: 0 auto;
}

.mark-item {
  width: 10px;
  height: 100px;
  margin-right: 5px;
  line-height: 10px;
  font-size: 48px;
  position: relative;

  & > span {
    position: absolute;
    width: 100%;
    bottom: 30px;
    writing-mode: vertical-rl;
    text-orientation: upright;
  }
}

.number-item {
  width: 35px;
  height: 46px;
  font-size: 60px;
  list-style: none;
  margin-right: 10px;
  border-radius: 4px;
  border: 1px solid #ddd;
  color: #0062ff;
  font-size: 35px;
  border: 1px solid #ddd;

  & > span {
    position: relative;
    display: inline-block;
    margin-right: 10px;
    width: 100%;
    height: 150%;
    writing-mode: vertical-rl;
    text-orientation: upright;
    overflow: hidden;

    & > i {
      font-style: normal;
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
      transition: transform 1s ease-in-out;
      letter-spacing: 10px;
    }
  }
}

.unit {
  width: 100px;
  color: red;
}

.number-item:last-child {
  margin-right: 0;
}

.comma {
  color: #0baaaa;
}
</style>

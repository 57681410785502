import request from "@/utils/request"

const menuApi = {
  GetRoleFunctions: "role/functions",
  GetRoleFunctionsByRoleIDs: "role/functions/role-ids",
  GetUserFunctions: "user/functions",
  ListRole: "role/all-list",
  GetSubordinate: "user/subordinate"
}

/**
 * getrole func
 * requirerole_id 填写的话是角色的权限列表 没填写是全部的权限列表
 * @param parameter
 * @returns {*}
 */
export function GetUserFunctions() {
  return request({
    url: menuApi.GetUserFunctions,
    method: "get"
  })
}

export function GetUserFunctionByUserID({ userID }) {
  if (!userID) {
    return false
  }
  return request({
    url: menuApi.GetUserFunctions,
    method: "get",
    data: { userID }
  })
}

export function GetRoleFunctions(data) {
  return request({
    url: menuApi.GetRoleFunctions,
    method: "get",
    data
  })
}

export function GetRoleFunctionsByRoleIDs(data) {
  return request({
    url: menuApi.GetRoleFunctionsByRoleIDs,
    method: "get",
    data
  })
}

export function listRole() {
  return request({
    url: menuApi.ListRole,
    method: "get"
  })
}

export function GetSubordinate() {
  return request({
    url: menuApi.GetSubordinate,
    method: "get"
  })
}

export default {
  GetUserFunctions,
  GetUserFunctionByUserID,
  GetRoleFunctions,
  GetRoleFunctionsByRoleIDs,
  listRole,
  GetSubordinate
}

var render, staticRenderFns
var script = {}


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./temp.vue?vue&type=custom&index=0&blockType=a-drawer&%3Atitle=crud.status.title&%3Avisible=crud.status.cu%20%3E%200%20%7C%7C%20crud.status.in%20%3E%200&%3Awidth=1200&placement=right&%40close=closeDrawer"
if (typeof block0 === 'function') block0(component)

export default component.exports
import { method } from "@/utils/request"

const api = {
  GetContractByCustomer: "contract/customer",
  GetContractAttachment: "contract/attachment",
  getCustomerListByContractUserID: "contract/customer/user-id",
  Add: "contract",
  Get: "contract/list",
  Del: "contract",
  Edit: "contract",
  Analysis: "contract/analysis",
  AnalysisTrend: "contract/trend/analysis",
  GetAllUserSignAnalysis: "contract/user/sign/analysis",
  AnalysisConnectRate: "contract/business-connect-type/analysis",
  AnalysisCooperationRate: "contract/business-cooperation-type/analysis",
  GetContractDetail:"contract",
}

const url = process.env.VUE_APP_API_URL

export function GetContractAttachment(parameter) {
  return method(url)({
    url: api.GetContractAttachment,
    method: "get",
    data: {
      ...parameter
    }
  })
}

/**
 * @param parameter
 * @returns {*}
 */
export function GetContractByCustomer(parameter) {
  return method(url)({
    url: api.GetContractByCustomer,
    method: "get",
    data: {
      ...parameter
    }
  })
}

export function add(parameter) {
  return method(url)({
    url: api.Add,
    method: "post",
    data: {
      ...parameter
    }
  })
}

/**
 * @param {*} parameter
 */
export function edit(parameter) {
  return method(url)({
    url: api.Edit,
    method: "put",
    data: parameter
  })
}

/**
 * @param {*} parameter
 */
export function get(parameter) {
  parameter.type = parameter.type ? Number(parameter.type) : 0
  return method(url)({
    url: api.Get,
    method: "post",
    data: parameter
  })
}

export function analysis(parameter) {
  return method(url)({
    url: api.Analysis,
    method: "get",
    data: parameter
  })
}

export function analysisTrend(parameter) {
  return method(url)({
    url: api.AnalysisTrend,
    method: "get",
    data: parameter
  })
}

export function AnalysisConnectRate(parameter) {
  return method(url)({
    url: api.AnalysisConnectRate,
    method: "get",
    data: parameter
  })
}

export function AnalysisCooperationRate(parameter) {
  return method(url)({
    url: api.AnalysisCooperationRate,
    method: "get",
    data: parameter
  })
}

export function getCustomerListByContractUserID(parameter) {
  return method(url)({
    url: api.getCustomerListByContractUserID,
    method: "get",
    data: parameter
  })
}

export function GetAllUserSignAnalysis(parameter) {
  return method(url)({
    url: api.GetAllUserSignAnalysis,
    method: "post",
    data: parameter
  })
}

export default {
  GetContractByCustomer,
  GetContractAttachment,
  getCustomerListByContractUserID,
  add,
  edit,
  get,
  analysis,
  analysisTrend,
  GetAllUserSignAnalysis,
  AnalysisConnectRate,
  AnalysisCooperationRate
}

export function getGetContractDetail(parameter){
  return method(url)({
    url: api.GetContractDetail,
    method: "get",
    data: parameter
  })
}
